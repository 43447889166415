import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import up from '../../../Assets/Images/up.png';
import down from '../../../Assets/Images/down.png';
import filter from '../../../Assets/Images/filter.png';
import up_black from '../../../Assets/Images/up_black.png';
import down_black from '../../../Assets/Images/down_black.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from '../Discovered/Navbar';
import { IComment, IDBChapter, IOeuvre } from '../../../Interfaces/Discover';
import { BookCommentRequest, getBookComments, getChapter, getDiscoverd, getMangaInfoByID } from '../../../Api/Oeuvres';
import { SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getUser } from '../../../Api/User';
import { GetTranslatorTeamMembers, TeamRequest, TeamRequestInfo } from '../../../Api/Team';

const Traduction: React.FC = () => {
    const {user, setmyteam, myteam} = useContext(MainContext);
    const [oeuvre, setOeuvre] = useState<IOeuvre | undefined>(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        const t = sessionStorage.getItem('token');
        if (t === null)
            navigate('/login');

        if (user)
            TeamRequest(user.id).then((t) => {
                if (t.status === 200 && t.data.team_ID !== null) {
                    TeamRequestInfo(t.data.team.team_ID).then((team) => {
                        if (team.data.team_id)
                            team.data.team_ID = team.data.team_id;

                        GetTranslatorTeamMembers(team.data.team_ID).then((res) => {
                            team.data.members = res;
                            setmyteam(team.data);
                            if (team.data.team_ID !== undefined) {
                                navigate('../my-team');
                                return;
                            }
                        });
                    });
                } else {
                    const url = new URL(window.location.href);
                    if (url.pathname.endsWith("traduction") || url.pathname.endsWith("traduction/"))
                        navigate('/traduction/create-team');
                }
            });
    }, [user]);

    useEffect(() => {
        const t = sessionStorage.getItem('token');
        if (t === null)
            navigate('/login');

        if (user)
            TeamRequest(user.id).then((t) => {
                if (t.status === 200) {
                    if (t.data.team.team_ID !== null && t.data.team.team_ID !== undefined) {
                        TeamRequestInfo(t.data.team.team_ID).then((team) => {
                            setmyteam(team.data);
                            if (team.data.team_ID !== undefined && team.data.team_ID !== null) {
                                navigate('../my-team');
                                return;
                            }
                        });
                    } else {
                        const url = new URL(window.location.href);
                        if (url.pathname.endsWith("traduction") || url.pathname.endsWith("traduction/"))
                            navigate('/traduction/create-team');
                    }
                } else {
                    const url = new URL(window.location.href);
                    if (url.pathname.endsWith("traduction") || url.pathname.endsWith("traduction/"))
                        navigate('/traduction/create-team');
                }
        });
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-screen h-screen overflow-x-hidden overflow-y-scroll bg-gray-100'>
                <div className='relative h-[10%]'>
                    <Navbar is_black={true} />
                </div>
                <div className='relative h-[10%] px-[8%]'>
                    <button onClick={() => {
                        navigate('./create-team');
                    }} className={`relative text-2xl Montserrat-SemiBold p-2 cursor-pointer px-16
                        ${new URL(window.location.href).pathname.endsWith("create-team") && 'border-b-4 border-gatek_red-500 text-gatek_red-500'}
                    `}>Créer une équipe</button>
                    <button onClick={() => {
                        navigate('./join-team');
                    }} className={`relative text-2xl Montserrat-SemiBold p-2 cursor-pointer px-16
                        ${new URL(window.location.href).pathname.endsWith("join-team") && 'border-b-4 border-gatek_red-500 text-gatek_red-500'}
                    `}>Rejoindre une équipe</button>
                </div>
                <div className='relative h-[80%]'>
                    <Outlet />
                </div>
            </div>
        </MainContext.Provider>
    );
}

export default Traduction;