import React, {useContext, useEffect, useRef, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import trad_front from '../../../Assets/Images/trad_front.png';
import left_to_right from '../../../Assets/Images/left_to_right.png';
import warning from '../../../Assets/Images/warning.png';
import up_black from '../../../Assets/Images/up_black.png';
import down_black from '../../../Assets/Images/down_black.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from '../Discovered/Navbar';
import { IComment, IDBChapter, IOeuvre } from '../../../Interfaces/Discover';
import { BookCommentRequest, getBookComments, getChapter, getDiscoverd, getMangaInfoByID } from '../../../Api/Oeuvres';
import { SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getUser } from '../../../Api/User';
import { TeamRequest, Unclaim } from '../../../Api/Team';
import { maxMB } from '../../Edit/AddChapter';

const Translate: React.FC = () => {
    const {user, myteam} = useContext(MainContext);
    const [oeuvre, setOeuvre] = useState<IOeuvre | undefined>(undefined);
    const [chapters, setchapters] = useState<IDBChapter[]>([]);
    const [author, setauthor] = useState<User | undefined>(undefined);
    const [open, setOpen] = useState<IDBChapter | undefined>(undefined);
    const [images, setImages] = useState<string[]>([]);
    const fileInputRefGg = useRef<HTMLInputElement>(null);
    const handleDivClick = () => {
        fileInputRefGg.current?.click();
    };
    const navigate = useNavigate();

    const url = new URL(window.location.href);
    const id = url.searchParams.get('id');
    const lang = url.searchParams.get('lang');

    const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.files);
        if (event.target.files && event.target.files.length > 0) {
            const maxSize = maxMB * 1024 * 1024; // Taille maximale de 5MB
            const minWidth = 800; // Largeur minimale requise
            const minHeight = 600; // Hauteur minimale requise
            const filesArray: File[] = Array.from(event.target.files);
            let loadedFiles: string[] = [];
            let validImageCount = 0; // Compter le nombre d'images valides
    
            filesArray.forEach((file, index) => {
                // Vérifier la taille du fichier
                if (file.size > maxSize) {
                    console.warn("Un fichier dépasse la taille maximale autorisée et a été ignoré.");
                    return;
                }
    
                const imgURI = URL.createObjectURL(file);
                const image = new Image();
                image.onload = () => {
                    // Vérifier les dimensions de l'image
                    // if (image.naturalWidth !== imageWidth || image.naturalHeight !== imageHeight) {
                    //     alert(`L'image doit être de ${imageWidth}x${imageHeight} pixels.`);
                    //     return;
                    // }
    
                    // Si l'image est valide, lire le contenu du fichier
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        loadedFiles.push(reader.result as string);
    
                        // Vérifier si tous les fichiers valides sont chargés
                        if (++validImageCount === filesArray.length) {
                            // Ajouter les images valides à la suite des autres dans l'état
                            setImages([...images, ...loadedFiles]);
                        }
                    };
                    reader.readAsDataURL(file);
                };
                image.onerror = () => {
                    console.warn("Impossible de charger une image. Elle a été ignorée.");
                };
                image.src = imgURI;
            });
        }
    };


    useEffect(() => {
        if (!user)
            navigate('/login');

        getMangaInfoByID(id).then((oeu) => {
            setOeuvre(oeu);
            if (oeu.author_ID)
                getUser(oeu.author_ID).then((u) => { setauthor(u); });
        });

        if (id)
            getChapter(id, "original", user?.id).then((chapter) => {
                setchapters(chapter || []);
            });
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full overflow-x-hidden overflow-y-scroll bg-gray-100'>
                <div className='relative w-full h-[30%]'>
                    <div className='absolute w-full h-full bg-black bg-opacity-80 z-10'></div>
                    <img src={trad_front} className='absolute h-full object-cover' />
                    <div className='relative h-[20%] z-10'>
                        <Navbar />
                    </div>
                </div>

                <div className='relative w-full h-[10%] z-10'>
                    <div className='absolute left-[8%] border-white -top-[200%] h-[400%] flex gap-2'>
                        <img src={backend_url+oeuvre?.cover} className='relative h-full border-white bg-gray-900 m-auto' />
                        <div className='relative w-1/2 h-full flex flex-col justify-center gap-4'>
                            <h1 className='relative Montserrat-SemiBold transform -translate-y-1/2 text-white text-4xl'>{oeuvre?.name}</h1>
                            <h1 className='relative italic Montserrat-Regular transform -translate-y-1/2 text-gray-100 text-base'>{author?.username}</h1>
                            <div className='relative flex gap-4 items-center justify-center p-2'>
                                <h1 className='Montserrat-SemiBold'>{oeuvre?.originalLanguage}</h1>
                                <img src={left_to_right} className='w-8 h-4' />
                                <h1 className='Montserrat-SemiBold'>{lang}</h1>
                            </div>
                        </div>
                    </div>
                    <button onClick={() => {
                        if (myteam && id)
                            Unclaim(id, myteam?.team_ID).then((response) => {
                                if (response.status === 200)
                                    navigate('../traduction');
                            });
                    }} className='absolute z-50 right-[8%] top-2 p-2 Montserrat-Regular flex gap-2 items-center justify-center text-white bg-red-700 rounded-xl'>
                        <img src={warning} className='w-8 h-8' />
                        unclaim
                    </button>
                </div>

                <div className='relative w-full flex flex-wrap mt-[8%] px-[8%] gap-8'>
                    {chapters.map((chapter, index) => {
                        return (
                            <div key={index} className='relative w-[40%] flex gap-8 bg-white rounded-xl border-2 p-2 border-gray-200'>
                                <img src={backend_url+chapter.cover} className='w-[100px] h-full object-cover' />
                                <div className='relative w-full h-full p-2'>
                                    <h1 className='Montserrat-SemiBold text-lg'>{chapter.name}</h1>
                                </div>
                                <button onClick={() => {
                                    navigate(`../upload-engine?id=${id}&chapter=${chapter.id}&lang=${lang}`);
                                }} className='relative self-end p-2 w-full Montserrat-SemiBold border border-red-700 rounded-xl'>Upload traduction</button>
                                <button onClick={(e) => {
                                    navigate(`../translate-engine?id=${id}&chapter=${chapter.id}&lang=${lang}`);
                                }} className='relative self-end p-2 Montserrat-SemiBold bg-red-700 text-white rounded-xl'>Traduire</button>
                            </div>
                        );
                    })}
                </div>

            </div>
        </MainContext.Provider>
    );
}

export default Translate;