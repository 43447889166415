import { backend_url } from "../App";
import { Post, AxiosResponse, Get } from "../Api/Axios";

export interface User {
    token?: string;

    avatar?: any;
    id: string;
    coins?: number;
    coinspot?: number;
    username: string;
    firstname: string;
    lastname: string;
    email: string;
    password?: string;
    passwordConfirm?: string;
    readingLanguage?: string;
    readingWay?: string;
    role: "author" | "reader" | "translator" | "admin" | "";
    birthday: Date | null;
    profilePicture?: string;
}

export const GetUsers = async (user_id: string):Promise<AxiosResponse> => {
    const response = await Post('/credentials', JSON.stringify({
        id: user_id
    }));
    const resp =  await Get('/credentials/private', {});
    response.data.readingLanguage = resp.data.readingLanguage;
    response.data.readingWay = resp.data.readingWay;
    return response;
}