import React, {useContext, useEffect, useState} from 'react';
import test from '../../../Assets/Images/test.jpg';
import { Outlet, useNavigate } from 'react-router-dom';

import like from '../../../Assets/Images/like.png';
import unlike from '../../../Assets/Images/unlike.png';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';

import { MainContext } from '../../../Context/Main';
import { IArticle, IComment, IDBChapter, IDiscover, IOeuvre } from '../../../Interfaces/Discover';
import { getMangaInfo, getDiscoverd, getChapter } from '../../../Api/Oeuvres';
import { backend_url } from '../../../App';
import { AllWorldLanguages } from './TraductorEditInterface';
import { getUser } from '../../../Api/User';
import { User } from '../../../Interfaces/User';
import { AddComment, SendLikeDislike, SendLikeDislikeArticle, getArticle, getComments } from '../../Edit/Utils/IAdd';
import Loading from './Loading/Loading';

const ArticleModal: React.FC<IArticle> = (d) => {
    const {user, setuser, setcurrent_oeuvre, setcurrent_chapitre} = useContext(MainContext);
    const [article, setarticle] = useState<IArticle | undefined>(undefined);
    const [comment, setcomment] = useState<IComment>({
        comment: "",
        author_ID: user?.id || "-1",
    });
    const [comments, setcomments] = useState<IComment[]>([]);
    const navigate = useNavigate();

    const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        // URL de votre image de remplacement
        e.currentTarget.src = logo_gatek;
    };

    const transformCreationDate = (date: string | undefined) => {
        if (!date) return "";
        const d = new Date(date);
        return d.toLocaleDateString() + " " + d.toLocaleTimeString();
    };

    useEffect(() => {
        console.log("d", d);
        if (user) {
            getArticle(user, d).then((art) => {
                setarticle(art);
            });
            getComments(user, d).then((art) => {
                setcomments(art);
            });
        }
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            {article ? <div onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}  className='relative z-50 w-1/2 h-3/4 bg-gatek_red-900 bg-opacity-100 p-4 overflow-y-auto shadow-gray-600 shadow-xl'>
                <div className='relative w-full h-[10%] flex justify-between'>
                    <h1 className='Poppins-SemiBold w-[90%] truncate text-3xl text-white'>{article.title}</h1>
                    <div className='relative w-[10%] h-[70%] flex justify-end gap-8 text-white p-2'>
                        <div className='relative h-full'>
                            <img src={like} className='h-full object-contain transform transition-all duration-150 hover:scale-90 cursor-pointer' onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (user)
                                    SendLikeDislikeArticle(user, article, article, true).then((response) => {
                                        getArticle(user, article).then((art) => {
                                            setarticle(art);
                                        });
                                    });
                            }} />
                            <p className='absolute -bottom-[30%] -right-[30%] text-sm'>{article.likes || 0}</p>
                        </div>
                        <div className='relative h-full'>
                            <img src={unlike} className='h-full object-contain transform transition-all duration-150 hover:scale-90 cursor-pointer' onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (user)
                                    SendLikeDislikeArticle(user, article, article, false).then((response) => {
                                        getArticle(user, article).then((art) => {
                                            setarticle(art);
                                        });
                                    });

                            }} />
                            <p className='absolute -bottom-[30%] -right-[30%] text-sm'>{article.dislikes || 0}</p>
                        </div>
                    </div>
                </div>
                
                <h1 className='Inter-Regular w-full h-[10%] truncate text-xs text-white text-right'>{transformCreationDate(article.creationDate)}</h1>
                <div className='relative w-full h-1/2 border-2 border-gatek_red-800 rounded-3xl overflow-hidden'>
                    <img src={backend_url+article.images[0]} alt={article.title} className='relative w-full h-full object-contain bg-white' onError={handleImageError} />
                </div>
                <h1 className='Inter-Regular mt-8 text-sm max-h-full text-white border-b border-gatek_red-800 p-2'>{article.description}</h1>

                {article.images.length > 1 && <div className='relative w-full h-full flex flex-col gap-4'>
                        <h1 className='mt-4 w-full h-[5%] p-4 Poppins-SemiBold text-2xl text-white'>📸 Galerie:</h1>
                        <div className='relative w-full h-[90%] flex gap-4 overflow-hidden overflow-x-scroll overflow-y-hidden'>
                            {article.images.map((img, index) => {
                                return (
                                    <div key={index} className='relative w-[20%] h-full flex flex-col'>
                                        <img src={backend_url+img} alt={article.title} className='relative w-full h-[80%] object-contain bg-white' onError={handleImageError} />
                                    </div>
                                );
                            })}
                        </div>
                </div>}

                <div className='relative w-full h-full gap-2'>
                    <div className='relative w-full h-full flex flex-col gap-4'>
                        <h1 className='mt-4 w-full h-[5%] p-4 Poppins-SemiBold text-2xl text-white'>🗯️ ({article.nbComment}) Commentaire(s):</h1>

                            <div className='relative w-full max-h-[55%] flex flex-col p-2 gap-4 overflow-hidden overflow-y-scroll overflow-x-hidden'>
                                {comments?.map((comment, index) => {
                                    return (
                                        <div key={index} className='relative w-full bg-white p-4 shadow-gray-600 shadow-md flex justify-between'>
                                            <h1 className='relative Inter-Regular text-xs w-[90%] h-full'>{comment.comment}</h1>
                                            <div className='relative w-[10%] gap-4 grid grid-cols-2 bg-gatek_red-900 p-4 rounded-full text-white'>
                                                <div className='relative w-full h-full flex flex-col'>
                                                    <img src={like} className='h-[70%] object-contain transform transition-all duration-150 hover:scale-90 cursor-pointer' onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        if (user)
                                                            SendLikeDislike(user, article, comment, true).then((response) => {
                                                                getComments(user, article).then((art) => {
                                                                    setcomments(art);
                                                                });
                                                            });
                                                    }} />
                                                    <p className='relative h-[30%] self-end text-xs'>{comment.likes || 0}</p>
                                                </div>
                                                <div className='relative w-full h-full flex flex-col'>
                                                    <img src={unlike} className='h-[70%] object-contain transform transition-all duration-150 hover:scale-90 cursor-pointer' onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        if (user)
                                                            SendLikeDislike(user, article, comment, false).then((response) => {
                                                                getComments(user, article).then((art) => {
                                                                    setcomments(art);
                                                                });
                                                            });
                
                                                    }} />
                                                    <p className='relative h-[30%] self-end text-xs'>{comment.dislikes || 0}</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                            })}
                        </div>

                        <div className='relative w-full h-[25%] bg-white p-4 flex justify-between gap-4 shadow-gray-600 shadow-md'>
                            <textarea value={comment?.comment} onChange={(e) => {
                                setcomment({...comment, comment: e.target.value});
                            }} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }} className='Poppins-Regular text-base p-2 w-full h-full resize-none bg-transparent border-2 border-gatek-400 rounded-2xl' />
                            <button className='
                                Poppins-SemiBold text-xs bg-gatek-200 text-white rounded-lg px-4 py-2
                                transform hover:scale-95 transition-all duration-300 w-1/4 h-1/4 self-end
                            ' onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (user)
                                    AddComment(user, article, comment).then((response) => {
                                        getComments(user, article).then((art) => {
                                            setcomments(art);
                                        });
                                    });
                            }}>Ajouter le commentaire
                            </button>
                        </div>
                    </div>
                </div>
            </div> : <Loading />}
        </MainContext.Provider>
    );
}

export default ArticleModal;