import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import redcross from '../../../Assets/Images/add.png';
import filter from '../../../Assets/Images/filter.png';
import up_black from '../../../Assets/Images/up_black.png';
import down_black from '../../../Assets/Images/down_black.png';
import up from '../../../Assets/Images/up_black.png';
import down from '../../../Assets/Images/down_black.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from '../Discovered/Navbar';
import { IArticle, IComment, IDBChapter, IOeuvre } from '../../../Interfaces/Discover';
import { BookCommentRequest, getBookComments, getChapter, getDiscoverd, getMangaInfoByID, GetPossibleTranslation } from '../../../Api/Oeuvres';
import { SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getMyArticles, getMyOeuvres, getUser } from '../../../Api/User';

const MyOeuvres: React.FC = () => {
    const {user} = useContext(MainContext);
    const [oeuvres, setOeuvres] = useState<IOeuvre[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const t = sessionStorage.getItem('token');
        if (t === null)
            navigate('/login');

        if (user) {
            getMyOeuvres(user).then((oeuvres) => {
                setOeuvres(oeuvres);
            });
            // GetPossibleTranslation(user.id).then((res) => {
            //     if (res.status === 200 && res.data)
            //         setPossibleTranslations(res.data);
            // });
        }
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-[100%] px-[8%] flex flex-wrap gap-[2%] py-4'>
                <div onClick={(e) => {
                    navigate('../../create-oeuvre');
                }} className='relative transform duration-150 hover:scale-95 cursor-pointer w-[49%] h-1/2 rounded-2xl border-2 border-dashed border-red-700 flex flex-col items-center justify-center'>
                    <img src={redcross} className='h-[14%]' />
                    <h1 className='text-2xl Montserrat-SemiBold text-red-700'>Créer une oeuvre!</h1>
                </div>

                {oeuvres.map((oeuvre, i) => {
                    return (
                        <div onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            navigate(`../../modify-oeuvre?id=${oeuvre.id}`);
                        }} className='relative transform duration-150 cursor-pointer hover:opacity-60 w-[49%] h-1/2 rounded-2xl border-2 border-red-700 flex gap-4'>
                            <img src={backend_url+oeuvre.cover} className='h-full w-[30%] object-cover rounded-2xl border-r-2 border-gray-700' />
                            <div className='relative w-full overflow-hidden flex flex-col gap-2'>
                                <h1 className='text-2xl Montserrat-SemiBold'>{oeuvre.name}</h1>
                                <div className='truncate w-full overflow-hidden flex gap-1'>
                                    {oeuvre.tags.map((tag, index) => {
                                        return (
                                            <span key={index} className='relative Montserrat-SemiBold px-2 py-1 rounded-lg Montserrat-Regular bg-gatek_red-500 text-white'>{tag}</span>
                                        );
                                    })}
                                </div>
                                <textarea value={oeuvre.description} disabled={true} className='h-[60%] w-full cursor-pointer outline-none Montserrat-Regular text-sm resize-none'></textarea>
                                <div className='relative w-full flex items-center justify-between'>
                                    <h1 className='text-xl Montserrat-SemiBold'>{oeuvre.originalLanguage}</h1>
                                    <div className='relative w-[20%] h-full flex gap-4 items-center justify-center'>
                                        <div className='relative flex justify-between w-1/3 h-[24px] items-center duration-150'>
                                            <p className='text-sm Montserrat-Regular'>{oeuvre.likes}</p>
                                            <img src={up} className='h-full object-contain' />
                                        </div>
                                        <div className='relative flex justify-between w-1/3 h-[24px] items-center duration-150'>
                                            <p className='text-sm Montserrat-Regular'>{oeuvre.dislikes}</p>
                                            <img src={down} className='h-full object-contain' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </MainContext.Provider>
    );
}

export default MyOeuvres;