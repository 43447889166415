import React, { useEffect, useState } from 'react';
import {Routes, Route, Navigate, useNavigate} from 'react-router-dom';
import Home from './Components/Home/Home';
import Login from './Components/Login/Login';
import Register from './Components/Register/Register';
import Choice from './Components/Register/Choice';
import ErrorNotFount from './Components/ErrorNotFount';
import { MainContext } from './Context/Main';
import { GetUsers, User } from './Interfaces/User';
import Dashboard from './Components/Dashboard/Dashboard';
import Discover from './Components/Dashboard/Subcomponent/Discover';
import Edit from './Components/Dashboard/Subcomponent/Edit';
import { IArticle, IDBChapter, IOeuvre } from './Interfaces/Discover';
import Read from './Components/Read/Read';
import Add from './Components/Edit/Add';
import Addarticle from './Components/Edit/Addarticle';
import Parametres from './Components/Dashboard/Subcomponent/Parametres';
import OeuvreInterface from './Components/Dashboard/Subcomponent/OeuvreInterface';
import Author from './Components/Dashboard/Subcomponent/Author';
import OeuvreTranslationInterface from './Components/Dashboard/Subcomponent/OeuvreTranslationInterface';
import AddChapter from './Components/Edit/AddChapter';
import Create from './Components/Dashboard/Subcomponent/Teams/Create';
import TeamHome from './Components/Dashboard/Subcomponent/Teams/TeamHome';
import { ITeam } from './Api/Team';
import TeamMembers from './Components/Dashboard/Subcomponent/Teams/TeamMembers';
import TeamTraductions from './Components/Dashboard/Subcomponent/Teams/TeamTraductions';
import Modifyarticle from './Components/Edit/Modifyarticle';
import Modifychapter from './Components/Edit/Modifychapter';
import Team from './Components/Dashboard/Subcomponent/Teams/Team';
import Forgotpassword from './Components/Login/Forgotpassword';
import Subscribe from './Components/Dashboard/Subcomponent/Subscribe';
import ValidateTranslation from './Components/Dashboard/Subcomponent/ValidateTranslation';
import HomeTeam from './Components/Home/Team';
import Contact from './Components/Home/Contact';
import ProjectInformation from './Components/Home/ProjectInformation';
import Discovered from './Components/Intra/Discovered/Discovered';
import OeuvreMod from './Components/Intra/Discovered/OeuvreMod';
import Edition from './Components/Intra/Edit/Edition';
import MyOeuvres from './Components/Intra/Edit/MyOeuvres';
import CreateOeuvre from './Components/Intra/Edit/CreateOeuvre';
import ModifyOeuvre from './Components/Intra/Edit/ModifyOeuvre';
import CreateChapter from './Components/Intra/Edit/CreateChapter';
import Traduction from './Components/Intra/Traduction/Traduction';
import CreateTeam from './Components/Intra/Traduction/CreateTeam';
import MyTeamInterface from './Components/Intra/Traduction/MyTeamInterface';
import Translate from './Components/Intra/Traduction/Translate';
import TranslateEngine from './Components/Intra/Traduction/TranslateEngine';
import { GetStorage } from './Api/Storage';
import TranslationValidate from './Components/Intra/Edit/TranslationValidate';
import MyArticle from './Components/Intra/Edit/MyArticle';
import CreateArticle from './Components/Intra/Edit/CreateArticle';
import ArticleMod from './Components/Intra/Discovered/ArticleMod';
import JoinTeam from './Components/Intra/Traduction/JoinTeam';
import AdvancedSearch from './Components/Intra/Discovered/AdvancedSearch';
import AuthorMod from './Components/Intra/Discovered/AuthorMod';
import TeamMod from './Components/Intra/Traduction/TeamMod';
import ModifyChapter from './Components/Intra/Edit/ModifyChapter';
import ModifyArticle from './Components/Intra/Edit/ModifyArticle';
import Subs from './Components/Dashboard/Subcomponent/Subs';
import EmailValidation from './Components/Register/EmailValidation';
import ModifyEmail from './Components/Dashboard/Subcomponent/ModifyEmail';
import ModifyPassword from './Components/Dashboard/Subcomponent/ModifyParameters';
import Library from './Components/Intra/Discovered/Library';
import MyTraductors from './Components/Intra/Edit/MyTraductors';
import ModifyPayment from './Components/Dashboard/Subcomponent/ModifyPayment';
import UploadEngine from './Components/Intra/Traduction/UploadEngine';

export const backend_url = process.env.REACT_APP_BACKEND_URL + ':' + process.env.REACT_APP_BACKEND_PORT;

function App() {
  const [token, setToken] = useState<string | undefined>(undefined);
  const [user, setuser] = useState<User | undefined>(undefined);
  const [current_oeuvre, setcurrent_oeuvre] = useState<IOeuvre | undefined>(undefined);
  const [current_chapitre, setcurrent_chapitre] = useState<IDBChapter | undefined>(undefined);
  const [myteam, setmyteam] = useState<ITeam | undefined>(undefined);
  const [language, setlanguage] = useState<string>('fr' as string);
  const [seedetails, setseedetails] = useState<IOeuvre | undefined>(undefined);
  const [seearticle, setseearticle] = useState<IArticle | undefined>(undefined);
  const [current_language, setcurrent_language] = useState<string | undefined>(undefined);
  const [can_load, setcan_load] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const t = sessionStorage.getItem('token');
    if (t !== null) {
      setToken(t);

      const user_id = GetStorage(t, 'user_id');
      if (user_id !== undefined) {
        GetUsers(user_id).then((res) => {
          if (res.data !== undefined) {
            res.data.id = user_id;
            setuser(res.data);
            setcan_load(true);
          } else {
            // while user is undefined, try every 500ms to get the user
            setTimeout(() => {
                GetUsers(user_id).then((res) => {
                    if (res.data !== undefined) {
                        res.data.id = user_id;
                        setuser(res.data);
                        setcan_load(true);
                    }
                });
            }, 500);
          }
        });
      } else {
          navigate("/login");
          setcan_load(true);
      }
    }
    setcan_load(true);
}, []);

  return (
    <div className="relative w-screen h-screen">
      {can_load && <MainContext.Provider value={{token, setToken, seedetails, setseedetails, seearticle, setseearticle, user, setuser, current_oeuvre, setcurrent_oeuvre, current_chapitre, setcurrent_chapitre, current_language, setcurrent_language, myteam, setmyteam, language, setlanguage}}>
        <Routes>
          <Route path='/' element={<Home/>}>
            <Route path='acceuil' element={<ProjectInformation/>}/>
            <Route path='teams' element={<HomeTeam/>}/>
            <Route path='contact' element={<Contact/>}/>
          </Route>
          <Route path='/login' element={<Login/>}/>
          <Route path='/forgotpassword' element={<Forgotpassword/>}/>
          <Route path='/register' element={<Register />}/>
          <Route index path='/register/email' element={<EmailValidation />}/>
          <Route index path='/register/choice' element={<Choice />}/>
          <Route index path='/discover' element={<Discovered />}/>
          <Route index path='/modify-email' element={<ModifyEmail />}/>
          <Route index path='/modify-password' element={<ModifyPassword />}/>
          <Route index path='/modify-payment' element={<ModifyPayment />}/>
          <Route index path='/upload-engine' element={<UploadEngine />}/>

          <Route path='read' element={<Read />}/>
          <Route path='edit' element={<Edition />}>
            <Route path='my-oeuvres' element={<MyOeuvres />}/>
            <Route path='my-articles' element={<MyArticle />}/>
            <Route path='my-traductors' element={<MyTraductors />}/>
          </Route>
          <Route path='create-oeuvre' element={<CreateOeuvre />}/>
          <Route path='create-article' element={<CreateArticle />}/>
          <Route path='add-chapter' element={<CreateChapter />}/>
          <Route path='modify-oeuvre' element={<ModifyOeuvre />}/>
          <Route path='modify-article' element={<ModifyArticle />}/>
          <Route path='modify-chapter' element={<ModifyChapter />}/>
          <Route path='search' element={<AdvancedSearch />}/>
          <Route path='author' element={<AuthorMod />}/>
          <Route path='subs' element={<Subs />}/>
          <Route path='team' element={<TeamMod />}/>
          <Route path='traduction' element={<Traduction />}>
            <Route path='create-team' element={<CreateTeam />}/>
            <Route path='join-team' element={<JoinTeam />}/>
          </Route>
          <Route path='my-team' element={<MyTeamInterface />}/>
          <Route path='translate' element={<Translate />}/>
          <Route path='translate-engine' element={<TranslateEngine />}/>
          <Route path='parameters' element={<Parametres />}/>
          <Route path='translate-validation' element={<TranslationValidate />}/>

          <Route index path='/oeuvre' element={<OeuvreMod />}/>
          <Route index path='/article' element={<ArticleMod />}/>
          <Route path='library' element={<Library />}/>
          <Route path='/dashboard' element={<Dashboard />}>
            <Route path='read' element={<Read />}/>
            <Route path='author' element={<Author />}/>
            <Route path='add' element={<Add />}/>
            <Route path='addarticle' element={<Addarticle />}/>
            <Route path='modifyarticle' element={<Modifyarticle />}/>
            <Route path='addchapter' element={<AddChapter />}/>
            <Route path='discover' element={<Discover />}/>
            <Route path='team' element={<Team />}/>
            <Route path='edit' element={<Edit />}>
              <Route path='teams' element={<Team />}/>
              <Route path='teammembers' element={<TeamMembers />}/>
              <Route path='teamtraductions' element={<TeamTraductions />}/>
              <Route path='translateoeuvre' element={<OeuvreTranslationInterface />}/>
            </Route>
            <Route path='editoeuvre' element={<OeuvreInterface />}/>
            <Route path='validateTranslation' element={<ValidateTranslation />}/>
            <Route path='modifychapter' element={<Modifychapter />}/>
            <Route path='translateoeuvre' element={<OeuvreTranslationInterface />}/>
            <Route path='parametres' element={<Parametres />}/>
            <Route path='subscribe' element={<Subscribe />}/>
            <Route path='teamcreate' element={<Create />} />
            <Route path='*' element={<Navigate to='./discover' />}/>
          </Route>
          <Route path='*' element={<ErrorNotFount/>}/>
        </Routes>
      </MainContext.Provider>}
    </div>
  );
}

export default App;