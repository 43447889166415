import logo_gatek from '../../../Assets/Images/logo_gatek.png';

import React, {useContext, useEffect, useState} from 'react';
import { SearchResponseType } from '../../../Api/Search';
import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import { IArticle, IOeuvre } from '../../../Interfaces/Discover';
import { getMangaInfo } from '../../../Api/Oeuvres';
import manga_ico from '../../../Assets/Images/comic.png';
import red_arrow_small from '../../../Assets/Images/red_arrow_small.png';
import gray_arrow from '../../../Assets/Images/gray_arrow.png';
import { useNavigate } from 'react-router-dom';
import { GetNotificationsAuthorGetUnseen, GetTranslatorTeamCompetition, GetTranslatorTeamCompetitionInfo, GetTranslatorTeamCompetitionRefuse, IGetNotificationsAuthorGetUnseen, IGetTranslatorTeamCompetition, IGetTranslatorTeamCompetitionInfo, IPostNotificationGetunseen, PostNotificationGetunseen, PostNotificationsAuthorUpdate, PostNotificationsUpdate } from '../../../Api/User';

const Notifications: React.FC = () => {
    const {user, setuser, setseedetails, setseearticle} = useContext(MainContext);
    const [bookNotifications, setBookNotifications] = useState<IPostNotificationGetunseen[]>([]);
    const [authorNotifications, setAuthorNotifications] = useState<IGetNotificationsAuthorGetUnseen | undefined>(undefined);
    const [traductionNotifications, setTraductionNotifications] = useState<IGetTranslatorTeamCompetition[]>([]);
    const [open, setOpen] = useState<IGetTranslatorTeamCompetition | undefined>(undefined);
    const [tradInfo, setTradInfo] = useState<IGetTranslatorTeamCompetitionInfo | undefined>(undefined);
    const [idx, setIdx] = useState(0);
    const navigate = useNavigate();

    const GetAllNotificationNumber = () => {
        if (!bookNotifications || !authorNotifications)
            return 0;
        return (authorNotifications.newArticle.length + authorNotifications.newManga.length + bookNotifications.length + traductionNotifications.length);
    }

    useEffect(() => {
        if (open === undefined)
            return;

        GetTranslatorTeamCompetitionInfo(open.competition_ID).then((res) => {
            setTradInfo(res);
        });
    }, [open]); 

    useEffect(() => {
        PostNotificationGetunseen().then((res) => {
            setBookNotifications(res);
        });
        GetNotificationsAuthorGetUnseen().then((res) => {
            setAuthorNotifications(res);
        });
        GetTranslatorTeamCompetition().then((res) => {
            setTraductionNotifications(res);
        });
    }, []);

    return (
        <>
            {(open && tradInfo) && <div onClick={(e) => {
                setOpen(undefined);
                setTradInfo(undefined);
                setIdx(0);
            }} className='fixed z-[60] top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center'>
                <div onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }} className='bg-gray-200 text-white w-[50%] h-[60%] rounded-xl flex items-center justify-between'>
                    <div className='relative w-[60%] h-full flex bg-gray-200 p-2'>
                        <div onClick={() => {
                                setIdx(idx-1);
                        }} className='w-[10%] flex items-center justify-center cursor-pointer'>
                            <img src={idx <= 0 ? gray_arrow : red_arrow_small} className={`w-6 cursor-pointer
                                ${idx > 0 && ' transform rotate-180'}
                            `} />
                        </div>
                        <img src={backend_url+tradInfo.pages[idx]} className='w-[80%] text-center h-full object-contain' />
                        <div onClick={() => {
                            setIdx(idx+1);
                        }} className='w-[10%] flex items-center justify-center cursor-pointer'>
                            <img src={idx >= tradInfo.pages.length-1 ? gray_arrow : red_arrow_small} className={`w-6 cursor-pointer
                                ${idx >= tradInfo.pages.length-1 && ' transform rotate-180'}
                            `} />
                        </div>
                    </div>

                    <div className='relative w-[40%] h-full bg-white flex flex-col items-start gap-4 p-4 Montserrat-Regular'>
                        <h1 className='text-xs text-gray-300 flex gap-2 items-center'>proposé par :<span className='relative Montserrat-SemiBold text-black text-sm'>{open.authorName}</span></h1>
                        <h1 className='text-xs text-gray-300 flex gap-2 items-center'>traduit par :<span className='relative Montserrat-SemiBold text-black text-sm'>{tradInfo.teamName}</span></h1>
                        <h1 className='text-xs text-gray-300 flex gap-2 items-center'>langue :<span className='relative Montserrat-SemiBold text-black text-sm'>{tradInfo.translatedLanguage}</span></h1>
                        <h1 className='text-xs text-gray-300 flex gap-2 items-center'>date :<span className='relative Montserrat-SemiBold text-black text-sm'>{new Date(tradInfo.translationDate).toLocaleString()}</span></h1>

                        <div className='relative flex gap-4 items-center w-full Montserrat-SemiBold text-black text-xs'>
                            <button onClick={() => {
                                GetTranslatorTeamCompetitionRefuse(open.competition_ID).then((res) => {
                                    if (res) {
                                        GetTranslatorTeamCompetition().then((res) => {
                                            setTraductionNotifications(res);
                                        });
                                    }
                                });
                            }} className='w-1/2 h-[40px] bg-white border border-gray-200 rounded-xl'> Refuser </button>

                            <button onClick={() => {
                                navigate(`../upload-engine?id=${open.manga_ID}&chapter=${tradInfo.chapter_ID}&lang=${tradInfo.translatedLanguage}&competition_ID=${open.competition_ID}`);
                            }} className='w-1/2 h-[40px] border-gatek-400 border rounded-xl'> Accepter (upload)</button>

                            <button onClick={() => {
                                navigate(`../translate-engine?id=${open.manga_ID}&chapter=${tradInfo.chapter_ID}&lang=${tradInfo.translatedLanguage}&competition_ID=${open.competition_ID}`);
                            }} className='w-1/2 h-[40px] bg-gatek-400 text-white rounded-xl'> Accepter (outil de traduction) </button>
                        </div>
                    </div>
                </div>
            </div>}
        {(bookNotifications && authorNotifications) ? <div className='relative border border-gray-300 w-full max-h-full p-4 bg-white rounded-xl text-xl Montserrat-Bold flex overflow-hidden flex-col gap-4'>
            <h1 className='text-black'>{GetAllNotificationNumber()} Notification(s)</h1>

            <div className='relative w-full h-full flex flex-wrap overflow-y-scroll'>
                {traductionNotifications.map((notification, index) => {
                    if (notification === undefined || notification === null)
                        return null;
                    return (
                        <div className='relative text-sm w-full flex flex-col gap-2 p-1 Montserrat-Regular border-b border-gray-300'>
                            <h1 className='text-black truncate'><span className='Montserrat-SemiBold'>{notification.authorName}</span> vous a invité à traduire un chapitre pour l’oeuvre <span className='Montserrat-SemiBold'>{notification.mangaName}</span></h1>
                            <h1 className='text-gray-400 text-xs Montserrat-SemiBold'>{new Date(notification.creationDate).toLocaleString()}</h1>
                            <div className='relative flex gap-4 justify-between'>
                                <button onClick={() => {
                                    GetTranslatorTeamCompetitionRefuse(notification.competition_ID).then((res) => {
                                        if (res) {
                                            GetTranslatorTeamCompetition().then((res) => {
                                                setTraductionNotifications(res);
                                            });
                                        }
                                    });
                                }} className='relative border-2 border-gatek_red-500 bg-white Montserrat-SemiBold px-4 py-1 text-xs rounded-lg flex gap-4 items-center justify-center'>Refuser</button>  
                                <button onClick={() => {
                                    setOpen(notification);
                                }} className='relative bg-gatek_red-500 text-white Montserrat-SemiBold px-4 py-1 text-xs rounded-lg flex gap-4 items-center justify-center'>Traduire</button>
                            </div>                                
                        </div>
                    );
                })}
                {authorNotifications && authorNotifications.newArticle.map((notification, index) => {
                    return (
                        <div onClick={() => {
                            PostNotificationsAuthorUpdate(notification.article_ID).then((res) => {
                                if (res) {
                                    navigate(`../article?id=${notification.article_ID}`);
                                }
                            });
                        }} className='relative text-sm w-full h-[50px] Montserrat-Regular border-b border-gray-300'>
                            <h1 className='text-black truncate'><span className='Montserrat-SemiBold'>{notification.author_name}</span> a publié un nouvel article <span className='Montserrat-SemiBold'>{notification.articleName}</span></h1>
                            <h1 className='text-gray-400 text-xs Montserrat-SemiBold'>{new Date(notification.date).toLocaleString()}</h1>
                        </div>
                    );
                })}
                {authorNotifications && authorNotifications.newManga.map((notification, index) => {
                    return (
                        <div onClick={() => {
                            PostNotificationsAuthorUpdate(undefined, notification.manga_ID).then((res) => {
                                if (res) {
                                    navigate(`../oeuvre?id=${notification.manga_ID}`);
                                }
                            });
                        }} className='relative text-sm w-full h-[50px] Montserrat-Regular border-b border-gray-300'>
                            <h1 className='text-black truncate'><span className='Montserrat-SemiBold'>{notification.author_name}</span> a publié une nouvelle oeuvre <span className='Montserrat-SemiBold'>{notification.mangaName}</span></h1>
                            <h1 className='text-gray-400 text-xs Montserrat-SemiBold'>{new Date(notification.date).toLocaleString()}</h1>
                        </div>
                    );
                })}
                {bookNotifications.map((notification, index) => {
                    return (
                        <div onClick={() => {
                            PostNotificationsUpdate(notification.manga_ID).then((res) => {
                                if (res) {
                                    navigate(`../read?id=${notification.manga_ID}&chapter=${notification.chapter_ID}&lang=original`);
                                }
                            });
                        }} className='relative text-sm w-full h-[50px] Montserrat-Regular border-b border-gray-300'>
                            <h1 className='text-black truncate'><span className='Montserrat-SemiBold'>{notification.manga_name}</span> a un nouveau chapitre, <span className='Montserrat-SemiBold'>{notification.chapter_name}</span></h1>
                            <h1 className='text-gray-400 text-xs Montserrat-SemiBold'>{new Date(notification.date).toLocaleString()}</h1>
                        </div>
                    );
                })}
            </div>
        </div> : null}
        </>
    );
}

export default Notifications;
