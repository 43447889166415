interface IStorage {
    [key: string]: string
}

export const SetStorage = (token: string | undefined, key: string, value: string) => {
    if (token === undefined) { return }

    let token_storage = sessionStorage.getItem(token)
    let storage: IStorage = {}

    if (token_storage !== null) { storage = JSON.parse(token_storage) }
    
    storage[key] = value
    sessionStorage.setItem(token, JSON.stringify(storage))
}

export const GetStorage = (token: string | undefined, key: string): string | undefined => {
    if (token === undefined) { return }

    let token_storage = sessionStorage.getItem(token)
    let storage: IStorage = {}

    if (token_storage !== null) { storage = JSON.parse(token_storage) }
    return storage[key]
}

export const RemoveStorage = (token: string | undefined, key: string) => {
    if (token === undefined) { return }

    let token_storage = sessionStorage.getItem(token)
    let storage: any = []

    if (token_storage !== null) { storage = JSON.parse(token_storage) }

    delete storage[key]
    sessionStorage.setItem(token, JSON.stringify(storage))
}