import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useNavigate } from 'react-router-dom';

import { MainContext } from '../../../Context/Main';
import { IArticle, IOeuvre } from '../../../Interfaces/Discover';
import { getMyArticles, getMyOeuvres } from '../../../Api/User';
import OeuvreInterface from './OeuvreInterface';
import TraductorEditInterface from './TraductorEditInterface';
import { GetPossibleTranslation, RequestCancelTranslation, RequestValidateTranslation, getMangaInfo, getStashedChapter } from '../../../Api/Oeuvres';
import ObjectBox from './ObjectBox';
import { TeamRequest } from '../../../Api/Team';
import { ITranslatedBook } from './Edit';
import { backend_url } from '../../../App';
import { handleImageError } from './Discover';
import Loading from './Loading/Loading';

const ValidateTranslation: React.FC = () => {
    const {user, setuser, setcurrent_oeuvre, setmyteam, myteam} = useContext(MainContext);
    const [myoeuvre, setmyoeuvre] = useState<IOeuvre | undefined>(undefined);
    const [myarticles, setmyarticles] = useState<IArticle | undefined>(undefined);
    const [possibleTranslation, setPossibleTranslation] = useState<ITranslatedBook | undefined>(undefined);
    const [images, setImages] = useState<string[]>([]);
    const [message, setMessage] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        const translation_data = sessionStorage.getItem('translation_data');
        if (translation_data !== null) {
            const data = JSON.parse(translation_data) as ITranslatedBook;
            // setmyoeuvre(data.myoeuvre);
            // setmyarticles(data.myarticles);
            setPossibleTranslation(data);
            getStashedChapter(data.translationInValidation_ID).then((res) => {
                console.log("res", res);
                if (res.status === 200) {
                    setImages(res.data);
                }
            });
        }
    }, []);

    return (
        <div className='relative w-full h-full'>
            {possibleTranslation && <div className='relative shadow-md shadow-black w-full h-1/2 justify-start flex gap-4 p-4 bg-gradient-to-r from-gatek_red-500 via-gatek_red-400 to-gatek_red-300'>
                <img className='relative w-1/6 h-full object-contain' src={backend_url+possibleTranslation?.mangaCover} onError={handleImageError} />
                <div className='relative w-1/2 h-full flex flex-col gap-2'>
                    <h1 className='Poppins-SemiBold text-xl bg-transparent text-white border-b flex justify-between items-center'>📕 Manga: {possibleTranslation.mangaName}</h1>
                    <h1 className='Poppins-SemiBold text-xl bg-transparent text-white border-b flex justify-between items-center'>📄 Chapitre: {possibleTranslation.chapterName}</h1>
                </div>
                <div className='relative w-1/2 h-full flex flex-col justify-center items-center gap-8'>
                    <button onClick={(e) => {
                        // Accept translation
                        RequestValidateTranslation(possibleTranslation.translationInValidation_ID).then((res) => {
                            if (res.status === 200) {
                                sessionStorage.removeItem('translation_data');
                                navigate('../edit');
                            }
                        });
                    }} className='relative w-1/2 flex gap-2 justify-between bg-gatek-200 text-white p-2 rounded-md'>✅ Valider</button>
                    <button onClick={(e) => {
                        // Refuse translation
                        RequestCancelTranslation(possibleTranslation.translationInValidation_ID, message).then((res) => {
                            if (res.status === 200) {
                                sessionStorage.removeItem('translation_data');
                                navigate('../edit');
                            }
                        });
                    }} className='relative w-1/2 flex gap-2 justify-between bg-gatek-200 text-white p-2 rounded-md'>❌ Refuser</button>
                    <textarea onChange={(e) => {
                        setMessage(e.target.value);
                    }} className='relative w-3/4 h-1/2 p-2 bg-transparent bg-white rounded-xl shadow-black shadow-lg' placeholder='Message'></textarea>
                </div>
            </div>}
            {images.length > 0 ? <div className='relative w-full h-screen grid grid-cols-2 gap-4 p-4'>
                {images.map((image, index) => {
                        return <img key={index} className='relative w-full h-full object-contain' src={backend_url+image} onError={handleImageError} />
                    }
                )}
            </div> : <Loading />}
        </div>
    );
}

export default ValidateTranslation;