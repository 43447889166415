import React, {useContext, useEffect, useRef, useState} from 'react';
import arrow from '../../Assets/Images/right-arrow.png';
import { Outlet, useNavigate } from 'react-router-dom';
import { MainContext } from '../../Context/Main';
import { IChapter, IDBChapter, IOeuvre } from '../../Interfaces/Discover';
import { AddManga, IAdd } from './Utils/IAdd';
import { AllWorldLanguages } from '../Dashboard/Subcomponent/TraductorEditInterface';

import logo_gatek from '../../Assets/Images/logo_gatek.png';
import add from '../../Assets/Images/add.png';
import red_arrow from '../../Assets/Images/red_arrow.png';
import recycle_bin from '../../Assets/Images/recycle-bin.png';
import { AddChapterToDB, DeleteChapterRequest, getAllChapter, getChapter, getChapterById, getMangaInfo, getMangaInfoById } from '../../Api/Oeuvres';
import { backend_url } from '../../App';
import { handleImageError } from '../Dashboard/Subcomponent/ObjectBox';

const Modifychapter: React.FC = () => {
    const {user, setuser} = useContext(MainContext);
    const navigate = useNavigate();
    const [chapter, setchapter] = useState<IDBChapter | undefined>(undefined);
    const [images, setImages] = useState<string[]>([]);

    useEffect(() => {
        const chapter_id = sessionStorage.getItem('chapter_id');
        const oeuvres_id = sessionStorage.getItem('oeuvre_id');
        if (chapter_id !== null && oeuvres_id !== null) {
            getChapterById(oeuvres_id, chapter_id).then((chapter) => {
                setchapter(chapter);
                console.log("chapter", chapter);
            });
        }
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            {images && <div className='relative w-full h-full flex flex-col gap-4 '>
                <div className='relative px-8 w-full h-full p-2 flex flex-col gap-4'>
                    {<div className='relative shadow-md shadow-black w-full h-1/4 justify-start flex gap-4 p-4 bg-gradient-to-r from-gatek_red-500 via-gatek_red-400 to-gatek_red-300'>
                        <img className='relative w-1/6 h-full object-contain' onError={handleImageError} src={backend_url+chapter?.cover} />
                        <div className='relative w-1/2 h-full flex flex-col gap-2'>
                            <h1 className='Poppins-SemiBold text-4xl bg-transparent text-white border-b flex justify-between items-center'>{chapter?.name} </h1>
                        </div>
                    </div>}
                    <h1 className='Poppins-SemiBold text-3xl text-gatek-400 border-b-2 border-gatek-400 flex justify-between p-1'>Pages du chapitre - {images.length || 0} page(s)
                        <div className='relative w-1/5 flex justify-between gap-2'>
                            <button onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                // faire la  💾
                                sessionStorage.removeItem('chapter_id');
                                navigate('../editoeuvre');
                            }} className='relative text-white bg-gatek-400 Inter-SemiBold text-xl transform hover:scale-95 duration-150 px-4'>💾 Enregister</button>
                            <button onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                if (chapter) {
                                    const oeuvres_id = sessionStorage.getItem('oeuvre_id');
                                    if (oeuvres_id !== null)
                                        DeleteChapterRequest(oeuvres_id, chapter.id);

                                }
                                // faire la  ❌
                                sessionStorage.removeItem('chapter_id');
                                navigate('../editoeuvre');
                            }} className='relative text-white bg-gatek-400 Inter-SemiBold text-xl transform hover:scale-95 duration-150 px-4'>❌ Supprimer</button>
                        </div>
                    </h1>
                    <div className='relative w-full h-full grid grid-cols-6 gap-4 p-4'>
                        {chapter?.pages && chapter?.pages.map((page, index) => {
                            return (
                                <div key={index} className='flex-shrink-0 flex items-center justify-center border-red-700 relative w-full h-full pr-1 pb-1 transform hover:scale-95 transition duration-150 cursor-pointer'>
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        // déplacer l'image vers la gauche

                                        if (index > 0) {
                                            // move image to the left
                                            setImages((prev) => {
                                                const tmp = [...prev];
                                                [tmp[index], tmp[index-1]] = [tmp[index-1], tmp[index]];
                                                return tmp;
                                            });
                                        }
                                    }} className='absolute z-10 left-0 top-0 h-full w-[16%] opacity-0 transition-all duration-300
                                        hover:bg-gradient-to-l from-gray-300 via-gray-200 to-transparent hover:opacity-80 cursor-pointer flex items-center justify-center transform rotate-180
                                    '>
                                        <img src={red_arrow} className='w-1/2 object-contain' />
                                    </div>
                                    <img onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        // remove image
                                        setImages((prev) => {
                                            const tmp = [...prev];
                                            tmp.splice(index, 1);
                                            return tmp;
                                        });
                                    }} src={recycle_bin} className='object-contain absolute z-10 h-full w-[66%] opacity-0 hover:bg-gray-300 hover:opacity-80 rounded-full'/>
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        // déplacer l'image vers la droite
                                        setImages((prev) => {
                                            if (index < prev.length-1) {
                                                const tmp = [...prev];
                                                [tmp[index], tmp[index+1]] = [tmp[index+1], tmp[index]];
                                                return tmp;
                                            }
                                            return prev;
                                        });
                                    }} className='absolute z-10 right-0 top-0 h-full w-[16%] opacity-0 transition-all duration-300
                                        hover:bg-gradient-to-l from-gray-300 via-gray-200 to-transparent hover:opacity-80 cursor-pointer flex items-center justify-center transform
                                    '>
                                        <img src={red_arrow} className='w-1/2 object-contain' />
                                    </div>
                                    <img src={backend_url+page} alt={"Ajouter"} className='relative object-contain w-full h-full'/>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>}
        </MainContext.Provider>
    );
}

export default Modifychapter;