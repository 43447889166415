import React, {useContext, useEffect, useState} from 'react';
import red_check from '../../../Assets/Images/red_check.png';
import sendmail from '../../../Assets/Images/sendmail.png';
import disconnect from '../../../Assets/Images/disconnect.png';
import black_coin from '../../../Assets/Images/black_coin.png';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import { MainContext } from '../../../Context/Main';
import { GetAuthorSupportList, GetSubscriptions, IGetAuthorSupportList, ISubscription, modifyUserInfo, PostAuthorSupportUnsupport, StripePay, SubscriptionType } from '../../../Api/User';
import { backend_url } from '../../../App';
import { handleImageError } from './Discover';
import Navbar from '../../Intra/Discovered/Navbar';
import { GetUsers } from '../../../Interfaces/User';
import { AllWorldLanguages } from './TraductorEditInterface';
import { PostCredentialsEmailExist, PostCredentialsEmailSecretCode, PostCredentialsEmailVerify } from '../../../Api/Register';

const ModifyEmail: React.FC = () => {
    const {user, setuser, language, setlanguage} = useContext(MainContext);
    const [code, setCode] = useState('');
    const [token, setToken] = useState<string | undefined>(undefined);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [error, seterror] = useState<string | undefined>(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        if (user)
            setEmail(user.email);
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full overflow-y-auto bg-gray-100'>
                <div className='relative h-[10%]'>
                    <Navbar is_black={true} />
                </div>
                <div className='relative flex flex-col gap-4 py-4 px-[8%]'>
                    <div className='relative w-full h-full flex flex-col gap-4 justify-center items-center'>
                        <h1 className='text-3xl Montserrat-Bold border-b border-black'>Modifier votre email</h1>
                        {error && <h1 className='text-red-700 Montserrat-SemiBold'>{error}</h1>}
                        <input name='email' type='text' placeholder="E-mail" value={email} onChange={(e) => {
                            if (user)
                                setEmail(e.target.value);

                            PostCredentialsEmailExist(e.target.value).then((res) => {
                                if (res)
                                    seterror('Email déjà utilisé');
                                else
                                    seterror(undefined);
                            });
                        }} className='relative Inter-Regular outline-none text-sm w-full h-full border-2 bg-white rounded-xl p-2' />
                        <button className={`w-[170px] h-[55px] Montserrat-Regular rounded-xl
                            ${!email || error ? 'opacity-50 cursor-not-allowed border-2 border-gray-500 bg-gray-300 text-gray-500' : 'bg-gatek-400 text-white '}
                        `} onClick={() => {
                            if (email && !error && user) {
                                PostCredentialsEmailSecretCode(email).then((res) => {
                                    setToken(res);
                                });
                            }
                        }}>Confirmer</button>
                    </div>
                    {token && <div className='relative w-full flex flex-col justify-center items-center'>
                        <h1 className='text-3xl Montserrat-SemiBold'>Nous avons envoyé un code sur votre email</h1>
                        <img src={sendmail} className='w-[200px] h-[177px]' />
                        <input type='text' placeholder='Code de validation' value={code} onChange={(e) => setCode(e.target.value)} className='relative w-1/3 h-10 bg-gatek_gray-100 text-gatek_gray-700 rounded-xl outline-none text-center' />

                        <button className={`w-[170px] h-[55px] Montserrat-Regular rounded-xl
                            ${code.length === 0 ? 'opacity-50 cursor-not-allowed border-2 border-gray-500 bg-gray-300 text-gray-500' : 'bg-gatek-400 text-white '}
                        `} onClick={() => {
                            if (code.length > 0 && token) {
                                PostCredentialsEmailVerify(token, code).then((res) => {
                                    if (res.status === 200 && user) {
                                        GetUsers(user.id).then((res) => {
                                            if (res.status === 200 && res.data) {
                                                setuser(res.data);
                                                navigate('/parameters');
                                            }
                                        });
                                    }
                                });
                            }
                        }}>Confirmer</button>
                    </div>}
                </div>
            </div>
        </MainContext.Provider>
    );
}

export default ModifyEmail;