import React, {useContext, useEffect, useRef, useState} from 'react';
import test from '../../../Assets/Images/test.jpg';
import { Outlet, useNavigate } from 'react-router-dom';

import red_arrow from '../../../Assets/Images/red_arrow.png';
import unlike from '../../../Assets/Images/unlike.png';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';

import { MainContext } from '../../../Context/Main';
import { IArticle, IChapter, IDiscover, IOeuvre } from '../../../Interfaces/Discover';
import { getDiscoverd, getMangaInfo, Tags } from '../../../Api/Oeuvres';
import { backend_url } from '../../../App';
import OeuvreModal from './OeuvreModal';
import { GetArticles } from '../../Edit/Utils/IAdd';
import ArticleModal from './ArticleModal';
import ObjectBox from './ObjectBox';
import bg_girl_book from '../../../Assets/Images/bg_girl_book.webp';
import pandaroux_livre from '../../../Assets/Images/pandaroux_livre.webp';
import SpecificOeuvreModal from './SpecificOeuvreModal';
import { SearchingSliceManga } from '../../../Api/Search';
import { AxiosResponse } from 'axios';

export const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    // URL de votre image de remplacement
    e.currentTarget.src = logo_gatek;
};

const Dashboard: React.FC = () => {
    const {user, setuser, setcurrent_oeuvre, setcurrent_chapitre} = useContext(MainContext);
    const [discover_oeuvres, setdiscover_oeuvres] = useState<IOeuvre[]>([]);
    const [discover_articles, setdiscover_articles] = useState<IArticle[]>([]);
    const [tagData, setTagData] = useState<{oeuvres: IOeuvre[], tag: string}[]>([]); // État pour stocker les données
    const navigate = useNavigate();

    useEffect(() => {
        if (user?.id) {
            getDiscoverd().then((new_oeuvres) => {
                setdiscover_oeuvres(new_oeuvres);
            });
            GetArticles(user).then((new_articles) => {
                setdiscover_articles(new_articles);
            });
            const tmp = Tags.map(async (tag) => {
                const res = await SearchingSliceManga(tag);
                if (res.status === 200 && res.data) {
                    return { tag: tag, oeuvres: res.data };
                }
                return { tag: tag, oeuvres: [] };
            })
            Promise.all(tmp).then((res) => {
                setTagData(res);
            });
        }
    }, [user]);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full'>
                <div className='relative w-full h-screen'>
                    {discover_oeuvres.length > 0 && <SpecificOeuvreModal oeuvre={discover_oeuvres[0]} />}
                    {/* <div className='absolute z-50 w-[18%] h-2/3 p-4'>
                        <h1 className='absolute right-0 w-2/3 h-1/2 bg-white rounded-r-full rounded-t-full flex p-8 Inter-Regular items-center bg-opacity-80'>
                            🎊 La bêta de la nouvelle version de Gatek est disponible !
                        </h1>
                        <img className='absolute bottom-0 h-1/2 rounded-full  object-contain' src={pandaroux_livre} />
                    </div>

                    <div className="relative w-full h-full">
                        <img className="object-cover w-full h-full" src={bg_girl_book} />
                        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-white opacity-100"></div>
                    </div> */}
                </div>
                <div className='relative w-full h-full p-8'>
                    <div className='relative w-full h-[75%]'>
                        {/* filter discover_oeuvres by remove first element */}
                        <ObjectBox label="Nouvelles oeuvres" oeuvres={discover_oeuvres.filter((_, index) => index !== 0)} white={true} />
                    </div>
                    <div className='relative w-full h-[75%]'><ObjectBox label="Nouveaux articles" articles={discover_articles} white={true}/></div>
                    {tagData.map((item) => {
                        if (item.oeuvres.length > 0)
                            return <div className='relative w-full h-[75%] text-white'><ObjectBox label={item.tag} oeuvres={item.oeuvres} white={true} /></div>
                    })}
                </div>
            </div>
        </MainContext.Provider>
    );
}

export default Dashboard;