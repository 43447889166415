import React, {useContext, useEffect, useState} from 'react';
import test from '../../../Assets/Images/test.jpg';
import { Outlet, useNavigate } from 'react-router-dom';

import like from '../../../Assets/Images/like.png';
import unlike from '../../../Assets/Images/unlike.png';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';

import { MainContext } from '../../../Context/Main';
import { IArticle, IComment, IDBChapter, IDiscover, IOeuvre } from '../../../Interfaces/Discover';
import { getMangaInfo, getDiscoverd, getChapter, getBookComments, BookCommentRequest, getMangaInfoById, getMangaInfoByID } from '../../../Api/Oeuvres';
import { backend_url } from '../../../App';
import { AllWorldLanguages } from './TraductorEditInterface';
import { getUser } from '../../../Api/User';
import { User } from '../../../Interfaces/User';
import ObjectBox from './ObjectBox';
import { AddComment, SendLikeDislike, SendLikeDislikeBook, SendLikeDislikeBookComment, getComments } from '../../Edit/Utils/IAdd';
import Loading from './Loading/Loading';
import { Textfit } from 'react-textfit';

const OeuvreModal: React.FC<IOeuvre> = (d) => {
    const {user, setuser, setcurrent_oeuvre, setcurrent_chapitre, setseedetails} = useContext(MainContext);
    const [oeuvre, setoeuvre] = useState<IOeuvre>(d);
    const [author, setauthor] = useState<User | undefined>(undefined);
    const [selected_language, setselected_language] = useState<string>(d.originalLanguage);
    const [chapters, setchapters] = useState<IDBChapter[]>([]);
    const [comment, setcomment] = useState<IComment>({
        comment: "",
        author_ID: user?.id || "-1",
    });
    const [comments, setcomments] = useState<IComment[]>([]);
    const navigate = useNavigate();

    const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        // URL de votre image de remplacement
        e.currentTarget.src = logo_gatek;
    };

    useEffect(() => {
        getMangaInfoByID(d.id).then((ovuer) => {
            setoeuvre(ovuer); 

            getUser(ovuer.author_ID || "").then((u) => {
                if (u) {
                    setauthor(u);
                }
            });
            sessionStorage.setItem('chapter_language', 'original');
            getChapter(ovuer.id, "original", user?.id).then((chapter) => {
                console.log("chapter", chapter);
                setchapters(chapter || []);
            });
        });
    }, []);

    useEffect(() => {
        getBookComments(oeuvre.id).then((response) => {
            console.log(response.data)
            if (response.status === 200)
                setcomments(response.data);
            else
                setcomments([]);
        });
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            {author ? <div onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }} className='relative z-50 w-1/2 h-3/4 opacity-100 bg-gatek_red-900 rounded-2xl overflow-hidden invisible-scrollbar overflow-y-auto shadow-black shadow-xl'>
                <div className='relative w-full h-[80%] flex overflow-hidden'>
                    <div className='relative w-[50%] h-full p-2 flex flex-col'>
                        <div className='relative w-full px-4 h-[20%] gap-4'>
                            <Textfit mode="single" min={8} max={64} className="relative Poppins-SemiBold text-white">
                                {oeuvre.name}
                            </Textfit>
                        </div>

                        <h1 className="w-full max-h-[60%] border-b p-2 text-sm justify-center Inter-Regular text-white text-justify overflow-y-scroll custom-scrollbar">
                            {oeuvre.description}
                        </h1>

                        <div className='relative w-full h-[20%] p-2'>
                            <h1 className='relative Inter-Regular text-gray-500 flex text-sm gap-2'>Tags:
                                <p className='relative Inter-Regular text-white italic'>
                                {oeuvre.tags.length > 0 ? (
                                    oeuvre.tags.map((tag, index) => (
                                        <span key={index}>
                                            {index > 0 && ', '}
                                            {tag}
                                        </span>
                                    ))
                                ) : (
                                    "Aucun tag"
                                )}</p>
                            </h1>
                        </div>

                        <div onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            sessionStorage.setItem('author_id', author.id);
                            navigate(`./author`);
                            setseedetails(undefined);
                        }} className='absolute bottom-2 right-2 flex items-center justify-between gap-2 transform hover:scale-105 duration-100 cursor-pointer'>
                            <img className='relative object-contain w-8 rounded-full bg-white border' src={backend_url+author.avatar} onError={handleImageError} />
                            <h1 className='Inter-Regular text-sm text-white'>{author.username}</h1>
                        </div>

                        <div className='absolute bottom-0 w-[20%] h-[8%] flex justify-end gap-4 Inter-Regular'>
                            <div className='relative h-full flex flex-col truncate'>
                                <img src={like} className='h-[60%] object-contain transform transition-all duration-150 hover:scale-90 cursor-pointer' onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (user) {
                                        SendLikeDislikeBook(user, oeuvre, oeuvre, true).then((response) => {
                                            getMangaInfoById(oeuvre.id).then((response) => {
                                                setoeuvre(response)
                                            })
                                        })
                                    }
                                }}/>
                                <p className='relative self-end text-white text-sm'>{oeuvre.likes || 0}</p>
                            </div>
                            <div className='relative h-full flex flex-col truncate'>
                                <img src={unlike} className='h-[60%] object-contain transform transition-all duration-150 hover:scale-90 cursor-pointer' onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (user) {
                                        SendLikeDislikeBook(user, oeuvre, oeuvre, false).then((response) => {
                                            getMangaInfoById(oeuvre.id).then((response) => {
                                                setoeuvre(response)
                                            })
                                        })
                                    }
                                }} />
                                <p className='relative self-end text-white text-sm'>{oeuvre.dislikes || 0}</p>
                            </div>
                        </div>

                    </div>

                    <div className='relative w-[50%] h-full'>
                        <img className="" src={backend_url + oeuvre.cover} />
                        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent via-transparent to-gatek_red-900 opacity-100"></div>
                        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-l from-transparent via-transparent to-gatek_red-900 opacity-100"></div>
                    </div>

                </div>

                <div className='relative w-full h-[10%] mt-[5%] flex justify-between items-center p-4'>
                    <h1 className='Inter-SemiBold text-white text-2xl w-1/2'>Chapitre(s)</h1>
                    <select onClick={
                        (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }
                    } onChange={
                        (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setselected_language(e.target.value);
                            console.log(e.target.value, oeuvre.originalLanguage);
                            sessionStorage.setItem('chapter_language', e.target.value);
                            if (oeuvre.originalLanguage === e.target.value) {
                                getChapter(d.id, "original", user?.id).then((chapter) => {
                                    setchapters(chapter || []);
                                });
                            } else {
                                getChapter(d.id, e.target.value, user?.id).then((chapter) => {
                                    setchapters(chapter || []);
                                });
                            }
                        }
                    } value={selected_language} className='relative p-1 w-[30%] text-white outline-none  Inter-SemiBold text-base border-2 rounded bg-gray-700 border-gray-600'>
                        <option className='bg-gray-700' value={oeuvre.originalLanguage}>[Original] {oeuvre.originalLanguage}</option>
                        {oeuvre.languageTranslated && oeuvre.languageTranslated.map((langue, index) => {
                            return (
                                <option className='bg-gray-700' value={langue}>{langue}</option>
                            );
                        })}
                    </select>
                </div>

                <div className='relative w-full h-full p-4'>
                    <ObjectBox label={chapters.length+" Chapitre(s) disponible"} oeuvres={[d]} chapters={chapters} white={true} isCol={true} />
                </div>

                <div className='relative w-full h-full gap-2 p-2'>
                    <div className='relative w-full h-full flex flex-col gap-4 border-t'>
                        <h1 className='mt-4 w-full h-[5%] p-4 Poppins-SemiBold text-2xl text-white'>({comments.length}) Commentaire(s):</h1>
                            <div className='relative w-full max-h-[55%] flex flex-col p-2 gap-4 overflow-hidden overflow-y-scroll overflow-x-hidden'>
                                {comments?.map((comment, index) => {
                                    return (
                                        <div key={index} className='relative w-full text-gatek_red-900 bg-white p-4 shadow-black shadow-md flex justify-between'>
                                            <div className='relative w-[90%] h-full flex flex-col gap-2'>
                                                <h1 className='relative Inter-Bold text-sm w-full border-b'>{comment.author_ID}</h1>
                                                <h1 className='relative Inter-Regular text-xs w-full'>{comment.comment}</h1>
                                            </div>
                                            <div className='relative w-[10%] gap-4 grid grid-cols-2 bg-gatek_red-900 px-4 py-2 rounded-full text-white'>
                                                <div className='relative w-full h-full flex flex-col'>
                                                    <img src={like} className='h-[70%] object-contain transform transition-all duration-150 hover:scale-90 cursor-pointer' onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        if (user) {
                                                            SendLikeDislikeBookComment(user, oeuvre, comment, true).then((response) => {
                                                                getBookComments(oeuvre.id).then((art) => {
                                                                    setcomments(art.data);
                                                                })
                                                            })
                                                        }
                                                    }} />
                                                    <p className='relative h-[30%] self-end text-xs'>{comment.likes || 0}</p>
                                                </div>
                                                <div className='relative w-full h-full flex flex-col'>
                                                    <img src={unlike} className='h-[70%] object-contain transform transition-all duration-150 hover:scale-90 cursor-pointer' onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        if (user) {
                                                            SendLikeDislikeBookComment(user, oeuvre, comment, false).then((response) => {
                                                                getBookComments(oeuvre.id).then((art) => {
                                                                    setcomments(art.data);
                                                                })
                                                            })
                                                        }
                                                    }} />
                                                    <p className='relative h-[30%] self-end text-xs'>{comment.dislikes || 0}</p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                            })}
                        </div>

                        <div className='relative w-full h-[25%] p-4 flex justify-between gap-4'>
                            <textarea placeholder='Ajouter un commentaire' value={comment?.comment} onChange={(e) => {
                                setcomment({...comment, comment: e.target.value});
                            }} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }} className='Poppins-Regular outline-none bg-gray-700 text-white border-gray-600 text-base p-2 w-full h-full resize-none bg-transparent border-2 rounded-2xl' />
                            <button className='
                                Poppins-SemiBold text-xs bg-gatek-200 text-white rounded-lg px-4 py-2
                                transform hover:scale-95 transition-all duration-300 w-1/4 h-1/4 self-end
                            ' onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (user)
                                    BookCommentRequest(user.id, oeuvre.id, oeuvre.id, comment.comment).then((response) => {
                                        if (response.status === 200) {
                                            setcomment({comment: "", author_ID: user.id});
                                            getBookComments(oeuvre.id).then((response) => {
                                                if (response.status === 200)
                                                    setcomments(response.data);
                                                else 
                                                    setcomments([]);
                                            });
                                        }
                                    });
                            }}>Ajouter le commentaire
                            </button>
                        </div>
                    </div>
                </div>
            </div> : <Loading />}
        </MainContext.Provider>
    );
}

export default OeuvreModal;