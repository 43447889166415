import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import redcross from '../../../Assets/Images/add.png';
import bin from '../../../Assets/Images/recycle-bin - Copie.png';
import add_tag from '../../../Assets/Images/add_tag.png';
import down_black from '../../../Assets/Images/down_black.png';
import up from '../../../Assets/Images/up_black.png';
import down from '../../../Assets/Images/down_black.png';
import red_arrow from '../../../Assets/Images/red_arrow.png';
import recycle_bin from '../../../Assets/Images/recycle-bin.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from '../Discovered/Navbar';
import { IArticle, IComment, IDBChapter, IOeuvre } from '../../../Interfaces/Discover';
import { BookCommentRequest, DeleteChapterRequest, DeleteMangaRequest, getBookComments, getChapter, getChapterById, getDiscoverd, getMangaInfoByID, GetPossibleTranslation, ModifyChapterRequest, ModifyMangaRequest, PostMangaChapterModify, Tags } from '../../../Api/Oeuvres';
import { SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getMyArticles, getMyOeuvres, getUser } from '../../../Api/User';

const ModifyChapter: React.FC = () => {
    const {user} = useContext(MainContext);
    const [oeuvre, setOeuvre] = useState<IOeuvre | undefined>(undefined);
    const [chapters, setchapters] = useState<IDBChapter[]>([]);
    const [author, setauthor] = useState<User | undefined>(undefined);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    const url = new URL(window.location.href);
    const chapter_id = url.searchParams.get('id');
    const manga_id = url.searchParams.get('manga_id');

    const [chapter, setchapter] = useState<IDBChapter | undefined>(undefined);

    useEffect(() => {
        if (chapter_id !== null && manga_id !== null) {
            getChapterById(manga_id, chapter_id).then((chapter) => {
                setchapter(chapter);
                console.log("chapter", chapter);
            });
        }
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full bg-gray-100 overflow-x-hidden'>
                <div className='relative h-[10%]'>
                    <Navbar is_black={true} />
                </div>
                <div className='relative px-[8%] py-4 flex flex-wrap gap-4'>

                    <div className='relative w-[100%] h-[40%] flex flex-col gap-4 items-center justify-center'>
                        <input type='text' className='relative w-1/2 h-10 bg-white rounded-xl p-2 outline-none' placeholder='Nom du chapitre' value={chapter?.name} onChange={(e) => {
                            if (chapter)
                                setchapter({...chapter, name: e.target.value});
                        }} />
                        <img className='relative w-1/6 h-full object-contain' onError={handleImageError} src={backend_url+chapter?.cover} />
                    </div>

                    <div className='relative w-full h-[50%] flex flex-col gap-4'>
                        <h1 className='relative text-2xl Poppins-SemiBold'>Pages du chapitre - {chapter?.pages?.length || 0} page(s)</h1>
                        <div className='relative w-full h-[90%] flex flex-wrap gap-4 select-none'>
                            {chapter?.pages?.map((page, index) => {
                                return (
                                    <div key={index} className='flex-shrink-0 w-[170px] h-[242px] flex items-center justify-center border-red-700 relative pr-1 pb-1 transform hover:scale-95 transition duration-150 cursor-pointer'>
                                        <div onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            // déplacer l'image vers la gauche

                                            if (index > 0) {
                                                // move image to the left
                                                setchapter((prev) => {
                                                    if (prev) {
                                                        const tmp = {...prev};
                                                        if (tmp.pages)
                                                            [tmp.pages[index], tmp.pages[index-1]] = [tmp.pages[index-1], tmp.pages[index]];
                                                        return tmp;
                                                    }
                                                    return prev;
                                                });
                                            }
                                        }} className='absolute z-10 left-0 top-0 h-full w-[16%] opacity-0 transition-all duration-300
                                            hover:bg-gradient-to-l from-gray-300 via-gray-200 to-transparent hover:opacity-80 cursor-pointer flex items-center justify-center transform rotate-180
                                        '>
                                            <img src={red_arrow} className='w-1/2 object-contain' />
                                        </div>
                                        <img onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            // remove image
                                            setchapter((prev) => {
                                                if (prev) {
                                                    const tmp = {...prev};
                                                    if (tmp.pages)
                                                        tmp.pages.splice(index, 1);
                                                    return tmp;
                                                }
                                                return prev;
                                            });
                                        }} src={recycle_bin} className='object-contain absolute z-10 h-full w-[66%] opacity-0 hover:bg-gray-300 hover:opacity-80 rounded-full'/>
                                        <div onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            // déplacer l'image vers la droite
                                            let temp = chapter
                                            setchapter((prev) => {
                                                if (prev) {
                                                    if (prev.pages && index < prev.pages.length-1) {
                                                        const tmp = {...prev};
                                                        if (tmp.pages)
                                                            [tmp.pages[index], tmp.pages[index+1]] = [tmp.pages[index+1], tmp.pages[index]];
                                                        return tmp;
                                                    }
                                                }
                                                return prev;
                                            });
                                        }} className='absolute z-10 right-0 top-0 h-full w-[16%] opacity-0 transition-all duration-300
                                            hover:bg-gradient-to-l from-gray-300 via-gray-200 to-transparent hover:opacity-80 cursor-pointer flex items-center justify-center transform
                                        '>
                                            <img src={red_arrow} className='w-1/2 object-contain' />
                                        </div>
                                        <img src={backend_url+page} alt={"Ajouter"} className='relative object-contain w-full h-full'/>
                                    </div>                                
                                );
                            })}
                        </div>
                    </div>

                    <div className='relative w-full h-[50px] flex justify-end'>
                        <button onClick={() => {
                            if (chapter_id && manga_id && chapter && chapter.pages)
                                PostMangaChapterModify(manga_id, chapter_id, chapter.name, true, chapter.pages).then((res) => {
                                    if (res.status === 200)
                                        navigate('/discover');
                                });
                        }}className="relative w-[20%] h-full rounded-2xl bg-gatek-400 text-white Montserrat-SemiBold flex items-center justify-center gap-2">
                            Modifier
                        </button>
                    </div>

                    <div  className='relative w-full h-full border-t-2 border-black py-4 flex justify-end'>
                        <button onClick={() => {
                            if (chapter_id && manga_id)
                                DeleteChapterRequest(manga_id, chapter_id).then((res) => {
                                    if (res.status === 200)
                                        navigate('/discover');
                                });
                        }}className="relative w-[18%] h-[30px] rounded-2xl bg-gatek-400 text-white Montserrat-Regular flex items-center justify-center gap-2">
                            <img src={bin} className='w-5 h-5 object-contain' />
                            Supprimer le chapitre
                        </button>
                    </div>
                </div>

            </div>
        </MainContext.Provider>
    );
}

export default ModifyChapter;