import React, {useEffect, useState} from 'react';
import logo_gatek from '../../Assets/Images/logo_gatek.png';
import { useNavigate } from 'react-router-dom';

import notFound from '../Assets/Images/404.gif';

const ErrorNotFount: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className='relative w-full h-full bg-gatek_red-500 flex flex-col justify-center'>
            <div className='relative flex flex-col items-center text-6xl bg-gatek_gray-100 w-1/2 h-1/2 p-8 self-center rounded-xl shadow-2xl'>
                <p className='PermanentMarker-Regular'>404</p>
                <p className='PermanentMarker-Regular'>Page not found</p>
                <img className='h-full object-contain cursor-pointer' onClick={() => navigate("/")} src={notFound}/>
            </div>
        </div>
    );
}

export default ErrorNotFount;