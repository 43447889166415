import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import little_comment_black from '../../../Assets/Images/little_comment_black.png';
import white_plus from '../../../Assets/Images/white_plus.png';
import black_pen from '../../../Assets/Images/black_pen.png';
import up_black from '../../../Assets/Images/up_black.png';
import down_black from '../../../Assets/Images/down_black.png';
import red_bin from '../../../Assets/Images/red_bin.png';
import down from '../../../Assets/Images/down_black.png';
import down_green from '../../../Assets/Images/down_green.png';
import up_red from '../../../Assets/Images/up_red.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from '../Discovered/Navbar';
import { IArticle, IComment, IDBChapter, IOeuvre } from '../../../Interfaces/Discover';
import { BookCommentRequest, GetAuthorWorkingTeams, getBookComments, getChapter, getDiscoverd, getMangaInfoByID, GetPossibleTranslation, IGetAuthorWorkingTeams } from '../../../Api/Oeuvres';
import { ArticleDeleteRequest, SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getMyArticles, getMyOeuvres, getUser } from '../../../Api/User';

const MyTraductors: React.FC = () => {
    const {user} = useContext(MainContext);
    const [teams, setTeams] = useState<IGetAuthorWorkingTeams[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        GetAuthorWorkingTeams().then((articles) => {
            setTeams(articles);
        });
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-[100%] px-[16%] flex flex-wrap py-4 gap-8'>
                {teams.map((team, i) => {
                    return (
                        <div key={i} className='w-[265px] h-[265px] bg-white border rounded-xl flex flex-col gap-4 p-2'>
                            <div className='h-[40px] flex gap-2 items-center justify-center'>
                                <img src={backend_url+team.team_icon} className='h-full object-contain rounded-full' onError={handleImageError} />
                                <h1 className='text-sm Montserrat-SemiBold'>{team.team_name}</h1>
                            </div>
                            <div className='relative h-[182px] flex flex-col gap-2 p-2 overflow-hidden items-center justify-center'>
                                {team.mangas_cover.map((manga, i) => {
                                    if (i > 5)
                                        return;
                                    return (
                                        <img style={{
                                            top: `${i*20}px`,
                                            left: `${i*40}px`
                                        }} src={backend_url+manga} className='absolute rounded-xl w-[100px] h-[142px] object-contain' onError={handleImageError} />
                                        );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </MainContext.Provider>
    );
}

export default MyTraductors;