import React, {useEffect, useState} from 'react';
import logo_gatek from '../../Assets/Images/logo_gatek.png';
import { useNavigate } from 'react-router-dom';

import notFound from '../../Assets/Images/404.gif';
import gatek from '../../Assets/Images/_Gatek.png';
import on from '../../Assets/Images/wifi.png';
import off from '../../Assets/Images/no-wifi.png';
import { backend_url } from '../../App';
import { Get } from '../../Api/Axios';


export const GetServerState = async ():Promise<boolean> => {
    try {
        const response = await Get('/', {});
        if (response.status === 200)
            return true;
        return false;
    } catch (error) {
        return false;
    }
}

const WatchDog: React.FC = () => {
    const [conneted, setconneted] = useState<boolean>(true);

    useEffect(() => {
        const interval = setInterval(() => {
            GetServerState().then((res) => {
                setconneted(res);
            }).catch((err) => {
                setconneted(false);
            });
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            {conneted !== true && <div className='fixed z-50 top-0 left-0 w-full h-full bg-white bg-opacity-80 flex flex-col items-center justify-center'>
                <img src={notFound} className='w-1/4 h-1/4 object-contain' />
                <h1 className='text-4xl Poppins-SemiBold text-gatek_red-900'>Les services de Gatek sont hors ligne</h1>
            </div>
            }
            <div className='relative px-4 py-2 w-full bg-gatek_red-900 border-2 rounded-xl border-gatek-200 flex truncate'>
                <div className= {`flex justify-between w-full h-full gap-2 ${!conneted && "animate-pulse"}`}>
                    <img className='w-5 h-5 object-contain' src={conneted ? on : off} />
                    <h1 className='flex gap-1 Poppins-SemiBold text-gatek_gray-100 text-sm'>Service Gatek: <p
                    className={`${conneted  ? "text-green-600" : "text-red-600"}
                    `}>{conneted ? "En ligne" : "Hors ligne"}</p></h1>
                </div>
            </div>
        </>
    );
}

export default WatchDog;