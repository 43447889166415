import React, {useContext, useEffect, useState} from 'react';
import red_check from '../../../Assets/Images/red_check.png';
import sendmail from '../../../Assets/Images/sendmail.png';
import disconnect from '../../../Assets/Images/disconnect.png';
import black_coin from '../../../Assets/Images/black_coin.png';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import { MainContext } from '../../../Context/Main';
import { GetAuthorSupportList, GetSubscriptions, IGetAuthorSupportList, ISubscription, modifyUserInfo, PostAuthorSupportUnsupport, PostCredentialsPasswordModify, StripePay, SubscriptionType } from '../../../Api/User';
import { backend_url } from '../../../App';
import { handleImageError } from './Discover';
import Navbar from '../../Intra/Discovered/Navbar';
import { GetUsers } from '../../../Interfaces/User';
import { AllWorldLanguages } from './TraductorEditInterface';
import { PostCredentialsEmailExist, PostCredentialsEmailSecretCode, PostCredentialsEmailVerify } from '../../../Api/Register';

const ModifyPayment: React.FC = () => {
    const {user, setuser, language, setlanguage} = useContext(MainContext);
    const [card, setCard] = useState<string | undefined>(undefined);
    const [expiration, setExpiration] = useState<string | undefined>(undefined);
    const [security, setSecurity] = useState<string | undefined>(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        setCard(sessionStorage.getItem('card') || '');
        setExpiration(sessionStorage.getItem('expiration') || '');
        setSecurity(sessionStorage.getItem('security') || '');
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full overflow-y-auto bg-gray-100'>
                <div className='relative h-[10%]'>
                    <Navbar is_black={true} />
                </div>
                <div className='relative flex flex-col gap-4 py-4 px-[8%]'>
                    <div className='relative w-full h-full flex flex-col gap-4 justify-center items-center'>
                        <h1 className='text-3xl Montserrat-Bold'>Modifier votre moyen de paiement</h1>

                        <h1 className='Montserrat-SemiBold border-b border-black w-full'>Numéro de carte</h1>
                        <input id="card" onChange={(e) => {
                            setCard(e.target.value);
                        }} value={card} type='text' placeholder="Numéro de carte" className='relative Inter-Regular outline-none text-sm w-full h-full border-2 bg-white rounded-xl p-2' />

                        <h1 className='Montserrat-SemiBold border-b border-black w-full'>Date d'expiration</h1>
                        <input id="expiration" onChange={(e) => {
                            setExpiration(e.target.value);
                        }} value={expiration} type='text' placeholder="MM/YY" className='relative Inter-Regular outline-none text-sm w-full h-full border-2 bg-white rounded-xl p-2' />

                        <h1 className='Montserrat-SemiBold border-b border-black w-full'>Code de sécurité</h1>
                        <input onChange={(e) => {
                            setSecurity(e.target.value);
                        }} value={security} id="security" type='text' placeholder="CVC" className='relative Inter-Regular outline-none text-sm w-full h-full border-2 bg-white rounded-xl p-2' />

                        <button className={`w-[170px] h-[55px] Montserrat-Regular rounded-xl
                            ${'bg-gatek-400 text-white '}
                        `} onClick={() => {
                            const card = document.getElementById('card') as HTMLInputElement;
                            const expiration = document.getElementById('expiration') as HTMLInputElement;
                            const security = document.getElementById('security') as HTMLInputElement;

                            sessionStorage.setItem('card', card.value);
                            sessionStorage.setItem('expiration', expiration.value);
                            sessionStorage.setItem('security', security.value);
                        }}>Confirmer</button>
                    </div>
                </div>
            </div>
        </MainContext.Provider>
    );
}

export default ModifyPayment;