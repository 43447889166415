import React from "react";
import { User } from "../Interfaces/User";
import { IArticle, IDBChapter, IOeuvre } from "../Interfaces/Discover";
import { ITeam } from "../Api/Team";

export interface IMainContext {
    token: string | undefined,
    setToken: React.Dispatch<React.SetStateAction<string | undefined>>;

    user: User | undefined,
    setuser: React.Dispatch<React.SetStateAction<User | undefined>>;

    current_oeuvre: IOeuvre | undefined,
    setcurrent_oeuvre: React.Dispatch<React.SetStateAction<IOeuvre | undefined>>;

    current_chapitre: IDBChapter | undefined,
    setcurrent_chapitre: React.Dispatch<React.SetStateAction<IDBChapter | undefined>>;
    
    myteam: ITeam | undefined,
    setmyteam: React.Dispatch<React.SetStateAction<ITeam | undefined>>;

    current_language: string | undefined,
    setcurrent_language: React.Dispatch<React.SetStateAction<string | undefined>>;

    language: string;
    setlanguage: React.Dispatch<React.SetStateAction<string>>;

    seedetails : IOeuvre | undefined,
    setseedetails : React.Dispatch<React.SetStateAction<IOeuvre | undefined>>;
    
    seearticle : IArticle | undefined,
    setseearticle : React.Dispatch<React.SetStateAction<IArticle | undefined>>;
}

export const MainContext = React.createContext<IMainContext>({
    token: undefined,
    setToken: () => {},

    user: undefined,
    setuser: () => {},
    current_oeuvre: undefined,
    setcurrent_oeuvre: () => {},
    current_chapitre: undefined,
    setcurrent_chapitre: () => {},
    myteam: undefined,
    setmyteam: () => {},

    current_language: undefined,
    setcurrent_language: () => {},

    language: 'fr',
    setlanguage: () => {},

    seedetails: undefined,
    setseedetails: () => {},

    seearticle: undefined,
    setseearticle: () => {},
});