import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import star from '../../../Assets/Images/star.png';
import black_star from '../../../Assets/Images/black_star.png';
import { Outlet, useNavigate } from 'react-router-dom';

import { MainContext } from '../../../Context/Main';
import { User } from '../../../Interfaces/User';
import { getMyArticles, getMyOeuvres, getUser } from '../../../Api/User';
import { IArticle, IOeuvre } from '../../../Interfaces/Discover';
import { backend_url } from '../../../App';
import { handleImageError } from './Discover';
import ObjectBox from './ObjectBox';

const Author: React.FC = () => {
    const {user, setuser} = useContext(MainContext);
    const navigate = useNavigate();
    const [author_id, setauthor_id] = useState<string>("-1");
    const [author, setauthor] = useState<User | undefined>(undefined);
    const [myoeuvre, setmyoeuvre] = useState<IOeuvre[]>([]);
    const [myarticles, setmyarticles] = useState<IArticle[]>([]);

    useEffect(() => {
        const author_id_temp = sessionStorage.getItem('author_id') || "-1";
        setauthor_id(author_id_temp);
        getUser(author_id_temp).then((u) => {
            if (u) {
                setauthor(u);
                getMyOeuvres(u).then((oeuvres) => {
                    setmyoeuvre(oeuvres);
                });
                // # get article of author_id_temp
                getMyArticles(u).then((articles) => {
                    setmyarticles(articles);
                });
            }
        });
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            {author && <div className='relative w-full h-full overflow-y-auto text-white'>
                <div className='relative w-full h-1/4 flex p-2 shadow-md shadow-black bg-gradient-to-r from-gatek_red-500 via-gatek_red-400 to-gatek_red-300'>
                    <img className='object-contain rounded-full' src={author?.avatar || logo_gatek} />
                    <div className='relative h-full w-full flex flex-col gap-2'>
                        <p className='Poppins-SemiBold text-3xl border-b border-gatek-200 text-white'>{
                        author?.role === 'author' ? '🖋️ Auteur' :
                        author?.role === 'translator' ? '🌍 Traducteur' :
                        author?.role === 'reader' ? '📖 Lecteur' :
                        author?.role === 'admin' ? '💻 Administrateur' : 'Inconnu'
                        }</p>
                        <p className='Inter-SemiBold text-white text-xl'>{author?.username}</p>
                    </div>
                </div>
                <div className='relative w-full h-full p-8'>
                <div className='relative w-full h-[75%]'><ObjectBox label="Oeuvres publiées" oeuvres={myoeuvre} white={true} /></div>
                <div className='relative w-full h-[45%]'><ObjectBox label="Vos articles" articles={myarticles} white={true} /></div>
                </div>
            </div>}
        </MainContext.Provider>
    );
}

export default Author;