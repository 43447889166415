import React, {useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';

import logo_gatek from '../../Assets/Images/logo_gatek.png';
import auteur from '../../Assets/Images/auteur.png';
import lecteur from '../../Assets/Images/lecteur.png';
import traducteur from '../../Assets/Images/traducteur.png';
import { MainContext } from '../../Context/Main';
import { registerNewUser } from '../../Api/Register';
import { loginRequest } from '../../Api/login';
import { SetStorage } from '../../Api/Storage';

const Choice: React.FC = () => {
    const {user, setuser, setToken} = useContext(MainContext);
    const [error, seterror] = useState('');

    useEffect(() => {
        if (user) {
            // set use role to author by default
            setuser({...user, role: 'reader'})
        }
        document.body.className = 'bg-gatek_red-500';
        return () => {
            document.body.className = '';
        };
    }, []);

    const navigate = useNavigate();
    return (
        <div className='relative w-full h-full bg-gatek_red-500 flex flex-col items-center justify-center p-8 overflow-y-scroll'>
            <div className='relative h-[20%] w-full flex items-center justify-center cursor-pointer' onClick={() => navigate('/')}>
                <img src={logo_gatek} />
                <p className='PermanentMarker-Regular text-gatek_gray-100 text-6xl'>GATEK</p>
            </div>
            <div className='relative w-full flex gap-1'>
                <div className='relative w-1/3 h-full  flex flex-col px-16 py-16 gap-8 overflow-hidden'>
                    {user?.role === 'author' && <div className='relative bg-gatek_gray-100 w-full h-1/4 rounded-xl Inter-Regular p-4 text-sm shadow-2xl duration-200'>
                        Un compte auteur permet de publier des chapitres et des livres, ainsi que de les modifier. Vous pouvez aussi consulter les statistiques de vos oeuvres et publier des articles.
                    </div>}
                    {user?.role === 'reader' && <div className='relative bg-gatek_gray-100 w-full h-1/4 rounded-xl Inter-Regular p-4 text-sm shadow-2xl duration-200'>
                        Un compte lecteur permet de lire des chapitres et des livres, ainsi que de les commenter.
                    </div>}
                    {user?.role === 'translator' && <div className='relative bg-gatek_gray-100 w-full h-1/4 rounded-xl Inter-Regular p-4 text-sm shadow-2xl duration-200'>
                        Un compte traducteur permet de traduire des chapitres et des livres, ainsi que de les modifier.
                    </div>}
                </div>
                <div className='relative bg-gatek_gray-100 w-1/3 h-full rounded-xl flex flex-col px-16 py-8 overflow-hidden'>
                    <p className='relative Poppins-SemiBold text-3xl self-center'>Vous souhaitez etre ?</p>
                    <div className='relative w-full h-5/6 flex flex-col gap-8 items-center justify-center'>
                        <div id='author' className={`
                            ${user?.role === 'author' ? 'border-gatek_red-500 text-gatek_red-500 scale-105' : 'border-gatek_gray-100 text-gray-500 opacity-50'}
                            relative w-1/2 h-1/4 flex flex-col items-center border rounded-xl cursor-pointer transform duration-150 hover:scale-105
                        `} onClick={() => {if (user)setuser({...user, role: 'author'})}}>
                            <img src={auteur} className='w-full h-5/6 object-contain'/>
                            <p className={`Poppins-Bold h-1/6 text-sm`}>Auteur</p>
                        </div>
                        <div id='reader' className={`
                            ${user?.role === 'reader' ? 'border-gatek_red-500 text-gatek_red-500 scale-105' : 'border-gatek_gray-100 text-gray-500 opacity-50'}
                            relative w-1/2 h-1/4 flex flex-col items-center border rounded-xl cursor-pointer transform duration-150 hover:scale-105
                            `} onClick={() => {if (user)setuser({...user, role: 'reader'})}}>
                            <img src={lecteur} className='w-full h-5/6 object-contain'/>
                            <p className={`Poppins-Bold h-1/6 text-sm`}>Lecteur</p>
                        </div>
                    </div>

                    <h1 className='relative Poppins-SemiBold text-sm self-center text-gatek_red-500'>{error}</h1>
                    <button name="confirm" onClick={()=> {
                        if(user && user.role !== '') {
                            registerNewUser(user).then((res) => {
                                if (res && res.status === 200) {
                                    if (user.email && user.password)
                                        loginRequest(user.email, user?.password).then((res) => {
                                            if (res.status === 200) {
                                                setToken(res.data.token);
                                                sessionStorage.setItem('token', res.data.token);
                                                SetStorage(res.data.token, 'user_id', res.data.id);
                                                navigate('../register/email');
                                            } else {
                                                if (res.data)
                                                    seterror(res.data.error);
                                                else
                                                    seterror('Une erreur est survenue');
                                            }
                                        })
                                } else {
                                    seterror(res?.data.error)
                                }
                            })
                        }
                    }}
                    className={` ${user?.role === '' ? 'bg-gray-500 cursor-not-allowed' : 'bg-gatek_red-500 cursor-pointer hover:scale-105'}
                        relative text-gatek_gray-100 Poppins-Bold self-end mt-8
                        rounded-xl w-full h-16 text-xl shadow-2xl transform duration-150 overflow-hidden
                    `}>Confirmez votre choix</button>
                </div>
            </div>
        </div>
    );
}

export default Choice;