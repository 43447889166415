import { Get, AxiosResponse, Post } from './Axios';
import { IOeuvre } from '../Interfaces/Discover';

export interface UserInfo {
    name: String,
    id: string, 
    icon: string
}


export interface SearchResponseType {
    books: Array<IOeuvre>
    authors: Array<UserInfo>
    teams: Array<UserInfo>
}

// Route existante mais pas sur dev pour le moment
export const searchingRequest = async (characters: string):Promise<AxiosResponse> => {    
    return await Get('/search', { name: characters });
    // if (characters !== "")
    //     return {status: 200, statusText: "GOOD", data: {
    //         "books": [{name: "book1", id: "65ccbdb5ae3a70fd4a7a5256", cover: "/uploads/OP2/Chapitre 0/original/31f0b5ac-11ea-433d-bbe3-54392b11a61b.jpg"}, {name: "book2", id: "2", cover: "/uploads/OP2/Chapitre 0/original/31f0b5ac-11ea-433d-bbe3-54392b11a61b.jpg"}, {name: "book3", id: "3", cover: "/uploads/OP2/Chapitre 0/original/31f0b5ac-11ea-433d-bbe3-54392b11a61b.jpg"}], 
    //         "authors": [{name: "author1", id: "65ccb985ae3a70fd4a7a50fb", icon: ""}, {name: "author2", id: "102", icon: ""}, {name: "author3", id: "103", icon: ""}], 
    //         "teams": [{name: "team1", id: "101", icon: ""}, {name: "team2", id: "101", icon: ""}, {name: "team3", id: "101", icon: ""}]
    // }};
    // else 
    //     return {status: 200, statusText: "GOOD", data: {
    //         "books": [], 
    //         "authors": [], 
    //         "teams": []
    //     }};
}

export const SearchingSliceManga = async (tag: string):Promise<AxiosResponse> => {    
    return await Post('/suggestion/slice/manga', JSON.stringify({
        slice: 10,
        index: 0,
        params: {
          tags: [
            tag
          ],
          recent: true,
          alpha: false
        }
      }));
}

export interface IPostSuggestionSliceManga {
  name: string,
  tags: string[],
  author: string,
  author_ID: string,
  cover: string,
  id: string
}
export const PostSuggestionSliceManga = async (name: string, slice: number, index: number, tags: string[], recent: boolean, alpha: boolean, author: string):Promise<IPostSuggestionSliceManga[]> => {
  const rest = await Post('/suggestion/slice/manga', JSON.stringify({
    name: name,
    slice: slice,
    index: index,
    params: {
      tags: tags,
      recent: recent,
      alpha: alpha,
      author: author
    },
  }));

  return rest.data;
}