import React, {useContext, useEffect, useRef, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import trad_front from '../../../Assets/Images/trad_front.png';
import arrow_gray from '../../../Assets/Images/Arrow_gray.png';
import warning from '../../../Assets/Images/warning.png';
import up_black from '../../../Assets/Images/up_black.png';
import down_black from '../../../Assets/Images/down_black.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from '../Discovered/Navbar';
import { IComment, IDBChapter, IOeuvre } from '../../../Interfaces/Discover';
import { BookCommentRequest, getBookComments, getChapter, getChapterById, getDiscoverd, getMangaInfoByID } from '../../../Api/Oeuvres';
import { SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getUser } from '../../../Api/User';
import { GetPreviewImage, getProcessImages, RequestChapterValidation, TeamRequest, Unclaim } from '../../../Api/Team';

export interface IBoxe {
    text: string;

    id: number;
    minx: number;
    miny: number;
    maxx: number;
    maxy: number;
}

interface IProccessImages {
    boxes: any[];
    imageWithoutText: string;
    orginalImage: string;
}

const TranslateEngine: React.FC = () => {
    const {user, setmyteam, myteam} = useContext(MainContext);
    const [oeuvre, setOeuvre] = useState<IOeuvre | undefined>(undefined);
    const [chapter, setchapter] = useState<IDBChapter | undefined>(undefined);
    const [author, setauthor] = useState<User | undefined>(undefined);
    const [ProccessImages, setProccessImages] = useState<IProccessImages[]>([]);
    const [ImagesIndexes, setImagesIndexes] = useState<number>(0);
    const [previewImgUrl, setpreviewImgUrl] = useState<string | undefined>(undefined);
    const navigate = useNavigate();
    const imgRef = useRef<HTMLImageElement>(null);

    const url = new URL(window.location.href);
    const id = url.searchParams.get('id');
    const chapter_id = url.searchParams.get('chapter');
    const lang = url.searchParams.get('lang');
    const competition_ID = url.searchParams.get('competition_ID');

    const adjustCoordinates = (boxe: IBoxe, imageOriginalWidth: number, imageOriginalHeight: number, newWidth: number, newHeight: number) => {
        const scaleX = newWidth / imageOriginalWidth;
        const scaleY = newHeight / imageOriginalHeight;

        return {
            adjustedMinx: boxe.minx * scaleX,
            adjustedMiny: boxe.miny * scaleY,
            adjustedMaxx: boxe.maxx * scaleX,
            adjustedMaxy: boxe.maxy * scaleY,
        };
    };

    const CanPrecedent = () => {
        if (previewImgUrl) {
            return true;
        }
        return ImagesIndexes > 0;
    }

    const CanSuivant = () => {
        return ImagesIndexes < ProccessImages.length - 1;
    }

    const AllBoxIsFill = () => {
        let count = 0;
        ProccessImages[ImagesIndexes]?.boxes?.forEach((boxe) => {
            if (boxe.text === undefined || boxe.text === '') {
                count++;
            }
        });
        console.log("count", count);
        return count === 0;
    }

    useEffect(() => {
        if (!user)
            navigate('/login');

        console.log("myteam", myteam);
        if (!myteam?.team_ID)
            navigate('/traduction');

        getMangaInfoByID(id).then((oeu) => {
            setOeuvre(oeu);
            if (oeu.author_ID)
                getUser(oeu.author_ID).then((u) => { setauthor(u); });
        });

        if (id && chapter_id) {
            getChapterById(id, chapter_id).then((chapter) => {
                setchapter(chapter);
            });

            getProcessImages(id, chapter_id).then((response) => {
                if (response.data.error !== undefined) {
                    alert(response.data.error);
                    navigate('/translate?id='+id+"&lang="+lang);
                    return;
                }

                if (response.data === undefined) {
                    return;
                }
                setProccessImages(response.data);
            });
        }
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className="relative w-full h-full overflow-x-hidden overflow-y-scroll bg-gray-100">
                <div className="relative w-full h-[40%]">
                    <div className="absolute w-full h-full bg-black bg-opacity-80 z-10"></div>
                    <img src={trad_front} className="absolute h-full object-cover" />
                    <div className="relative h-[20%] z-10">
                        <Navbar />
                    </div>
                    <div className="relative w-full h-[80%] z-10 flex gap-4 px-[8%] p-2">
                        <img src={backend_url + oeuvre?.cover} className="relative h-full" />
                        <div className="relative w-full">
                            <h1 className="text-2xl Montserrat-SemiBold text-white">{oeuvre?.name}</h1>
                            <h2 className="text-white Montserrat-Regular text-base">{author?.username}</h2>
                            <h1 className="absolute bottom-2 text-white Montserrat-Regular text-sm italic">{chapter?.name}</h1>
                        </div>
                    </div>
                </div>

                <div className="relative w-full h-[2%] px-[8%] py-1.5">
                    <div className='relative bg-gray-400 w-full h-full rounded-full overflow-hidden'>
                        <div style={{
                            width:(ImagesIndexes / ProccessImages.length) * 100 + '%'
                        }} className='w-full bg-red-700 h-full'></div>
                    </div>
                </div>

                <div className="relative w-full h-[8%] px-[8%] py-4 flex justify-between items-center">
                    <button onClick={() => {
                        if (CanPrecedent()) {
                            if (previewImgUrl) {
                                setpreviewImgUrl(undefined);
                            }

                            if (ImagesIndexes > 0) {
                                setImagesIndexes((prev) => prev - 1);
                            }
                        }
                    }} className={`relative w-[full%] h-full Montserrat-SemiBold text-xl rounded-xl p-4 flex items-center justify-center gap-2 transform duration-150
                        ${CanPrecedent() ? 'cursor-pointer bg-gatek_red-500 text-white' : 'cursor-not-allowed bg-gray-300 text-gray-500'}
                    `}>
                        précédent
                    </button>
                    {previewImgUrl === undefined && <h1 className="text-gatek_red-500 Montserrat-SemiBold text-xl">Veuillez remplir tous les champs pour prévisualiser</h1>}
                    {previewImgUrl && <h1 className="text-gatek_red-500 Montserrat-SemiBold text-xl">Cliquez sur Valider et changer de page</h1>}

                    {previewImgUrl === undefined && <button onClick={(e) => {
                        e.stopPropagation();
                        if (id && chapter_id && myteam?.team_ID && AllBoxIsFill()) {
                            GetPreviewImage(id, chapter_id, myteam?.team_ID, ProccessImages[ImagesIndexes].imageWithoutText, ProccessImages[ImagesIndexes].boxes, competition_ID || undefined).then((response) => {
                                setpreviewImgUrl(response.data);
                            });
                        }
                    }} className={`relative w-[full%] h-full Montserrat-SemiBold text-xl rounded-xl p-4 flex items-center justify-center gap-2 transform duration-150
                        ${AllBoxIsFill() ? 'cursor-pointer bg-gatek_red-500 text-white' : 'cursor-not-allowed bg-gray-300 text-gray-500'}
                    `}>
                        prévisualiser
                    </button>}

                    {previewImgUrl && (ImagesIndexes < ProccessImages.length -1) && <button onClick={(e) => {
                        e.stopPropagation();
                        if (previewImgUrl) {
                            setImagesIndexes((prev) => prev + 1);
                            setpreviewImgUrl(undefined);
                        }
                    }} className={`relative w-[full%] h-full Montserrat-SemiBold text-xl rounded-xl p-4 flex items-center justify-center gap-2 transform duration-150
                        ${previewImgUrl ? 'cursor-pointer bg-gatek_red-500 text-white' : 'cursor-not-allowed bg-gray-300 text-gray-500'}
                    `}>
                        valider
                    </button>}

                    {previewImgUrl && (ImagesIndexes >= ProccessImages.length -1) && <button onClick={(e) => {
                        e.stopPropagation();
                        if (previewImgUrl && id && chapter_id && myteam?.team_ID) {
                            RequestChapterValidation(id, chapter_id, myteam?.team_ID, competition_ID || undefined).then((response) => {
                                if (response.status === 200) {
                                    navigate('/traduction');
                                }
                            });
                        }
                    }} className={`relative w-[full%] h-full Montserrat-SemiBold text-xl rounded-xl p-4 flex items-center justify-center gap-2 transform duration-150
                        ${previewImgUrl ? 'cursor-pointer bg-gatek_red-500 text-white' : 'cursor-not-allowed bg-gray-300 text-gray-500'}
                    `}>
                        Terminer
                    </button>}
                </div>
                <div className="relative w-full h-[10%] px-[8%] flex items-center justify-center gap-8 Montserrat-SemiBold text-gray-400">
                    {oeuvre?.originalLanguage}
                    <img src={arrow_gray} className="w-[10%] h-4" />
                    {lang}
                </div>
                <div className="relative w-full h-full px-[8%] flex gap-[10%]">
                    <img src={backend_url + ProccessImages[ImagesIndexes]?.orginalImage} className="w-[45%] h-[122%] object-contain" />
                    <div className="relative w-[45%] h-[122%]">
                        {!previewImgUrl && ProccessImages[ImagesIndexes]?.boxes?.length > 0 &&
                            ProccessImages[ImagesIndexes]?.boxes.map((boxe, index) => {
                                if (!imgRef || !imgRef.current) {
                                    return null;
                                }

                                const imageOriginalWidth = imgRef.current.naturalWidth;
                                const imageOriginalHeight = imgRef.current.naturalHeight;

                                const newWidth = imgRef.current.width;
                                const newHeight = imgRef.current.height;

                                const { adjustedMinx, adjustedMiny, adjustedMaxx, adjustedMaxy } = adjustCoordinates(boxe, imageOriginalWidth, imageOriginalHeight, newWidth, newHeight);

                                return (
                                    <textarea
                                        key={index}
                                        className="absolute outline-none resize-none border-2 border-gray-400 bg-white bg-opacity-70 p-1"
                                        style={{
                                            left: `${adjustedMinx}px`,
                                            top: `${adjustedMiny}px`,
                                            width: `${adjustedMaxx - adjustedMinx}px`,
                                            height: `${adjustedMaxy - adjustedMiny}px`,
                                        }}
                                        defaultValue={boxe.text}
                                        onChange={(e) => {
                                            setProccessImages((prev) => {
                                                const newProccessImages = [...prev];
                                                newProccessImages[ImagesIndexes].boxes[index].text = e.target.value;
                                                return newProccessImages;
                                            });
                                        }}
                                    />
                                );
                            })}
                        <img ref={imgRef} src={backend_url + (previewImgUrl ? previewImgUrl : ProccessImages[ImagesIndexes]?.imageWithoutText)} className="w-full h-full object-contain border border-gray-300" />
                    </div>
                </div>
            </div>
        </MainContext.Provider>
    );
};

export default TranslateEngine;