import React, {useContext, useEffect, useState} from 'react';
import japongpt from '../../../../Assets/Images/japongpt.webp';
import logo_gatek from '../../../../Assets/Images/logo_gatek.png';

import { Outlet, useNavigate } from 'react-router-dom';

import { MainContext } from '../../../../Context/Main';
import { CreateTeamRequest, ITeam, TeamRequest } from '../../../../Api/Team';

const Create: React.FC = () => {
    const {user, setuser, setcurrent_oeuvre, current_language, setcurrent_language} = useContext(MainContext);
    const [myteam, setmyteam] = useState<ITeam>({
        team_ID: '',
        team_name: 'Scan Trad #1',
        user_leader_id: '',
        cover: undefined,
        description: 'Nous somme une équipe de traduction de scantrad de manga. Nous sommes à la recherche de nouveaux membres pour nous aider à traduire des chapitres de manga en français. Rejoignez-nous !'
    });
    const navigate = useNavigate();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const imgURI = URL.createObjectURL(file);
    
            const image = new Image();
            image.onload = () => {
    
                // Si tout est bon, procéder à la mise à jour de l'état
                const reader = new FileReader();
                reader.onloadend = () => {
                    // Mettre à jour l'état ici
                    // settmpchapter({...tmpchapter, cover: reader.result as string});
                    setmyteam({...myteam, cover: reader.result as string});
                };
                reader.readAsDataURL(file);
            };
            image.onerror = () => {
                alert("Impossible de charger l'image. Veuillez réessayer.");
            };
            image.src = imgURI;
        }
    };

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full flex flex-col items-center justify-center overflow-hidden'>
                <img src={japongpt} alt='japongpt' className='absolute left-0 top-0 w-full h-full opacity-40' />
                <div className='relative w-[30%] h-[80%] flex flex-col gap-4 p-4 shadow-md shadow-black bg-gradient-to-r from-gatek_red-500 via-gatek_red-400 to-gatek_red-300 text-white'>
                    <h1 className='relative Inter-Bold text-3xl text-center'>Créer une équipe de traduction</h1>
                    <div className='relative self-center w-1/4 h-1/4'>
                        <img className='relative border-2 border-dashed w-full h-[90%] object-contain' src={myteam.cover || logo_gatek} />
                        <input className='text-xs w-full h-[10%]' type="file" accept="image/*" multiple onChange={handleFileChange} />
                    </div>

                    <h1 className='relative Inter-Regular text-xl flex gap-2 border-b'>Nom de l'équipe :<input onChange={(e) => {
                        setmyteam({...myteam, team_name: e.target.value});
                    }} type='text' value={myteam.team_name} className='relative text-gray-300 Inter-Regular italic bg-transparent outline-none' /></h1>
                    <h1 className='relative Inter-Regular text-xl flex gap-2'>Description de l'équipe :</h1>
                    <textarea onChange={(e) => {
                        setmyteam({...myteam, description: e.target.value});
                    }} value={myteam.description} className='relative h-1/3 text-gray-300 Inter-Regular italic bg-transparent outline-none border-b' />

                    <button onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (user && myteam.team_name && myteam.description)
                            CreateTeamRequest(user.id, myteam.team_name, myteam.description).then((response) => {
                                if (response.status === 200) {
                                    navigate('/dashboard/edit');
                                }
                            });
                    }
                    } className='absolute bottom-4 w-1/2 left-1/4 Inter-Bold text-3xl bg-gatek-200 py-2 px-8 rounded-2xl text-white shadow-md shadow-black transform transition-all duration-150 hover:scale-95 hover:bg-gatek_orange-600'>Créer</button>
                </div>
            </div>
        </MainContext.Provider>
    );
}

export default Create;