import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../Assets/Images/logo_gatek.png';
import { Outlet, useNavigate } from 'react-router-dom';

import { MainContext } from '../../../Context/Main';
import { IOeuvre } from '../../../Interfaces/Discover';
import { getMangaInfoByID, getMangasInfoByIDs } from '../../../Api/Oeuvres';
import { backend_url } from '../../../App';
import ObjectBox from './ObjectBox';

export const AddReadBook = (oeuvres_id: string, chapter_id: string ) => {
    let last_read_books = localStorage.getItem('last_read_books');
    if (last_read_books === null) {
        last_read_books = JSON.stringify([]);
    }

    let notjson_last_read_books = JSON.parse(last_read_books);

    // Check if the book is already in the list
    let found = false;
    notjson_last_read_books.forEach((element: {oeuvres_id: string, chapter_id: string}) => {
        if (element.oeuvres_id === oeuvres_id) {
            found = true;
        }
    });

    if (found)
        return;

    notjson_last_read_books.push({oeuvres_id: oeuvres_id, chapter_id: chapter_id});

    if (notjson_last_read_books.length > 5)
        notjson_last_read_books.shift();

    localStorage.setItem('last_read_books', JSON.stringify(notjson_last_read_books));
}

const Library: React.FC = () => {
    const {user, setuser} = useContext(MainContext);
    const [books, setbooks] = useState<IOeuvre[]>([]);
    const [add_books, setadd_books] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        let last_read_books = localStorage.getItem('last_read_books');
        if (last_read_books !== null) {
            let notjson_last_read_books = JSON.parse(last_read_books);

            console.log("notjson_last_read_books", notjson_last_read_books.length);

            // Check double and remove it
            let tmp: {oeuvres_id: string, chapter_id: string}[] = [];
            notjson_last_read_books.forEach((element: {oeuvres_id: string, chapter_id: string}) => {
                let found = false;
                tmp.forEach((element2: {oeuvres_id: string, chapter_id: string}) => {
                    if (element2.oeuvres_id === element.oeuvres_id) {
                        found = true;
                    }
                });
                if (!found) {
                    tmp.push(element);
                }
            });

            notjson_last_read_books = tmp;

            localStorage.setItem('last_read_books', JSON.stringify(notjson_last_read_books));

            console.log("notjson_last_read_books", notjson_last_read_books.length);
            getMangasInfoByIDs(notjson_last_read_books.map((element: {oeuvres_id: string, chapter_id: string}) => element.oeuvres_id)).then((response) => {
                setbooks(response);
            });
        }
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full p-8 overflow-y-auto text-gatek-400'>
                <div className='relative w-full h-[75%]'><ObjectBox label="Continuez vos lectures" oeuvres={books} /></div>
                {/* <div className='relative w-full h-60'>
                    <h1 className='Poppins-SemiBold text-xl'>Dernier chapitre</h1>
                </div>
                <div className='relative w-full h-60'>
                    <h1 className='Poppins-SemiBold text-xl'>Recommandation de genre</h1>
                </div>
                <div className='relative w-full h-60'>
                    <h1 className='Poppins-SemiBold text-xl'>Du même auteur</h1>
                </div> */}
            </div>
        </MainContext.Provider>
    );
}

export default Library;