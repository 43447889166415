import React, {useContext, useEffect, useRef, useState} from 'react';
import arrow from '../../Assets/Images/right-arrow.png';
import { Outlet, useNavigate } from 'react-router-dom';
import { MainContext } from '../../Context/Main';
import { IChapter, IDBChapter, IOeuvre } from '../../Interfaces/Discover';
import { AddManga, IAdd } from './Utils/IAdd';
import { AllWorldLanguages } from '../Dashboard/Subcomponent/TraductorEditInterface';

import logo_gatek from '../../Assets/Images/logo_gatek.png';
import add from '../../Assets/Images/add.png';
import red_arrow from '../../Assets/Images/red_arrow.png';
import recycle_bin from '../../Assets/Images/recycle-bin.png';
import { AddChapterToDB, getChapter, getMangaInfo, getMangaInfoById } from '../../Api/Oeuvres';

export const imageWidth = 1133;
export const imageHeight = 1757;
export const maxMB = 5;

const AddChapter: React.FC = () => {
    const {user, setuser} = useContext(MainContext);
    const [oeuvre, setoeuvre] = useState<IOeuvre | undefined>(undefined)
    const navigate = useNavigate();
    const [images, setImages] = useState<string[]>([]);
    const [chapters , setchapters] = useState<IDBChapter[]>([]);
    const [tmpchapter, settmpchapter] = useState<IDBChapter>({
        id: "",
        name: "Titre",
        cover: undefined,
        images: [],
        chapterFree: false,
    });

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const imgURI = URL.createObjectURL(file);
    
            // Vérifier la taille du fichier (exemple : 5MB max)
            const maxSize = maxMB * 1024 * 1024; // 5MB en octets
            if (file.size > maxSize) {
                alert("Le fichier est trop grand. Taille maximale : 5MB.");
                return;
            }
    
            const image = new Image();
            image.onload = () => {
                // Vérifier les dimensions de l'image
                if (image.naturalWidth !== imageWidth || image.naturalHeight !== imageHeight) {
                    alert(`L'image doit être de ${imageWidth}x${imageHeight} pixels.`);
                    return;
                }
    
                // Si tout est bon, procéder à la mise à jour de l'état
                const reader = new FileReader();
                reader.onloadend = () => {
                    // Mettre à jour l'état ici
                    settmpchapter({...tmpchapter, cover: reader.result as string});
                };
                reader.readAsDataURL(file);
            };
            image.onerror = () => {
                alert("Impossible de charger l'image. Veuillez réessayer.");
            };
            image.src = imgURI;
        }
    };
    

    const fileInputRef = useRef<HTMLInputElement>(null);
    const handleDivClick = () => {
        fileInputRef.current?.click();
    };
    

    const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const maxSize = maxMB * 1024 * 1024; // Taille maximale de 5MB
            const minWidth = 800; // Largeur minimale requise
            const minHeight = 600; // Hauteur minimale requise
            const filesArray: File[] = Array.from(event.target.files);
            let loadedFiles: string[] = [];
            let validImageCount = 0; // Compter le nombre d'images valides
    
            filesArray.forEach((file, index) => {
                // Vérifier la taille du fichier
                if (file.size > maxSize) {
                    console.warn("Un fichier dépasse la taille maximale autorisée et a été ignoré.");
                    return;
                }
    
                const imgURI = URL.createObjectURL(file);
                const image = new Image();
                image.onload = () => {
                    // Vérifier les dimensions de l'image
                    if (image.naturalWidth !== imageWidth || image.naturalHeight !== imageHeight) {
                        alert(`L'image doit être de ${imageWidth}x${imageHeight} pixels.`);
                        return;
                    }
    
                    // Si l'image est valide, lire le contenu du fichier
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        loadedFiles.push(reader.result as string);
    
                        // Vérifier si tous les fichiers valides sont chargés
                        if (++validImageCount === filesArray.length) {
                            // Ajouter les images valides à la suite des autres dans l'état
                            settmpchapter(prevState => ({
                                ...prevState,
                                images: [...(prevState.images || []), ...loadedFiles]
                            }));
                        }
                    };
                    reader.readAsDataURL(file);
                };
                image.onerror = () => {
                    console.warn("Impossible de charger une image. Elle a été ignorée.");
                };
                image.src = imgURI;
            });
        }
    };

    useEffect(() => {
        const oeuvre_id = sessionStorage.getItem('current_oeuvre_id');
        if (oeuvre_id) {
            getMangaInfoById(oeuvre_id).then((ovuer) => {
                setoeuvre(ovuer);
            });
            getChapter(oeuvre_id, "original", user?.id).then((chapter) => {
                setchapters(chapter);
                settmpchapter({
                    ...tmpchapter,
                    name: "Chapitre "+(chapter.length+1),
                });
            });
        }
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full flex flex-col gap-4'>
                <div className='relative shadow-md shadow-black w-full h-1/2 justify-start flex gap-4 p-4 bg-gradient-to-r from-gatek_red-500 via-gatek_red-400 to-gatek_red-300'>
                    <div className='relative w-[13%] h-full'>
                        <img className='relative border-2 border-dashed w-full h-[90%] object-contain' src={tmpchapter.cover || logo_gatek} />
                        <input className='text-xs w-full h-[10%]' type="file" accept="image/*" multiple onChange={handleFileChange} />
                    </div>
                    <div className='relative w-1/2 h-full flex flex-col gap-2 justify-between'>
                        <h1 className='Poppins-SemiBold text-xl flex w-full text-white border-b gap-4 p-2'>Titre du chapitre:
                            <input className='Inter-Regular bg-transparent outline-none' type='text' placeholder="Titre du chapitre" value={tmpchapter.name} onChange={(e) => {
                                settmpchapter({...tmpchapter, name: e.target.value});
                            }}/>
                        </h1>
                        <h1 className='Poppins-SemiBold text-xl flex w-full text-white border-b gap-4 p-2 justify-between'>Chapitre gratuit:
                            <input type='checkbox' className='' onChange={(e) => {
                                settmpchapter({...tmpchapter, chapterFree: e.target.checked});
                            }}/>
                        </h1>
                        {tmpchapter.cover && tmpchapter.images && tmpchapter.images.length > 0 && <button onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (oeuvre)
                                AddChapterToDB(tmpchapter, oeuvre).then((res) => {
                                    if (res.status === 200) {
                                        navigate('/dashboard/edit');
                                    }
                                });
                        }} className='Poppins-SemiBold text-xl bg-gatek-200 text-white p-2 rounded-md transform transition-all duration-200 hover:scale-95'>Ajouter le chapitre</button>}
                            
                        <h1 className='Inter-Regular text-base text-white italic'>⚠️ Les images des chapitres, comme l'image de couverture, doit être de {imageWidth}x{imageHeight}px et de maximum {maxMB}MB.</h1>
                    </div>
                </div>

                <div className='relative px-8 w-full h-full'>
                    <h1 className='Poppins-SemiBold text-3xl text-white border-b-2 border-white'>Pages du chapitre - {tmpchapter.images?.length || 0} page(s)</h1>
                    <div className='relative w-full h-full grid grid-cols-6 gap-4 p-4'>
                        {tmpchapter.images && tmpchapter.images.map((page, index) => {
                            return (
                                <div key={index} className='flex-shrink-0 flex items-center justify-center border-white relative w-full h-full pr-1 pb-1 transform hover:scale-95 transition duration-150 cursor-pointer'>
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        // déplacer l'image vers la gauche
                                        let tmp = tmpchapter.images;
                                        if (index > 0 && tmp) {
                                            [tmp[index], tmp[index-1]] = [tmp[index-1], tmp[index]];
                                            settmpchapter({...tmpchapter, images: tmp});
                                        }
                                    }} className='absolute z-10 left-0 top-0 h-full w-[16%] opacity-0 transition-all duration-300
                                        hover:bg-gradient-to-l from-gray-300 via-gray-200 to-transparent hover:opacity-80 cursor-pointer flex items-center justify-center transform rotate-180
                                    '>
                                        <img src={red_arrow} className='w-1/2 object-contain' />
                                    </div>
                                    <img onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        let tmp = tmpchapter.images;
                                        tmp?.splice(index, 1);
                                        settmpchapter({...tmpchapter, images: tmp});
                                    }} src={recycle_bin} className='object-contain absolute z-10 h-full w-[66%] opacity-0 hover:bg-gray-300 hover:opacity-80 rounded-full'/>
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        // déplacer l'image vers la droite
                                        let tmp = tmpchapter.images;
                                        if (tmpchapter.images && index < tmpchapter.images.length-1 && tmp) {
                                            [tmp[index], tmp[index+1]] = [tmp[index+1], tmp[index]];
                                            settmpchapter({...tmpchapter, images: tmp});
                                        }
                                    
                                    }} className='absolute z-10 right-0 top-0 h-full w-[16%] opacity-0 transition-all duration-300
                                        hover:bg-gradient-to-l from-gray-300 via-gray-200 to-transparent hover:opacity-80 cursor-pointer flex items-center justify-center transform
                                    '>
                                        <img src={red_arrow} className='w-1/2 object-contain' />
                                    </div>
                                    <img src={page} alt={"Ajouter"} className='relative object-contain w-full h-full'/>
                                </div>
                            );
                        })}
                        <div onClick={handleDivClick} className='flex-shrink-0 border-2 border-dashed flex items-center justify-center border-white relative w-full h-full pr-1 pb-1 transform hover:scale-95 transition duration-150 cursor-pointer'>
                            <img src={add} alt={"Ajouter"} className='relative object-contain w-1/2 h-full'/>
                            <input
                                type="file"
                                ref={fileInputRef}
                                multiple
                                onChange={handleFilesChange}
                                style={{ display: 'none' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </MainContext.Provider>
    );
}

export default AddChapter;