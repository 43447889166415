import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import up from '../../../Assets/Images/up.png';
import down from '../../../Assets/Images/down.png';
import filter from '../../../Assets/Images/filter.png';
import up_black from '../../../Assets/Images/up_black.png';
import down_black from '../../../Assets/Images/down_black.png';
import white_heart from '../../../Assets/Images/white_heart.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from './Navbar';
import { IArticle, IComment, IDBChapter, IOeuvre } from '../../../Interfaces/Discover';
import { BookCommentRequest, getBookComments, getChapter, GetChapterAuthor, getDiscoverd, getMangaInfoByID, GetMangaReport, IGetChapterAuthor, PostMangaReport, PostNotificationFollow, PostNotificationIsfollowing, PostNotificationUnfollow } from '../../../Api/Oeuvres';
import ObjectBox from '../../Dashboard/Subcomponent/ObjectBox';
import { GetArticles, SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getUser } from '../../../Api/User';

const OeuvreMod: React.FC = () => {
    const {user} = useContext(MainContext);
    const [oeuvre, setOeuvre] = useState<IOeuvre | undefined>(undefined);
    const [author, setauthor] = useState<User | undefined>(undefined);
    const [chapters, setchapters] = useState<IDBChapter[]>([]);
    const [comments, setcomments] = useState<IComment[]>([]);
    const [isfollowing, setisfollowing] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [reportType, setReportType] = useState<string>('');
    const [reportMsg, setReportMsg] = useState<string>('');
    const [reportingTime, setReportingTime] = useState<number>(0);
    const [chapterAuthor, setChapterAuthor] = useState<IGetChapterAuthor | undefined>(undefined);
    const navigate = useNavigate();

    const url = new URL(window.location.href);
    const id = url.searchParams.get('id');

    useEffect(() => {
        if (!id) {
            navigate('/discover');
            return;
        }

        getMangaInfoByID(id).then((oeu) => {
            setOeuvre(oeu);
            if (oeu.author_ID)
                getUser(oeu.author_ID).then((u) => { setauthor(u); });
        });

        getChapter(id, "original", user?.id).then((chapter) => {
            setchapters(chapter || []);
        });

        GetChapterAuthor(id, "original", user?.id).then((response) => { setChapterAuthor(response); });

        getBookComments(id).then((response) => {
            if (response.status === 200)
                setcomments(response.data);
            else
                setcomments([]);
        });

        PostNotificationIsfollowing(id).then((response) => {
            setisfollowing(response);
        });

        GetMangaReport(id).then((response) => {
            setReportingTime(response.length);
        });
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            {open && <div onClick={(e) => {
                setOpen(false);
            }} className='fixed z-[60] top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center'>
                <div onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }} className='bg-white w-[50%] h-[50%] rounded-xl flex flex-col gap-4 items-center justify-between p-4'>
                    <h1 className='text-xl Montserrat-Bold'>Signaler l'oeuvre</h1>

                    <h1 className='relative flex gap-2 items-center w-full'>
                        <h1 className='text-sm Montserrat-Regular'>Signalement : </h1>
                        <select onChange={(e) => {
                            setReportType(e.target.value);
                        }} className='relative w-1/2 h-full bg-white border-2 outline-none border-gray-400 border-opacity-50 rounded-lg text-sm Montserrat-Regular p-2'>
                            <option value=''>Choisir un type de signalement</option>
                            <option value='violence'>Plagiat</option>
                            <option value='tags'>Tag trompeur</option>
                            <option value='name'>Nom inapproprié</option>
                        </select>
                    </h1>

                    <textarea onChange={(e) => {
                        setReportMsg(e.target.value);
                    }} placeholder='Expliquer votre signalement' className='relative h-full outline-none text-sm resize-none w-full border-2 border-gray-500 border-opacity-60 rounded-xl p-2'>
                    </textarea>

                    <button onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (user && oeuvre && reportType && reportMsg) {
                            // send report
                            PostMangaReport(oeuvre.id, [reportType], reportMsg).then((response) => {
                                if (response.status === 200)
                                    setOpen(false);
                            });
                        }
                    }} className={`relative ${reportType && reportMsg ? 'bg-gatek-400 text-white' : 'bg-gray-300'} Montserrat-SemiBold self-end rounded-full p-4 w-[30%] cursor-pointer`}>
                        envoyé
                    </button>
                </div>
            </div>}
            {oeuvre && <div className='relative w-screen h-screen overflow-x-hidden overflow-y-scroll'>
                <div className='relative h-[50%] w-full overflow-hidden flex flex-col justify-between'>
                    <div className='absolute w-full h-full bg-black bg-opacity-70'></div>
                    <img src={backend_url+oeuvre.cover} className='absolute w-full object-cover -z-10' />
                    <div className='relative h-[10%]'>
                        <Navbar />
                    </div>
                    <div className='h-[70%] flex justify-between px-[8%] py-4 gap-8'>
                        <img src={backend_url+oeuvre.cover} className='relative w-auto h-full object-contain' />
                        <div className='relative w-full h-full flex flex-col gap-4 bg-gray-800 bg-opacity-70 rounded-xl p-4 overflow-y-scroll'>
                            <h1 className='text-white text-4xl Montserrat-SemiBold'>{oeuvre.name}</h1>
                            <p className='text-white text-sm Montserrat-Regular'>{oeuvre.description}</p>
                            <div onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                navigate(`/author?id=${oeuvre.author_ID}`);
                            }} className='absolute hover:bg-white hover:bg-opacity-10 rounded-xl px-4 right-4 bottom-4 flex justify-between items-center gap-2 cursor-pointer'>
                                <img src={backend_url+author?.avatar} onError={handleImageError} className='w-8 h-8 rounded-full object-cover' />
                                <h1 className='text-gray-300 text-sm Montserrat-Regular '>{author?.username}</h1>
                            </div>
                        </div>
                    </div>
                    <div className='h-[20%] flex gap-2 px-[8%] items-center'>
                        <div className='relative w-[92%] h-full gap-2 overflow-y-scroll flex flex-wrap'>
                            {oeuvre.tags.map((tag, index) => {
                                return (
                                    <p key={index} className='relative h-[22px] bg-white text-sm Poppins-SemiBold px-2 rounded-lg truncate'>{tag}</p>
                                );
                            })}
                        </div>
                        <div className='relative w-[30%] h-full flex gap-4 items-center justify-center'>
                            <div className='h-full flex w-[40%] items-center justify-center gap-4'>
                                <div onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (user) {
                                        SendLikeDislikeBook(user, oeuvre, oeuvre, true).then((_) => {
                                            getMangaInfoByID(oeuvre.id).then((response) => { setOeuvre(response); })
                                        })
                                    }
                                }} className='relative flex justify-between w-1/3 h-[24px] items-center duration-150 hover:opacity-60 cursor-pointer'>
                                    <p className='text-white text-sm Montserrat-Regular'>{oeuvre.likes}</p>
                                    <img src={up} className='h-full object-contain' />
                                </div>
                                <div onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (user) {
                                        SendLikeDislikeBook(user, oeuvre, oeuvre, false).then((_) => {
                                            getMangaInfoByID(oeuvre.id).then((response) => { setOeuvre(response); })
                                        })
                                    }
                                }} className='relative flex justify-between w-1/3 h-[24px] items-center duration-150 hover:opacity-60 cursor-pointer'>
                                    <p className='text-white text-sm Montserrat-Regular'>{oeuvre.dislikes}</p>
                                    <img src={down} className='h-full object-contain' />
                                </div>
                            </div>

                            {!isfollowing ? <button onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                PostNotificationFollow(oeuvre.id).then((response) => {
                                    if (response.status === 200)
                                        setisfollowing(!isfollowing);

                                });
                            }} className='relative h-[40%] w-[30%] bg-gatek-400 text-sm Montserrat-SemiBold rounded-lg text-white flex items-center justify-center gap-2 overflow-hidden'>
                                <img src={white_heart} className='h-4 object-contain' />
                                follow
                            </button> : <button onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                PostNotificationUnfollow(oeuvre.id).then((response) => {
                                    if (response.status === 200)
                                        setisfollowing(!isfollowing);

                                });
                            }} className='relative h-[40%] w-[30%] border border-gatek-400 text-gatek-400 text-sm Montserrat-SemiBold rounded-lg bg-white flex items-center justify-center gap-2 overflow-hidden'>
                                unfollow
                            </button>}

                            <button onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setOpen(!open);
                            }} className='relative h-[40%] w-[30%] bg-white text-sm Montserrat-SemiBold rounded-lg flex items-center justify-center gap-2 overflow-hidden'>
                                signaler
                            </button>
                        </div>
                    </div>
                </div>
                {reportingTime > 7 && <h1 className='text-2xl text-center animate-pulse Montserrat-Bold bg-gatek-400 text-white p-4'>Ce manga a été signalé {reportingTime} fois</h1>}
                <div className='relative px-[8%] overflow-hidden justify-between p-4 flex'>
                    <img src={filter} className='h-full object-cover' />
                    <select id="selectedLang" onChange={(e) => {
                        if (id && user) {
                            if (e.target.value === oeuvre.originalLanguage){
                                getChapter(id, "original", user.id).then((chapter) => { setchapters(chapter || []); });
                                GetChapterAuthor(id, "original",  user.id).then((response) => { setChapterAuthor(response); });
                           } else {
                                getChapter(id, e.target.value, user.id).then((chapter) => { setchapters(chapter || []); });
                                GetChapterAuthor(id, e.target.value,  user.id).then((response) => {
                                    if (response === undefined || response.name === undefined)
                                        GetChapterAuthor(id, "original",  user.id).then((response) => { setChapterAuthor(response); });
                                    else
                                        setChapterAuthor(response);
                                });
                            }
                        }
                    }} defaultValue={oeuvre.originalLanguage} className='relative w-[15%] h-full bg-white border-2 outline-none border-gray-400 border-opacity-50 rounded-lg text-sm Montserrat-Regular p-2'>
                        <option value={oeuvre.originalLanguage}>{oeuvre.originalLanguage}</option>
                        {oeuvre.languageTranslated?.map((langue, _) => {
                            return (
                                <option value={langue}>{langue}</option>
                            );
                        })}
                    </select>
                </div>
                <div className='relative px-[8%] overflow-hidden justify-between p-4 flex'>
                    <div className='relative w-[85%] grid grid-cols-1 gap-4 border-b border-black border-opacity-70 py-8'>
                        {chapters && chapters.map((chapter, index) => {
                            return (
                                <div key={index} onClick={() => {
                                    const lang = (document.getElementById('selectedLang') as HTMLTextAreaElement)?.value;
                                    if (lang !== oeuvre.originalLanguage)
                                        navigate(`/read?id=${id}&chapter=${chapter.id}&lang=${lang}`);
                                    else
                                        navigate(`/read?id=${id}&chapter=${chapter.id}&lang=original`);
                                }} className='relative w-full h-[120px] flex gap-4 items-center border-b border-gray-400 border-opacity-70 hover:opacity-70 cursor-pointer'>
                                    <img src={backend_url+chapter.cover} className='h-full object-contain' />
                                    <h1 className='text-lg Montserrat-SemiBold'>{chapter.name}</h1>
                                    <h1 className='absolute right-0 text-lg Montserrat-SemiBold text-gray-400'>#{index}</h1>
                                </div>
                            );
                        })}
                    </div>
                    <div className='relative w-[15%]  border-b border-black border-opacity-70'>
                        <div onClick={() => {
                            if (oeuvre.author_ID === chapterAuthor?.id)
                                navigate(`/author?id=${chapterAuthor?.id}`);
                            else
                                navigate(`/team?id=${chapterAuthor?.id}`);
                        }} className='relative flex items-center gap-2 cursor-pointer select-none'>
                            <img src={backend_url+author?.avatar} onError={handleImageError} className='w-8 h-8 rounded-full object-cover' />
                            <h1 className='text-base Montserrat-SemiBold '>{chapterAuthor?.name}</h1>
                        </div>
                    </div>
                </div>
                <div className='relative w-[70%] px-[8%] overflow-hidden justify-between p-4'>
                    <h1 className='text-2xl Montserrat-SemiBold'>{comments.length} commentaire(s)</h1>
                    <div className='relative h-[180px] w-full flex flex-col items-end gap-2'>
                        <div className='relative h-[80%] w-full flex Montserrat-Regular gap-2'>
                            <img src={backend_url+author?.avatar} onError={handleImageError} className='w-8 h-8 rounded-full object-cover' />
                            <textarea id="new_comment" placeholder='ajouter un commentaire' className='relative outline-none text-sm resize-none w-full border-2 border-gray-500 border-opacity-60 rounded-xl p-2'>
                            </textarea>
                        </div>
                        <button onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            const comment = (document.getElementById('new_comment') as HTMLTextAreaElement)?.value;

                            if (user)
                                BookCommentRequest(user.id, oeuvre.id, oeuvre.id, comment).then((response) => {
                                    if (response.status === 200) {
                                        getBookComments(oeuvre.id).then((response) => {
                                            if (response.status === 200)
                                                setcomments(response.data);
                                            else 
                                                setcomments([]);
                                        });
                                    }
                                });
                        }} className='relative bg-gray-300 Montserrat-SemiBold rounded-full p-4 w-[10%]'>
                            envoyé
                        </button>
                    </div>

                    {comments.map((comment, index) => {
                            return (
                                <div key={index} className='relative h-[180px] w-full flex flex-col items-end gap-2'>
                                    <div className='relative h-[80%] w-full flex Montserrat-Regular gap-2'>
                                        <img src={backend_url+author?.avatar} onError={handleImageError} className='w-8 h-8 rounded-full object-cover' />
                                        <div className='relative w-full flex flex-col'>
                                            <h1 className='relative w-full Montserrat-SemiBold'>{comment.author}</h1>
                                            <h1 className='relative text-sm w-full'>{comment.comment}</h1>
                                        </div>
                                    </div>
                                    <div className='relative w-[8%] h-full flex gap-4 items-center justify-center'>
                                        <div onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (user) {
                                                SendLikeDislikeBookComment(user, oeuvre, comment, true).then((_) => {
                                                    if (id)
                                                        getBookComments(id).then((response) => {
                                                            if (response.status === 200)
                                                                setcomments(response.data);
                                                            else
                                                                setcomments([]);
                                                        });                                                })
                                            }
                                        }} className='relative flex justify-between w-1/3 h-[24px] items-center duration-150 hover:opacity-60 cursor-pointer'>
                                            <p className='text-sm Montserrat-Regular'>{comment.likes}</p>
                                            <img src={up_black} className='h-full object-contain' />
                                        </div>
                                        <div onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (user) {
                                                SendLikeDislikeBookComment(user, oeuvre, comment, false).then((_) => {
                                                    if (id)
                                                        getBookComments(id).then((response) => {
                                                            if (response.status === 200)
                                                                setcomments(response.data);
                                                            else
                                                                setcomments([]);
                                                        });                                                })
                                            }
                                        }} className='relative flex justify-between w-1/3 h-[24px] items-center duration-150 hover:opacity-60 cursor-pointer'>
                                            <p className='text-sm Montserrat-Regular'>{comment.dislikes}</p>
                                            <img src={down_black} className='h-full object-contain' />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                </div>
            </div>}
        </MainContext.Provider>
    );
}

export default OeuvreMod;