import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import little_comment_black from '../../../Assets/Images/little_comment_black.png';
import white_plus from '../../../Assets/Images/white_plus.png';
import black_pen from '../../../Assets/Images/black_pen.png';
import up_black from '../../../Assets/Images/up_black.png';
import down_black from '../../../Assets/Images/down_black.png';
import red_bin from '../../../Assets/Images/red_bin.png';
import down from '../../../Assets/Images/down_black.png';
import down_green from '../../../Assets/Images/down_green.png';
import up_red from '../../../Assets/Images/up_red.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from '../Discovered/Navbar';
import { IArticle, IComment, IDBChapter, IOeuvre } from '../../../Interfaces/Discover';
import { BookCommentRequest, getBookComments, getChapter, getDiscoverd, getMangaInfoByID, GetPossibleTranslation } from '../../../Api/Oeuvres';
import { ArticleDeleteRequest, SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getMyArticles, getMyOeuvres, getUser } from '../../../Api/User';

const MyArticle: React.FC = () => {
    const {user} = useContext(MainContext);
    const [oeuvres, setOeuvres] = useState<IOeuvre[]>([]);
    const [articles, setArticles] = useState<IArticle[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const t = sessionStorage.getItem('token');
        if (t === null)
            navigate('/login');

        if (user) {
            getMyArticles(user).then((articles) => {
                setArticles(articles);
            });
            // GetPossibleTranslation(user.id).then((res) => {
            //     if (res.status === 200 && res.data)
            //         setPossibleTranslations(res.data);
            // });
        }
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-[100%] px-[16%] flex flex-wrap py-4'>
                <div className='relative w-[90%]'>
                    {articles.map((article, i) => {
                        return (
                            <div key={i} className='w-full flex gap-2 mt-4'>
                                <div className='w-[90%] border-2 border-gray-300 rounded-xl p-2 gap-2 flex flex-col items-center bg-white'>
                                    <h1 className='text-2xl Montserrat-SemiBold w-full'>{article.title}</h1>
                                    <textarea disabled={true} className='text-base overflow-hidden Montserrat-Regular w-full resize-none bg-transparent'>{article.description}</textarea>
                                    <img src={backend_url+article.images[0]} className='object-contain' />
                                    <div className='relative w-full flex justify-between overflow-hidden'>
                                        <div className='relative bg-gray-300 rounded-full w-[12%] h-full flex gap-4 items-center justify-center p-2'>
                                            <div className='relative flex justify-between w-1/3 h-[24px] items-center duration-150 hover:opacity-60 cursor-pointer'>
                                                <p className={`text-sm ${article?.likedByUser && "text-red-700"} Montserrat-SemiBold`}>{article?.likes}</p>
                                                {!article?.likedByUser && <img src={up_black} className='h-full object-contain' />}
                                                {article?.likedByUser && <img src={up_red} className='h-full object-contain' />}
                                            </div>
                                            <div className='relative flex justify-between w-1/3 h-[24px] items-center duration-150 hover:opacity-60 cursor-pointer'>
                                                <p className={`text-sm ${article?.likedByUser !== undefined && !article?.likedByUser && "text-green-500"} Montserrat-SemiBold`}>{article?.dislikes}</p>
                                                {(article?.likedByUser === undefined || article?.likedByUser) && <img src={down_black} className='h-full object-contain' />}
                                                {(article?.likedByUser !== undefined && !article?.likedByUser) && <img src={down_green} className='h-full object-contain' />}
                                            </div>
                                        </div>
                                        
                                        <div className='relative bg-gray-300 rounded-2xl w-[6%] h-full flex gap-4 items-center justify-center p-2'>
                                            <img src={little_comment_black} className='h-full object-contain' />
                                            <p className='text-sm Montserrat-SemiBold'>{article?.nbComment}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='relative w-[10%] flex flex-col gap-4'>
                                    <button onClick={() => {
                                        navigate(`../../modify-article?id=${article.id}`);
                                    }} className='w-[40px] h-[40px] rounded-full bg-white border flex items-center justify-center transform hover:scale-95 duration-150'>
                                        <img src={black_pen} className='p-1' />
                                    </button>
                                    <button onClick={() => {
                                        ArticleDeleteRequest(article.id).then((res) => {
                                            if (res.status === 200) {
                                                const newArticles = [...articles];
                                                newArticles.splice(i, 1);
                                                setArticles(newArticles);
                                            }
                                        });
                                    }} className='w-[40px] h-[40px] rounded-xl bg-white border flex items-center hover:bg-red-100 justify-center transform hover:scale-95 duration-150'>
                                        <img src={red_bin} className='p-1' />
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className='fixed h-1/2 right-[6%] w-[10%] flex items-center justify-between'>
                    <button onClick={(e) => {
                        navigate('../../create-article');
                    }} className='relative w-[70%] h-[8%] Montserrat-Regular text-white bg-red-700 rounded-2xl flex items-center justify-center'>
                            <img src={white_plus} className='h-[80%]' />
                            créer
                    </button>
                </div>
            </div>
        </MainContext.Provider>
    );
}

export default MyArticle;