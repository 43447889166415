import React, {useContext, useEffect, useRef, useState} from 'react';
import arrow from '../../Assets/Images/right-arrow.png';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Outlet, useNavigate } from 'react-router-dom';
import { MainContext } from '../../Context/Main';
import { IDBChapter, IOeuvre } from '../../Interfaces/Discover';
import { getAllChapter, getChapter } from '../../Api/Oeuvres';
import { backend_url } from '../../App';
import red_arrow from '../../Assets/Images/red_arrow.png';
import { AddReadBook } from '../Dashboard/Subcomponent/Library';
import zoom_in from '../../Assets/Images/zoom-in.png';
import zoom_out from '../../Assets/Images/zoom_out.png';

const Read: React.FC = () => {
    const {user, setuser, current_oeuvre, current_chapitre} = useContext(MainContext);
    const [images, setimages] = useState<any[]>([]);
    const [chapitre, setchapitre] = useState<IDBChapter | undefined>(undefined);
    const [chapters, setchapters] = useState<IDBChapter[]>([]);
    const [zoom, setzoom] = useState<number>(100);
    const [pagination, setpagination] = useState<"simple_page" | "double_page" | "pellicule" | "scroll">((sessionStorage.getItem("pagination") as any) || "scroll");

    const [width, setWidth] = useState<number>(0);
    const [pages, setpages] = useState<number>(1);
    const scrollDiv = useRef<HTMLDivElement>(null);
    const progressDiv = useRef<HTMLDivElement>(null);
    const imageRefs = useRef<(HTMLImageElement | null)[]>([]); // Refs for each image

    const [position, setPosition] = useState<'left' | 'right' | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const leftRef = useRef<HTMLDivElement>(null);
    const rightRef = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();

    const url = new URL(window.location.href);
    const oeuvres_id = url.searchParams.get('id');
    const chapter_id = url.searchParams.get('chapter');
    const lang = url.searchParams.get('lang');

    useEffect(() => {
        // const oeuvres_id = sessionStorage.getItem('oeuvres_id');
        // const chapter_id = sessionStorage.getItem('chapter_id');
        // const lang = sessionStorage.getItem('chapter_language');
        if (oeuvres_id && chapter_id && lang) {
          getAllChapter(oeuvres_id, chapter_id).then((img) => {
              setimages(img);
              // devrait me donner toutes les infos d'un chapitre + image et la langue
              getChapter(oeuvres_id, lang, user?.id).then((chapter) => {
                  setchapters(chapter || []);
              });
              AddReadBook(oeuvres_id, chapter_id);
          });
        }
    }, []);
  
    const handleScroll = () => {
        if (progressDiv.current) {
          const scrollTop = progressDiv.current.scrollTop;
          const scrollHeight = progressDiv.current.scrollHeight - progressDiv.current.clientHeight;
          const newWidth = (scrollTop / scrollHeight) * 100;
          setWidth(newWidth);
    
          // Determine the current page based on scroll position
          const visibleImageIndex = imageRefs.current.findIndex((ref, index) => {
            if (ref) {
              const { offsetTop, clientHeight } = ref;
              return scrollTop >= offsetTop && scrollTop < offsetTop + clientHeight;
            }
            return false;
          });
    
          if (visibleImageIndex !== -1 && visibleImageIndex + 1 !== pages) {
            setpages(visibleImageIndex + 1);
          }
        }
      };
    
      const scrollToPage = (pageIndex: number) => {
        if (progressDiv.current && imageRefs.current[pageIndex]) {
          progressDiv.current.scrollTo({
            top: imageRefs.current[pageIndex]?.offsetTop,
            behavior: 'smooth',
          });
        }
      };

      useEffect(() => {
        if (progressDiv.current) {
          progressDiv.current.addEventListener('scroll', handleScroll);
        }
        return () => {
          if (progressDiv.current) {
            progressDiv.current.removeEventListener('scroll', handleScroll);
          }
        };
      }, [pages]);

      const handleMouseMove = (event: MouseEvent) => {
        if (containerRef.current) {
          const { left, right } = containerRef.current.getBoundingClientRect();
          const mouseX = event.clientX;
    
          if (mouseX < (left + right) / 2) {
            setPosition('left');
          } else {
            setPosition('right');
          }
        }
      };

      useEffect(() => {
        const container = containerRef.current;
        if (container) {
          container.addEventListener('mousemove', handleMouseMove);
        }
        return () => {
          if (container) {
            container.removeEventListener('mousemove', handleMouseMove);
          }
        };
      }, []);    

    return (
        <MainContext.Provider value={useContext(MainContext)}>
        <div className='relative w-full h-full flex flex-col overflow-hidden'>
            <div className='relative w-full h-[5%] bg-gray-800'>
                <div className='relative w-full h-[95%] flex justify-between px-4 py-2'>
                    <div className='relative w-[8%] h-full flex justify-between items-center'>
                        <img src={zoom_in} alt='zoom-in' className=' h-full object-contain cursor-pointer' onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (zoom < 200)
                                setzoom(zoom + 5);
                        }} />
                        <h1 className='text-white text-lg font-bold'>{zoom}%</h1>
                        <img src={zoom_out} alt='zoom-out' className='h-full object-contain cursor-pointer' onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (zoom > 70)
                                setzoom(zoom - 5);
                        }} />
                    </div>

                    {/* Choix du chapitre */}
                    <select className='relative Inter-Regular text-sm bg-gray-700 w-[40%] text-white outline-none rounded-md'
                        onChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            sessionStorage.setItem('chapter_id', e.target.value);
                            window.location.reload();
                        }}
                    value={sessionStorage.getItem('chapter_id') || ""}>
                        {/* <option value={chapitre?.id}>{chapitre?.name}</option> */}
                        {chapters.map((chapter, index) => {
                          if ((chapter.chapterFree) || (!chapter.chapterFree && chapter.availability))
                            return <option key={index} value={chapter.id}>{chapter.name}</option>
                        })}

                    </select>
                    <select className='relative Inter-Regular text-sm bg-gray-700 w-[5%] outline-none text-white rounded-md'
                        onChange={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setpages(parseInt(e.target.value));
                            scrollToPage(parseInt(e.target.value) - 1);
                        }} value={pages}
                    >
                        {Array.from({ length: images.length }, (_, i) => i + 1).map((img, index) => ( <option key={index} value={img}>Page {img}</option> ))}
                    </select>

                    <div className='relative w-[8%] h-full items-center text-white p-2 flex justify-between Inter-Regular text-xs'>
                        <button className={` ${pagination === "simple_page" ? "Inter-Bold" : " cursor-pointer hover:underline"} `}onClick={() => {setpagination("simple_page"); sessionStorage.setItem("pagination", "simple_page")}}>Simple Page</button>
                        {/* <button className={` ${pagination === "double_page" ? "Inter-Bold" : " cursor-pointer hover:underline"} `}onClick={() => {setpagination("double_page"); sessionStorage.setItem("pagination", "double_page")}}>Double Page</button>
                        <button className={` ${pagination === "pellicule" ? "Inter-Bold" : " cursor-pointer hover:underline"} `}onClick={() => {setpagination("pellicule"); sessionStorage.setItem("pagination", "pellicule")}}>Pellicule</button> */}
                        <button className={` ${pagination === "scroll" ? "Inter-Bold" : " cursor-pointer hover:underline"} `}onClick={() => {setpagination("scroll"); sessionStorage.setItem("pagination", "scroll")}}>Scroll</button>
                    </div>
                </div>
                <div
                    ref={scrollDiv}
                    className='relative h-[5%] bg-gatek-400'
                    style={{ width: `${width}%` }}
                ></div>
            </div>
            <div
                onScroll={handleScroll}
                ref={progressDiv}
                className='relative w-full h-[95%] overflow-y-scroll'
            >
                {pagination === "scroll" && (
                    <div className='relative flex flex-col h-screen items-center gap-1 invisible-scrollbar'>
                        {images.map((img, index) => (
                            <img
                            ref={el => imageRefs.current[index] = el}
                            style={{ height: `${zoom}%` }}
                            key={index}
                            src={backend_url + img}
                            alt='img'
                            className='object-contain w-full'
                            />
                        ))}
                    </div>
                )}

                {pagination === "simple_page" && (<div ref={containerRef} className='relative w-full h-full cursor-pointer'>
                    {position === 'left' && (
                        <div ref={leftRef} className="fixed -z-10 left-0 top-0 h-full w-1/2 fade-in-complete">
                            <img src={red_arrow} alt='img' className='absolute transform rotate-180 left-[5%] top-[45%] h-[10%] rounded-full bg-gray-700 p-3 bg-opacity-50' />
                        </div>
                    )}
                    {position === 'right' && (
                        <div ref={rightRef} className="fixed -z-10 right-0 top-0 h-full w-1/2 fade-in-complete">
                            <img src={red_arrow} alt='img' className='absolute right-[5%] top-[45%] h-[10%] rounded-full bg-gray-700 p-3 bg-opacity-50' />
                        </div>
                    )}
                    <div className='relative h-full flex items-center gap-16 overscroll-auto'>
                        {images.map((img, index) => (
                            <img
                            ref={el => imageRefs.current[index] = el}
                            style={{ height: `${zoom}%` }}
                            key={index}
                            src={backend_url + img}
                            alt='img'
                            className='flex-shrink-0 object-contain w-full'
                            />
                        ))}
                    </div>
                </div>)}
            </div>
        </div>
      </MainContext.Provider>
    );
}


export default Read;