import React, {useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';

import sendmail from '../../Assets/Images/sendmail.png';
import arobase from '../../Assets/Images/arobase.png';
import lock from '../../Assets/Images/lock.png';
import userlogo from '../../Assets/Images/user.png';
import calendar from '../../Assets/Images/calendar.png';

import { User } from '../../Interfaces/User';
import { MainContext } from '../../Context/Main';
import { PostCredentialsEmailSecretCode, PostCredentialsEmailVerify } from '../../Api/Register';

const EmailValidation: React.FC = () => {
    const navigate = useNavigate();
    const {user, setuser} = useContext(MainContext);
    const [code, setCode] = useState('');
    const [token, setToken] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (user && token == undefined)
            PostCredentialsEmailSecretCode().then((res) => {
                setToken(res);
            });

        document.body.className = 'bg-gatek_red-500';
        return () => {
            document.body.className = '';
        };
    }, []);

    return (
        <div className='relative w-full h-full bg-gray-100 flex flex-col gap-8 items-center justify-center p-8 overflow-y-scroll'>
            <h1 className='text-3xl Montserrat-SemiBold'>Nous avons envoyé un code sur votre email</h1>
            <img src={sendmail} className='w-[200px] h-[177px]' />
            <input type='text' placeholder='Code de validation' value={code} onChange={(e) => setCode(e.target.value)} className='relative w-1/3 h-10 bg-gatek_gray-100 text-gatek_gray-700 rounded-xl outline-none text-center' />

            <button className={`w-[170px] h-[55px] Montserrat-Regular rounded-xl
                ${code.length === 0 ? 'opacity-50 cursor-not-allowed border-2 border-gray-500 bg-gray-300 text-gray-500' : 'bg-gatek-400 text-white '}
            `} onClick={() => {
                if (code.length > 0 && token) {
                    PostCredentialsEmailVerify(token, code).then((res) => {
                        if (res.status === 200)
                            navigate('/login');
                    });
                }
            }}>Confirmer</button>
        </div>
    );
}

export default EmailValidation;