import { User } from '../Interfaces/User';
import { backend_url } from '../App';
import { Post, AxiosResponse, Get } from './Axios';

export const loginRequest = async (user: string, password: string):Promise<AxiosResponse> => {    
    return await Post('/credentials/login', JSON.stringify({ email:user, password:password }));
}

export const secretCode = async (email: string):Promise<AxiosResponse> => {    
    return await Get('/credentials/password/secretCode', { email:email });
}


export const passwordReset = async (resetPassword_token: string, newPassword: string, code:string):Promise<AxiosResponse> => {   
    return await Post('/credentials/password/reset', JSON.stringify({ resetPassword_token:resetPassword_token, newPassword:newPassword, code:code }));
}


export const disconnectRequest = async ():Promise<boolean> => {
    // const response = await axios.post('https://127.0.0.1/login', {user:user, password:password}, {
    // });
    // if (response.status === 200) {
    //     return JSON.parse(response.data);
    // } else {
    //     return null;
    // }
    return true
}