import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useNavigate } from 'react-router-dom';

import { MainContext } from '../../../Context/Main';
import { IArticle, IOeuvre } from '../../../Interfaces/Discover';
import { getMyArticles, getMyOeuvres } from '../../../Api/User';
import OeuvreInterface from './OeuvreInterface';
import TraductorEditInterface from './TraductorEditInterface';
import { GetPossibleTranslation, getMangaInfo } from '../../../Api/Oeuvres';
import ObjectBox from './ObjectBox';
import { TeamRequest } from '../../../Api/Team';

export interface ITranslatedBook {
    team_ID: string;
    manga_ID: string;
    chapter_ID: string;
    language: string;
    translationInValidation_ID: string;
    chapterName: string;
    mangaName: string;
    mangaCover: string;
}

const Edit: React.FC = () => {
    const {user, setuser, setcurrent_oeuvre, setmyteam, myteam} = useContext(MainContext);
    const [myoeuvre, setmyoeuvre] = useState<IOeuvre[]>([]);
    const [myarticles, setmyarticles] = useState<IArticle[]>([]);
    const [possibleTranslations, setPossibleTranslations] = useState<ITranslatedBook[]>([]);
    const [submenu, setsubmenu] = useState<string>("");
    const navigate = useNavigate();

    useEffect(() => {
        let url = new URL(window.location.href).pathname;
        let split = url.split('/');
        if (split[3] === undefined && user?.role !== 'author') {
            navigate('./teams');
            setsubmenu('teams');
        } else
            setsubmenu(split[3]);
    }, []);

    useEffect(() => {
        if (user && user.role === 'author') {
            getMyOeuvres(user).then((oeuvres) => {
                setmyoeuvre(oeuvres);
            });
            getMyArticles(user).then((articles) => {
                setmyarticles(articles);
            });
            GetPossibleTranslation(user.id).then((res) => {
                if (res.status === 200 && res.data)
                    setPossibleTranslations(res.data);
            });
        }

        if (user && user.role !== 'author') {
            TeamRequest(user.id).then((team) => {
                if (team.status === 200 && team.data.team_ID !== null) {
                    console.log("team", team)
                    setmyteam(team.data);
                }
            });
        }
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full bg-gray-200 overflow-hidden p-4 flex gap-4'>
                <div className='w-[15%] h-full flex flex-col gap-4'>
                    <div className='w-full h-[20%] bg-gatek-400 rounded-xl flex flex-col gap-2 p-2 text-white'>
                        <div className='w-full h-1/2 flex gap-2 border-b p-1'>
                            <img className='object-contain rounded-2xl w-1/4 h-full' src={user?.avatar || logo_gatek} />
                            <p className='Inter-Bold text-sm '>{user?.username}</p>
                        </div>

                        <div className='relative h-full w-full flex flex-col gap-2'>
                            <p className='Inter-Regular text-base'>{
                                user?.role === 'author' ? '🖋️ Auteur' :
                                user?.role === 'translator' ? '🌍 Traducteur' :
                                user?.role === 'reader' ? '📖 Lecteur' :
                                user?.role === 'admin' ? '💻 Administrateur' : 'Inconnu'
                            }</p>
                            {myteam && <p className='Inter-SemiBold text-base'>✍️{myteam.team_name}</p>}
                        </div>
                    </div>
                    <div className='w-full h-[80%] bg-gatek-400 rounded-xl overflow-hidden'>
                        {user?.role !== 'author' && <>
                            <button onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                navigate('/dashboard/edit/teams');
                                setsubmenu('teams');
                            }} className={`w-full h-[10%] text-white Inter-Regular ${submenu === "teams" && "bg-gatek_red-700"} text-start px-4 hover:bg-gatek_red-700`}>Accueil</button>
                            <button onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                navigate('/dashboard/edit/teamtraductions');
                                setsubmenu('teamtraductions');
                            }} className={`w-full h-[10%] text-white Inter-Regular ${submenu === "teamtraductions" && "bg-gatek_red-700"} text-start px-4 hover:bg-gatek_red-700`}>Traductions</button>
                            <button onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                navigate('/dashboard/edit/teammembers');
                                setsubmenu('teammembers');
                            }} className={`w-full h-[10%] text-white Inter-Regular ${submenu === "teammembers" && "bg-gatek_red-700"} text-start px-4 hover:bg-gatek_red-700`}>Membres</button>
                        </>}
                    </div>
                </div>
                <div className='w-[85%] h-full gap-4 border-2 border-gatek-400 rounded-xl p-2 overflow-y-scroll'>
                    {user?.role !== 'author' ? <Outlet /> : <>
                        <div className='relative w-full h-[55%]'><ObjectBox label="Vos oeuvres" oeuvres={myoeuvre} author={true} /></div>
                        <div className='relative w-full h-[55%]'><ObjectBox label="Vos articles" articles={myarticles} author={true} /></div>
                        <div className='relative w-full h-[55%]'><ObjectBox label="Voir les traductions en attentes" traductions={possibleTranslations} author={true} /></div>
                    </>}
                </div>
                {/* <div className='relative w-full h-1/4 flex p-2 bg-gatek_red-800 text-white truncate'>
                    <img className='object-contain rounded-full' src={user?.avatar || logo_gatek} />
                    <div className='relative h-full w-full flex flex-col gap-2'>
                        <p className='Inter-Bold text-3xl border-b border-white pb-[0.5%]'>{user?.username}</p>
                        <p className='Inter-Regular text-xl'>{
                            user?.role === 'author' ? '🖋️ Auteur' :
                            user?.role === 'translator' ? '🌍 Traducteur' :
                            user?.role === 'reader' ? '📖 Lecteur' :
                            user?.role === 'admin' ? '💻 Administrateur' : 'Inconnu'
                        }</p>
                        {myteam && <p className='Inter-SemiBold text-xl'>✍️{myteam.team_name}</p>}
                    </div>
                </div>
                <div className='relative w-full h-full p-8'>
                    {user?.role === 'author' && <>
                        <div className='relative w-full h-[75%]'><ObjectBox label="Vos oeuvres" oeuvres={myoeuvre} author={true} white={true}/></div>
                        <div className='relative w-full h-[75%]'><ObjectBox label="Vos articles" articles={myarticles} author={true} white={true} /></div>
                        <div className='relative w-full h-[75%]'><ObjectBox label="Voir les traductions en attentes" traductions={possibleTranslations} author={true} white={true} /></div>
                    </>}
                    {user?.role === 'translator' && <>
                        <TraductorEditInterface />
                    </>}
                </div> */}
            </div>
        </MainContext.Provider>
    );
}

export default Edit;