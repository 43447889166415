import React, {useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';

import { MainContext } from '../../Context/Main';
import { secretCode, passwordReset } from '../../Api/login';

const Forgotpassword: React.FC = () => {
    const {user, setuser} = useContext(MainContext);

    const [email, setemail] = useState<string>('');
    const [password, setpassword] = useState<string>('');
    const [secretcode, setsecretcode] = useState<string>('');
    const [error, seterror] = useState<string>('');
    const [resetPassword_token, setresetPassword_token] = useState<string>('');

    const navigate = useNavigate();
    return (
        <div className='relative w-full h-full bg-gatek_red-500 flex flex-col items-center justify-center p-8'>
            {!resetPassword_token ? <div className='relative bg-gatek_gray-100 w-1/4 rounded-xl flex flex-col p-2 py-8 overflow-hidden gap-4 shadow-2xl shadow-black'>
                <h1 className='relative Poppins-SemiBold text-3xl w-full border-b-2 border-gatek-400 text-center p-2'>Mot de passe oublié</h1>
                <h1 className='relative Poppins-SemiBold text-sm w-full p-2'>Entrez votre adresse e-mail de récupération :</h1>
                <input type='email' name='email' placeholder="Entrez votre e-mail" value={email} onChange={(e) => {setemail(e.target.value)}}
                className='
                    relative Inter-SemiBold outline-none text-sm w-full h-10 border border-gatek-400 rounded-xl p-2
                '/>
                <div className='relative w-full flex justify-between'>
                    <button onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        navigate('../login');
                    }} className='relative w-1/4 h-10 border border-gatek-400 text-black Poppins-SemiBold text-sm rounded-xl'>◀️ Retour</button>

                    <button onClick={(e) => {
                        secretCode(email).then((res) => {
                            if (res.status === 200) {
                                setresetPassword_token(res.data.resetPassword_TOKEN);
                            }
                        });
                    }} className='relative w-1/4 h-10 bg-gatek-400 text-white Poppins-SemiBold text-sm rounded-xl'>Envoyer</button>
                </div>
                
            </div> : <div className='relative bg-gatek_gray-100 w-1/4 rounded-xl flex flex-col p-2 py-8 overflow-hidden gap-4 shadow-2xl shadow-black'>
                <h1 className='relative Poppins-SemiBold text-3xl w-full border-b-2 border-gatek-400 text-center p-2'>Mot de passe oublié</h1>
                <h1 className='relative Poppins-SemiBold text-sm w-full p-2'>Entrez votre nouveau mot de passe :</h1>
                <input type='text' name='password' placeholder="Entrez votre mot de passe" value={password} onChange={(e) => {setpassword(e.target.value)}}
                className='
                    relative Inter-SemiBold outline-none text-sm w-full h-10 border border-gatek-400 rounded-xl p-2
                '/>
                <input type='text' name='secretcode' placeholder="Entrez le code de récupération" value={secretcode} onChange={(e) => {setsecretcode(e.target.value)}}
                className='
                    relative Inter-SemiBold outline-none text-sm w-full h-10 border border-gatek-400 rounded-xl p-2
                '/>
                <div className='relative w-full flex justify-between'>
                    <button onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        navigate('../login');
                    }} className='relative w-1/4 h-10 border border-gatek-400 text-black Poppins-SemiBold text-sm rounded-xl'>◀️ Retour</button>

                    <button onClick={(e) => {
                        passwordReset(resetPassword_token, password, secretcode).then((res) => {
                            if (res.status === 200) {
                                navigate('../login');
                            }
                        });
                    }} className='relative w-1/4 h-10 bg-gatek-400 text-white Poppins-SemiBold text-sm rounded-xl'>Envoyer</button>
                </div>
            </div> }
        </div>
    );
}

export default Forgotpassword;