import React, {useContext, useEffect, useState} from 'react';
import pierre from '../../Assets/Images/Vitrine/Team/Pierre.png';
import eric from '../../Assets/Images/Vitrine/Team/Eric.png';
import claire from '../../Assets/Images/Vitrine/Team/Claire.png';
import raphael from '../../Assets/Images/Vitrine/Team/Raphael.png';
import arthur from '../../Assets/Images/Vitrine/Team/Arthur.png';
import thomas from '../../Assets/Images/Vitrine/Team/Thomas.png';

const Membre: React.FC<{ name: string; img: string; desc: string, tags: string[] }> = ({ name, img, desc, tags }) => {
    return (
      <div>
        <img className='w-45 h-45' src={img} alt={name} />
        <h2 className='flex text-xl font-bold w-full justify-center'>{name}</h2>
        <div className='relative flex rows'>
            {tags.map((tag, index) => (
                <div key={index} className='rounded-full px-4 bg-red-100 p-2 m-1 inline-block'>{tag}</div>
            ))}
        </div>
        <p>{desc}</p>
      </div>
    );
  };

const Team: React.FC<{}> = () => {
    return (
        <div>
            <div className='relative w-full flex px-16 pb-8'>
                    <div className='relative bg-gatek_gray-100 w-full h-full rounded-tr shadow-2xl p-4'>
                        <p className='Poppins-SemiBold text-3xl'>L'Equipe</p>
                        <div className='relative flex rows'>
                            <div className='relative flex justify-center items-center w-1/3 h-full'>
                            <div className='relative cols gap-10'>                                    <p className='Poppins-SemiBold text-3xl mt-8'>Web Team</p>
                                    <Membre name="Pierre" img={pierre}  tags={["Pole lead - Web"]} desc=""/>
                                    <Membre name="Arthur" img={arthur} tags={["Tech Lead", "Architecture", "VM"]} desc=""/>
                                </div>
                            </div>
                            <div className='relative flex justify-center items-center w-1/3 h-full'>
                                <div className='relative cols gap-10'>
                                    <p className='Poppins-SemiBold text-3xl mt-8'>Backend Team</p>
                                    <Membre name="Eric" img={eric} tags={["Pole lead - Backend"]} desc=""/>
                                    <Membre name="Raphael" img={raphael} tags={["IA"]} desc=""/>
                                </div>
                            </div>
                            <div className='relative flex justify-center items-center w-1/3 h-full'>
                            <div className='relative cols gap-10'>                                    <p className='Poppins-SemiBold text-3xl mt-8'>Mobile Team</p>
                                    <Membre name="Claire" img={claire} tags={["Pole lead - Mobile", "Android"]} desc=""/>
                                    <Membre name="Thomas" img={thomas} tags={["IOS"]}desc=""/>
                                </div>
                            </div>
                        </div>
                        
                        {/* 
                        <p className='Inter-Regular text-xl px-6 py-2'>
                            GATEK est une librairie numérique orientée sur les mangas, plus
                            particulièrement les œuvres méconnues en France. Gatek possède un espace dédié à la communication entre les auteurs
                            et leurs publics.
                        </p>
                        <p className='Inter-Regular text-xl px-6 py-2'>
                            La france est :
                            <ul className='list-disc list-inside'>
                                <li>Le 2ème plus gros consommateur de mangas au monde</li>
                                <li>Le 1er pays d'Europe en terme de consommation de mangas</li>
                            </ul>
                            Et seulenent 20-30% des œuvres japonaises sont éditées en France, de plus 80% des auteurs ne sont pas édités.
                            <div className='relative w-full h-full flex items-center justify-center'>
                                <img src={gatek} alt='' className='h-1/2 w-1/2'/>
                            </div>
                        </p> */}
                    </div>
                </div>
        </div>)
}

export default Team;