import axios from "axios";
import { backend_url } from "../App";

export interface AxiosResponse {
    status: number;
    statusText: string;
    data: any;
}

export const Get = async (url: string, params: any, content_type?: string):Promise<AxiosResponse> => {
    if (content_type === undefined) {content_type = 'application/json';}
    let token = sessionStorage.getItem("token") || ''
    if (params.token)
        token = params.token
    try {
        console.log(backend_url+url, params)
        console.log(content_type)
        console.log(params)
        console.log(token)
        const response = await axios.get(backend_url+url, {
            headers: {
                'Content-Type': content_type,
                'authorization': `bearer ${token}`
            },
            params : params
        });
        console.log("Get", response.status, response.data);
        return {status: response.status, statusText: response.statusText, data: response.data};
    } catch (error) {
        console.log(error)
        if (axios.isAxiosError(error)) {
            return {status: error.response?.status || -1, statusText: error.response?.statusText || "", data: error.response?.data};
        } else {
            console.error(error);
        }
    }
    return {status: -1, statusText: "Error", data: null};
}

export const Post = async (url: string, params: any, content_type?: string):Promise<AxiosResponse> => {
    if (content_type === undefined) {content_type = 'application/json';}
    let token = sessionStorage.getItem("token") || ''
    if (params.token)
        token = params.token

    console.log(backend_url+url, params)
    try {
        const response = await axios.post(backend_url+url, params, {
            headers: {
                'Content-Type': content_type,
                'authorization': `bearer ${token}`
            }
        });
        console.log("Post", response.status, response.data);
        return {status: response.status, statusText: response.statusText, data: response.data};
    } catch (error) {
        console.log("error");
        if (axios.isAxiosError(error)) {
            console.log("AxiosError:", error.response?.statusText)
            return {status: error.response?.status || -1, statusText: error.response?.statusText || "", data: error.response?.data};
        } else {
            console.log("new error");
            console.error(error);
        }
    }
    return {status: -1, statusText: "Error", data: null};
}

export const Delete = async (url: string, params: any, content_type?: string):Promise<AxiosResponse> => {
    console.log("DEL", url, params, content_type)
    if (content_type === undefined) {content_type = 'application/json';}
    let token = sessionStorage.getItem("token") || ''
    if (params.token)
        token = params.token

    console.log(backend_url+url, params)
    try {
        const response = await axios.delete(backend_url+url, {
            headers: {
                'Content-Type': content_type,
                'authorization': `bearer ${token}`
            },
            data: params,
        });
        console.log("Delete", response.status, response.data);
        return {status: response.status, statusText: response.statusText, data: response.data};
    } catch (error) {
        console.log("error");
        if (axios.isAxiosError(error)) {
            console.log("AxiosError:", error.response?.statusText)
            return {status: error.response?.status || -1, statusText: error.response?.statusText || "", data: error.response?.data};
        } else {
            console.log("new error");
            console.error(error);
        }
    }
    return {status: -1, statusText: "Error", data: null};
}

export const PostForm = async (url: string, params: FormData, content_type?: string):Promise<AxiosResponse> => {
    if (content_type === undefined) {content_type = 'multipart/form-data';}

    console.log(url, params)
    let token = sessionStorage.getItem("token") || ''
    console.log("token", token)
    if (params.get("token") != null) {
        token = params.get("token")?.toString() ?? ""
    }
    try {
        const response = await axios.post(backend_url+url, params, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'authorization': `bearer ${token}`
            }
        });
        console.log("Post", response.status, response.data);
        return {status: response.status, statusText: response.statusText, data: response.data};
    } catch (error) {
        console.log("error");
        if (axios.isAxiosError(error)) {
            console.log("AxiosError:", error.response?.statusText)
            return {status: error.response?.status || -1, statusText: error.response?.statusText || "", data: error.response?.data};
        } else {
            console.log("new error");
            console.error(error);
        }
    }
    return {status: -1, statusText: "Error", data: null};
}