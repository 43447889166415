import { IChapter, IDBChapter, IDBChapterMod, IOeuvre, ITranslatedBook } from "../Interfaces/Discover";
import { User } from "../Interfaces/User";
import { Get, Post, PostForm, AxiosResponse, Delete } from "./Axios";

export const Tags: string[] = [
    "Horreur",
    "Action",
    "Aventure",
    "Comédie",
    "Drame",
    "Fantaisie",
    "Mystère",
    "Romance",
    "Science-fiction",
    "Tranche de vie",
    "Sport",
    "Thriller",
    "Historique",
    "Psychologique",
    "Surnaturel",
    "Tragédie",
    "Mature",
    "Seinen",
    "Shonen",
    "Shojo",
    "Josei",
    "Harem",
    "Ecchi",
    "Yuri",
    "Yaoi",
    "Mecha"
];

export const getDiscoverd = async ():Promise<IOeuvre[]> => {
    // return mockOeuvres;
    const response = await Get('/suggestion/trending', {});
    console.log("getDiscoverd", response.data);
    return response.data;
}

export const getLastedManga = async ():Promise<IOeuvre[]> => {
    // return mockOeuvres;
    const response = await Get('/suggestion/5lastMangas', {});
    console.log("getLastedManga", response.data);
    return response.data;
}

export interface ILastChapter {
    name: string;
    cover: string;
    manga_ID: string;
    chapter_ID: string;
    chapterNumber: number;
    author: string;
    author_ID: string;
    id: string;
}

export const getLastedChapter = async ():Promise<ILastChapter[]> => {
    // return mockOeuvres;
    const response = await Get('/suggestion/lastChapter', {});
    console.log("getLastedManga", response.data);
    return response.data;
}

export const getMangaInfoById = async (oeuvre_id: string):Promise<IOeuvre> => {
    const response = await Get('/manga', {manga_ID: oeuvre_id});
    response.data.id = oeuvre_id;
    console.log(response.data);
    return response.data;
}

export const getMangaInfo = async (oeuvre: IOeuvre):Promise<IOeuvre> => {
    const response = await Get('/manga', {manga_ID: oeuvre.id});
    response.data.id = oeuvre.id;
    console.log(response.data);
    return response.data;
}

export const getMangaInfoByID = async (oeuvre_id: string | undefined | null):Promise<IOeuvre> => {
    const response = await Get('/manga', {manga_ID: oeuvre_id});
    response.data.id = oeuvre_id;
    console.log(response.data);
    return response.data;
}

export const PostNotificationIsfollowing = async (manga_ID: string):Promise<boolean> => {
    const response = await Post('/notifications/isfollowing', JSON.stringify({manga_ID: manga_ID}));
    console.log("PostNotificationIsfollowing", response.data)
    return (response.data.status === 1)
}

export const PostMangaReport = async (manga_ID: string, tags: string[], description: string):Promise<AxiosResponse> => {
    const response = await Post('/manga/report', JSON.stringify({manga_ID: manga_ID, description: description}));
    return response
}

export interface IGetMangaReport {
    user_ID: string,
    description: string
}
export const GetMangaReport = async (manga_ID: string):Promise<IGetMangaReport[]> => {
    const response = await Get('/manga/report', {manga_ID: manga_ID});
    return response.data
}

export const PostNotificationFollow = async (manga_ID: string):Promise<AxiosResponse> => {
    const response = await Post('/notifications/follow', JSON.stringify({manga_ID: manga_ID}));
    return response
}

export const PostNotificationUnfollow = async (manga_ID: string):Promise<AxiosResponse> => {
    const response = await Post('/notifications/unfollow', JSON.stringify({manga_ID: manga_ID}));
    return response
}

export const PostNotificationAuthorIsfollowing = async (author_ID: string):Promise<boolean> => {
    const response = await Post('/notifications/author/isfollowing', JSON.stringify({author_ID: author_ID}));
    console.log("PostNotificationAuthorIsfollowing", response.data, (response.data.status === 1))
    return (response.data.status === 1)
}

export const PostNotificationAuthorFollow = async (author_ID: string):Promise<AxiosResponse> => {
    const response = await Post('/notifications/author/follow', JSON.stringify({author_ID: author_ID}));
    return response
}

export const PostNotificationAuthorUnfollow = async (author_ID: string):Promise<AxiosResponse> => {
    const response = await Post('/notifications/author/unfollow', JSON.stringify({author_ID: author_ID}));
    return response
}




export const getMangasInfoByIDs = async (oeuvre_ids: string[]):Promise<IOeuvre[]> => {
    // use getMangaInfoByID for each oeuvre_id

    let oeuvres: IOeuvre[] = [];
    await Promise.all(oeuvre_ids.map(async (oeuvre_id) => {
        const oeuvre = await getMangaInfoByID(oeuvre_id);
        oeuvres.push(oeuvre);
    }));

    return oeuvres;
}

export const getChapter = async (oeuvre_id: string, language: string, user_ID?: string):Promise<IDBChapter[]> => {
    console.log(oeuvre_id, language);
    const response = await Get('/manga/chapter', {manga_ID: oeuvre_id, language: language, user_ID: user_ID});
    console.log(response.data);
    return response.data.chapters_accessibles;
}

export interface IGetChapterAuthor {
    name: string,
    id: string,
}
export const GetChapterAuthor = async (oeuvre_id: string, language: string, user_ID?: string):Promise<IGetChapterAuthor> => {
    console.log(oeuvre_id, language);
    const response = await Get('/manga/chapter', {manga_ID: oeuvre_id, language: language, user_ID: user_ID});
    console.log(response.data);
    return response.data.chapterAuthorInfo;
}

export const AddChapterToDB = async (chapter: IDBChapter, oeuvre: IOeuvre): Promise<AxiosResponse> => {
    const formData = new FormData();
    console.log(oeuvre.id, chapter.name)
    formData.append('manga_ID', oeuvre.id);
    formData.append('chapterName', chapter.name);
    formData.append('chapterFree', JSON.stringify(chapter.chapterFree));

    if (chapter.images) {
        const imageBlobs = await Promise.all(chapter.images.map(async (imageUri, index) => {
            const response = await fetch(imageUri);
            const blob = await response.blob();
            return new File([blob], `images_${index}.jpg`, { type: 'image/jpeg' });
        }));
    
        imageBlobs.forEach(blob => {
            formData.append('images', blob);
        });
    }

    console.log(formData);
    const response = await PostForm('/manga/chapter/create', formData, 'multipart/form-data');

    return response;
}

export const AddTranslatedChapter = async (chapter: IChapter, oeuvre: IOeuvre, userID: string, chapterID: string, language: string): Promise<AxiosResponse> => {
    const formData = new FormData();
    console.log(oeuvre.id, chapter.title)
    formData.append('user_ID', userID);
    formData.append('chapter_ID', chapterID);
    formData.append('language', language);
    formData.append('manga_ID', oeuvre.id);
    formData.append('chapterNameTranslated', chapter.title);

    if (chapter.imageUris) {
        const imageBlobs = await Promise.all(chapter.imageUris.map(async (imageUri, index) => {
            const response = await fetch(imageUri);
            const blob = await response.blob();
            return new File([blob], `images_${index}.jpg`, { type: 'image/jpeg' });
        }));
    
        imageBlobs.forEach(blob => {
            formData.append('images', blob);
        });
    }

    console.log(formData);
    const response = await Post('/manga/chapter/translate', formData, 'multipart/form-data');

    return response;
}


export const getAllChapter = async (oeuvre_id: string, chapter_id: string):Promise<any[]> => {
    const response = await Get('/manga/chapter/getImages', {manga_ID: oeuvre_id, chapter_ID: chapter_id});
    console.log(response.data);
    return response.data;
}

export const getChapterById = async (oeuvre_id: string, chapter_id: string):Promise<IDBChapter> => {
    const response = await Get('/manga/chapter/info', {manga_ID: oeuvre_id, chapter_ID: chapter_id});
    console.log(response.data);
    return response.data;
}

export const GetNoTradManga = async (language: string):Promise<IOeuvre[]> => {
    // return mockOeuvres;
    const response = await Get('/manga/missingTranslation', {language: language});
    console.log("GetNoTradManga", response.data);
    return response.data;
}

export const getChapterAvailable = async(userID: string, mangaID: string, language: string):Promise<IDBChapter[]> => {
    // return;
    const list = await getChapter(mangaID, 'original');
    console.log("user: ", userID)
    console.log("manga: ", mangaID)
    console.log("language: ", language)
    try {
        const response = await Get('/translator/chapterTranslated', {
                user_ID: userID, 
                manga_ID: mangaID,
                language: language
            }
        );
        if (response.status > 300 || response.status == -1) {
            if (response.status == 304) {
                return list
            }
            return [];
        }
        const response_chapter_translated: IDBChapterMod[] = response.data;
        console.log(response_chapter_translated)
        console.log(list);
    //    const resp_data = list;
        const resp_data = list.filter(itemB => !response_chapter_translated.some(itemA => String(itemA.chapterNumber) === String(itemB.numberChapter)));
    
        console.log("getChapterAvailable: data", resp_data);
        return resp_data;
    } catch (error) { // really AxiosError?
        console.error(error);
    }
    return [];
}

export const getMangaComments = async(book_ID: string, user_ID: string):Promise<AxiosResponse> => {
    const response = await Get('manga/comment', {"user_ID": user_ID, "book_ID": book_ID});
    return {status: 200, statusText: "Success", data: JSON.stringify([{"likes": 21, "dislikes": 42, "comment": "niceuh", "response": ["65853213a8877b2216925b4f", "65853213a8877b2216925b4f"], "creationDate": 1704509462, "author_ID": "65adfd1767b7a99733388f1e", "id": "658532f391c61ca9fbf82ffd", "likedByUser": false}])}
    return response
}

export const addMangaCommentRequest = async(userID: string, book_ID: string, dest_ID: string, comment: string):Promise<AxiosResponse> => {
    const response = await Post('manga/comment', {"user_ID": userID, "manga_ID": book_ID, "dest_ID": dest_ID , "comment": comment});
    return response
};

export const ModifyMangaRequest = async(oeuvre: IOeuvre):Promise<AxiosResponse> => {
    const formData = new FormData();
    console.log(oeuvre.id)
    formData.append('manga_ID', oeuvre.id);
    formData.append('desc', oeuvre.description);
    formData.append('name', oeuvre.name);
    formData.append('tags', JSON.stringify(oeuvre.tags));

    // if (oeuvre.cover) {
        // await fetch(oeuvre.cover)
        // .then(response => response.blob())
        // .then(blob => {
            // Créer un objet File à partir du Blob
            // var file = new File([blob], oeuvre.name + '_cover.jpg', { type: 'image/jpeg' });
            // 
            // Ajouter le fichier à FormData
            // formData.append('image', file);
        // })
        // .catch(error => {
            // console.error('Erreur lors de la récupération de l\'image :', error);
        // });
    // }

    console.log(formData);
    const response = await PostForm('/manga/modify', formData, 'multipart/form-data');
    return response;

}

export const DeleteMangaRequest = async(book_ID: string):Promise<AxiosResponse> => {
    const response = await Delete('/manga/delete', {"manga_ID": book_ID});
    return response;
}

// Route non implementée
export const ModifyChapterRequest = async(chapter: IDBChapter, oeuvre: IOeuvre):Promise<AxiosResponse> => {
        const formData = new FormData();
        console.log(oeuvre.id, chapter.name)
        formData.append('manga_ID', oeuvre.id);
        formData.append('chapterName', chapter.name);
    
        if (chapter.images) {
            const imageBlobs = await Promise.all(chapter.images.map(async (imageUri, index) => {
                const response = await fetch(imageUri);
                const blob = await response.blob();
                return new File([blob], `images_${index}.jpg`, { type: 'image/jpeg' });
            }));
        
            imageBlobs.forEach(blob => {
                formData.append('images', blob);
            });
        }
    
        console.log(formData);
        const response = await PostForm('/manga/chapter/modify', formData, 'multipart/form-data');
    
        return response;
}

export const PostMangaChapterModify = async (manga_ID: string, chapter_ID: string, name: string, ChapterFree: boolean, images: string[]):Promise<AxiosResponse> => {
    const resp = await Post('/manga/chapter/modify', JSON.stringify({
        manga_ID: manga_ID,
        chapter_ID: chapter_ID,
        name: name,
        chapterFree: ChapterFree,
        images: images
    }));
    return resp;
}

// Route non implementée
export const DeleteChapterRequest = async(book_ID?: string, chapter_ID?: string):Promise<AxiosResponse> => {
    const response = await Delete('/manga/chapter/delete', {"manga_ID": book_ID, "chapter_ID": chapter_ID});
    return response
}

export const getBookComments = async(book_ID: string):Promise<AxiosResponse> => {
    console.log("getBookComments params", {manga_ID: book_ID})
    const response = await Get('/manga/comment', {manga_ID: book_ID});
    console.log("getBookComments", response.data)
    return response
}

export const BookCommentRequest = async(user_ID: string, manga_ID: string, dest_ID: string, message: string):Promise<AxiosResponse> => {
    const response = await Post('/manga/comment', JSON.stringify({user_ID, manga_ID, dest_ID, comment: message}));
    console.log("BookCommentRequest", response.data)
    return response
}

export const GetPossibleTranslation = async(author_ID: string):Promise<AxiosResponse> => {
    const response = await Get('/author/newTranslation', {author_ID: author_ID});
    console.log("GetPossibleTranslation", response.data)
    return response
}
export interface IGetAuthorClaim {
    team_ID: string,
    team_name: string,
    team_icon: string,
    language: string,
    manga_ID: string,
    manga_name: string,
    status: string,
    claim_ID: string
}
export const GetAuthorClaim = async():Promise<IGetAuthorClaim[]> => {
    const response = await Get('/author/claim', {});
    console.log("GetAuthorClaim", response.data)
    return response.data
}

export const PostAuthorClaimInteract = async(claim_ID: string, authorized: boolean):Promise<AxiosResponse> => {
    const response = await Post('/author/claim/interact', JSON.stringify({claim_ID: claim_ID, authorized: authorized}));
    return response
}

export const RequestValidateTranslation = async(translationInValidation_ID: string):Promise<AxiosResponse> => {
    const response = await Post('/author/validateTranslation', JSON.stringify({translationInValidation_ID: translationInValidation_ID}));
    console.log("RequestValidateTranslation", response.data)
    return response
}

export const RequestCancelTranslation = async(translationInValidation_ID: string, message: string):Promise<AxiosResponse> => {
    const response = await Post('/author/cancelTranslation', JSON.stringify({translationInValidation_ID: translationInValidation_ID, message: message}));
    console.log("RequestCancelTranslation", response.data)
    return response
}

export const getStashedChapter = async(translationInValidation_ID: string):Promise<AxiosResponse> => {
    const response = await Get('/author/getStashedChapter', {translationInValidation_ID: translationInValidation_ID});
    console.log("getStashedChapter", response.data)
    return response
}

export const BuyChapter = async(user_ID?: string, manga_ID?: string, chapter_ID?: string):Promise<AxiosResponse> => {
    const response = await Post('/manga/chapter/buy', JSON.stringify({user_ID: user_ID, manga_ID: manga_ID, chapter_ID: chapter_ID}));
    console.log("ByChapter", response.data)
    return response
}

export interface IGetAuthorWorkingTeams {
    team_ID: string,
    team_name: string,
    team_icon: string,
    mangas_cover: string[],
}
export const GetAuthorWorkingTeams = async():Promise<IGetAuthorWorkingTeams[]> => {
    const response = await Get('/author/workingTeams', {});
    return response.data
}

export const GetAuthorTeamCompetition = async(translationInValidation_ID: string, newTeam_ID: string):Promise<AxiosResponse> => {
    const response = await Post('/author/team/competition', JSON.stringify({translationInValidation_ID: translationInValidation_ID, newTeam_ID: newTeam_ID}));
    return response
}
