import React, {useContext, useEffect, useState} from 'react';
import gatek from '../../Assets/Images/_Gatek.png';
import timeline from '../../Assets/Images/Vitrine/roadmap.png';

interface Actualities {
    title: string;
    content: string;
    date: string;
    // Ajoutez d'autres propriétés si nécessaire
}

const fetchActualities = (): Actualities[] => {
    const Actualities_list: Actualities[] = [
        {title: "Phase Alpha", content: "Nous avons lancé pour la premiere fois la phase Alpha de notre service", date: "29/01/2024"},
        {title: "Phase Prototype", content: "", date: "29/09/2023"}
    ]; // Initialisation d'une liste vide

    return Actualities_list; // Retourner la liste
}

const fetchNextSteps = (): Actualities[] => {
    const NextSteps_list: Actualities[] = [
        {title: "Phase Beta", content: "", date: "29/05/2024"},
        {title: "Phase Securisation", content: "", date: "29/09/2024"}
    ]; // Initialisation d'une liste vide

    return NextSteps_list; // Retourner la liste
}


const Actualités: React.FC<{actu: Actualities}> = ({actu}) => {


    return (
        <div className='flex items-center bg-gatek_gray-100 shadow-lg rounded-full border py-4 my-5 cursor-pointer' style={{ borderRadius: '20px' }}> 
            <h2 className='text-xl rounded-full bg-gatek-200 opacity-80 py-2 px-4 mr-4'>{actu.date}</h2>
            <h2 className='font-bold text-xl'>{actu.title}</h2>
        </div>
    );
}

const Timeline: React.FC<{}> = () => {
    const [actualities, setActualities] = useState(fetchActualities())
    const [nextSteps, setNextSteps] = useState(fetchNextSteps())

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalActu, setModalActu] = useState<Actualities>();

    const openModal = () => {
        setModalIsOpen(true);
    }

    const closeModal = () => {
        setModalIsOpen(false);
    }

    return (
        <div>
            <div className='relative w-full flex px-16 pb-8'>
                <div className='relative bg-gatek_gray-100 w-full h-full rounded-tr shadow-2xl p-4'>
                    <p className='Poppins-SemiBold text-3xl'>Nos Actualités</p>
                    <div className=''>
                        <img src={timeline}/>
                    </div>
                    <div className='relative w-full h-full flex flex-col gap-4'>
                        <h1 className='relative Poppins-SemiBold text-xl'>📖 Plus de détails ?</h1>
                        <div className='relative w-full flex flex-col border-b border-gatek-400'>
                            <h1 className='relative Poppins-SemiBold text-base'>💡 Initialisation</h1>
                            <h1 className='relative Inter-Regular text-base'>
                                Le processus de réflexion pour la création de GATEK a été méthodique et basé sur une compréhension approfondie du marché des mangas en France. En se concentrant sur les besoins non satisfaits des lecteurs et des auteurs, GATEK se positionne comme une librairie numérique innovante, offrant une diversité d’œuvres et une interactivité accrue entre les créateurs et leur public.
                            </h1>
                        </div>
                        <div className='relative w-full flex flex-col border-b border-gatek-400'>
                            <h1 className='relative Poppins-SemiBold text-base'>⚙️ Prototyping</h1>
                            <h1 className='relative Inter-Regular text-base'>
                                La phase de prototypage de GATEK vise à transformer les concepts théoriques en un produit tangible et fonctionnel. L'objectif principal est de valider les idées et les fonctionnalités imaginées pour s'assurer qu'elles répondent aux besoins des utilisateurs. Pour commencer, des wireframes et des maquettes de l'interface utilisateur sont créés. Les wireframes sont des schémas simplifiés qui montrent la disposition générale des éléments tels que les menus, les boutons et les sections, sans détails graphiques. Ensuite, des mockups plus détaillés et visuellement aboutis sont réalisés pour offrir un aperçu plus réaliste de l'apparence finale de la plateforme.

À partir de ces mockups, un prototype interactif est développé. Ce prototype simule le fonctionnement de la plateforme et permet aux utilisateurs de naviguer à travers les différentes sections. Les éléments inclus sont une page d'accueil présentant les œuvres disponibles, les nouveautés et les recommandations personnalisées, une page de lecture dédiée à la lecture des mangas avec des options de zoom et de navigation, et un espace de communication où les auteurs peuvent publier des mises à jour et les lecteurs peuvent commenter et interagir.

Une fois le prototype interactif prêt, des sessions de test utilisateurs sont organisées. Les participants, représentatifs de l'audience cible, utilisent le prototype et partagent leurs impressions. Les tests évaluent la facilité de navigation, l'esthétique et la lisibilité de la plateforme ainsi que l'adéquation des fonctionnalités proposées aux attentes et besoins des utilisateurs. Les retours des utilisateurs sont recueillis et analysés pour identifier les problèmes potentiels et les opportunités d'amélioration avant le développement final. Cette étape permet d'effectuer des itérations sur le prototype afin de peaufiner l'expérience utilisateur et de s'assurer que GATEK offre une interface intuitive, attrayante et fonctionnelle répondant aux besoins spécifiques des lecteurs et des auteurs de mangas en France.
                            </h1>
                        </div>
                        <div className='relative w-full flex flex-col border-b border-gatek-400'>
                            <h1 className='relative Poppins-SemiBold text-base'>🧠 Improvement Process & Methodology</h1>
                            <h1 className='relative Inter-Regular text-base'>
                                Le processus d'amélioration et la méthodologie pour GATEK sont conçus pour garantir que la plateforme reste constamment alignée sur les besoins des utilisateurs et qu'elle évolue en fonction des retours et des tendances du marché. Ce processus commence par la collecte de données et de feedbacks. Les données proviennent de diverses sources, notamment les sessions de test utilisateurs, les commentaires des utilisateurs sur la plateforme, et les analyses de performance. Ces informations sont essentielles pour identifier les domaines nécessitant des améliorations.

Ensuite, une analyse approfondie des données recueillies est réalisée. Cette analyse permet de détecter les points de friction, les fonctionnalités manquantes ou sous-performantes, et les opportunités d'amélioration. Les retours des utilisateurs sont priorisés en fonction de leur fréquence et de leur impact potentiel sur l'expérience utilisateur.

Sur la base de cette analyse, une feuille de route d'amélioration est élaborée. Cette feuille de route détaille les modifications à apporter, les nouvelles fonctionnalités à développer et les ajustements à effectuer. Les améliorations sont classées par ordre de priorité, en se concentrant d'abord sur les aspects les plus critiques pour l'expérience utilisateur.

Le développement des améliorations se fait ensuite de manière itérative. Chaque itération inclut des phases de conception, de développement, et de test. Les prototypes des nouvelles fonctionnalités ou des améliorations sont testés en interne avant d'être déployés auprès d'un groupe d'utilisateurs bêta. Ces tests bêta permettent de recueillir des feedbacks supplémentaires et d'effectuer les ajustements nécessaires avant le déploiement à grande échelle.

Une fois les améliorations déployées, un suivi continu est mis en place pour évaluer leur impact. Les métriques clés sont surveillées pour s'assurer que les modifications apportées améliorent effectivement l'expérience utilisateur et répondent aux objectifs définis. Les utilisateurs sont également encouragés à continuer de fournir des feedbacks, assurant ainsi un cycle d'amélioration continue.

Ce processus méthodique d'amélioration garantit que GATEK évolue constamment pour offrir une expérience utilisateur optimale, en restant réactif aux besoins des lecteurs et des auteurs de mangas, et en s'adaptant aux évolutions du marché et de la technologie.
                            </h1>
                        </div>
                        <div className='relative w-full flex flex-col border-b border-gatek-400'>
                            <h1 className='relative Poppins-SemiBold text-base'> 💥Alpha </h1>
                            <h1 className='relative Inter-Regular text-base'>
                            La phase de bêta pour GATEK est une étape cruciale visant à tester la plateforme dans des conditions réelles avant son lancement officiel. L'objectif principal de cette phase est d'identifier et de corriger les éventuels problèmes techniques, de valider les fonctionnalités et de recueillir des retours détaillés des utilisateurs. Cette phase commence par la sélection d'un groupe représentatif d'utilisateurs bêta, composé de lecteurs passionnés de mangas et d'auteurs intéressés par la plateforme. Ces utilisateurs sont choisis pour leur capacité à fournir des retours constructifs et détaillés.

Avant de lancer la bêta, une version préliminaire de GATEK est préparée. Cette version inclut toutes les fonctionnalités principales prévues pour le lancement, telles que l'accès aux mangas, les outils de communication entre auteurs et lecteurs, et les options de personnalisation de l'expérience utilisateur. La stabilité et la sécurité de la plateforme sont également testées en interne pour s'assurer qu'elle est prête à être utilisée par un plus grand nombre de personnes.

Une fois la version bêta prête, les utilisateurs sélectionnés reçoivent des instructions détaillées sur la manière d'accéder à la plateforme et d'utiliser ses différentes fonctionnalités. Ils sont encouragés à explorer la plateforme de manière exhaustive, à tester toutes les fonctionnalités et à utiliser la plateforme comme ils le feraient après le lancement officiel. Pendant cette phase, un canal de communication direct est mis en place pour que les utilisateurs bêta puissent facilement rapporter les bugs, partager leurs impressions et suggérer des améliorations.

Les retours des utilisateurs bêta sont analysés en continu. Les problèmes techniques signalés sont priorisés et corrigés rapidement. Les suggestions d'amélioration sont également prises en compte et, lorsque cela est possible, intégrées avant la fin de la phase bêta. Cette approche itérative permet d'affiner la plateforme et d'assurer qu'elle répond aux attentes des utilisateurs.

En parallèle, des métriques clés sont surveillées pour évaluer la performance de la plateforme. Ces métriques incluent le temps de chargement des pages, la stabilité du système, et l'engagement des utilisateurs. Les données recueillies aident à identifier les zones de la plateforme nécessitant des optimisations supplémentaires.

La phase de bêta se termine par une évaluation globale de la plateforme, en tenant compte des retours des utilisateurs et des données collectées. Les améliorations finales sont apportées et une version stable et optimisée de GATEK est préparée pour le lancement officiel. Cette phase permet de s'assurer que GATEK est prête à offrir une expérience utilisateur de haute qualité dès son lancement, en répondant efficacement aux besoins des lecteurs et des auteurs de mangas.
                            </h1>
                        </div>
                        <div className='relative w-full flex flex-col border-b border-gatek-400'>
                            <h1 className='relative Poppins-SemiBold text-base'>📱 Beta </h1>
                            <h1 className='relative Inter-Regular text-base'>
                            La phase d'alpha de GATEK est une étape préliminaire et essentielle dans le développement de la plateforme, visant à tester les fonctionnalités de base dans un environnement contrôlé avant d'atteindre un public plus large. Cette phase a pour objectif principal de s'assurer que les fonctionnalités essentielles fonctionnent correctement et d'identifier les principaux bugs et problèmes techniques. La phase d'alpha commence par le développement d'une version initiale de la plateforme, incluant les fonctionnalités de base telles que l'accès aux mangas, la navigation sur la plateforme, et les outils de communication entre auteurs et lecteurs.

Un groupe restreint de testeurs, composé principalement de membres de l'équipe de développement, de partenaires proches, et de quelques utilisateurs expérimentés et de confiance, est sélectionné pour participer à cette phase. Ces testeurs ont pour mission d'explorer la plateforme de manière exhaustive et de tester toutes les fonctionnalités disponibles. Leur rôle est crucial pour détecter les bugs et les incohérences qui pourraient ne pas être apparus lors des tests internes.

Les testeurs reçoivent des instructions détaillées sur les aspects spécifiques de la plateforme à tester et sont encouragés à documenter leurs expériences, en notant les problèmes rencontrés et en proposant des améliorations. Un canal de communication dédié est mis en place pour faciliter le rapport des bugs et la discussion des retours entre les testeurs et l'équipe de développement.

Pendant cette phase, l'accent est mis sur la robustesse technique de la plateforme. Les problèmes de performance, les bugs critiques et les failles de sécurité sont identifiés et corrigés. Des tests de charge sont également effectués pour évaluer la capacité de la plateforme à gérer plusieurs utilisateurs simultanément, même si le nombre de testeurs est limité. Ces tests permettent de s'assurer que la structure technique de GATEK est solide et capable de supporter une utilisation plus intensive lors des phases ultérieures.

Les retours des testeurs sont analysés en continu, et des cycles de développement rapides sont mis en place pour adresser les problèmes identifiés. Les itérations fréquentes permettent de tester rapidement les corrections et les améliorations apportées, garantissant ainsi que les problèmes critiques sont résolus avant de passer à la phase suivante.

La phase d'alpha se termine par une évaluation globale de la stabilité et de la fonctionnalité de la plateforme. Les résultats de cette évaluation déterminent si GATEK est prêt à passer à la phase de bêta, où un public plus large testera la plateforme. Cette phase assure que GATEK est techniquement solide et que les principales fonctionnalités répondent aux attentes avant d'être testées par un nombre plus important d'utilisateurs.
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

export default Timeline;