import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import cover_test from '../../../Assets/Images/cover_test.png';
import ico_play_white from '../../../Assets/Images/ico_play_white.png';
import white_plus from '../../../Assets/Images/white_plus.png';
import action from '../../../Assets/Images/Genders/action.png';
import autre from '../../../Assets/Images/Genders/autre.png';
import comedie from '../../../Assets/Images/Genders/comedie.png';
import fantaisie from '../../../Assets/Images/Genders/fantaisie.png';
import mystere from '../../../Assets/Images/Genders/mystere.png';
import romance from '../../../Assets/Images/Genders/romance.png';
import science from '../../../Assets/Images/Genders/science.png';
import sport from '../../../Assets/Images/Genders/sport.png';
import book_black from '../../../Assets/Images/book_black.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar from './Navbar';
import { IArticle, IOeuvre } from '../../../Interfaces/Discover';
import { getDiscoverd, getLastedChapter, getLastedManga, ILastChapter } from '../../../Api/Oeuvres';
import ObjectBox from '../../Dashboard/Subcomponent/ObjectBox';
import { GetArticles } from '../../Edit/Utils/IAdd';
import { GetStorage } from '../../../Api/Storage';
import { GetUsers } from '../../../Interfaces/User';
import ArticleBox from './ArticleBox';

export const Genders:{name:string, cover:string, desc?:string, tag?: string}[] = [
    {name: 'Action', cover: action, desc: 'Sentez les frissons des combats acharnés !', tag: 'action'},
    {name: 'Comédie', cover: comedie, desc: 'Riez aux éclats avec des situations hilarantes !', tag: 'comedy'},
    {name: 'Fantaisie', cover: fantaisie, desc: 'Découvrez des univers magiques et merveilleux !', tag: 'fantasy'},
    {name: 'Science', cover: science, desc: 'Voyagez à travers l\'espace et le temps avec des technologies futuristes !', tag: 'science-fiction'},
    {name: 'Sport', cover: sport, desc: 'Vivez la passion et l\'intensité de la compétition sur le terrain !', tag: 'sport'},
    {name: 'Romance', cover: romance, desc: 'Vibrez au rythme des histoires d\'amour passionnées.', tag: 'romance'},
    {name: 'Mystère', cover: mystere, desc: 'Résolvez des énigmes et dévoilez des secrets bien gardés.', tag: 'mystery'},
    {name: 'Autre', cover: autre}
];

const Discovered: React.FC = () => {
    const {user, setuser, token, setToken} = useContext(MainContext);
    const [discover_oeuvres, setdiscover_oeuvres] = useState<IOeuvre[]>([]);
    const [lasted_oeuvres, setlasted_oeuvres] = useState<IOeuvre[]>([]);
    const [discover_articles, setdiscover_articles] = useState<IArticle[]>([]);
    const [last_chapters, setlast_chapters] = useState<ILastChapter[]>([]);
    const [discoverIdx, setDiscoverIdx] = useState<number>(0);
    const navigate = useNavigate();

    useEffect(() => {
        if (token !== undefined) {
            console.log("token", token);
            sessionStorage.setItem('token', token);

            // const user_id = sessionStorage.getItem('user_id');
            const user_id = GetStorage(token, 'user_id');
            if (user_id !== undefined) {
                GetUsers(user_id).then((res) => {
                    console.log("GetUsers", res.data);
                    if (res.data !== undefined) {
                        res.data.id = user_id;
                        setuser(res.data);
                    } else {
                        // while user is undefined, try every 500ms to get the user
                        setTimeout(() => {
                            GetUsers(user_id).then((res) => {
                                if (res.data !== undefined) {
                                    res.data.id = user_id;
                                    setuser(res.data);
                                }
                            });
                        }, 500);
                    }
                });
            } else {
                navigate("/login");
            }
        }
    }, [token]);

    useEffect(() => {
        const t = sessionStorage.getItem('token');
        console.log(t);
        if (t !== null) { setToken(t); } else { navigate("../login"); return; }

        const path = window.location.pathname;
        // if (!path.includes('/dashboard')) {
        //     navigate("/dashboard/discover");
        // }

    }, [])

    useEffect(() => {
        getDiscoverd().then((new_oeuvres) => { // backend_url+discover_oeuvres[0].cover
            setdiscover_oeuvres(new_oeuvres);
        });
        getLastedManga().then((new_oeuvres) => {
            setlasted_oeuvres(new_oeuvres);
        });
        getLastedChapter().then((new_oeuvres) => {
            setlast_chapters(new_oeuvres);
        });
        if (user) {
            GetArticles(user).then((new_articles) => {
                setdiscover_articles(new_articles);
            });
        }
    }, [user]);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-screen h-screen overflow-x-hidden overflow-y-scroll'>
                <div className='relative h-[50%] w-full overflow-hidden flex flex-col justify-between'>
                    <div className='absolute w-full h-full bg-black bg-opacity-70'></div>
                    {(discover_oeuvres.length > 0) && <img src={backend_url+discover_oeuvres[discoverIdx].cover} className='absolute w-full object-cover -z-10' />}
                    <div className='relative h-[10%]'>
                        <Navbar />
                    </div>
                    {(discover_oeuvres.length > 0) && <div className='h-[80%] flex justify-between px-[8%]'>
                        <div className='relative w-1/3 flex flex-col text-left justify-start'>
                            <h1 className='relative text-white text-4xl Poppins-SemiBold'>{discover_oeuvres[discoverIdx].name}</h1>
                            <div className='relative max-h-full flex flex-wrap gap-2 items-center overflow-y-scroll'>
                                {discover_oeuvres[discoverIdx].tags.map((tag, index) => {
                                    return (
                                        <span key={index} className='relative Montserrat-SemiBold px-2 py-1 text-sm rounded-lg Montserrat-Regular bg-white'>{tag}</span>
                                    );
                                })}
                            </div>
                        </div>
                        <img  src={backend_url+discover_oeuvres[discoverIdx].cover} className='relative w-1/3 object-contain justify-start p-4' />
                        <div className='relative w-1/3 flex flex-col gap-[8%] justify-start'>
                            <textarea disabled={true} className='relative w-full h-1/2 resize-none bg-transparent outline-none text-gray-300' value={discover_oeuvres[discoverIdx].description} readOnly></textarea>

                            <h1 className='relative text-gray-300 Montserrat-Regular italic'>{discover_oeuvres[discoverIdx].author}</h1>

                            <button onClick={() => {
                                navigate(`../oeuvre?id=${discover_oeuvres[discoverIdx].id}`);
                            }} className='Montserrat-SemiBold rounded-2xl w-1/4 p-2 duration-150 flex items-center justify-center gap-2 bg-gatek_red-600 hover:bg-gatek_red-700 text-white'>
                                <img src={ico_play_white} className='h-5 object-contain' />
                                Lire
                            </button>
                            <div className='h-5 flex gap-4 flex-wrap justify-end'>
                                {Array.from({length: discover_oeuvres.length}).map((_, index) => {
                                    return (
                                        <div onClick={(e) => {
                                            setDiscoverIdx(index);
                                        }} key={index} className={`
                                            rounded-full h-full w-5 ${discoverIdx === index ? 'bg-gatek_red-600' : 'bg-white'} cursor-pointer
                                        `}>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>}
                </div>

                {(discover_oeuvres.length > 0) && <div className='relative h-[50%] px-[8%] w-full mt-4'>
                    <ObjectBox label="Nouvelles oeuvres" oeuvres={lasted_oeuvres} />
                </div>}

                {<div className='relative h-[50%] px-[8%] w-full mt-4'>
                    <h1 className={`relative h-[15%] text-4xl text-black Poppins-SemiBold p-4`}>Nouveaux chapitres</h1>
                    <div className='relative h-[80%] grid grid-cols-4 grid-rows-2 gap-4'>
                        {last_chapters.map((chapter, index) => {
                            if (index > 7) return;
                            return (
                                <div onClick={() => {
                                    navigate(`../read?id=${chapter.manga_ID}&chapter=${chapter.chapter_ID}&lang=original`);
                                }} className='border-b border-gray-400 border-opacity-30 w-full flex gap-4 duration-150 hover:opacity-70 cursor-pointer'>
                                    <img src={backend_url+chapter.cover} className='h-full object-contain' />
                                    <div className='relative h-full'>
                                        <h1 className='Montserrat-SemiBold'>{chapter.name}</h1>
                                        <h1 className='Montserrat-SemiBold flex gap-2 items-center'><img src={book_black} className='h-4' />Ch. {chapter.chapterNumber}</h1>
                                        <h1 className='absolute Montserrat-SemiBold text-gray-400 bottom-0 left-0'>{chapter.author}</h1>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>}

                <div className='relative h-[90%] px-[8%] w-full bg-gray-800 py-4  mt-4'>
                    <h1 className='relative h-[10%] text-white text-2xl Poppins-SemiBold'>Genres</h1>
                    <div className='relative h-[90%] grid grid-cols-4 gap-4'>
                        {Genders.map((gender, index) => {                         
                            return (
                                <div onClick={(e) => {
                                    if (gender.name !== 'Autre')
                                        navigate(`../search?tags=${gender.name}`);
                                    else
                                        navigate(`../search`);
                                }} key={index} className='relative w-full h-full bg-gatek_red-400 rounded-3xl bg-opacity-60 cursor-pointer'>
                                    <img src={gender.cover} className='absolute w-full h-full opacity-30' />
                                    <div className='p-4 w-full h-full flex flex-col justify-between'>
                                        <h1 className='relative text-white text-2xl Poppins-SemiBold italic'>{gender.name}</h1>
                                        <h1 className='relative text-white text-sm Poppins-Regular italic'>{gender.desc}</h1>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className='relative h-[90%] px-[8%] flex flex-col gap-4 w-full bg-gradient-to-t from-gray-400 to-white py-4'>
                    <h1 className='relative h-[10%] text-2xl Poppins-SemiBold text-center py-4'>Articles</h1>
                    <div className='relative h-[80%] flex gap-4'>
                        <div className='relative w-1/2 flex flex-col gap-4'>
                            {discover_articles[0] && <ArticleBox {...discover_articles[0]} />}
                            {discover_articles[1] && <ArticleBox {...discover_articles[1]} />}
                        </div>
                        <div className='relative w-1/2 items-center justify-center flex'>
                            {discover_articles[2] && <ArticleBox {...discover_articles[2]} />}
                        </div>
                    </div>
                    <div className='relative w-full h-[10%] flex items-center justify-center'>
                        <button className='relative bg-red-700 text-white flex items-center justify-center gap-2 rounded-2xl p-2'>
                            <h1 className='relative Montserrat-SemiBold' >Voir</h1>
                            <img src={white_plus} className='' />
                        </button>
                    </div>
                </div>
            </div>
        </MainContext.Provider>
    );
}

export default Discovered;