import React, {useContext, useEffect, useRef, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import up from '../../../Assets/Images/up_black.png';
import down from '../../../Assets/Images/down_black.png';
import filter from '../../../Assets/Images/filter.png';
import up_black from '../../../Assets/Images/up_black.png';
import down_black from '../../../Assets/Images/down_black.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from './Navbar';
import { IArticle, IComment, IDBChapter, IOeuvre } from '../../../Interfaces/Discover';
import { BookCommentRequest, getBookComments, getChapter, getDiscoverd, getMangaInfoByID } from '../../../Api/Oeuvres';
import ObjectBox from '../../Dashboard/Subcomponent/ObjectBox';
import { AddArticleComment, AddComment, getArticleByID, GetArticles, getComments, SendLikeDislike, SendLikeDislikeArticle, SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getUser } from '../../../Api/User';

const ArticleMod: React.FC = () => {
    const {user} = useContext(MainContext);
    const [article, setArticle] = useState<IArticle | undefined>(undefined);
    const [author, setauthor] = useState<User | undefined>(undefined);
    const [chapters, setchapters] = useState<IDBChapter[]>([]);
    const [comments, setcomments] = useState<IComment[]>([]);
    const navigate = useNavigate();

    const url = new URL(window.location.href);
    const id = url.searchParams.get('id');

    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const ModifyTextAreaRef = () => {
        if (textareaRef.current) {
            // Fonction pour ajuster la hauteur
            const adjustHeight = () => {
                const textarea = textareaRef.current!;
                textarea.style.height = 'auto';
                textarea.style.height = `${textarea.scrollHeight}px`;
            };
    
            // Ajuster la hauteur initialement et lors de chaque changement de contenu
            adjustHeight();
        }
    }

    useEffect(() => {
        if (!id || ! user) {
            navigate('/discover');
            return;
        }

        getArticleByID(user, id).then((oeu) => {
            setArticle(oeu);
            ModifyTextAreaRef();
            if (oeu.author_ID)
                getUser(oeu.author_ID).then((u) => { setauthor(u); });

            getComments(user, oeu).then((response) => {
                setcomments(response);
            });
        });
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            {article && <div className='relative w-screen h-screen overflow-x-hidden overflow-y-scroll bg-gray-100'>
                <div className='relative w-full overflow-hidden justify-between'>
                    <div className='relative h-[10%]'>
                        <Navbar is_black={true} />
                    </div>
                    <div className='relative px-[16%] mt-4'>

                        <div className='flex flex-col justify-between py-4 gap-8'>
                            <h1 className='text-black text-2xl Montserrat-SemiBold'>{article.title}</h1>
                            <textarea ref={textareaRef} disabled={true} className='text-black resize-none text-sm Montserrat-Regular'>{article.description}</textarea>
                            {backend_url+article.images[0] && <img src={backend_url+article.images[0]} className='relative w-auto h-full object-contain' />}
                        </div>

                        <div className='h-[40px] flex gap-2 items-center Montserrat-Regular mt-4'>
                            <div className='relative w-[8%] h-full flex gap-4 items-center justify-center bg-gray-300 rounded-2xl'>
                                <div onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (user) {
                                        SendLikeDislikeArticle(user, article, article, true).then((_) => {
                                            getArticleByID(user, article.id).then((response) => { setArticle(response); })
                                        })
                                    }
                                }} className='relative flex justify-between w-1/3 h-[24px] items-center duration-150 hover:opacity-60 cursor-pointer'>
                                    <p className='text-sm Montserrat-Regular'>{article.likes}</p>
                                    <img src={up} className='h-full object-contain' />
                                </div>
                                <div onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (user) {
                                        SendLikeDislikeArticle(user, article, article, false).then((_) => {
                                            getArticleByID(user, article.id).then((response) => { setArticle(response); })
                                        })
                                    }
                                }} className='relative flex justify-between w-1/3 h-[24px] items-center duration-150 hover:opacity-60 cursor-pointer'>
                                    <p className='text-sm Montserrat-Regular'>{article.dislikes}</p>
                                    <img src={down} className='h-full object-contain' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='relative w-[70%] px-[8%] overflow-hidden justify-between p-4'>
                    <h1 className='text-2xl Montserrat-SemiBold'>{comments.length} commentaire(s)</h1>
                    <div className='relative h-[180px] w-full flex flex-col items-end gap-2'>
                        <div className='relative h-[80%] w-full flex Montserrat-Regular gap-2'>
                            <img src={backend_url+author?.avatar} onError={handleImageError} className='w-8 h-8 rounded-full object-cover' />
                            <textarea id="new_comment" placeholder='ajouter un commentaire' className='relative outline-none text-sm resize-none w-full border-2 border-gray-500 border-opacity-60 rounded-xl p-2'>
                            </textarea>
                        </div>
                        <button onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            const comment = (document.getElementById('new_comment') as HTMLTextAreaElement)?.value;

                            if (user)
                                AddArticleComment(user, article, comment).then((response) => {
                                    if (response) {
                                        getComments(user, article).then((response) => { setcomments(response); });
                                    }
                                });
                        }} className='relative bg-gray-300 Montserrat-SemiBold rounded-full p-4 w-[10%]'>
                            envoyé
                        </button>
                    </div>

                    {comments.map((comment, index) => {
                            return (
                                <div key={index} className='relative h-[180px] w-full flex flex-col items-end gap-2'>
                                    <div className='relative h-[80%] w-full flex Montserrat-Regular gap-2'>
                                        <img src={backend_url+author?.avatar} onError={handleImageError} className='w-8 h-8 rounded-full object-cover' />
                                        <div className='relative w-full flex flex-col'>
                                            <h1 className='relative w-full Montserrat-SemiBold'>{comment.author_name}</h1>
                                            <h1 className='relative text-sm w-full'>{comment.comment}</h1>
                                        </div>
                                    </div>
                                    <div className='relative w-[8%] h-full flex gap-4 items-center justify-center'>
                                        <div onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (user) {
                                                SendLikeDislike(user, article, comment, true).then((_) => {
                                                    getComments(user, article).then((response) => { setcomments(response); })
                                                })
                                            }
                                        }} className='relative flex justify-between w-1/3 h-[24px] items-center duration-150 hover:opacity-60 cursor-pointer'>
                                            <p className='text-sm Montserrat-Regular'>{comment.likes}</p>
                                            <img src={up_black} className='h-full object-contain' />
                                        </div>
                                        <div onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (user) {
                                                SendLikeDislike(user, article, comment, false).then((_) => {
                                                    getComments(user, article).then((response) => { setcomments(response); })
                                                })
                                            }
                                        }} className='relative flex justify-between w-1/3 h-[24px] items-center duration-150 hover:opacity-60 cursor-pointer'>
                                            <p className='text-sm Montserrat-Regular'>{comment.dislikes}</p>
                                            <img src={down_black} className='h-full object-contain' />
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                    </div>
                </div>
            </div>}
        </MainContext.Provider>
    );
}

export default ArticleMod;