import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import add_tag from '../../../Assets/Images/add_tag.png';
import filter from '../../../Assets/Images/filter.png';
import up_black from '../../../Assets/Images/up_black.png';
import down_black from '../../../Assets/Images/down_black.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from '../Discovered/Navbar';
import { IArticle, IComment, IDBChapter, IOeuvre } from '../../../Interfaces/Discover';
import { BookCommentRequest, getBookComments, getChapter, getDiscoverd, getMangaInfoByID, GetPossibleTranslation, Tags } from '../../../Api/Oeuvres';
import { AddManga, SendArticle, SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getMyArticles, getMyOeuvres, getUser } from '../../../Api/User';
import { imageHeight, imageWidth, maxMB } from '../../Edit/AddChapter';
import { AllWorldLanguages } from '../../Dashboard/Subcomponent/TraductorEditInterface';

const CreateArticle: React.FC = () => {
    const {user} = useContext(MainContext);
    const [oeuvre, setOeuvre] = useState<IArticle>({
        id: "",
        title: "",
        author: "",
        tags: [],
        description: "",
        images: [],
    });
    const [open, setOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const imgURI = URL.createObjectURL(file);
    
            // Vérifier la taille du fichier (exemple : 5MB max)
            const maxSize = maxMB * 1024 * 1024; // 5MB en octets
            if (file.size > maxSize) {
                alert("Le fichier est trop grand. Taille maximale : 5MB.");
                return;
            }
    
            const image = new Image();
            image.onload = () => {
                // if (image.naturalWidth !== imageWidth || image.naturalHeight !== imageHeight) {
                //     alert(`L'image doit être de ${imageWidth}x${imageHeight} pixels.`);
                //     return;
                // }
    
                const reader = new FileReader();
                reader.onloadend = () => {
                    setOeuvre({...oeuvre, images: [reader.result as string]});
                };
                reader.readAsDataURL(file);
            };
            image.onerror = () => {
                alert("Impossible de charger l'image. Veuillez réessayer.");
            };
            image.src = imgURI;
        }
    };

    const CanValidate = () => {
        if (oeuvre.title && oeuvre.description) {
            return true;
        }
        return false;
    };

    useEffect(() => {
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full overflow-hidden bg-gray-100'>
                <div className='relative h-[10%]'>
                    <Navbar is_black={true} />
                </div>
                <div className='relative h-[90%] px-[16%] py-8 gap-4 overflow-y-scroll'>
                    <div className='relative w-full h-[10%] flex gap-4 items-center'>
                        <input className='relative w-[100%] h-full p-2 border-2 border-gray-400 border-opacity-40 bg-white outline-none text-xl Montserrat-SemiBold rounded-xl' type='text' placeholder="Titre de l'article" value={oeuvre.title} onChange={(e) => {
                            setOeuvre({...oeuvre, title: e.target.value});
                        }}/>
                    </div>
                    <div className='mt-4 relative w-full h-[70%] flex flex-col rounded-xl border-2 border-gray-400 border-opacity-40 bg-white p-2'>
                        <img className='relative w-full h-[95%] object-contain' src={oeuvre.images[0] || logo_gatek} />
                        <input className='text-xs w-full h-[5%]' type="file" accept="image/*" multiple onChange={handleFileChange} />
                    </div>
                    <textarea placeholder='Description' onChange={(e) => {
                        setOeuvre({...oeuvre, description: e.target.value});
                    }} className='mt-4 relative w-full h-[50%] flex Montserrat-Regular p-2 gap-4 items-center outline-none text-black Poppins-SemiBold text-sm border-2 border-gray-400 border-opacity-40 rounded-xl'>
                    </textarea>
                    <div className='relative w-full h-[20%] flex items-end justify-end'>
                        <button disabled={!CanValidate()} onClick={() => {
                            if (user)
                                SendArticle(user, oeuvre).then((res) => {
                                    if (res && res.status === 200) {
                                        navigate('../edit/my-articles');
                                    } else {
                                        alert("Erreur lors de l'ajout de l'oeuvre");
                                    }
                                });
                        }} className={`relative w-[20%] h-[50%] Montserrat-SemiBold text-xl rounded-xl flex items-center justify-center gap-2 transform duration-150
                            ${CanValidate() ? 'cursor-pointer bg-gatek_red-500 text-white ' : 'cursor-not-allowed bg-gray-300 text-gray-500'}
                        `}>
                            Créer
                        </button>
                    </div>
                </div>
            </div>
        </MainContext.Provider>
    );
}

export default CreateArticle;