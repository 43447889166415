import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../../Assets/Images/logo_gatek.png';
import { Outlet, useNavigate } from 'react-router-dom';

import { MainContext } from '../../../../Context/Main';
import { CancelTranslation, GetBooksClaimed, ITeam, ITranslationInValidation, RequestBookClaimed, TeamRequest, TranslationInValidation } from '../../../../Api/Team';
import { AllWorldLanguages } from '../TraductorEditInterface';
import { IOeuvre, ITranslatedBook } from '../../../../Interfaces/Discover';
import { GetNoTradManga, getMangaInfo } from '../../../../Api/Oeuvres';
import { backend_url } from '../../../../App';
import OeuvreModal from '../OeuvreModal';
import { handleImageError } from '../Discover';

const TeamTraductions: React.FC = () => {
    const {user, setuser, setcurrent_oeuvre, myteam, current_language, setcurrent_language, setseedetails} = useContext(MainContext);
    const [result_string, setresult_string] = useState<string>('');
    const [searchoeuvre, setsearchoeuvre] = useState<IOeuvre[]>([]);
    const [mytraduction, setmytraduction] = useState<ITranslatedBook[]>([]);
    const [option , setoption] = useState<string>('langue');
    const [selected_language, setselected_language] = useState<string>(AllWorldLanguages[0]);
    const [hide, sethide] = useState<boolean>(false);
    const [TranslationInValidationData, setTranslationInValidationData] = useState<ITranslationInValidation[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        if (myteam) {
            GetBooksClaimed(myteam.team_ID).then((response) => {
                if (response.status === 200) {
                    // let _d: {manga_claimed: ITranslatedBook[]} = response.data;
                    setmytraduction(response.data);
                }
            });

            TranslationInValidation(myteam.team_ID).then((res) => {
                if (res.status === 200 && res.data) {
                    setTranslationInValidationData(res.data);
                }
            });
        }
    }, [myteam]);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full'>
                <div className='relative w-full h-1/6 flex gap-4 justify-between p-4 border-b'>
                     <h1 className='Poppins-SemiBold text-2xl  self-baseline flex flex-col gap-1 w-1/3'>📚 Oeuvres :
                         <input type='text' placeholder='Rechercher une oeuvre' className='relative text-black p-1 w-full h-1/3 outline-none Poppins-SemiBold text-base'/>
                     </h1>
                     <h1 className='Poppins-SemiBold text-2xl self-baseline  flex flex-col w-1/4 gap-1'>💬 Langue :
                         <select onChange={(e) => {
                             setselected_language(e.target.value);
                             setcurrent_language(e.target.value);
                         }} className='relative p-1 w-full h-1/3 outline-none text-black Poppins-SemiBold text-base'>
                             {AllWorldLanguages.map((langue, index) => {
                                 return (
                                     <option value={langue}>{langue}</option>
                                 );
                             })}
                         </select>
                     </h1>
                     <h1 className='Poppins-SemiBold text-2xl self-baseline  flex flex-col w-1/4 gap-1'>🔎 Filtre :
                         <select onChange={(e) => {
                             console.log(e.target.value)
                             setoption(e.target.value);
                         }} className='relative p-1 w-full h-1/3 outline-none text-black Poppins-SemiBold text-base'>
                             <option value='langue'>Langue traduite</option>
                             <option value='no_langue'>Langue non traduite</option>
                         </select>
                     </h1>
                     <div className='relative w-1/4 h-full flex flex-col gap-4 justify-between'>
                         <button className='relative w-full h-1/3 self-baseline Poppins-SemiBold text-base bg-gatek-200 transform hover:scale-105 transition-all duration-300 rounded-lg  hover:bg-opacity-70'
                         onClick={() => {
                            // setresult_string("Il n'y a pas de traduction pour cette oeuvre avec vos paramètres de recherche.");
                            if (option === 'no_langue') {
                                GetNoTradManga(selected_language).then((res) => {
                                    setsearchoeuvre(res);
                                });
                            } else {
                                setsearchoeuvre([]);
                            }
                            setsearchoeuvre([]);
                         }}>Rechercher</button>
                         <p className='Poppins-SemiBold text-base  italic'>{result_string}</p>
                     </div>
                 </div>
                {searchoeuvre.length > 0 && <div className='relative mt-8 w-full flex justify-between items-center border-b border-gatek-400'>
                        <h1 className='Poppins-SemiBold text-2xl self-baseline'>📚 Oeuvres trouvées ({searchoeuvre.length}): </h1>
                        <button onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            sethide(!hide);
                        }} className='relative w-1/4 Poppins-SemiBold text-base transform hover:scale-105 transition-all duration-300 rounded-lg'>{hide ? '👁️ Afficher' : '❌ Cacher'}</button>
                    </div>}
                {searchoeuvre.length > 0 && !hide && <div className='relative w-full h-[100%] grid grid-cols-7 gap-4 p-4 overflow-y-auto'>
                    {searchoeuvre.map((oeuvre, index) => {
                        return (
                            <div key={index} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                // getMangaInfo(oeuvre).then((oeu) => {
                                //     setcurrent_oeuvre(oeu);
                                //     navigate(`../translateoeuvre/`);
                                // });
                            }} className='relative w-full h-full flex flex-col'>
                                <img className='absolute left-[10%] w-[90%] h-[85%]' src={backend_url+oeuvre.cover || logo_gatek} />
                                <div className='absolute bottom-0 Poppins-SemiBold w-full h-[15%] text-white text-base truncate p-2 bg-gradient-to-r from-gatek_red-500 via-gatek_red-400 to-gatek_red-300 shadow-gray-600 shadow-md'>
                                    <h1 className='Inter-SemiBold text-sm bg-transparent h-[50%] w-full text-white truncate'>{oeuvre.name}</h1>
                                    <div className='relative w-full h-[50%] flex flex-row gap-4'>
                                        <button onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                                if (myteam)
                                                RequestBookClaimed(oeuvre.id, myteam.team_ID, selected_language).then((res) => {
                                                    if (res.status === 200) {
                                                        setresult_string("✅ L'oeuvre a été claim avec succès.");
                                                        // remove from search
                                                        let _d = searchoeuvre.filter((o) => o.id !== oeuvre.id);
                                                        setsearchoeuvre(_d);

                                                        GetBooksClaimed(myteam.team_ID).then((response) => {
                                                            setmytraduction(response.data);
                                                        });
                                                    } else {
                                                        setresult_string("Une erreur est survenue lors du claim de l'oeuvre.");
                                                    }
                                                });
                                            // sessionStorage.setItem('current_oeuvre', oeuvre.id);
                                            // setcurrent_language(oeuvre.originalLanguage);
                                            // navigate(`../translateoeuvre/`);
                                        }} className='relative w-1/2 bg-gatek-200 Poppins-SemiBold text-base transform hover:scale-105 transition-all duration-300 rounded-lg hover:bg-opacity-70'>Claim</button>
                                        <button onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            getMangaInfo(oeuvre).then((oeu) => {
                                                setseedetails(oeu);
                                            });
                                        }} className='relative w-1/2 bg-gatek-200 Poppins-SemiBold text-base transform hover:scale-105 transition-all duration-300 rounded-lg hover:bg-opacity-70'>Détails</button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                 </div>}
                <div className='relative w-full h-3/6 flex flex-col gap-4 p-4 overflow-y-auto border-b'>
                    <h1 className='Poppins-SemiBold text-2xl  self-baseline'>📚 Vos oeuvres claim(s) :</h1>
                    {mytraduction.length === 0 && <p className='Poppins-SemiBold text-base  italic'>Vous n'avez pas encore traduit d'oeuvre.</p>}
                    <div className='relative w-full h-full grid grid-cols-8 gap-4 overflow-y-auto p-2'>
                        {mytraduction !== undefined && mytraduction?.map((oeuvre, index) => {
                            return (
                                <div key={index} onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    sessionStorage.setItem('current_oeuvre', oeuvre.manga_id);
                                    setcurrent_language(oeuvre.language);
                                    navigate(`../translateoeuvre/`);
                                }} className='flex-shrink-0 relative w-full h-full flex flex-col pr-1 pb-1 transform hover:scale-95 transition duration-150 cursor-pointer'>
                                    <img className='absolute left-[10%] w-[90%] h-[90%]' src={backend_url+oeuvre.manga_cover} onError={handleImageError} />
                                    <h1 className="absolute bottom-0 Poppins-SemiBold w-full h-[10%] text-white text-base truncate p-2 bg-gradient-to-r from-gatek_red-500 via-gatek_red-400 to-gatek_red-300 shadow-gray-600 shadow-md">
                                        {oeuvre.manga_name} ({oeuvre.language})
                                    </h1>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <br />

                <div className='relative w-full h-3/6 flex flex-col gap-4 p-4 border-b'>
                    <h1 className='Poppins-SemiBold text-2xl  self-baseline'>📚 Vos traduction en attentes de validation :</h1>
                    {TranslationInValidationData.length === 0 && <p className='Poppins-SemiBold text-base  italic'>Vous n'avez pas encore traduit d'oeuvre.</p>}
                    <div className='relative w-full h-full p-2'>
                        {TranslationInValidationData?.map((oeuvre, index) => {
                            return (
                                <div key={index} onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }} className='flex-shrink-0 relative mt-4 w-full h-[80%] flex pr-1 pb-1 transition duration-150 cursor-pointer bg-gatek-400'>
                                    <img className='w-[10%] h-[100%]' src={backend_url+oeuvre.mangaCover} onError={handleImageError} />
                                    <div className='w-[90%] h-[100%] p-2'>
                                        <h1 className="Inter-Regular text-white border-b p-0.5"> {oeuvre.mangaName} - {oeuvre.chapterName} ({oeuvre.language})</h1>
                                        <h1 className="Inter-Regular text-white p-0.5 italic">{oeuvre.message !== undefined ? oeuvre.message+" - "+oeuvre.refusedTime : "Aucun retour"}</h1>
                                        <button onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            // navigate(`../translateoeuvre/`);
                                            if (myteam)
                                                CancelTranslation(myteam.team_ID, oeuvre.manga_ID, oeuvre.chapter_ID).then((res) => {
                                                    if (res.status === 200) {
                                                        let _d = TranslationInValidationData.filter((o) => o.translationInValidation_ID !== oeuvre.translationInValidation_ID);
                                                        setTranslationInValidationData(_d);
                                                    }
                                                });
                                        }} className='absolute bottom-2 right-2 w-[10%] bg-gatek-200 Poppins-SemiBold text-base transform hover:scale-95 transition-all duration-300 rounded-lg hover:bg-opacity-70'>Annuler</button>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                
            </div>
        </MainContext.Provider>
    );
}

export default TeamTraductions;