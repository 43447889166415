import React, {useContext, useEffect, useState} from 'react';
import gatek from '../../Assets/Images/_Gatek.png';
import gmail from '../../Assets/Images/gmail.png'
import insta from '../../Assets/Images/insta.png'
const Contact: React.FC<{}> = () => {
    return (
    <div>
        <div className='relative w-full flex px-16 pb-8'>
            <div className='relative bg-gatek_gray-100 w-full h-full rounded-tr shadow-2xl p-4'>
                <p className='Poppins-SemiBold text-3xl'>Moyens de nous contacter</p>
                <div className='relative mt-8'>
                    <a href="mailto:gatek.corp@gmail.com" className='flex rows gap-5'>          
                        <img className='relative w-12' src={gmail}/>
                        <p className='Poppins-SemiBold text-xl'>Gmail</p>
                    </a>
                    <a href="https://www.instagram.com/" className='flex rows gap-5'>          
                        <img className='relative w-12' src={insta}/>
                        <p className='Poppins-SemiBold text-xl'>Instagram</p>
                    </a>
                </div>

                <h1 className='Poppins-SemiBold text-3xl mt-8'>Formulaire de contact</h1>
                <div className='relative mt-8'>
                    <form className='relative w-full flex flex-col gap-4'>
                        <input type='text' placeholder='Nom' className='Poppins-SemiBold text-xl p-2'/>
                        <input type='email' placeholder='Email' className='Poppins-SemiBold text-xl p-2'/>
                        <textarea placeholder='Message' className='Poppins-SemiBold text-xl p-2'/>
                        <button className='Poppins-SemiBold text-xl bg-gatek_orange-500 text-gatek_gray-100 p-2'>Envoyer</button>
                    </form>
                </div>
            </div>
        </div>
    </div>)
}

export default Contact;