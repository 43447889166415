import React, {useContext, useEffect, useState} from 'react';
import gatek from '../../Assets/Images/_Gatek.png';
import Timeline from './Timeline';

const ProjectInformation: React.FC<{}> = () => {
    return (
        <div className='relative w-full overflow-hidden'>
            <div className='relative w-full flex px-16 pb-8'>
                <div className='relative bg-gatek_gray-100 w-full h-full rounded-tr shadow-2xl p-4'>
                    <p className='Poppins-SemiBold text-3xl'>GATEK c'est quoi ?</p>
                    <p className='Inter-Regular text-xl px-6 py-2'>
                        GATEK est une librairie numérique orientée sur les mangas, plus
                        particulièrement les œuvres méconnues en France. Gatek possède un espace dédié à la communication entre les auteurs
                        et leurs publics.
                    </p>
                    <p className='Poppins-SemiBold text-3xl mt-8'>Un peu de contexte</p>
                    <p className='Inter-Regular text-xl px-6 py-2'>
                        La france est :
                        <ul className='list-disc list-inside'>
                            <li>Le 2ème plus gros consommateur de mangas au monde</li>
                            <li>Le 1er pays d'Europe en terme de consommation de mangas</li>
                        </ul>
                        Et seulement 20-30% des œuvres japonaises sont éditées en France, de plus 80% des auteurs ne sont pas édités.
                        <div className='relative w-full h-full flex items-center justify-center'>
                            <img src={gatek} alt='' className='h-1/2 w-1/2'/>
                        </div>
                    </p>
                </div>
            </div>
            <Timeline/>
        </div>
    );
}

export default ProjectInformation;