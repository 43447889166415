import React, {useContext, useEffect, useState} from 'react';
import { MainContext } from '../../Context/Main';
import { IArticle } from '../../Interfaces/Discover';
import { useNavigate } from 'react-router-dom';
import { ArticleDeleteRequest, ArticleModifyRequest, getArticleByID } from './Utils/IAdd';

const Modifyarticle: React.FC = () => {
    const {user, setuser, current_oeuvre} = useContext(MainContext);
    const [article, setarticle] = useState<IArticle | undefined>(undefined);
    const [temp, settemp] = useState<IArticle | undefined>(undefined);
    const navigate = useNavigate();
    
    const HaveBeenChanged = () => {
        if (article && temp) {
            return article.title !== temp.title || article.description !== temp.description;
        }
        return false;
    }

    useEffect(() => {
        let article_id = sessionStorage.getItem('article_id');
        if (user && article_id !== null) {
            getArticleByID(user, article_id).then((data) => {
                setarticle(data);
                settemp(data);
            });
        }
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            {article && temp && <div className='relative w-full h-full flex flex-col gap-4 p-2'>
                <div className='relative w-full h-1/2 p-2 shadow-md shadow-black bg-gradient-to-r from-gatek_red-500 via-gatek_red-400 to-gatek_red-300'>
                    {/* <img className='object-contain rounded-full' src={user?.avatar || logo_gatek} /> */}
                    <div className='relative h-full w-full flex flex-col gap-2 justify-center items-center'>
                        <input className='outline-none w-full bg-transparent Inter-Regular text-3xl text-white border-b border-gatek-500' type='text' value={temp.title} onChange={(e) => settemp({...temp, title: e.target.value})} />
                        <textarea className='outline-none w-full h-full bg-transparent Inter-Regular text-base text-white border-b border-gatek-500' value={temp.description} onChange={(e) => settemp({...temp, description: e.target.value})} />
                        <div className='relative w-full h-[10%] flex justify-between p-2 gap-4'>
                            {HaveBeenChanged() && <button onClick={(e) => {
                                e.stopPropagation();
                                ArticleModifyRequest(article.id, temp.title, temp.description)
                                sessionStorage.removeItem('article_id');
                                navigate('../edit');
                            }} className='relative w-1/2 bg-gatek-200 Inter-SemiBold text-xl transform hover:scale-95 duration-150'>💾 Enregistrer</button>}
                            <button onClick={(e) => {
                                e.stopPropagation();
                                ArticleDeleteRequest(article.id);
                                sessionStorage.removeItem('article_id');
                                navigate('../edit');
                            }} className='relative w-1/2 bg-gatek-200 Inter-SemiBold text-xl transform hover:scale-95 duration-150'>❌ Supprimer</button>
                        </div>
                    </div>
                </div>
            </div>}
        </MainContext.Provider>
    );
}

export default Modifyarticle;