import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import white_coin from '../../../Assets/Images/white_coin.png';
import little_comment_black from '../../../Assets/Images/little_comment_black.png';
import sub_tier_1 from '../../../Assets/Images/sub_tier_1.png';
import sub_tier_2 from '../../../Assets/Images/sub_tier_2.png';
import up_black from '../../../Assets/Images/up_black.png';
import down_black from '../../../Assets/Images/down_black.png';
import market_white from '../../../Assets/Images/market_white.png';
import white_validate from '../../../Assets/Images/white_validate.png';
import down_green from '../../../Assets/Images/down_green.png';
import up_red from '../../../Assets/Images/up_red.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from './Navbar';
import { IArticle, IComment, IDBChapter, IOeuvre } from '../../../Interfaces/Discover';
import { BookCommentRequest, getBookComments, getChapter, getDiscoverd, getMangaInfoByID, PostNotificationAuthorFollow, PostNotificationAuthorIsfollowing, PostNotificationAuthorUnfollow, PostNotificationFollow, PostNotificationIsfollowing, PostNotificationUnfollow } from '../../../Api/Oeuvres';
import ObjectBox from '../../Dashboard/Subcomponent/ObjectBox';
import { GetArticles, SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { GetUsers, User } from '../../../Interfaces/User';
import { getMyArticles, getMyOeuvres, getUser, PostAuthorSupport } from '../../../Api/User';

const AuthorMod: React.FC = () => {
    const {user} = useContext(MainContext);
    const [author, setauthor] = useState<User | undefined>(undefined);
    const [isfollowing, setisfollowing] = useState<boolean>(false);
    const [oeuvres, setoeuvres] = useState<IOeuvre[]>([]);
    const [articles, setarticles] = useState<IArticle[]>([]);
    const [subMenu, setSubMenu] = useState<string>('oeuvres');
    const [open, setOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState<string | undefined>(undefined);
    const navigate = useNavigate();

    const url = new URL(window.location.href);
    const id = url.searchParams.get('id');

    useEffect(() => {
        if (!id) {
            navigate('/discover');
            return;
        }

        GetUsers(id).then((resp) => {
            if (resp.status === 200) {
                resp.data.id = id;
                setauthor(resp.data);
                getMyOeuvres(resp.data).then((oeuvres) => {
                    setoeuvres(oeuvres);
                });
                getMyArticles(resp.data).then((articles) => {
                    setarticles(articles);
                });
            }
        });

        PostNotificationAuthorIsfollowing(id).then((res) => {
            setisfollowing(res);
        });
        
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            {open && <div onClick={(e) => {
                setOpen(false);
            }} className='fixed z-[60] top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center'>
                <div onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }} className='bg-black text-white w-[50%] h-[60%] rounded-xl flex flex-col items-center justify-between p-4'>
                    <h1 className='text-xl Montserrat-Bold'>Soutenir</h1>

                    <div onClick={() => {
                        if (selected === 'tiers1')
                            setSelected(undefined);
                        else
                            setSelected('tiers1');
                    }} className={`
                        relative h-[50%] w-full rounded-xl overflow-hidden flex flex-col hover:scale-95 transform duration-150 cursor-pointer select-none
                        ${selected === 'tiers1' && 'border scale-95'}
                    `}>
                        <div className='absolute w-full h-full bg-gatek-400 bg-opacity-75 z-[70]'></div>
                        <img src={sub_tier_1} className='absolute h-full object-cover opacity-60' />
                        <div className='relative w-full h-full p-4 z-[70] flex flex-col gap-4'>
                            <h1 className='relative text-xl Montserrat-Bold text-white'>Soutien niveau 1</h1>
                            <h2 className='relative text-sm Montserrat-SemiBold text-gray-400'>Soutener l’auteur en payant 4,99$ par mois, et beneficier des avantages qui sont seulement reservé a ceux qui soutiennent l’auteur. Vous pourrez accéder à des oeuvres exclusives</h2>
                            <h1 className='relative flex items-center Montserrat-Bold gap-2 text-white self-center'>
                                <img src={white_validate} className='w-6 h-6 mr-2' />
                                accèder à des oeuvres exclusives
                            </h1>
                            <h1 className='absolute right-4 bottom-4 flex gap-2 items-center text-xl Montserrat-Bold text-white'>
                                <img src={market_white} className='w-6 h-6 mr-2' />
                                4,99$/mois
                            </h1>
                        </div>
                    </div>

                    <div className='relative h-[30%] w-full rounded-xl overflow-hidden flex flex-col cursor-not-allowed'>
                        <div className='absolute w-full h-full bg-gray-200 bg-opacity-75 z-[70]'></div>
                        <img src={sub_tier_2} className='absolute h-full object-cover opacity-60' />
                        <div className='relative w-full h-full p-4 z-[70] flex flex-col gap-4'>
                            <h1 className='relative text-xl Montserrat-Bold text-gray-800'>Soutien niveau 2</h1>
                            <h1 className='relative flex items-center Montserrat-Bold gap-2 text-gray-800 self-center'>
                                accèder à une version papier mensuellement
                            </h1>
                            <h1 className='absolute right-4 bottom-4 flex gap-2 items-center text-xl Montserrat-Bold text-gray-800'>
                                {/* <img src={market_white} className='w-6 h-6 mr-2' /> */}
                                19,99$/mois
                            </h1>
                        </div>
                    </div>

                    <button onClick={() => {
                        if (selected === undefined) return;
                        if (id) {
                            PostAuthorSupport(author?.id).then((res) => {
                                if (res.status === 200) {
                                    setOpen(false);
                                }
                            });
                        }
                    }} className={`relative w-1/4 h-10 Montserrat-SemiBold rounded-lg self-end
                        ${selected === undefined ? 'cursor-not-allowed bg-gray-300 text-gray-500' : "bg-gatek-400 text-white"}
                    `}>
                        Confirmer
                    </button>

                </div>
            </div>}
            {(id && author) && <div className='relative w-screen h-screen overflow-hidden overflow-y-scroll bg-gray-100 flex flex-col'>
                <div className='relative h-[10%]'>
                    <Navbar is_black={true} />
                </div>
                <div className='relative w-full h-full flex flex-col gap-2 items-center px-[8%] py-4 overflow-hidden'>
                    <img src={backend_url + author?.avatar} className='w-[170px] h-[170px] rounded-full object-contain' onError={handleImageError} />
                    <h1 className='text-2xl Montserrat-SemiBold'>{author?.username}</h1>
                    <h2 className='text-sm text-gray-400 Montserrat-Regular'>{author?.email}</h2>
                    <div className='relative w-1/4 gap-8 flex justify-between Montserrat-SemiBold'>
                        {!isfollowing ? <button className='w-1/2 h-10 border border-black rounded-lg flex items-center justify-center' onClick={() => {
                            PostNotificationAuthorFollow(id).then((res) => {
                                if (res.status === 200) {
                                    setisfollowing(true);
                                }
                            });
                        }}>
                            Follow
                        </button> : <button className='w-1/2 h-10 border border-green-600 text-green-600 rounded-lg flex items-center justify-center' onClick={() => {
                            PostNotificationAuthorUnfollow(id).then((res) => {
                                if (res.status === 200) {
                                    setisfollowing(false);
                                }
                            });
                        }}>
                            Unfollow
                        </button>}
                        <button className='w-1/2 h-10 bg-gatek-400 text-white rounded-lg flex items-center justify-center' onClick={() => {
                            setOpen(!open);
                        }}>
                            <img src={white_coin} className='w-4 h-4 mr-2' />
                            Soutenir
                        </button>
                    </div>
                    <div className='relative h-[10%] px-[8%] mb-8'>
                        <button onClick={() => {
                            setSubMenu('oeuvres');
                        }} className={`relative text-2xl Montserrat-SemiBold p-2 cursor-pointer px-16
                            ${subMenu === ("oeuvres") && 'border-b-4 border-gatek_red-500 text-gatek_red-500'}
                        `}>vos oeuvres</button>
                        <button onClick={() => {
                            setSubMenu('articles');
                        }} className={`relative text-2xl Montserrat-SemiBold p-2 cursor-pointer px-16
                            ${subMenu === ("articles") && 'border-b-4 border-gatek_red-500 text-gatek_red-500'}
                        `}>vos articles</button>
                    </div>
                    <div className='relative w-full h-full flex flex-wrap gap-4 overflow-y-scroll'>
                        {subMenu === 'oeuvres' && oeuvres.map((oeuvre, index) => {
                            return (
                                <div onClick={(e) => {
                                    console.log(oeuvre);
                                    navigate(`/oeuvre?id=${oeuvre.id}`);
                                }} key={index} className='relative w-[170px] h-[263px] flex flex-col gap-0.5 cursor-pointer transform hover:scale-95 duration-150'>
                                    <img src={backend_url + oeuvre.cover} alt='cover' className='relative w-full select-none h-[90%] object-cover rounded-md' />
                                    <h1 className='h-[10%] text-xs Montserrat-Bold'>{oeuvre.name}</h1>
                                </div>
                            );
                        })}
                        {subMenu === 'articles' && articles.map((article, index) => {
                            return (
                                <div key={index} onClick={() => {
                                    navigate(`/article?id=${article.id}`);
                                }} className='w-full flex gap-2 mt-4'>
                                    <div className='w-[90%] border-2 border-gray-300 rounded-xl p-2 gap-2 flex flex-col items-center bg-white'>
                                        <h1 className='text-2xl Montserrat-SemiBold w-full'>{article.title}</h1>
                                        <textarea disabled={true} className='text-base overflow-hidden Montserrat-Regular w-full resize-none bg-transparent'>{article.description}</textarea>
                                        {article.images[0] && <img src={backend_url+article.images[0]} className='object-contain' />}
                                        <div className='relative w-full flex justify-between overflow-hidden'>
                                            <div className='relative bg-gray-300 rounded-full w-[12%] h-full flex gap-4 items-center justify-center p-2'>
                                                <div className='relative flex justify-between w-1/3 h-[24px] items-center duration-150'>
                                                    <p className={`text-sm ${article?.likedByUser && "text-red-700"} Montserrat-SemiBold`}>{article?.likes}</p>
                                                    {!article?.likedByUser && <img src={up_black} className='h-full object-contain' />}
                                                    {article?.likedByUser && <img src={up_red} className='h-full object-contain' />}
                                                </div>
                                                <div className='relative flex justify-between w-1/3 h-[24px] items-center duration-150'>
                                                    <p className={`text-sm ${article?.likedByUser !== undefined && !article?.likedByUser && "text-green-500"} Montserrat-SemiBold`}>{article?.dislikes}</p>
                                                    {(article?.likedByUser === undefined || article?.likedByUser) && <img src={down_black} className='h-full object-contain' />}
                                                    {(article?.likedByUser !== undefined && !article?.likedByUser) && <img src={down_green} className='h-full object-contain' />}
                                                </div>
                                            </div>
                                            
                                            <div className='relative bg-gray-300 rounded-2xl w-[6%] h-full flex gap-4 items-center justify-center p-2'>
                                                <img src={little_comment_black} className='h-full object-contain' />
                                                <p className='text-sm Montserrat-SemiBold'>{article?.nbComment}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>}
        </MainContext.Provider>
    );
}

export default AuthorMod;