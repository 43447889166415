import React, {useContext, useEffect, useRef, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import red_arrow_small from '../../../Assets/Images/red_arrow_small.png';
import gray_arrow from '../../../Assets/Images/gray_arrow.png';
import book_black from '../../../Assets/Images/book_black.png';
import white_validate from '../../../Assets/Images/white_validate.png';
import black_cross from '../../../Assets/Images/black_cross.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from '../Discovered/Navbar';
import { IArticle, IComment, IDBChapter, IOeuvre, ITranslatedBook } from '../../../Interfaces/Discover';
import { AddChapterToDB, BookCommentRequest, GetAuthorClaim, GetAuthorTeamCompetition, GetAuthorWorkingTeams, getBookComments, getChapter, getDiscoverd, getMangaInfoByID, GetPossibleTranslation, getStashedChapter, IGetAuthorClaim, IGetAuthorWorkingTeams, PostAuthorClaimInteract, RequestCancelTranslation, RequestValidateTranslation, Tags } from '../../../Api/Oeuvres';
import { AddManga, SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getMyArticles, getMyOeuvres, getUser } from '../../../Api/User';
import { imageHeight, imageWidth, maxMB } from '../../Edit/AddChapter';
import { AllWorldLanguages } from '../../Dashboard/Subcomponent/TraductorEditInterface';
import TranslationValidateBox, { IValidation } from './TranslationValidateBox';
import { ITeam, TeamRequestInfo } from '../../../Api/Team';


const TranslationValidate: React.FC = () => {
    const {user} = useContext(MainContext);
    const [possibleTranslations, setPossibleTranslations] = useState<IValidation[]>([]);
    const [claims, setClaims] = useState<IGetAuthorClaim[]>([]);
    const [selectedTrad, setSelectedTrad] = useState<IValidation | undefined>(undefined);
    const [images, setImages] = useState<string[]>([]);
    const [team, setTeam] = useState<ITeam | undefined>(undefined);
    const [idx, setIdx] = useState(0);
    const [GoConcurance, setGoConcurance] = useState(false);
    const [teams, setTeams] = useState<IGetAuthorWorkingTeams[]>([]);
    const [selectedTeam, setSelectedTeam] = useState<IGetAuthorWorkingTeams | undefined>(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedTrad) {
            getStashedChapter(selectedTrad.translationInValidation_ID).then((res) => { setImages(res.data); });
            TeamRequestInfo(selectedTrad.team_ID).then((t) => { setTeam(t.data); });
        }
        setIdx(0);
    }, [selectedTrad]);

    useEffect(() => {
        if (GoConcurance) {
            GetAuthorWorkingTeams().then((res) => { setTeams(res); });
        }
    }, [GoConcurance]);

    useEffect(() => {
        const t = sessionStorage.getItem('token');
        if (t === null)
            navigate('/login');

        if (user)
            GetPossibleTranslation(user.id).then((res) => {
                if (res.status === 200 && res.data)
                    setPossibleTranslations(res.data);
            });

            GetAuthorClaim().then((res) => { setClaims(res); });
    }, [user]);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full flex flex-wrap gap-4 overflow-x-hidden bg-gray-100'>
                {(selectedTrad && team) && <div onClick={(e) => {
                    setSelectedTrad(undefined);
                }} className='fixed z-[60] top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center'>
                    <div onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }} className='bg-white w-[930px] h-[580px] rounded-xl overflow-hidden flex'>
                        <div className='relative w-[60%] h-full flex bg-gray-200 p-2'>
                            <div onClick={() => {
                                    setIdx(idx-1);
                            }} className='w-[10%] flex items-center justify-center cursor-pointer'>
                                <img src={idx <= 0 ? gray_arrow : red_arrow_small} className={`w-6 cursor-pointer
                                    ${idx > 0 && ' transform rotate-180'}
                                `} />
                            </div>
                            <img src={backend_url+images[idx]} className='w-[80%] text-center h-full object-contain' />
                            <div onClick={() => {
                                setIdx(idx+1);
                            }} className='w-[10%] flex items-center justify-center cursor-pointer'>
                                <img src={idx >= images.length-1 ? gray_arrow : red_arrow_small} className={`w-6 cursor-pointer
                                    ${idx >= images.length-1 && ' transform rotate-180'}
                                `} />
                            </div>
                        </div>

                        {!GoConcurance ? <div className='relative w-[40%] h-full flex flex-col items-start gap-4 p-4'>
                            <div className='relative h-[40px] flex items-center justify-center gap-2'>
                                <img src={backend_url+team.cover} className='h-full rounded-full border-2 border-gray-400 border-opacity-40' onError={handleImageError} />
                                <h1 className='text-xl Montserrat-SemiBold'>{team.team_name}</h1>
                            </div>

                            <h1 className='text-sm text-gray-400 Montserrat-Regular'>Langue : <span className='Montserrat-SemiBold text-black text-base'>{selectedTrad.language}</span></h1>

                            <div className='relative h-[70%] w-full border-b-2 border-t-2 border-gray-300 py-2 flex flex-col gap-4'>
                                <h1 className='text-lg text-gatek-400 Montserrat-SemiBold'>mettre en concurrence</h1>
                                <h1 className='text-sm Montserrat-Regular'>Vous pensez que la traduction du chapitre est mauvaise et qu’une autre équipe fera un meilleur travaille ? Utilisez la fonctionnalité “mettre en concurrence” pour comparer les traductions fait par 2 équipes et choisissez la meilleur.</h1>

                                <button onClick={() => {
                                    setGoConcurance(true);
                                }} className='w-1/2 self-end text-xs h-[40px] bg-gatek-400 text-white Montserrat-SemiBold rounded-xl'>mettre en concurrence</button>
                            </div>
                            <div className='relative flex gap-4 items-center w-full Montserrat-SemiBold'>
                                <button onClick={() => {
                                    RequestCancelTranslation(selectedTrad.translationInValidation_ID, "REFUSED").then((res) => {
                                        if (res.status === 200) {
                                            sessionStorage.removeItem('translation_data');
                                            navigate('../edit');
                                        }
                                    });
                                }} className='w-1/2 h-[40px] bg-white border border-gray-200 rounded-xl'> refuser </button>
                                <button onClick={() => {
                                    RequestValidateTranslation(selectedTrad.translationInValidation_ID).then((res) => {
                                        if (res.status === 200)
                                            navigate('../edit');
                                    });
                                }} className='w-1/2 h-[40px] bg-gatek-400 text-white rounded-xl'> accepter </button>
                            </div>
                        </div> : <div className='relative w-[40%] h-full flex flex-col items-start gap-4 p-4'>
                            <h1 className='text-lg Montserrat-SemiBold h-[10%]'>Mettre en concurrence</h1>
                            <h1 className='text-xs Montserrat-Regular text-gray-400 border-b border-gray-300 w-full'>choisissez une équipe qui a travaillé avec vous.</h1>
                            <div className='relative max-h-full w-full flex flex-wrap overflow-y-scroll gap-4'>
                                {teams.map((t, i) => {
                                    return (
                                        <div onClick={() => {
                                            if (selectedTeam?.team_ID === t.team_ID)
                                                setSelectedTeam(undefined);
                                            else
                                                setSelectedTeam(t);
                                        }} key={i} className={`relative w-full h-[50px] flex gap-4 rounded-xl items-center p-1 duration-150 cursor-pointer
                                            ${(selectedTeam?.team_ID === t.team_ID) ? 'bg-gatek-400 text-white' : "bg-gray-200"}
                                        `}>
                                            <img src={backend_url+t.team_icon} className='h-full object-contain rounded-full' onError={handleImageError} />
                                            <h1 className='text-sm Montserrat-SemiBold'>{t.team_name}</h1>
                                        </div>
                                    );
                                })}
                            </div>

                            <button onClick={() => {
                                if (selectedTeam && selectedTeam?.team_ID)
                                    GetAuthorTeamCompetition(selectedTrad.translationInValidation_ID, selectedTeam?.team_ID).then((res) => {
                                        if (res.status === 200) {
                                            setGoConcurance(false);
                                            setSelectedTeam(undefined);
                                            setSelectedTrad(undefined);
                                        }
                                    });
                            }} className={`w-1/2 h-[40px] bg-gatek-400 text-white rounded-xl Montserrat-Regular self-center
                                ${selectedTeam ? '' : 'bg-gray-300 text-gray-400 cursor-not-allowed'}
                            `}>demander</button>
                        </div>}
                    </div>
                </div>}
                <div className='relative h-[10%] w-full'>
                    <Navbar is_black={true} />
                </div>

                <div className='px-[8%] w-full'>
                    <h1 className='text-2xl border-b-2 border-black Montserrat-SemiBold'>{possibleTranslations.length} traduction(s) en attente</h1>
                    <div className='relative w-full flex flex-wrap gap-4 bg-gray-100 py-8'>
                        {possibleTranslations.map((translation, i) => {
                            // return ( <TranslationValidateBox key={i} {...translation} /> );
                            return (
                                <div className='relative w-[30%] h-[400px] bg-white border-2 border-gray-400 border-opacity-40 rounded-xl p-4 overflow-hidden'>
                                    <div className='relative w-full h-[20%] border-b-2 border-gray-400 border-opacity-40 flex justify-between items-center'>
                                        <div className='relative w-1/2 flex items-center overflow-hidden'>
                                            <img src={logo_gatek} className='relative h-14 rounded-full border-2 border-gray-400 border-opacity-40' />
                                            <h1 className='Montserrat-SemiBold ml-4 truncate'>{translation.teamName}</h1>
                                        </div>
                                        <h1 className='text-sm Montserrat-SemiBold'>{translation.language}</h1>
                                    </div>
                        
                                    <div className='relative w-full h-[60%] p-2 flex gap-8'>
                                        <img src={backend_url+translation.mangaCover} className='h-full object-contain rounded-lg' />
                                        <div className='relative flex flex-col gap-2'>
                                            <h1 className='Montserrat-SemiBold text-xl truncate'>{translation.mangaName}</h1>
                                            <h1 className='text-base Montserrat-SemiBold flex gap-2 items-center'>
                                                <img src={book_black} className='h-4 inline' />
                                                Ch. {translation.chapterNumber}
                                            </h1>
                                        </div>
                                        <button onClick={() => {
                                            setSelectedTrad(translation);
                                        }} className='absolute bottom-2 right-2 text-gatek_red-600 underline Montserrat-SemiBold'>lire la traduction</button>
                        
                                    </div>
                                    <div className='relative w-full h-[20%] border-t-2 border-gray-400 border-opacity-40 flex justify-between items-center gap-8'>
                                        <button onClick={() => {
                                            RequestCancelTranslation(translation.translationInValidation_ID, "REFUSED").then((res) => {
                                                if (res.status === 200) {
                                                    sessionStorage.removeItem('translation_data');
                                                    navigate('../edit');
                                                }
                                            });
                                        }} className='relative border-2 border-gray-400 w-1/2 border-opacity-40 bg-white Montserrat-SemiBold px-4 py-1 text-xl rounded-lg flex gap-4 items-center justify-center'>
                                                <img src={black_cross} className='h-6 inline' />
                                            Refuser
                                        </button>
                                        <button onClick={() => {
                                            RequestValidateTranslation(translation.translationInValidation_ID).then((res) => {
                                                if (res.status === 200)
                                                    navigate('../edit');
                                            });
                                        }} className='relative border-2 bg-red-700 w-1/2  text-white Montserrat-SemiBold px-4 py-1 text-xl rounded-lg flex gap-4 items-center justify-center'>
                                            <img src={white_validate} className='h-6 inline' />
                                            Accepter
                                        </button>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <h1 className='text-2xl border-b-2 border-black Montserrat-SemiBold'>{claims.length} claim(s) en attente</h1>
                    <div className='relative w-full grid grid-cols-4 p-2'>
                        <h1 className='text-base Montserrat-SemiBold text-gray-400'>Team</h1>
                        <h1 className='text-base Montserrat-SemiBold text-gray-400'>Oeuvre</h1>
                        <h1 className='text-base Montserrat-SemiBold text-gray-400'>Langue</h1>
                        <h1 className='text-base Montserrat-SemiBold text-gray-400'>Action</h1>
                    </div>
                    <div className='relative flex flex-wrap flex-col gap-4 p-2'>
                        {claims.map((claim, i) => {
                            return (
                                <div key={i} className='relative h-[80px] p-2 grid grid-cols-4 gap-4 items-center border-2 border-gray-300 rounded-xl bg-white'>
                                    <h1 className='text-base Montserrat-SemiBold'>{claim.team_name}</h1>
                                    <h1 className='text-base Montserrat-SemiBold'>{claim.manga_name}</h1>
                                    <h1 className='text-base Montserrat-SemiBold'>{claim.language}</h1>
                                    <div className='relative flex gap-4 items-center w-full Montserrat-SemiBold'>
                                        <button onClick={() => {
                                            PostAuthorClaimInteract(claim.claim_ID, false).then((res) => {
                                                if (res.status === 200)
                                                    GetAuthorClaim().then((res) => { setClaims(res); });
                                            });
                                        }} className='w-1/2 h-[40px] bg-white border border-gray-200 rounded-xl'> refuser </button>
                                        <button onClick={() => {
                                            PostAuthorClaimInteract(claim.claim_ID, true).then((res) => {
                                                if (res.status === 200)
                                                    GetAuthorClaim().then((res) => { setClaims(res); });
                                            });
                                        }} className='w-1/2 h-[40px] bg-gatek-400 text-white rounded-xl'> accepter </button>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

            </div>
        </MainContext.Provider>
    );
}

export default TranslationValidate;