import { User } from '../Interfaces/User';
import { backend_url } from '../App';
import { Post, AxiosResponse } from './Axios';

export const registerNewUser = async (user: User):Promise<AxiosResponse> => {
    const response = await Post('/credentials/register', JSON.stringify(user));
    console.log(response);
    return response;
}

export const PostCredentialsEmailExist = async (email: string):Promise<boolean> => {
    const response = await Post('/credentials/email/exist', JSON.stringify({email: email}));
    return response.data;
}

export const PostCredentialsEmailSecretCode = async (newEmail?: string):Promise<string> => {
    const response = await Post('/credentials/email/secretCode', JSON.stringify({newEmail: newEmail}));
    return response.data.emailVerification_TOKEN;
}

export const PostCredentialsEmailVerify = async (emailVerification_TOKEN: string, code: string):Promise<AxiosResponse> => {
    const response = await Post('/credentials/email/verify', JSON.stringify({EmailVerification_token: emailVerification_TOKEN, code: code}));
    return response;
}