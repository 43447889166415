import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import lecteur from '../../../Assets/Images/reading.png';
import traducteur from '../../../Assets/Images/languages.png';
import bgjapon from '../../../Assets/Images/japongpt.webp';
import endless from '../../../Assets/Images/endless.png';

import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import { MainContext } from '../../../Context/Main';
import { GetSubscriptions, ISubscription, modifyUserInfo, StripePay, SubscriptionType } from '../../../Api/User';

const Subscribe: React.FC = () => {
    const {user, setuser} = useContext(MainContext);
    const [isOpen, setIsOpen] = useState(false);
    const [isPasswordOpen, setIsPasswordOpen] = useState(false);
    const [Subscriptions, setSubscriptions] = useState<ISubscription | undefined>(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
        }
    }, [user]);

    useEffect(() => {
        //Get user data
        GetSubscriptions(user?.id).then((res) => {
            if (res.status === 200 && res.data) {
                setSubscriptions(res.data);
            }
        });
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            {Subscriptions && user && <div className='relative w-full h-full bg-white p-8 overflow-y-auto flex items-center justify-center gap-16'>
                <img className='absolute object-cover w-full h-full opacity-40' src={bgjapon} />

                <div className={`relative w-1/4 h-full transform ${Subscriptions?.currents && Subscriptions.currents[0]?.abonnementType === SubscriptionType.subscription_basic ? "scale-100" : "scale-90 opacity-80"} rounded-3xl shadow-lg shadow-slate-900 overflow-hidden gap-4 flex flex-col bg-white`}>
    
                    <h1 className='Poppins-SemiBold text-3xl p-2 bg-gatek-400 w-full text-center text-white'>Formule classique</h1>
    
                    <div className='relative w-full h-1/4  flex items-center justify-center'>
                        <h1 className='Poppins-SemiBold text-4xl text-gatek-400'>4.99€/mois</h1>
                    </div>
    
                    <h1 className='Poppins-SemiBold text-2xl w-full text-center border-b border-gatek-400'>✨ Avantages</h1>

                    <div className='relative w-full flex flex-col bg-gatek-400 divide-y text-base'>
                        <p className='Inter-Regular text-white flex justify-between items-center px-4'>+30 coins / semaine <p>✅</p></p>
                        <p className='Inter-Regular text-white flex justify-between items-center px-4'>Accès aux création d'équipes <p>❌</p></p>
                    </div>

                    <img className='object-contain h-1/4' src={lecteur} />

                    <button disabled={Subscriptions?.currents && Subscriptions.currents[0]?.abonnementType === SubscriptionType.subscription_basic} onClick={(e) => {
                        StripePay(user?.id, SubscriptionType.subscription_basic).then((res) => {
                            if (res.status === 200) {
                                GetSubscriptions(user?.id).then((res) => {
                                    if (res.status === 200 && res.data) {
                                        setSubscriptions(res.data);
                                    }
                                });
                            }
                        });
                    }}  className='absolute transform duration-200 hover:scale-110 bottom-0 w-full Poppins-SemiBold text-xl bg-gatek-400 text-white p-2'>S'abonner</button>
                </div>

                <div className={`relative w-1/4 h-full transform ${Subscriptions?.currents && Subscriptions.currents[0]?.abonnementType === SubscriptionType.subscription_advanced ? "scale-100" : "scale-90 opacity-80"} rounded-3xl shadow-lg shadow-slate-900 overflow-hidden gap-4 flex flex-col bg-white`}>
    
                    <h1 className='Poppins-SemiBold text-3xl p-2 bg-gatek-400 w-full text-center text-white'>Formule professionnelle</h1>
    
                    <div className='relative w-full h-1/4  flex items-center justify-center'>
                        <h1 className='Poppins-SemiBold text-4xl text-gatek-400'>12.99€/mois</h1>
                    </div>
    
                    <h1 className='Poppins-SemiBold text-2xl w-full text-center border-b border-gatek-400'>✨ Avantages</h1>

                    <div className='relative w-full flex flex-col bg-gatek-400 divide-y text-base'>
                        <p className='Inter-Regular text-white flex justify-between items-center px-4'>+60 coins / semaine <p>✅</p></p>
                        <p className='Inter-Regular text-white flex justify-between items-center px-4'>Accès aux création d'équipes <p>✅</p></p>
                    </div>

                    <img className='object-contain h-1/4' src={traducteur} />

                    <button disabled={Subscriptions?.currents && Subscriptions.currents[0]?.abonnementType === SubscriptionType.subscription_advanced} onClick={(e) => {
                        StripePay(user?.id, SubscriptionType.subscription_advanced).then((res) => {
                            if (res.status === 200) {
                                GetSubscriptions(user?.id).then((res) => {
                                    if (res.status === 200 && res.data) {
                                        setSubscriptions(res.data);
                                    }
                                });
                            }
                        });
                    }}  className='absolute transform duration-200 hover:scale-110 bottom-0 w-full Poppins-SemiBold text-xl bg-gatek-400 text-white p-2'>S'abonner</button>
                </div>

                <div className={`relative w-1/4 h-full transform ${Subscriptions?.currents && Subscriptions.currents[0]?.abonnementType === SubscriptionType.subscription_infinite ? "scale-100" : "scale-90 opacity-80"} rounded-3xl shadow-lg shadow-slate-900 overflow-hidden gap-4 flex flex-col bg-white`}>
    
                    <h1 className='Poppins-SemiBold text-3xl p-2 bg-gatek-400 w-full text-center text-white'>Formule Infinity</h1>

                    <div className='relative w-full h-1/4  flex items-center justify-center'>
                        <h1 className='Poppins-SemiBold text-4xl text-gatek-400'>19.99€/mois</h1>
                    </div>

                    <h1 className='Poppins-SemiBold text-2xl w-full text-center border-b border-gatek-400'>✨ Avantages</h1>

                    <div className='relative w-full flex flex-col bg-gatek-400 divide-y text-base'>
                    <p className='Inter-Regular text-white flex justify-between items-center px-4'>Accès à toutes les oeuvres sans achat <p>✅</p></p>
                        <p className='Inter-Regular text-white flex justify-between items-center px-4'>Accès aux création d'équipes <p>✅</p></p>
                    </div>

                    <img className='object-contain h-1/4' src={endless} />

                    <button disabled={Subscriptions?.currents && Subscriptions.currents[0]?.abonnementType === SubscriptionType.subscription_infinite} onClick={(e) => {
                        StripePay(user?.id, SubscriptionType.subscription_infinite).then((res) => {
                            if (res.status === 200) {
                                GetSubscriptions(user?.id).then((res) => {
                                    if (res.status === 200 && res.data) {
                                        setSubscriptions(res.data);
                                    }
                                });
                            }
                        });
                    }} className='absolute transform duration-200 hover:scale-110 bottom-0 w-full Poppins-SemiBold text-xl bg-gatek-400 text-white p-2'>S'abonner</button>
                </div>

            </div>}
        </MainContext.Provider>
    );
}

export default Subscribe;