import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import search_black from '../../../Assets/Images/search_black.png';
import down from '../../../Assets/Images/down.png';
import filter from '../../../Assets/Images/filter.png';
import up_black from '../../../Assets/Images/up_black.png';
import down_black from '../../../Assets/Images/down_black.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from './Navbar';
import { IArticle, IComment, IDBChapter, IOeuvre } from '../../../Interfaces/Discover';
import { BookCommentRequest, getBookComments, getChapter, getDiscoverd, getMangaInfoByID, Tags } from '../../../Api/Oeuvres';
import ObjectBox from '../../Dashboard/Subcomponent/ObjectBox';
import { GetArticles, SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getUser } from '../../../Api/User';
import { IPostSuggestionSliceManga, PostSuggestionSliceManga } from '../../../Api/Search';

const AdvancedSearch: React.FC = () => {
    const {user} = useContext(MainContext);
    const [oeuvre, setOeuvre] = useState<IOeuvre | undefined>(undefined);
    const [chapters, setchapters] = useState<IDBChapter[]>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [tags, setTags] = useState<string[]>([]);
    const [author, setauthor] = useState<string>("");
    const [selected, setSelected] = useState<string>("recent");
    const [searchValue, setSearchValue] = useState<string>('');
    const [searchRt, setsearchRt] = useState<IPostSuggestionSliceManga[]>([]);
    const navigate = useNavigate();

    const url = new URL(window.location.href);
    const default_tag = url.searchParams.get('tags');

    const DoSearch = () => {
        if (selected === 'recent') {
            PostSuggestionSliceManga(searchValue, 10, 0, tags, true, false, author).then((res) => {
                setsearchRt(res);
            });
        } else if (selected === 'ancien') {
            PostSuggestionSliceManga(searchValue, 10, 0, tags, false, false, author).then((res) => {
                setsearchRt(res);
            });
        } else if (selected === 'alpha') {
            PostSuggestionSliceManga(searchValue, 10, 0, tags, false, true, author).then((res) => {
                setsearchRt(res);
            });
        }
    }

    useEffect(() => { DoSearch(); }, [searchValue, tags, author, selected]);

    useEffect(() => {
        if (default_tag) {
            setTags([default_tag]);
            PostSuggestionSliceManga('', 10, 0, [], true, false, author).then((res) => {
                setsearchRt(res);
            });
        }
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            {open && <div  onClick={(e) => {setOpen(!open)}} className='absolute z-10 w-full h-full bg-gray-400 bg-opacity-70 flex items-center justify-center'>
                <div onClick={(e) => {e.stopPropagation()}} className='relative w-1/2 h-1/2 bg-white rounded-2xl shadow-lg gap-2 overflow-hidden overflow-y-scroll p-4'>
                    <div  className='relative flex flex-wrap gap-2 select-none'>
                        {Tags.map((tag, index) => {
                            return (
                                <span onClick={(e) => {
                                    e.stopPropagation();

                                    if (tags.includes(tag)) {
                                        setTags(tags.filter((t) => t !== tag));
                                    } else {
                                        setTags([...tags, tag]);
                                    }
                                }}
                                    key={index}
                                    className={`relative Montserrat-SemiBold px-2 py-1 rounded-lg cursor-pointer
                                        ${tags.includes(tag) ? 'bg-gatek_red-500 text-white' : 'bg-gray-200 text-gray-400'}    
                                    `}
                                >
                                {tag}
                            </span>
                            );
                        })}
                    </div>
                </div>
            </div>}
            <div className='relative w-screen h-screen overflow-x-hidden overflow-y-scroll bg-gray-100'>
                <div className='relative h-[10%]'>
                    <Navbar is_black={true} />
                </div>
                <div className='relative px-[8%] flex flex-col gap-4'>
                    <div className='relative w-full px-[15%]'>
                        <h1 className='text-3xl Montserrat-Bold text-center mt-10'>Recherche avancé</h1>
                        <div className='relative h-[50px] flex border border-gray-400 rounded-md bg-white p-1 gap-2'>
                            <img src={search_black} alt='search' className='relative h-full object-contain' />
                            <input onChange={(e) => {
                                setSearchValue(e.target.value);
                            }} type='text' className='w-full h-full bg-transparent outline-none' placeholder='Rechercher' />
                        </div>
                        <div className='flex Montserrat-Regular py-2 gap-8'>
                            <select value={selected} onChange={(e) => {
                                setSelected(e.target.value);
                            }} className='w-[20%] h-10 mt-5 border rounded-md px-2 border-red-600 outline-none'>
                                <option value='recent'>Trier par : récent</option>
                                <option value='ancien'>Trier par : ancien</option>
                                <option value='alpha'>Trier par : alphabétique</option>
                            </select>
                            <button onClick={(e) => {setOpen(!open)}} className={`w-[10%] h-10 mt-5 bg-white rounded-md px-2 outline-none flex items-center justify-center
                                ${tags.length > 0 ? 'bg-text-black border border-red-600' : 'text-gray-400 border border-gray-400'}
                            `}>
                                {tags.length} tags
                            </button>
                            <input value={author} type='text' onChange={(e) => {
                                setauthor(e.target.value);
                            }} className={`w-[30%] h-10 mt-5 rounded-md px-2 outline-none
                                ${author ? 'border border-red-600' : 'border border-gray-400'}
                            `} placeholder='Auteur' />
                            <button onClick={(e) => {
                                setSelected('recent');
                                setTags([]);
                                setSearchValue('');
                                setauthor('');
                                setsearchRt([]);
                            }} className='w-[40%] Montserrat-Bold text-sm text-gatek_red-500 text-right px-2 self-start right-0'
                            >CLEAR
                            </button>
                        </div>
                    </div>
                    <div className='relative w-full flex flex-wrap gap-8'>
                    {searchRt.map((book, index) => {
                            return (
                                <div onClick={(e) => {
                                    console.log(book);
                                    navigate(`/oeuvre?id=${book.id}`);
                                }} key={index} className='relative w-[170px] h-[263px] flex flex-col gap-0.5 cursor-pointer transform hover:scale-95 duration-150'>
                                    <img src={backend_url + book.cover} alt='cover' className='relative w-full select-none h-[90%] object-cover rounded-md' />
                                    <h1 className='h-[10%] text-xs Montserrat-Bold'>{book.name}</h1>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </MainContext.Provider>
    );
}

export default AdvancedSearch;