
import React, {useContext, useEffect, useState} from 'react';
import { MainContext } from '../../../Context/Main';

import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { backend_url } from '../../../App';
import { getAllChapter, getChapterAvailable, getMangaInfoById, AddTranslatedChapter, getChapter } from '../../../Api/Oeuvres';
import { IChapter, IDBChapter, IOeuvre } from '../../../Interfaces/Discover';
import { useNavigate } from 'react-router-dom';
import { handleImageError } from './Discover';
import { GetPreviewImage, RequestChapterValidation, Unclaim, getProcessImages } from '../../../Api/Team';
import Loading from './Loading/Loading';

export interface IBoxe {
    text: string;

    id: number;
    minx: number;
    miny: number;
    maxx: number;
    maxy: number;
}

interface IProccessImages {
    boxes: any[];
    imageWithoutText: string;
    orginalImage: string;
}

const OeuvreTranslationInterface: React.FC = () => {
    const {user, setuser, current_oeuvre, setcurrent_oeuvre, current_language, setcurrent_language, myteam} = useContext(MainContext);
    const [oeuvre, setoeuvre] = useState<IOeuvre | undefined>(undefined);
    const [selectedChapter, setselectedChapter] = useState<IDBChapter | undefined>(undefined);
    const [ProccessImages, setProccessImages] = useState<IProccessImages[]>([]);
    const [actualPage, setactualPage] = useState<number>(0);
    const [hover, sethover] = useState<number | undefined>(undefined);
    const [previewImgUrl, setpreviewImgUrl] = useState<string>("");
    const [previewBtn, setpreviewBtn] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (selectedChapter && oeuvre) {
            getProcessImages(oeuvre.id, selectedChapter.id).then((response) => {
                if (response.data === undefined) {
                    return;
                }
                console.log("response", response);
                setProccessImages(response.data);
            });
        }
    }, [selectedChapter]);

    useEffect(() => {
        let oeuvre_id = sessionStorage.getItem('current_oeuvre');
        let chapter_id = sessionStorage.getItem('current_chapter');
        if (oeuvre_id !== null) {
            getMangaInfoById(oeuvre_id).then((oeu) => {
                getChapter(oeu.id, "original", user?.id).then((chapter) => {
                    oeu.chapters = chapter;
                    setoeuvre(oeu);
                    if (chapter_id !== null) {
                        let chapter = oeu.chapters.find((chap) => chap.id === chapter_id);
                        if (chapter !== undefined) {
                            setselectedChapter(chapter);
                        }
                    }
                });
            });
        }
    }, []);

    const CanValidateChapter = () => {
        if (actualPage === ProccessImages.length - 1 && previewImgUrl !== "" && previewImgUrl !== "WAITING") {
            return true;
        }
        return false;
    }

    const CanValidate = () => {
        if (ProccessImages.length === 0 || ProccessImages[actualPage].boxes === undefined) {
            return false;
        }

        // check if all boxes have text in acutal page
        for (let box of ProccessImages[actualPage].boxes) {
            if (box.text === "" || box.text === undefined) {
                return false;
            }
        }

        return true;
    }

    return (
        <div className='relative w-full h-full gap-4 p-4'>
            {oeuvre && <div className='relative w-full text-white h-1/4 flex gap-4 p-4 shadow-md shadow-black bg-gradient-to-b from-gatek_red-500 via-gatek_red-400 to-gatek_red-300'>
                <button onClick={() => {
                    if (myteam)
                        Unclaim(oeuvre.id, myteam?.team_ID).then((response) => {
                            if (response.status === 200) {
                                sessionStorage.removeItem('current_oeuvre');
                                navigate('/dashboard/edit/teamtraductions');
                            }
                        });
                }} className='absolute top-2 right-2 Poppins-Regular w-1/4 bg-gatek-200 text-white p-0.5 rounded-md'>⚠️ Unclaim</button>
                <div className='relative flex flex-col w-1/2 h-full'>
                    <h1 className='relative Poppins-SemiBold text-xl border-b'>{oeuvre?.name}</h1>
                    <h1 className='relative Inter-Regular'>🖋️ {oeuvre?.author}</h1>
                    <h1 className='relative Inter-Regular '>📚 {oeuvre?.chapters?.length} Chapitre(s)</h1>
                    {/* <h1 className='relative Inter-Regular '>📖 Complétion : {58} % des pages traduites</h1> */}
                    {/* <h1 className='relative Inter-Regular '>📅 Temps restant : {72} heure(s)</h1> */}
                    {selectedChapter && <button onClick={() => {
                        sessionStorage.removeItem('current_chapter');
                        setselectedChapter(undefined);
                    } } className='absolute bottom-0 left-0 Poppins-Regular w-1/4 bg-gatek-200 text-white p-0.5 rounded-md'>⬅️ Retour</button>}
                </div>
                {selectedChapter && <div className='relative flex flex-col gap-2 w-1/2 h-full'>
                    <h1 className='relative Poppins-SemiBold text-xl border-b'>Chapitre actuel : {selectedChapter.name}</h1>
                    <h1 className='relative Inter-Regular '>📜 Pages : {ProccessImages.length} page(s)</h1>
                    {/* <h1 className='relative Inter-Regular '>📖 Complétion : {12} % des pages traduites</h1> */}
                    <div className='relative w-full h-1/2'>
                        {/* selection de la page */}
                        <div className='relative w-full h-1/2 flex flex-row gap-4 overflow-x-auto'>
                            <h1 className='w-1/2'>📄 Page actuel: <input className='relative outline-none w-1/2 h-full bg-gatek-200 text-white p-2 rounded-md' type='number' min={0} max={ProccessImages.length} value={actualPage} onChange={(e) => {
                                let nb = parseInt(e.target.value);
                                if (nb < 0) {
                                    nb = 0;
                                } else if (nb >= ProccessImages.length) {
                                    nb = ProccessImages.length - 1;
                                }
                                setactualPage(nb);
                            }} /></h1>
                        </div>
                    </div>
                    {CanValidate() && <button onClick={() => {
                        if (myteam) {
                            setpreviewImgUrl("WAITING");
                            if (ProccessImages[actualPage].boxes) {
                                GetPreviewImage(oeuvre.id, selectedChapter.id, myteam?.team_ID, ProccessImages[actualPage].imageWithoutText, ProccessImages[actualPage].boxes).then((response) => {
                                    setpreviewImgUrl(response.data);
                                });
                            }
                        }
                    }} className='relative Poppins-Regular w-full bg-gatek-200 self-end text-white px-2 rounded-md'>Prévisulation</button>}
                    {CanValidateChapter() && <button onClick={() => {
                        if (myteam) {
                            RequestChapterValidation(oeuvre.id, selectedChapter.id, myteam?.team_ID).then((response) => {
                                if (response.status === 200) {
                                    sessionStorage.removeItem('current_chapter');
                                    setselectedChapter(undefined);
                                }
                            });
                        }
                    }} className='relative Poppins-Regular w-full bg-gatek-200 self-end text-white px-2 rounded-md'>💾 Valider le chapitre</button>}
                </div>}
            </div>}
            {selectedChapter && oeuvre ? (ProccessImages.length > 0 && ProccessImages[actualPage].boxes !== undefined) ? <div className='relative w-full h-full mt-8 flex gap-12'>
                <div className='relative w-1/2 h-screen flex flex-col gap-4 shadow-md shadow-black bg-gradient-to-r from-gatek_red-500 via-gatek_red-400 to-gatek_red-300'>
                    <div className='relative w-full h-[5%]'>

                    </div>
                    <div className='relative w-full h-[95%]'>
                        <img className='relative w-full h-screen' src={backend_url+ProccessImages[actualPage].orginalImage} onError={handleImageError} />
                    </div>
                </div>
                <div className='relative Inter-Regular text-black w-1/2 h-screen flex flex-col shadow-md shadow-black bg-gradient-to-l from-gatek_red-500 via-gatek_red-400 to-gatek_red-300'>
                    <div className='relative w-full h-[5%]'>
                        {previewImgUrl !== "" && previewImgUrl !== "WAITING" && <button onClick={() => {
                            console.log("previewBtn", previewImgUrl);
                            setpreviewBtn(!previewBtn);
                        }} className='relative Poppins-Regular bg-gatek-200 text-white p-2 rounded-md'> {
                            previewBtn ? "Retour sur la zone de modification" : "Voir la prévisualisation"
                        }</button>}
                    </div>
                    <div className='relative w-full h-[95%]'>
                        {previewImgUrl === "WAITING" ? <Loading /> : (previewImgUrl !== "" && previewBtn) ?
                            <img className='relative w-full h-screen' src={backend_url+previewImgUrl} onError={handleImageError} />
                        :<> <img className='relative w-full h-screen' src={backend_url+ProccessImages[actualPage].imageWithoutText} onError={handleImageError} />
                            {ProccessImages[actualPage].boxes && ProccessImages[actualPage].boxes.map((box, index) => {
                                const style: React.CSSProperties = {
                                    left: `${(box.minx / 400) * 100}%`, // Assume 400 is the width of the original image
                                    top: `${(box.miny / 300) * 100}%`, // Assume 300 is the height of the original image
                                    width: `${((box.maxx - box.minx) / 400) * 100}%`,
                                    height: `${((box.maxy - box.miny) / 300) * 100}%`,
                                };
                                return <div key={box.id} style={style} className={`absolute opacity-70`}>
                                    <textarea placeholder='Texte de remplacement' onChange={(e) => {
                                        // Replace the text in the box with specific id directly in ProccessImages
                                        let newProccessImages = JSON.parse(JSON.stringify(ProccessImages));
                                        newProccessImages[actualPage].boxes[index].text = e.target.value;
                                        setProccessImages(newProccessImages);
                                    }} className='relative w-full h-full text-start outline-none p-1 resize-none text-sm' value={box.text} />
                                </div>;
                            })}
                        </>}
                    </div>
                </div>
            </div> : <Loading /> : null}
            {selectedChapter === undefined && <h1 className='relative Poppins-SemiBold mt-8 text-4xl border-b border-gatek-400'>Chapitres</h1>}
            {selectedChapter === undefined && oeuvre &&
            <div className='relative w-full h-1/2 mt-8 flex flex-col gap-4'>
                {oeuvre.chapters && oeuvre.chapters.map((chapter, index) => {
                    return <div key={index} className='relative w-full text-white h-full flex gap-4 p-4 shadow-md shadow-black bg-gradient-to-b from-gatek_red-500 via-gatek_red-400 to-gatek_red-300'>
                        <img className='relative w-1/4 h-full object-contain' src={backend_url+chapter.cover} onError={handleImageError} />
                        <div className='relative w-1/2 h-full'>
                            <h1 className='relative Poppins-SemiBold text-xl border-b'>Chapitre : {chapter.name}</h1>
                            {/* <h1 className='relative Inter-Regular'>📖 Complétion : {chapter.images?.length} % des pages traduites</h1> */}
                        </div>
                        <button onClick={() => {
                            sessionStorage.setItem('current_chapter', chapter.id);
                            setselectedChapter(chapter)
                        }} className='relative Poppins-Regular h-[10%] w-full bg-gatek-200 self-end text-white p-2 rounded-md'>Traduire</button>
                    </div>
                })}
            </div>}
        </div>
    )
}

export default OeuvreTranslationInterface;