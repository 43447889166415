import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import up from '../../../Assets/Images/up.png';
import down from '../../../Assets/Images/down.png';
import filter from '../../../Assets/Images/filter.png';
import up_black from '../../../Assets/Images/up_black.png';
import send_white from '../../../Assets/Images/send_white.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from '../Discovered/Navbar';
import { IComment, IDBChapter, IOeuvre } from '../../../Interfaces/Discover';
import { BookCommentRequest, getBookComments, getChapter, getDiscoverd, getMangaInfoByID } from '../../../Api/Oeuvres';
import { SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getUser } from '../../../Api/User';
import { CreateTeamRequest, Get5LastTeams, GetCredentialsInvitation, GetSearchTeams, IGet5LastTeams, IGetCredentialsInvitation, IGetSearchTeams, ITeam, PostCredentialsInvitation, PostulateForTeamRequest, TeamRequest } from '../../../Api/Team';
import { maxMB } from '../../Edit/AddChapter';

const JoinTeam: React.FC = () => {
    const {user, setmyteam, myteam} = useContext(MainContext);
    const [teams, setTeams] = useState<IGetSearchTeams[]>([]);
    const [invitations, setInvitations] = useState<IGetCredentialsInvitation[]>([]);
    const [openModal, setOpenModal] = useState<string | undefined>(undefined);
    const [postulantMessage, setPostulantMessage] = useState<string>('');
    const [search, setSearch] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        GetSearchTeams(search, 10).then((ts) => {
            setTeams(ts);
        });

    }, [search]);

    useEffect(() => {
        if (!user)
            navigate('/login');

        GetSearchTeams('', 10).then((ts) => {
            setTeams(ts);
        });

        GetCredentialsInvitation().then((invitations) => {
            setInvitations(invitations);
        });

    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            {openModal !== undefined && <div className='fixed z-[60] top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center'>
                <div className='bg-white w-[50%] h-[50%] rounded-xl flex flex-col items-center justify-between p-4'>
                    <h1 className='text-xl Montserrat-SemiBold'>Postuler à une équipe</h1>
                    <textarea onChange={(e) => {
                        setPostulantMessage(e.target.value);
                    }} className='w-full h-[50%] outline-none resize-none bg-transparent' placeholder='Pourquoi voulez-vous rejoindre cette équipe ?'></textarea>
                    <div className='w-full flex items-center justify-between gap-2'>
                        <button onClick={() => setOpenModal(undefined)} className='w-1/2 bg-red-400 text-lg Montserrat-Regular text-white rounded-xl flex items-center justify-center gap-2'>
                            Annuler
                        </button>
                        <button disabled={postulantMessage === ''} onClick={() => {
                            if (user)
                                PostulateForTeamRequest(user?.id, openModal, postulantMessage).then((res) => {
                                    if (res.status === 200) {
                                        alert('Votre demande a été envoyée');
                                        setOpenModal(undefined);
                                    } else {
                                        alert('Une erreur est survenue');
                                    }
                                });
                        }} className={`w-1/2 ${postulantMessage === '' ? "bg-gray-400" :"bg-gatek-400"} text-lg Montserrat-Regular text-white rounded-xl flex items-center justify-center gap-2`}>
                            <img src={send_white} className='w-5 h-5 object-contain'/> Envoyer
                        </button>

                    </div>
                </div>
            </div>}
            <div className='relative w-full h-full overflow-hidden bg-gray-100 px-[8%]'>
                <div className='relative flex w-full justify-end'>
                    <input type='text' value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Rechercher une équipe' className='relative w-1/4 h-10 border-2 border-gray-200 rounded-xl p-2 outline-none'/>
                </div>

                <h1 className='text-xl Montserrat-SemiBold  border-b-2 border-black'>{`${invitations.length} invitations`}</h1>
                {(invitations.length <= 0) && <h1 className=' italic text-center p-4 Montserrat-Regular'>Pas d'invitation</h1>}
                {invitations.map((team, index) => {
                    return (
                        <div className='bg-white w-full h-[100px] rounded-xl mt-4 border-2 border-gray-200 p-2 flex justify-between gap-2'>
                            <div className='relative w-[8%] h-full border-r-2 border-gray-200'>
                                <img src={logo_gatek} alt='logo' className='w-full h-full object-contain' onError={handleImageError}/>
                            </div>
                            <div className='relative w-[70%] h-full border-r-2 border-gray-200'>
                                <h1 className='text-lg Montserrat-SemiBold'>{team.team_name}</h1>
                                <textarea className='text-sm w-full h-[50%] outline-none resize-none bg-transparent' value={team.team_name} readOnly></textarea>
                            </div>
                            <div className='relative w-[20%] h-full flex gap-2'>
                                <div className='relative w-1/2 flex flex-col gap-2'>
                                    {/* <h1 className='text-lg Montserrat-SemiBold'>{team.} membre(s)</h1>
                                    <h1 className='text-xs Montserrat-Regular truncate'>{team.books_claimed} oeuvres claimed</h1>
                                    <h1 className='text-xs  Montserrat-Regular truncate'>{team.translation_accepted} traductions</h1> */}
                                </div>
                                <div className='relative w-1/2 flex flex-col gap-2 items-center justify-center'>
                                    <button onClick={() => {
                                        PostCredentialsInvitation(true, team.invitation_ID).then((res) => {
                                            if (res) {
                                                navigate('/traduction');
                                                // GetCredentialsInvitation().then((invitations) => {
                                                //     setInvitations(invitations);
                                                // });
                                            }
                                        });
                                    }} className='w-full h-1/2 bg-gatek-400 text-lg Montserrat-Regular text-white rounded-xl flex items-center justify-center gap-2'>
                                        Accepter
                                    </button>
                                    <button onClick={() => {
                                        PostCredentialsInvitation(false, team.invitation_ID).then((res) => {
                                            if (res) {
                                                GetCredentialsInvitation().then((invitations) => {
                                                    setInvitations(invitations);
                                                });
                                            }
                                        });
                                    }}  className='w-full h-1/2 bg-white text-lg Montserrat-Regular border-2 border-black rounded-xl flex items-center justify-center gap-2'>
                                        Refuser
                                    </button>
                                </div>
                            </div>
                        </div>
                    );
                })}

                <h1 className='text-xl Montserrat-SemiBold border-b-2 border-black'>Équipes disponibles</h1>
                {(teams.length <= 0) && <h1 className=' italic text-center p-4 Montserrat-Regular'>Pas d'équipe disponible</h1>}
                {teams.map((team, index) => {
                    return (
                        <div className='bg-white w-full h-[100px] rounded-xl mt-4 border-2 border-gray-200 p-2 flex justify-between gap-2'>
                            <div className='relative w-[8%] h-full border-r-2 border-gray-200'>
                                <img src={logo_gatek} alt='logo' className='w-full h-full object-contain' onError={handleImageError}/>
                            </div>
                            <div className='relative w-[70%] h-full border-r-2 border-gray-200'>
                                <h1 className='text-lg Montserrat-SemiBold'>{team.name}</h1>
                                <textarea className='text-sm w-full h-[50%] outline-none resize-none bg-transparent' value={team.description} readOnly></textarea>
                            </div>
                            <div className='relative w-[20%] h-full flex gap-2'>
                                <div className='relative w-1/2 flex flex-col gap-2'>
                                    <h1 className='text-lg Montserrat-SemiBold'>{team.number_member} membre(s)</h1>
                                    <h1 className='text-xs Montserrat-Regular truncate'>{team.books_claimed} oeuvres claimed</h1>
                                    <h1 className='text-xs  Montserrat-Regular truncate'>{team.translation_accepted} traductions</h1>

                                </div>
                                <div className='relative w-1/2 flex items-center justify-center'>
                                    <button onClick={() => setOpenModal(team.team_ID)} className='w-full h-1/2 bg-gatek-400 text-lg Montserrat-Regular text-white rounded-xl flex items-center justify-center gap-2'>
                                        <img src={send_white} alt='send' className='w-5 h-5 object-contain'/>
                                        postuler
                                    </button>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </MainContext.Provider>
    );
}

export default JoinTeam;