import React, {useContext, useEffect, useRef, useState} from 'react';
import { Textfit } from 'react-textfit';

import test from '../../../Assets/Images/test.jpg';
import { Outlet, useNavigate } from 'react-router-dom';

import red_arrow from '../../../Assets/Images/red_arrow.png';
import unlike from '../../../Assets/Images/unlike.png';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';

import { MainContext } from '../../../Context/Main';
import { IArticle, IChapter, IDBChapter, IDiscover, IOeuvre } from '../../../Interfaces/Discover';
import { getChapter, getDiscoverd, getMangaInfo } from '../../../Api/Oeuvres';
import { backend_url } from '../../../App';
import OeuvreModal from './OeuvreModal';
import { GetArticles } from '../../Edit/Utils/IAdd';
import ArticleModal from './ArticleModal';
import ObjectBox from './ObjectBox';
import bg_girl_book from '../../../Assets/Images/bg_girl_book.webp';
import pandaroux_livre from '../../../Assets/Images/pandaroux_livre.webp';
import { getUser } from '../../../Api/User';
import { User } from '../../../Interfaces/User';

export const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    // URL de votre image de remplacement
    e.currentTarget.src = logo_gatek;
};

const SpecificOeuvreModal: React.FC<{oeuvre: IOeuvre}> = (o)=> {
    const {user, setseedetails} = useContext(MainContext);
    const navigate = useNavigate();
    const [author, setauthor] = useState<User | undefined>(undefined);
    const [chapters, setchapters] = useState<IDBChapter[]>([]);

    useEffect(() => {
        if (o.oeuvre.author_ID)
            getUser(o.oeuvre.author_ID).then((u) => {
                if (u) {
                    setauthor(u);
                }
            });

        if (o.oeuvre.id)
            getChapter(o.oeuvre.id, "original", user?.id).then((chapter) => {
                setchapters(chapter || []);
            });
    }, [user]);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full overflow-hidden'>
                <div className="absolute w-1/2 h-full right-0">
                    <img className="" src={backend_url + o.oeuvre.cover} />
                    <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent via-transparent to-black opacity-100"></div>
                    <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-l from-transparent via-transparent to-black opacity-100"></div>
                </div>

                {author && <div onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    sessionStorage.setItem('author_id', author.id);
                    navigate(`../author`);
                    setseedetails(undefined);
                }} className='absolute top-[60%] left-0 w-[10%] h-[8%] flex justify-between bg-gradient-to-l from-transparent to-gatek_red-800 p-2
                transform hover:scale-105 duration-100 cursor-pointer'>
                    <img src={backend_url+author.avatar} className='relative object-contain w-full h-full' onError={handleImageError} />
                    <Textfit mode="single" min={12} max={16} className="w-full h-20% flex items-center justify-center Poppins-SemiBold text-white">
                        {author.username}
                    </Textfit>
                </div>}

                <div className='absolute top-[24%] left-0 w-[45%] flex flex-col p-4 gap-4'>
                    <div className='relative w-full px-4'>
                        <Textfit mode="single" min={8} max={64} className="relative Poppins-SemiBold text-white">
                            {o.oeuvre.name}
                        </Textfit>
                    </div>

                    <Textfit mode="multi"  min={14} max={16} className="w-full h-[40%] line-clamp-5 text-sm justify-center Inter-Regular text-white text-justify">
                    {o.oeuvre.description}
                    </Textfit>
                    <div className='relative w-[40%] h-[40%] flex justify-between gap-8 p-4'>
                        <button onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setseedetails(o.oeuvre);
                        }} className='Poppins-Regular text-white rounded-xl bg-gatek-400 w-1/2 p-2 hover:bg-gatek_red-600 duration-100 truncate'>
                            <Textfit mode="single" min={12} max={16} className="w-full h-[20%] flex items-center justify-center Poppins-SemiBold text-white">
                                ▶ Lire
                            </Textfit>
                        </button>
                        <button onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setseedetails(o.oeuvre);
                        }} className='Poppins-Regular text-white w-1/2 bg-gatek-200 rounded-xl p-2 hover:bg-gatek_orange-600 hover:bg-opacity-50 bg-opacity-50 duration-100 truncate'>
                            <Textfit mode="single" min={12} max={16} className="w-full h-[20%] flex items-center justify-center Poppins-SemiBold text-white">
                                En savoir plus
                            </Textfit>
                        </button>
                    </div>

                </div>

            </div>
        </MainContext.Provider>
    );
}

export default SpecificOeuvreModal;