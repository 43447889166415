import { User } from "../Interfaces/User";
import { IArticle, IChapter, IOeuvre, ITranslatedBook } from "../Interfaces/Discover";
import { backend_url } from "../App";
import { AxiosResponse, Get, Post, PostForm } from "./Axios";
import { json } from "stream/consumers";

export const modifyUserInfo = async (pseudo: string, firstname: string, lastname: string, readingLanguage: string, readingWay?: string):Promise<AxiosResponse> => {
    const response = await Post('/credentials/modify', JSON.stringify({
        pseudo: pseudo,
        firstname: firstname,
        lastname: lastname,
        readingLanguage: readingLanguage,
        readingWay: readingWay
    }));
    return response;
}

export const getMyOeuvres = async (user: User):Promise<IOeuvre[]> => {
    console.log(user);
    if (user?.role == 'author') {
        const response = await Post('/author/info/allMangas', JSON.stringify({author_ID: user.id}));
        console.log(response);
        if (response.status === 200) {
            return response.data;
        } else {
            return [];
        }
    }
    return [];
}

export const getMyArticles = async (user: User):Promise<IArticle[]> => {
    console.log(user);
    if (user?.role == 'author') {
        const response = await Get('/article/fromUser', {user_ID:user.id, author_ID: user.id, nsfw: true});
        console.log("getMyArticles", response);
        if (response.status === 200) {
            return response.data;
        } else {
            return [];
        }
    }
    return [];
}

export const getMyTranslatedOeuvres = async (user: User):Promise<ITranslatedBook[]> => {
    console.log(user);
    if (user?.role == 'translator') {
        const response = await Get('/translator/mangaTranslated', {user_ID: user.id}
        );
        console.log("getMyOeuvres", response);
        if (response.status === 200) {
            return response.data;
        } else {
            return [];
        }
    } else {
        return [];
    }
}

export const getUser = async (user_id: string):Promise<User | undefined> => {
    const response = await Post('/credentials', JSON.stringify({id: user_id}));
    if (response.status === 200) {
        response.data.id = user_id;
        const resp = await Get('/credentials/private', JSON.stringify({id: user_id}));
        if (resp.status === 200) {
            response.data.readingLanguage = resp.data.readingLanguage;
            response.data.readingWay = resp.data.readingWay;
        }

        return response.data;
    } else {
        return undefined;
    }
}


export const registerChapter = async (chapitre: IChapter):Promise<boolean> => {
    // const response = await axios.post(backend_url+'/register_chapter', JSON.stringify({
    //     chapitre: chapitre,
    // }), {
    //     headers: {
    //         'Content-Type': 'application/json'
    //     }
    // });
    // if (response.status === 201) {
    //     return response.data;
    // } else {
    //     return [];
    // }
    return false
}

export enum SubscriptionType {
    subscription_basic = "subscription_basic",
    subscription_advanced = "subscription_advanced",
    subscription_infinite = "subscription_infinite",
}

export interface ISubscription {
    currents: {
        subscription_ID: string,
        abonnementType: SubscriptionType,
        abonnementExpirationDate: string,
    }[],
    previous: {
        subscription_ID: string,
        abonnementType: SubscriptionType,
        abonnementExpirationDate: string,
    }[]
}

export const GetSubscriptions = async(user_ID?: string):Promise<AxiosResponse> => {
    const response = await Get('/payment/subscriptions', {user_ID: user_ID});
    return response
}

export const GetPayementCancel = async():Promise<AxiosResponse> => {
    const response = await Post('/payment/cancel', JSON.stringify({}));
    return response
}

export const PostPaymentRenew = async(subscription_ID: string):Promise<AxiosResponse> => {
    const response = await Post('/payment/renew', JSON.stringify({subscription_ID: subscription_ID}));
    return response
}

export const PostPaymentRefund = async():Promise<AxiosResponse> => {
    const response = await Post('/payment/refund', JSON.stringify({}));
    return response
}

export interface IGetAuthorSupportList {
    author_ID: string,
    author_name: string,
    author_profile_picture: string
}
export const GetAuthorSupportList = async():Promise<IGetAuthorSupportList[]> => {
    const response = await Get('/author/support/list',{});
    return response.data
}

export const StripePay = async(user_ID?: string, paymentType?: string):Promise<AxiosResponse> => {
    const response = await Post('/payment/stripe/create', JSON.stringify({
        amount: 1999,
        currency: "usd",
        userID: user_ID,
        paymentType: paymentType,
        cardInfo: {
            cardType: "visa",
            lastDigits: "4242",
            cardExpirationDate: "12/24",
            cardOwner: "John Doe"
        }
    }));
    return response
}

export interface IGetPaymentSubscriptionsType {
    label: string,
    price: number
}
export const GetPaymentSubscriptionsType = async():Promise<IGetPaymentSubscriptionsType[]> => {
    const response = await Get('/payment/subscriptions/type', {});
    return response.data
}

export const PostAuthorSupport = async(author_ID?: string):Promise<AxiosResponse> => {
    const response = await Post('/author/support/', JSON.stringify({
        amount: 5000,
        currency: "usd",
        paymentTypeTier: 1,
        cardInfo: {
          lastDigits: "4242424242424242",
          cardType: 12,
          cardExpirationDate: "12/24",
          cardOwner: "r"
        },
        authorID: author_ID
    }));
    return response
}

export const PostAuthorSupportUnsupport = async(author_ID: string):Promise<AxiosResponse> => {
    const response = await Post('/author/support/unsupport', JSON.stringify({
        author_ID: author_ID
    }));
    return response
}

export interface IPostNotificationGetunseen {
    manga_name: string,
    manga_ID: string,
    chapter_ID: string,
    chapter_name: string,
    date: string
}
export const PostNotificationGetunseen = async():Promise<IPostNotificationGetunseen[]> => {
    const response = await Post('/notifications/getunseen', JSON.stringify({}));
    return response.data
}

export interface IGetNotificationsAuthorGetUnseen {
    newManga: {
        manga_ID: string,
        mangaName: string,
        author_ID: string,
        author_name: string,
        date: string
    }[],
    newArticle: {
        article_ID: string,
        articleName: string,
        author_ID: string,
        author_name: string,
        date: string
    }[]
}
export const GetNotificationsAuthorGetUnseen = async():Promise<IGetNotificationsAuthorGetUnseen> => {
    const response = await Get('/notifications/author/getUnseen', {});
    return response.data
}

export const PostNotificationsAuthorUpdate = async(article_ID?: string, manga_ID?: string):Promise<AxiosResponse> => {
    const response = await Post('/notifications/author/update', JSON.stringify({
        article_ID: article_ID,
        manga_ID: manga_ID
    }));
    return response
}

export const PostNotificationsUpdate = async(manga_ID: string):Promise<AxiosResponse> => {
    const response = await Post('/notifications/update', JSON.stringify({
        manga_ID: manga_ID
    }));
    return response
}

export interface IGetTranslatorTeamCompetition {
    manga_ID: string,
    mangaName: string,
    author_ID: string,
    authorName: string,
    creationDate: string,
    competition_ID: string
}
export const GetTranslatorTeamCompetition = async():Promise<IGetTranslatorTeamCompetition[]> => {
    const response = await Get('/translator/team/competition', {});
    return response.data
}

export const GetTranslatorTeamCompetitionRefuse = async(competition_ID: string):Promise<AxiosResponse> => {
    const response = await Post('/translator/team/competition/refuse', {competition_ID: competition_ID});
    return response
}

export interface IGetTranslatorTeamCompetitionInfo {
    chapter_ID: string,
    chapterName: string,
    originalLanguage: string,
    translatedLanguage: string,
    team_ID: string,
    teamName: string,
    pages: string[],
    translationDate: string
}
export const GetTranslatorTeamCompetitionInfo = async(competition_ID: string):Promise<IGetTranslatorTeamCompetitionInfo> => {
    const response = await Get('/translator/team/competition/info', {competition_ID: competition_ID});
    return response.data
}

export const PostCredentialsPasswordModify = async(password: string, newPassword: string):Promise<AxiosResponse> => {
    const response = await Post('/credentials/password/modify', JSON.stringify({
        currentPassword: password,
        newPassword: newPassword
    }));
    return response
}

export const PostCredentialsModifyProfile = async(image : string):Promise<AxiosResponse> => {
    const formData = new FormData();
    if (image) {
        await fetch(image)
        .then(response => response.blob())
        .then(blob => {
            // Créer un objet File à partir du Blob
            var file = new File([blob], 'cover.jpg', { type: 'image/jpeg' });
            
            // Ajouter le fichier à FormData
            formData.append('image', file);
        })
        .catch(error => {
            console.error('Erreur lors de la récupération de l\'image :', error);
        });
    }

    return await PostForm('/credentials/modifyProfile', formData, 'multipart/form-data');
}