import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import black_pen from '../../../Assets/Images/black_pen.png';
import disconnect from '../../../Assets/Images/disconnect.png';
import black_coin from '../../../Assets/Images/black_coin.png';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import { MainContext } from '../../../Context/Main';
import { GetPaymentSubscriptionsType, GetSubscriptions, IGetPaymentSubscriptionsType, ISubscription, modifyUserInfo, PostCredentialsModifyProfile, SubscriptionType } from '../../../Api/User';
import { backend_url } from '../../../App';
import { handleImageError } from './Discover';
import Navbar from '../../Intra/Discovered/Navbar';
import { GetUsers } from '../../../Interfaces/User';
import { AllWorldLanguages } from './TraductorEditInterface';

const Parametres: React.FC = () => {
    const {user, setuser, setToken, language, setlanguage} = useContext(MainContext);
    const [isOpen, setIsOpen] = useState(false);
    const [isPasswordOpen, setIsPasswordOpen] = useState(false);
    const [imgOpen, setImgOpen] = useState(false);
    const [Subscriptions, setSubscriptions] = useState<IGetPaymentSubscriptionsType[]>([]);
    const [modify, setModify] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const navigate = useNavigate();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const imgURI = URL.createObjectURL(file);
    
            const image = new Image();
            image.onload = () => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    if (user) {
                        console.log(reader.result);
                        setuser({...user, avatar: reader.result as string});

                    }
                };
                reader.readAsDataURL(file);
            };
            image.onerror = () => {
                alert("Impossible de charger l'image. Veuillez réessayer.");
            };
            image.src = imgURI;
        }
    };

    useEffect(() => {
        GetPaymentSubscriptionsType().then((res) => {
            setSubscriptions(res);
        });
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            {imgOpen && <div>
                <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex items-center justify-center'>
                    <div className='relative w-1/3 h-1/3 bg-white flex flex-col gap-4 p-4'>
                        <h1 className='relative text-xl Poppins-Bold'>Changer d'avatar</h1>
                        <input className='relative w-full h-1/3' type="file" accept="image/*" multiple onChange={handleFileChange} />
                        <button onClick={() => {
                            if (user && user.avatar) {
                                PostCredentialsModifyProfile(user.avatar).then((res) => {
                                    if (res.status === 200) {
                                        GetUsers(user.id).then((res) => {
                                            if (res.data !== undefined) {
                                                res.data.id = user.id;
                                                setuser(res.data);
                                                setImgOpen(false);
                                            }
                                        });
                                    } else
                                        setError(res.data.error);
                                });
                            }
                        }} className='relative w-full h-1/3 bg-gatek_light_red-600 text-white Poppins-Regular'>Valider</button>
                    </div>
                </div>
            </div>}
            <div className='relative w-full h-full overflow-y-auto bg-gray-100'>
                <div className='relative h-[10%]'>
                    <Navbar is_black={true} />
                </div>
                <div className='absolute w-1/2 left-[25%] flex flex-col gap-4 py-8'>
                    <div className='relative w-full flex flex-col justify-start gap-8 items-center pb-4'>
                        <h1 className='relative Inter-Bold text-3xl w-full'>Mon Compte</h1>
                        <div className='relative'>
                            <img className='relative bg-gray-100 w-[180px] border-4 border-white h-[180px] rounded-full object-contain' src={backend_url+user?.profilePicture} onError={handleImageError} />
                            <div onClick={() => {
                                setImgOpen(true);
                            }} className='absolute right-1 bottom-4 hover:scale-95 transform duration-150 cursor-pointer bg-white rounded-full p-1 h-10 w-10 flex items-center justify-center'>
                                <img src={black_pen} className='w-4 h-4 bg-white' />
                            </div>
                        </div>
                    </div>

                    <div className='relative w-full flex flex-col justify-start gap-4 items-center pb-8'>
                        {error && <h1 className='relative text-red-600'>{error}</h1>}
                        <h1 className='relative Inter-Regular text-xl w-full border-b-2 border-black py-2'>Information du compte</h1>

                        {/* UserName */}
                        <div className='relative w-full flex justify-between'>
                            {modify !== 'username' ? <h1 className='relative Montserrat-Regular text-gray-500 w-[50%] truncate'>Pseudo: <span className='Montserrat-SemiBold text-black'>{user?.username}</span></h1> : 
                            <h1 className='relative Montserrat-Regular text-gray-500 w-[100%] truncate'>Pseudo: <input className='relative Montserrat-SemiBold p-1 w-[50%] outline-none bg-gray-800 border-gatek-400 border text-white' type='text' value={user?.username} onChange={(e) => {
                                if (user)
                                    setuser({...user, username: e.target.value});
                            }} /></h1>}
                            <button onClick={() =>{
                                if (modify === 'username') {
                                    if (user) {
                                        modifyUserInfo(user.username, user.firstname, user.lastname, language, user.readingWay).then((res) => {
                                            if (res.status === 200) {
                                                GetUsers(user.id).then((res) => {
                                                    if (res.data !== undefined) {
                                                        res.data.id = user.id;
                                                        setuser(res.data);
                                                    }
                                                });
                                            } else
                                                    setError(res.data.error);
                                        });
                                    }
                                    setModify(undefined);
                                } else
                                    setModify('username');
                            }} className='relative Inter-Regular hover:underline w-[50%] text-right text-blue-600'>{modify !== 'username' ? 'Modifier le pseudo' : 'Valider'}</button>
                        </div>

                        {/* Email */}
                        <div className='relative w-full flex justify-between'>
                            {modify !== 'email' ? <h1 className='relative Montserrat-Regular text-gray-500 w-[50%] truncate'>Email: <span className='Montserrat-SemiBold text-black'>{user?.email}</span></h1> : 
                            <h1 className='relative Montserrat-Regular text-gray-500 w-[100%] truncate'>Email: <input className='relative Montserrat-SemiBold p-1 w-[50%] outline-none bg-gray-800 border-gatek-400 border text-white' type='text' value={user?.email} onChange={(e) => {
                                if (user)
                                    setuser({...user, email: e.target.value});
                            }} /></h1>}
                            <button onClick={() =>{
                                navigate('/modify-email');
                            }} className='relative Inter-Regular hover:underline w-[50%] text-right text-blue-600'>{modify !== 'email' ? 'Modifier l\'email' : 'Valider'}</button>
                        </div>
                            
                        {/* Password */}
                        <div className='relative w-full flex justify-between'>
                            <h1 className='relative Montserrat-Regular text-gray-500 w-[50%] truncate'>Mot de passe: ********</h1>
                            <button onClick={() => {
                                navigate('/modify-password');
                            }} className='relative Inter-Regular hover:underline w-[50%] text-right text-blue-600'>Modifier le mot de passe</button>
                        </div>
                    </div>

                    <div className='relative w-full flex flex-col justify-start gap-4 items-center border-b pb-8'>
                        <h1 className='relative Inter-Regular text-xl w-full border-b-2 border-black py-2'>Préférence</h1>

                        <div className='relative w-full flex justify-between'>
                            <h1 className='relative Montserrat-Regular text-gray-500 w-[50%] truncate'>Langue: </h1>
                            <select className='relative Montserrat-SemiBold w-[50%] outline-none bg-transparent' value={user?.readingLanguage} onChange={(e) => {                                
                                if (user) {
                                    setuser({...user, readingLanguage: e.target.value});
                                    modifyUserInfo(user.username, user.firstname, user.lastname, e.target.value, user.readingWay).then((res) => {
                                        if (res.status === 200) {
                                            GetUsers(user.id).then((res) => {
                                                if (res.data !== undefined) {
                                                    res.data.id = user.id;
                                                    setuser(res.data);
                                                }
                                            });
                                        } else
                                            setError(res.data.error);
                                    });
                                }

                            }}>
                                {AllWorldLanguages.map((lang) => {
                                    return <option value={lang}>{lang}</option>
                                })}
                            </select>
                        </div>

                        <div className='relative w-full flex justify-between'>
                            <h1 className='relative Montserrat-Regular text-gray-500 w-[50%] truncate'>Lecture: {}</h1>
                            <select className='relative Montserrat-SemiBold w-[50%] outline-none bg-transparent' onChange={(e) => {
                                if (user && user.readingLanguage) {
                                    setuser({...user, readingWay: e.target.value});
                                    modifyUserInfo(user.username, user.firstname, user.lastname, user.readingLanguage, e.target.value).then((res) => {
                                        if (res.status === 200) {
                                            GetUsers(user.id).then((res) => {
                                                if (res.data !== undefined) {
                                                    res.data.id = user.id;
                                                    setuser(res.data);
                                                }
                                            });
                                        } else
                                            setError(res.data.error);
                                    });
                                }
                            }} value={user?.readingWay} >
                                <option value='defilement'>Défilement</option>
                                <option value='pagination'>Pagination</option>
                            </select>
                        </div>
                    </div>

                    <div className='relative w-full flex flex-col justify-start gap-4 items-center pb-8'>
                        <h1 className='relative Inter-Regular text-xl w-full border-b-2 border-black py-2'>Abonnement et soutien</h1>

                        <div className='relative w-full flex justify-between'>
                            <h1 className='relative Montserrat-Regular w-[50%] truncate flex gap-2 items-center'>
                                <img className='w-5 h-5 object-contain' src={black_coin} />    
                                soutien(s)
                            </h1>
                            <button onClick={(e) => {
                                e.stopPropagation();
                                navigate("../subs");
                            }}className="relative w-[25%] h-[60px] p-4 rounded-xl bg-gatek-400 text-white Montserrat-SemiBold flex items-center justify-center gap-2">
                                Consulter soutien et abonnement
                            </button>
                        </div>
                        <button onClick={() =>{
                            navigate('/modify-payment');
                        }} className='relative Inter-Regular self-end hover:underline w-[50%] text-right text-blue-600'>{modify !== 'payment' ? 'Modifier le moyen de paiement' : 'Valider'}</button>
                    </div>
                    <div className='relative w-full h-[8%]'>
                        <div  className='relative w-full h-full border-t border-gray-300 py-4 flex justify-end'>
                            <button onClick={(e) => {
                                e.stopPropagation();
                                setToken(undefined);
                                sessionStorage.removeItem('token');
                                setuser(undefined);
                                navigate("/");
                            }}className="relative w-[20%] h-[40px] rounded-2xl bg-gatek-400 text-white Montserrat-SemiBold flex items-center justify-center gap-2">
                                <img src={disconnect} className='w-5 h-5 object-contain' />
                                Se déconnecter
                            </button>
                        </div>
                    </div>
                </div>

                {/* <div className='relative flex w-full h-1/2 gap-4'>
                    <div className='relative w-1/4 h-full flex flex-col'>
                        <img className='relative border border-dashed border-gatek-200 w-full h-[90%] object-contain' src={user?.avatar || logo_gatek} />
                        <input className='text-xs h-[10%]' type="file" accept="image/*" multiple onChange={(e) => {
                            if (!e.target.files || e.target.files.length === 0) return;

                            const file = e.target.files[0];
                            const reader = new FileReader();

                            reader.onloadend = () => {
                                if (user)
                                    setuser({...user, avatar: reader.result as string});
                            };

                            reader.readAsDataURL(file);
                        }} />
                    </div>
                    <img onClick={()=> {
                        setIsOpen(!isOpen);
                    }} className='relative w-5 h-5 transform transition-all hover:scale-110 cursor-pointer' src={edit}/>
                    {!isOpen ? 
                    <div className='relative w-3/4 h-full'>
                        <p className='relative mt-8 Poppins-Bold border-t-4 border-gatek_light_red-600'>Informations</p>
                        <div className='flex text-xl gap-4'>
                            <p className='Poppins-SemiBold '>Pseudo :</p>
                            <p className='Poppins-Regular text-gatek_light_red-600'>{user?.username}</p>
                        </div>
                        <div className='flex text-xl gap-4'>
                            <p className='Poppins-SemiBold '>Prénom :</p>
                            <p className='Poppins-Regular text-gatek_light_red-600'>{user?.firstname}</p>
                        </div>
                        <div className='flex text-xl gap-4'>
                            <p className='Poppins-SemiBold '>Nom :</p>
                            <p className='Poppins-Regular text-gatek_light_red-600'>{user?.lastname}</p>
                        </div>
                        <div className='flex text-xl gap-4'>
                            <p className='Poppins-SemiBold '>Email :</p>
                            <p className='Poppins-Regular text-gatek_light_red-600'>{user?.email}</p>
                        </div>
                        <div className='flex text-xl gap-4'>
                            <p className='Poppins-SemiBold '>Date de naissance :</p>
                            <p className='Poppins-Regular text-gatek_light_red-600'>{user?.birthday?.toDateString()}</p>
                        </div>
                        <p className='relative mt-8 Poppins-Bold border-t-4 border-gatek_light_red-600'>Sécurité</p>
                        <div className='flex text-xl gap-4'>
                            <p className='Poppins-SemiBold '>Mot de passe :</p>
                            <p className='Poppins-Regular text-gatek_light_red-600'>*********</p>
                        </div>
                    </div>
                    :
                    <div className='relative w-3/4 h-full'>
                        <p className='relative mt-8 Poppins-Bold border-t-4 border-gatek_light_red-600'>Informations</p>
                        <div className='flex text-xl gap-4'>
                            <p className='Poppins-SemiBold '>Pseudo :</p>
                            <input className='
                                Poppins-Regular text-gatek_light_red-600 outline-none
                            ' type='text' value={user?.username} onChange={(e) => {
                                if (user)
                                    setuser({...user, username: e.target.value});
                            }}/>
                        </div>
                        <div className='flex text-xl gap-4'>
                            <p className='Poppins-SemiBold '>Prénom :</p>
                            <input className='
                                Poppins-Regular text-gatek_light_red-600 outline-none
                            ' type='text' value={user?.firstname} onChange={(e) => {
                                if (user)
                                    setuser({...user, firstname: e.target.value});
                            }}/>
                        </div>
                        <div className='flex text-xl gap-4'>
                            <p className='Poppins-SemiBold '>Nom :</p>
                            <input className='
                                Poppins-Regular text-gatek_light_red-600 outline-none
                            ' type='text' value={user?.lastname} onChange={(e) => {
                                if (user)
                                    setuser({...user, lastname: e.target.value});
                            }}/>
                        </div>
                        <div className='flex text-xl gap-4'>
                            <p className='Poppins-SemiBold '>Email :</p>
                            <input className='
                                Poppins-Regular text-gatek_light_red-600 outline-none
                            ' type='text' value={user?.email} onChange={(e) => {
                                if (user)
                                    setuser({...user, email: e.target.value});
                            }}/>
                        </div>
                        <div className='flex text-xl gap-4'>
                            <p className='Poppins-SemiBold '>Date de naissance :</p>
                            <input className='
                                Poppins-Regular text-gatek_light_red-600 outline-none
                            ' type='date' value={user?.birthday?.toDateString()} onChange={(e) => {
                                if (user)
                                    setuser({...user, birthday: new Date(e.target.value)});
                            }}/>
                        </div>
                        <p className='relative mt-8 Poppins-Bold border-t-4 border-gatek_light_red-600'>Sécurité</p>
                        <div className='flex text-xl gap-4 items-center'>
                            <p className='Poppins-SemiBold '>Password :</p>
                            <input className='
                                Poppins-Regular text-gatek_light_red-600 outline-none
                            ' type={!isPasswordOpen ? 'password' : "text"} value={user?.password} onChange={(e) => {
                                if (user)
                                    setuser({...user, password: e.target.value});
                            }}/>
                            {!isPasswordOpen ? <img className='relative h-5 w-5 object-contain transform hover:scale-110 transition-all duration-150 cursor-pointer' src={eye}
                            onClick={() => {setIsPasswordOpen(!isPasswordOpen)}} /> :
                            <img className='relative h-5 w-5 object-contain transform hover:scale-110 transition-all duration-150 cursor-pointer' src={hidden} 
                            onClick={() => {setIsPasswordOpen(!isPasswordOpen)}} />}
                        </div>
                    </div>
                    }

                </div>
                <div className='relative h-1/2 w-full'>
                    <h1 className='relative w-full Poppins-SemiBold border-b border-gatek-400'>🌍 Langue :
                        <select className='Poppins-Regular text-gatek_light_red-600 outline-none' value={language} onChange={(e) => {
                            setlanguage(e.target.value);
                        }}>
                            <option value="fr">Français</option>
                            <option value="en">English</option>
                        </select>
                    </h1>
                </div> */}
            </div>
        </MainContext.Provider>
    );
}

export default Parametres;