import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import little_comment_black from '../../../Assets/Images/little_comment_black.png';
import down_green from '../../../Assets/Images/down_green.png';
import up_red from '../../../Assets/Images/up_red.png';
import filter from '../../../Assets/Images/filter.png';
import up_black from '../../../Assets/Images/up_black.png';
import down_black from '../../../Assets/Images/down_black.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from './Navbar';
import { IArticle, IComment, IDBChapter, IOeuvre } from '../../../Interfaces/Discover';
import { BookCommentRequest, getBookComments, getChapter, getDiscoverd, getMangaInfoByID } from '../../../Api/Oeuvres';
import ObjectBox from '../../Dashboard/Subcomponent/ObjectBox';
import { getArticleByID, GetArticles, SendLikeDislikeArticle, SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getUser } from '../../../Api/User';

const ArticleBox: React.FC<IArticle> = (d) => {
    const {user} = useContext(MainContext);
    const [author, setauthor] = useState<User | undefined>(undefined);
    const [article, setArticle] = useState<IArticle | undefined>(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        if (d.author_ID)
            getUser(d.author_ID).then((u) => { setauthor(u); });

        if (user)
            getArticleByID(user, d.id).then((a) => { setArticle(a); });
    }, [user]);

    return (
        <div onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            navigate(`/article?id=${d.id}`);
        }} className='relative w-full h-1/2 bg-white rounded-2xl p-4 flex flex-col gap-4 cursor-pointer'>
            <div className='relative w-[30%] h-[10%] flex gap-4 items-center'>
                <img src={backend_url+author?.avatar} onError={handleImageError} className='h-full' />
                <h1 className='relative Montserrat-SemiBold'>{author?.username}</h1>
            </div>
            <h1 className='relative Montserrat-SemiBold text-lg'>{article?.title}</h1>
            <textarea value={article?.description} disabled={true} className='resize-none bg-transparent Montserrat-Regular text-sm h-[50%] overflow-hidden outline-none' />
            <div className='relative w-full h-[15%] flex items-center justify-between'>
                <div className='relative bg-gray-300 rounded-full w-[15%] h-full flex gap-4 items-center justify-center'>
                    <div onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (user && article) {
                            SendLikeDislikeArticle(user, article, article, true).then((response) => {
                                getArticleByID(user, d.id).then((a) => { setArticle(a); });
                            });
                        }
                    }} className='relative flex justify-between w-1/3 h-[24px] items-center duration-150 hover:opacity-60 cursor-pointer'>
                        <p className={`text-sm ${article?.likedByUser && "text-red-700"} Montserrat-SemiBold`}>{article?.likes}</p>
                        {!article?.likedByUser && <img src={up_black} className='h-full object-contain' />}
                        {article?.likedByUser && <img src={up_red} className='h-full object-contain' />}
                    </div>
                    <div onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (user && article) {
                            SendLikeDislikeArticle(user, article, article, false).then((response) => {
                                getArticleByID(user, d.id).then((a) => { setArticle(a); });
                            });
                        }
                    }} className='relative flex justify-between w-1/3 h-[24px] items-center duration-150 hover:opacity-60 cursor-pointer'>
                        <p className={`text-sm ${article?.likedByUser !== undefined && !article?.likedByUser && "text-green-500"} Montserrat-SemiBold`}>{article?.dislikes}</p>
                        {(article?.likedByUser === undefined || article?.likedByUser) && <img src={down_black} className='h-full object-contain' />}
                        {(article?.likedByUser !== undefined && !article?.likedByUser) && <img src={down_green} className='h-full object-contain' />}
                    </div>
                </div>

                <div className='relative bg-gray-300 rounded-2xl w-[8%] h-full flex gap-4 items-center justify-center p-4'>
                    <img src={little_comment_black} className='h-full object-contain' />
                    <p className='text-sm Montserrat-SemiBold'>{article?.nbComment}</p>
                </div>
            </div>
        </div>
    );
}

export default ArticleBox;