import React, {useContext, useEffect, useState} from 'react';
import arrow from '../../Assets/Images/right-arrow.png';
import logo_gatek from '../../Assets/Images/logo_gatek.png';
import cross from '../../Assets/Images/recycle-bin.png';

import { Outlet, useNavigate } from 'react-router-dom';
import { MainContext } from '../../Context/Main';
import { IArticle, IChapter, IOeuvre } from '../../Interfaces/Discover';
import { SendArticle } from './Utils/IAdd';
import { imageHeight, imageWidth, maxMB } from './AddChapter';

const Addarticle: React.FC = () => {
    const {user, setuser, current_oeuvre} = useContext(MainContext);
    const [article, setarticle] = useState<IArticle>({
        id: "0",
        title: 'Titre',
        description: 'Contenu',
        images: [],
        tags: [],
        author: user ? user.username : ""
    })
    const navigate = useNavigate();
    
    const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const maxSize = maxMB * 1024 * 1024; // Taille maximale de 5MB
            const minWidth = 800; // Largeur minimale requise
            const minHeight = 600; // Hauteur minimale requise
            const filesArray: File[] = Array.from(event.target.files);
            let loadedFiles: string[] = [];
            let validImageCount = 0; // Compter le nombre d'images valides
    
            filesArray.forEach((file, index) => {
                // Vérifier la taille du fichier
                if (file.size > maxSize) {
                    console.warn("Un fichier dépasse la taille maximale autorisée et a été ignoré.");
                    return;
                }
    
                const imgURI = URL.createObjectURL(file);
                const image = new Image();
                image.onload = () => {
                    // Vérifier les dimensions de l'image
                    // if (image.naturalWidth !== imageWidth || image.naturalHeight !== imageHeight) {
                    //     alert(`L'image doit être de ${imageWidth}x${imageHeight} pixels.`);
                    //     return;
                    // }
    
                    // Si l'image est valide, lire le contenu du fichier
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        loadedFiles.push(reader.result as string);
    
                        // Vérifier si tous les fichiers valides sont chargés
                        if (++validImageCount === filesArray.length) {
                            // Ajouter les images valides à la suite des autres dans l'état
                            setarticle(prevState => ({
                                ...prevState,
                                images: [...(prevState.images || []), ...loadedFiles]
                            }));
                        }
                    };
                    reader.readAsDataURL(file);
                };
                image.onerror = () => {
                    console.warn("Impossible de charger une image. Elle a été ignorée.");
                };
                image.src = imgURI;
            });
        }
    };

    useEffect(() => {
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full overflow-y-scroll overflow-hidden'>
                <div className='relative shadow-md shadow-black w-full h-[50%] justify-start flex gap-4 p-4 bg-gradient-to-r from-gatek_red-500 via-gatek_red-400 to-gatek_red-300'>
                    <div className='relative w-[15%] h-full'>
                        <img className='relative border-2 border-dashed w-full h-[90%] object-contain' src={article.images[0] || logo_gatek} />
                    </div>
                    <div className='relative w-[40%] h-full flex flex-col gap-2'>
                        <h1 className='Poppins-SemiBold text-xl flex w-full text-white border-b gap-4 p-2'>Titre de l'oeuvre: 
                            <input className='Inter-Regular bg-transparent outline-none' type='text' placeholder="Titre de l'oeuvre" value={article.title} onChange={(e) => {
                                setarticle({...article, title: e.target.value});
                            }}/>
                        </h1>
                        <textarea className='Inter-Regular text-sm h-full bg-transparent text-white border-b p-2' placeholder="Description de l'oeuvre" value={article.description} onChange={(e) => {
                            setarticle({...article, description: e.target.value});
                        }
                        }></textarea>
                    </div>
                    <div className='relative w-[40%] h-full flex flex-col gap-2'>
                        <div className='relative w-full h-1/2 flex flex-col gap-2'>
                            <h1 className='Poppins-SemiBold text-xl flex w-full text-white border-b gap-4 p-2'>Tags:
                                <input className='Inter-Regular bg-transparent outline-none' type='text' placeholder="Ajouter un tag" onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        let newTags = article.tags;
                                        newTags.push(e.currentTarget.value);
                                        setarticle({...article, tags: newTags});
                                        e.currentTarget.value = "";
                                    }
                                }}/>
                            </h1>
                            <div className='relative h-auto w-auto grid grid-cols-5 overflow-y-scroll gap-2'>
                                {article.tags.map((tag, index) => {
                                    return (
                                        <div className='relative flex gap-4 bg-gatek-200 items-center justify-between p-0.5'>
                                            <h1 className='Inter-Regular text-sm'>{tag}</h1>
                                            <img className='object-contain w-4 h-4 transform hover:scale-110 cursor-pointer' src={cross} onClick={() => {
                                                let newTags = article.tags;
                                                newTags.splice(index, 1);
                                                setarticle({...article, tags: newTags});
                                            }}/>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <button onClick={(e) => {
                            if (user)
                                SendArticle(user, article).then((response) => {
                                    console.log(response);
                                    navigate('../edit');
                                });
                        }} className='
                            Poppins-Regular bg-gatek-200 w-1/2 p-4 text-xl self-center rounded-lg
                            transform hover:scale-105 transition-all duration-200
                        '>Valider</button>
                    </div>
                </div>

                {/* Ajouter les images a l'articles */}
                <div className='relative w-full flex flex-col gap-4 p-2'>
                    <h1 className='Poppins-SemiBold text-3xl text-gatek-400 text-center'>Ajouter des images à l'article</h1>
                    <h1 className='Inter-Regular text-sm italic border-b border-gatek-400'>⚠️ La première image ajoutée sera l'image de couverture de l'article. Vous avez le droit à 10 images maximum.</h1>
                    <div className='relative w-full h-1/2 flex flex-col gap-4'>
                        <div className='relative w-full h-1/4 flex gap-4'>
                            <h1 className='Poppins-SemiBold text-xl text-gatek-400'>Ajouter une image</h1>
                            <input className='w-1/2 h-1/2' type="file" accept="image/*" multiple onChange={handleFilesChange} />
                        </div>
                    </div>
                </div>
                <div className='relative w-full h-full flex flex-col gap-4 p-2'>
                    <h1 className='Poppins-SemiBold text-xl text-gatek-400 border-b border-gatek-400'>Images ajoutées</h1>
                    <div className='relative w-full grid grid-cols-5 gap-4'>
                        {article.images.map((image, index) => {
                            return (
                                <div className='relative w-full flex gap-4'>
                                    <img className='object-contain w-full h-full' src={image} />
                                    <img className='absolute object-contain w-full h-full transform opacity-0 hover:opacity-100 hover:scale-110 cursor-pointer'
                                        src={cross} onClick={() => {
                                            let newImages = article.images;
                                            newImages.splice(index, 1);
                                            setarticle({...article, images: newImages});
                                        }}/>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </MainContext.Provider>
    );
}

export default Addarticle;