import React, {useContext, useEffect, useRef, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import up from '../../../Assets/Images/up_black.png';
import down from '../../../Assets/Images/down_black.png';
import filter from '../../../Assets/Images/filter.png';
import up_black from '../../../Assets/Images/up_black.png';
import down_black from '../../../Assets/Images/down_black.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from './Navbar';
import { IArticle, IComment, IDBChapter, IOeuvre } from '../../../Interfaces/Discover';
import { BookCommentRequest, getBookComments, getChapter, getDiscoverd, getMangaInfoByID } from '../../../Api/Oeuvres';
import ObjectBox from '../../Dashboard/Subcomponent/ObjectBox';
import { AddArticleComment, AddComment, getArticleByID, GetArticles, getComments, IPostCredentialsLibrary, PostCredentialsLibrary, SendLikeDislike, SendLikeDislikeArticle, SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getUser } from '../../../Api/User';

const Library: React.FC = () => {
    const {user} = useContext(MainContext);
    const [oeuvres, setOeuvres] = useState<IPostCredentialsLibrary[]>([]);
    const navigate = useNavigate();


    useEffect(() => {
        PostCredentialsLibrary().then((oeu) => { setOeuvres(oeu); });
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-screen h-screen overflow-x-hidden overflow-y-scroll bg-gray-100'>
                <div className='relative h-[10%]'>
                    <Navbar is_black={true} />
                </div>
                <div className='relative px-[8%] py-4'>
                    <h1 className='text-3xl Montserrat-Bold border-b-2 border-black'>Bibliothèque</h1>
                    <div className='relative w-full flex gap-4 flex-wrap'>
                        {oeuvres.map((oeuvre, i) => {
                            return (
                                <div onClick={() => {
                                    navigate(`../read?id=${oeuvre.manga_ID}&chapter=${oeuvre.chapter_ID}&lang=${oeuvre.language}`);
                                }} key={i} className=' w-[170px] h-[282px] rounded-xl flex flex-col cursor-pointer transform hover:scale-95 duration-150 select-none'>
                                    <img src={backend_url+oeuvre.cover} className='w-full h-[100%] object-cover rounded-2xl' onError={handleImageError} />
                                    <h1 className='text-sm Montserrat-SemiBold'>{oeuvre.mangaName}</h1>
                                    <h1 className='text-sm Montserrat-Regular text-gatek-400'>Ch. {oeuvre.chapterNumber}</h1>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </MainContext.Provider>
    );
}

export default Library;