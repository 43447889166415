import logo_gatek from '../../../Assets/Images/logo_gatek.png';

import React, {useContext, useEffect, useState} from 'react';
import { SearchResponseType } from '../../../Api/Search';
import { backend_url } from '../../../App';
import ObjectBox, { handleImageError } from './ObjectBox';
import { MainContext } from '../../../Context/Main';
import { IArticle, IOeuvre } from '../../../Interfaces/Discover';
import OeuvreModal from './OeuvreModal';
import ArticleModal from './ArticleModal';
import { getMangaInfo } from '../../../Api/Oeuvres';
import manga_ico from '../../../Assets/Images/comic.png';
import author_ico from '../../../Assets/Images/writer.png';
import team_ico from '../../../Assets/Images/partners.png';
import { useNavigate } from 'react-router-dom';

interface Props {
    searchTab: SearchResponseType | undefined;
    setOpenSearch: any;
}

const SearchSuggestions: React.FC<Props> = ({ searchTab, setOpenSearch }) => {
    const {user, setuser, setseedetails, setseearticle} = useContext(MainContext);
    const hasSuggestions = !!searchTab && (searchTab.books.length > 0 || searchTab.authors.length > 0 || searchTab.teams.length > 0);
    const navigate = useNavigate();

    return (
        <div className='relative w-full h-full p-4 bg-black rounded-xl text-xl Montserrat-Bold overflow-y-scroll'>
            {(searchTab?.books && searchTab?.books.length > 0) &&
                <div className='relative w-full flex flex-col gap-4'>
                    <h1 className='text-white'>Oeuvre(s)</h1>
                    {searchTab.books.map((oeuvre, index) => {
                        return (
                            <div onClick={(e) => {
                                navigate(`../oeuvre?id=${oeuvre.id}`);
                                window.location.reload();
                            }} key={index} className='relative flex bg-slate-800 Montserrat-SemiBold text-base items-center gap-4 rounded-xl select-none cursor-pointer'>
                                <img src={oeuvre.cover ? `${backend_url}${oeuvre.cover}` : manga_ico} alt='cover' className='w-20 h-16 object-cover' onError={handleImageError} />
                                <h1 className='text-white'>{oeuvre.name}</h1>
                            </div>
                        )
                    })}
                </div>
            }
            {(searchTab?.authors && searchTab?.authors.length > 0) &&
                <div className='relative w-full flex flex-col gap-4 mt-4'>
                    <h1 className='text-white'>Auteur(s)</h1>
                    {searchTab.authors.map((oeuvre, index) => {
                        return (
                            <div onClick={(e) => {
                                navigate(`../author?id=${oeuvre.id}`);
                                window.location.reload();
                            }} key={index} className='relative flex bg-slate-800 Montserrat-SemiBold text-base items-center gap-4 rounded-xl select-none cursor-pointer'>
                                <img src={oeuvre.icon ? `${backend_url}${oeuvre.icon}` : manga_ico} alt='cover' className='w-20 h-16 object-cover' onError={handleImageError} />
                                <h1 className='text-white'>{oeuvre.name}</h1>
                            </div>
                        )
                    })}
                </div>
            }
            {(searchTab?.teams && searchTab?.teams.length > 0) &&
                <div className='relative w-full flex flex-col gap-4 mt-4'>
                    <h1 className='text-white'>Équipe(s)</h1>
                    {searchTab.teams.map((oeuvre, index) => {
                        return (
                            <div onClick={(e) => {
                                navigate(`../team?id=${oeuvre.id}`);
                                window.location.reload();
                            }} key={index} className='relative flex bg-slate-800 Montserrat-SemiBold text-base items-center gap-4 rounded-xl select-none cursor-pointer'>
                                <img src={oeuvre.icon ? `${backend_url}${oeuvre.icon}` : manga_ico} alt='cover' className='w-20 h-16 object-cover' onError={handleImageError} />
                                <h1 className='text-white'>{oeuvre.name}</h1>
                            </div>
                        )
                    })}
                </div>
            }
            {!hasSuggestions && <h1 className='absolute Inter-Regular italic top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-2xl'>Aucun résultat</h1>}
        </div>
    );
}

export default SearchSuggestions;
