import React, {useEffect, useState} from 'react';
import logo_gatek from '../../../../Assets/Images/logo_gatek.png';
import panda_lockingfor from '../../../../Assets/Images/panda_lockingfor.webp';

const Loading: React.FC = () => {
    const [loadingText , setLoadingText] = useState<string>('Loading...');

    // each second, change the loading text
    useEffect(() => {
        const interval = setInterval(() => {
            switch (loadingText) {
                case 'Loading...':
                    setLoadingText('Loading');
                    break;
                case 'Loading':
                    setLoadingText('Loading.');
                    break;
                case 'Loading.':
                    setLoadingText('Loading..');
                    break;
                case 'Loading..':
                    setLoadingText('Loading...');
                    break;
                default:
                    setLoadingText('Loading...');
                    break;
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [loadingText]);

    return (
        <div className='relative w-full h-full overflow-hidden flex items-center justify-center'>
            <div className='relative w-1/2 h-1/2 animate-pulse rounded-full flex flex-col items-center justify-center'>
                <img className='object-contain w-1/2 m-auto rounded-full' src={panda_lockingfor} />
                <p className='Poppins-SemiBold text-2xl text-gatek-400 text-center'>{loadingText}</p>
            </div>
        </div>
    );
}

export default Loading;