import React, {useContext, useEffect, useRef, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import trad_front from '../../../Assets/Images/trad_front.png';
import left_to_right from '../../../Assets/Images/left_to_right.png';
import warning from '../../../Assets/Images/warning.png';
import up_black from '../../../Assets/Images/up_black.png';
import down_black from '../../../Assets/Images/down_black.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from '../Discovered/Navbar';
import { IComment, IDBChapter, IOeuvre } from '../../../Interfaces/Discover';
import { BookCommentRequest, getBookComments, getChapter, getDiscoverd, getMangaInfoByID } from '../../../Api/Oeuvres';
import { SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getUser } from '../../../Api/User';
import { PostMangaChapterProcessAlternate, TeamRequest, Unclaim } from '../../../Api/Team';
import { maxMB } from '../../Edit/AddChapter';

const UploadEngine: React.FC = () => {
    const {user, myteam} = useContext(MainContext);
    const [oeuvre, setOeuvre] = useState<IOeuvre | undefined>(undefined);
    const [chapters, setchapters] = useState<IDBChapter[]>([]);
    const [author, setauthor] = useState<User | undefined>(undefined);
    const [open, setOpen] = useState<IDBChapter | undefined>(undefined);
    const [images, setImages] = useState<string[]>([]);
    const fileInputRefGg = useRef<HTMLInputElement>(null);
    const handleDivClick = () => {
        fileInputRefGg.current?.click();
    };
    const navigate = useNavigate();

    const url = new URL(window.location.href);
    const id = url.searchParams.get('id');
    const chapter_id = url.searchParams.get('chapter');
    const lang = url.searchParams.get('lang');
    const competition_ID = url.searchParams.get('competition_ID');

    const handleFilesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.files);
        if (event.target.files && event.target.files.length > 0) {
            const maxSize = maxMB * 1024 * 1024; // Taille maximale de 5MB
            const minWidth = 800; // Largeur minimale requise
            const minHeight = 600; // Hauteur minimale requise
            const filesArray: File[] = Array.from(event.target.files);
            let loadedFiles: string[] = [];
            let validImageCount = 0; // Compter le nombre d'images valides
    
            filesArray.forEach((file, index) => {
                // Vérifier la taille du fichier
                if (file.size > maxSize) {
                    console.warn("Un fichier dépasse la taille maximale autorisée et a été ignoré.");
                    return;
                }
    
                const imgURI = URL.createObjectURL(file);
                const image = new Image();
                image.onload = () => {
                    // Vérifier les dimensions de l'image
                    // if (image.naturalWidth !== imageWidth || image.naturalHeight !== imageHeight) {
                    //     alert(`L'image doit être de ${imageWidth}x${imageHeight} pixels.`);
                    //     return;
                    // }
    
                    // Si l'image est valide, lire le contenu du fichier
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        loadedFiles.push(reader.result as string);
    
                        // Vérifier si tous les fichiers valides sont chargés
                        if (++validImageCount === filesArray.length) {
                            // Ajouter les images valides à la suite des autres dans l'état
                            setImages([...images, ...loadedFiles]);
                        }
                    };
                    reader.readAsDataURL(file);
                };
                image.onerror = () => {
                    console.warn("Impossible de charger une image. Elle a été ignorée.");
                };
                image.src = imgURI;
            });
        }
    };


    useEffect(() => {
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full overflow-x-hidden overflow-y-scroll bg-gray-100'>
                <div className='relative w-full h-[10%]'>
                    <div className='relative h-[100%] z-10'>
                        <Navbar is_black={true} />
                    </div>
                </div>

                <div className='relative w-full flex flex-col gap-8 items-center justify-center px-[8%] py-4 h-3/4'>
                    <div onClick={handleDivClick} className='border-2 border-dashed border-red-700 w-[170px] h-[340px] flex flex-col items-center justify-center transform duration-150 hover:scale-95 cursor-pointer'>
                        <h1 className='text-sm Montserrat-SemiBold text-red-700'>Upload</h1>
                        <input
                            type="file"
                            ref={fileInputRefGg}
                            multiple
                            onChange={handleFilesChange}
                            style={{ display: 'none' }}
                        />
                    </div>

                    {images.length > 0 && <button onClick={() => {
                        if (id && chapter_id)
                            PostMangaChapterProcessAlternate(id, chapter_id, images, competition_ID).then((res) => {
                                if (res.status === 200) {
                                    navigate(`/traduction`);
                                }
                            });
                    }} className='bg-red-700 text-white px-4 py-2 rounded-lg ml-4'>Upload</button>}
                </div>
            </div>
        </MainContext.Provider>
    );
}

export default UploadEngine;