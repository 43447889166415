import { User } from "../../../Interfaces/User";
import { IArticle, IComment, IOeuvre } from "../../../Interfaces/Discover";
import { backend_url } from "../../../App";
import { Post, PostForm, AxiosResponse, Get, Delete } from "../../../Api/Axios";

export interface IAdd {
    name: string;
    description: string;
    tags: string[];
    frontImg: any;
    imageUri?: any;
    file?: File;
    originalLanguage: string;
}

export interface IChapter {
    name_chapter: string;
    image: any;
}

export const AddManga = async (manga: IOeuvre, user: User): Promise<AxiosResponse> => {
    console.log(manga.originalLanguage);
    const formData = new FormData();
    formData.append('name', manga.name);
    formData.append('description', manga.description);
    formData.append('author_ID', user.id);
    formData.append('tags', JSON.stringify(manga.tags));
    formData.append('language', manga.originalLanguage);

    if (manga.cover) {
        await fetch(manga.cover)
        .then(response => response.blob())
        .then(blob => {
            // Créer un objet File à partir du Blob
            var file = new File([blob], manga.name + '_cover.jpg', { type: 'image/jpeg' });
            
            // Ajouter le fichier à FormData
            formData.append('image', file);
        })
        .catch(error => {
            console.error('Erreur lors de la récupération de l\'image :', error);
        });
    }

    // Afficher le contenu de l'objet FormData dans la console
    formData.forEach(function(value, key){
        console.log(key + ': ' + value);
    });
    return await PostForm('/manga/create', formData, 'multipart/form-data');
}

export const SendArticle = async (user: User , article: IArticle) => {
    // faire en sorte d'envoyer les images dans la var images[]
    const formData = new FormData();
    formData.append('title', article.title);
    formData.append('description', article.description);
    formData.append('tags', JSON.stringify(article.tags));
    formData.append('nsfw', false.toString());

    if (article.images) {
        const imageBlobs = await Promise.all(article.images.map(async (imageUri, index) => {
            const response = await fetch(imageUri);
            const blob = await response.blob();
            return new File([blob], `images_${index}.jpg`, { type: 'image/jpeg' });
        }));
    
        imageBlobs.forEach(blob => {
            formData.append('images', blob);
        });
    }

    return await PostForm('/article/add', formData, 'multipart/form-data');
    // const response = await Post('/article/add', {author_ID: user.id,title: article.title,description: article.description, tags: ["action"],nsfw: false}, 'application/json');
    // console.log(response);
    // return response;
}


export const GetArticles = async (user: User):Promise<IArticle[]> => {
    console.log("get articles", user.id);
    const response = await Get('/suggestion/20LastArticles', {user_ID: user.id, nsfw: true}, 'application/json')
    console.log('/suggestion/20LastArticles', response.data);
    return response.data;
}

export const getArticle = async (user: User, article: IArticle):Promise<IArticle> => {
    const response = await Get(`/article`, {article_ID: article.id,user_ID: user.id}, 'application/json')
    response.data.id = article.id;
    console.log(response.data);
    return response.data;
}

export const getArticleByID = async (user: User, article_id: string):Promise<IArticle> => {
    const response = await Get(`/article`, {article_ID: article_id,user_ID: user.id}, 'application/json');
    response.data.id = article_id;
    console.log(response.data);
    return response.data;
}

export const ArticleModifyRequest = async (article_id: string, title: string, content: string) => {
    const response = await Post(`/article/modify`, {article_ID: article_id, title: title, description: content}, 'application/json');
    console.log("Article Modify Request", response, 'args', {article_ID: article_id, title: title, description: content})
    return response;
}

export const ArticleDeleteRequest = async (article_id: string) => {
    const response = await Post(`/article/delete`, {article_ID: article_id}, 'application/json');
    console.log("Article Delete Request", response, 'args', {article_ID: article_id})
    return response;
}

export const getComments = async (user: User, article: IArticle):Promise<IComment[]> => {
    try {
        const response = await Get(`/article/comment`, {article_ID: article.id,user_ID: user.id}, 'application/json');
        console.log(response.data);
        if (response.status === 200)
            return response.data;
        else {
            return []
        }
    } catch (error) {
        console.error('Error fetching comments:', error);
        return [];
    }
}

export const AddComment = async (user: User, article: IArticle, comment: IComment):Promise<boolean> => {
    console.log(user.id, article.id, comment.comment);
    const response = await Post(`/article/comment`, {user_ID: user.id,article_ID: article.id,dest_ID: article.id, comment: comment.comment}, 'application/json');
    console.log(response.data);
    return response.data;
}

export const AddArticleComment = async (user: User, article: IArticle, comment: string):Promise<boolean> => {
    console.log(user.id, article.id, comment);
    const response = await Post(`/article/comment`, {user_ID: user.id,article_ID: article.id,dest_ID: article.id, comment: comment}, 'application/json');
    console.log(response.data);
    return response.data;
}

export const SendLikeDislike = async (user: User, article: IArticle, comment: IComment, like: boolean):Promise<boolean> => {
    console.log(user.id, article.id, comment.id, like);
    const response = await Post(`/article/likeDislike`, {user_ID: user.id,article_ID: article.id, dest_ID: comment.id, like: like},'application/json');
    console.log(response.data);
    return response.data;
}

export const SendLikeDislikeArticle = async (user: User, article: IArticle, dest: IArticle, like: boolean):Promise<boolean> => {
    console.log(user.id, article.id, dest.id, like);
    const response = await Post(`/article/likeDislike`, {user_ID: user.id,article_ID: article.id,dest_ID: dest.id,like: like},'application/json');
    console.log(response.data);
    return response.data;
}

export const SendLikeDislikeBook = async (user: User, book: IOeuvre, dest: IOeuvre, like: boolean):Promise<boolean> => {
    console.log(user.id, book.id, dest.id, like);
    const response = await Post(`/manga/likeDislike`, {user_ID: user.id, manga_ID: book.id, dest_ID: dest.id,like: like},'application/json');
    console.log(response.data);
    return response.data;
}

export const SendLikeDislikeBookComment = async (user: User, book: IOeuvre, dest: IComment, like: boolean):Promise<boolean> => {
    console.log(user.id, book.id, dest.id, like);
    const response = await Post(`/manga/likeDislike`, {user_ID: user.id, manga_ID: book.id, dest_ID: dest.id, like: like},'application/json');
    console.log(response.data);
    return response.data;
}

export interface IPostCredentialsLibrary {
    manga_ID: string,
    chapter_ID: string,
    cover: string,
    mangaName: string,
    language: string,
    chapterNumber: number,
    lastLecture: string;
}
export const PostCredentialsLibrary = async ():Promise<IPostCredentialsLibrary[]> => {
    const response = await Get('/credentials/library', {});
    return response.data;
}
