import React, {useContext, useEffect, useState} from 'react';
import arrow from '../../Assets/Images/right-arrow.png';
import { Outlet, useNavigate } from 'react-router-dom';
import { MainContext } from '../../Context/Main';
import { IChapter, IOeuvre } from '../../Interfaces/Discover';
import { AddManga, IAdd } from './Utils/IAdd';
import { AllWorldLanguages } from '../Dashboard/Subcomponent/TraductorEditInterface';
import logo_gatek from '../../Assets/Images/logo_gatek.png';
import { imageHeight, imageWidth, maxMB } from './AddChapter';

import cross from '../../Assets/Images/recycle-bin.png';
import { Tags } from '../../Api/Oeuvres';

const Add: React.FC = () => {
    const {user, setuser, current_oeuvre} = useContext(MainContext);
    const [oeuvre, setoeuvre] = useState<IOeuvre>({
        id: "",
        name: "",
        description: "",
        tags: [],
        cover: undefined,
        frontPageURL: "",
        originalLanguage : AllWorldLanguages[0],
    })
    const navigate = useNavigate();
    const [images, setImages] = useState<string[]>([]);
    const [addChapter , setaddChapter] = useState<boolean>(false);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const imgURI = URL.createObjectURL(file);
    
            // Vérifier la taille du fichier (exemple : 5MB max)
            const maxSize = maxMB * 1024 * 1024; // 5MB en octets
            if (file.size > maxSize) {
                alert("Le fichier est trop grand. Taille maximale : 5MB.");
                return;
            }
    
            const image = new Image();
            image.onload = () => {
                // Vérifier les dimensions de l'image
                if (image.naturalWidth !== imageWidth || image.naturalHeight !== imageHeight) {
                    alert(`L'image doit être de ${imageWidth}x${imageHeight} pixels.`);
                    return;
                }
    
                // Si tout est bon, procéder à la mise à jour de l'état
                const reader = new FileReader();
                reader.onloadend = () => {
                    // Mettre à jour l'état ici
                    // settmpchapter({...tmpchapter, cover: reader.result as string});
                    setoeuvre({...oeuvre, cover: reader.result as string});
                };
                reader.readAsDataURL(file);
            };
            image.onerror = () => {
                alert("Impossible de charger l'image. Veuillez réessayer.");
            };
            image.src = imgURI;
        }
    };

    useEffect(() => {
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full overflow-hidden flex flex-col gap-4 bg-white'>
                <div className='relative shadow-md shadow-black w-full h-1/2 justify-start flex gap-4 p-4 bg-gradient-to-r from-gatek_red-500 via-gatek_red-400 to-gatek_red-300'>
                    <div className='relative w-[15%] h-full'>
                        <img className='relative border-2 border-dashed w-full h-[90%] object-contain' src={oeuvre.cover || logo_gatek} />
                        <input className='text-xs w-full h-[10%]' type="file" accept="image/*" multiple onChange={handleFileChange} />
                    </div>
                    <div className='relative w-[40%] h-full flex flex-col gap-2'>
                        <h1 className='Poppins-SemiBold text-xl flex w-full text-white border-b gap-4 p-2'>Titre de l'oeuvre: 
                            <input className='Inter-Regular bg-transparent outline-none' type='text' placeholder="Titre de l'oeuvre" value={oeuvre.name} onChange={(e) => {
                                setoeuvre({...oeuvre, name: e.target.value});
                            }}/>
                        </h1>
                        <textarea className='Inter-Regular text-sm h-full bg-transparent text-white border-b p-2' placeholder="Description de l'oeuvre" value={oeuvre.description} onChange={(e) => {
                            setoeuvre({...oeuvre, description: e.target.value});
                        }}></textarea>
                        <h1 className='Poppins-SemiBold text-xl flex w-full text-white border-b gap-4 p-2'>Langue originale:
                            <select onClick={
                                (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                            } onChange={
                                (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setoeuvre({...oeuvre, originalLanguage: e.target.value});
                                }
                            } className='relative p-1 w-1/2 outline-none text-black Poppins-SemiBold text-sm'>
                                {AllWorldLanguages.map((langue, index) => {
                                    return (
                                        <option value={langue}>{langue}</option>
                                    );
                                })}
                            </select>
                        </h1>
                        <h1 className='Inter-Regular text-sm text-white italic'>⚠️ Les images des chapitres, comme l'image de couverture, doit être de {imageWidth}x{imageHeight}px et de maximum {maxMB}MB.</h1>
                    </div>
                    <div className='relative w-[40%] h-full flex flex-col gap-2'>
                        <div className='relative w-full h-1/2 flex flex-col gap-2'>
                            <h1 className='Poppins-SemiBold text-xl flex w-full text-white border-b gap-4 p-2'>Tags:
                                {/* <input className='Inter-Regular bg-transparent outline-none' type='text' placeholder="Ajouter un tag" onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        let newTags = oeuvre.tags;
                                        newTags.push(e.currentTarget.value);
                                        setoeuvre({...oeuvre, tags: newTags});
                                        e.currentTarget.value = "";
                                    }
                                }}/> */}
                                <select onClick={
                                    (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }
                                } onChange={
                                    (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        let newTags = oeuvre.tags;
                                        newTags.push(e.target.value);
                                        setoeuvre({...oeuvre, tags: newTags});
                                    }
                                } className='relative p-1 w-1/2 outline-none text-black Poppins-SemiBold text-sm'>
                                    {Tags.map((tag, index) => {
                                        return (
                                            <option value={tag}>{tag}</option>
                                        );
                                    })}
                                </select>
                            </h1>
                            <div className='relative h-auto w-auto grid grid-cols-5 overflow-y-scroll gap-2'>
                                {oeuvre.tags.map((tag, index) => {
                                    return (
                                        <div className='relative flex gap-4 bg-gatek-200 items-center justify-between p-0.5 px-2 rounded-full'>
                                            <h1 className='Inter-Regular text-sm'>{tag}</h1>
                                            <img className='object-contain w-4 h-4 transform hover:scale-110 cursor-pointer' src={cross} onClick={() => {
                                                let newTags = oeuvre.tags;
                                                newTags.splice(index, 1);
                                                setoeuvre({...oeuvre, tags: newTags});
                                            }}/>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                {oeuvre.name && oeuvre.cover && <button className='
                    Poppins-Regular bg-gatek-200 w-3/4 p-4 text-xl self-center rounded-lg
                    transform hover:scale-105 transition-all duration-200
                ' onClick={() => {
                    if (user)
                        AddManga(oeuvre, user).then((res) => {
                            if (res && res.status === 200) {
                                navigate('/dashboard/edit');
                            } else {
                                alert("Erreur lors de l'ajout de l'oeuvre");
                            }
                        })
                }} >Valider</button>}
            </div>
        </MainContext.Provider>
    );
}

export default Add;