import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import trad_front from '../../../Assets/Images/trad_front.png';
import crown from '../../../Assets/Images/crown.png';
import full_start from '../../../Assets/Images/full_start.png';
import empty_start from '../../../Assets/Images/empty_start.png';
import black_filter from '../../../Assets/Images/black_filter.png';
import leave_white from '../../../Assets/Images/leave_white.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from '../Discovered/Navbar';
import { IComment, IDBChapter, IOeuvre, ITranslatedBook } from '../../../Interfaces/Discover';
import { BookCommentRequest, getBookComments, getChapter, getDiscoverd, getMangaInfoByID, GetNoTradManga } from '../../../Api/Oeuvres';
import { SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getUser } from '../../../Api/User';
import { GetBooksClaimed, GetPostulatedMembers, GetTranslatorTeamMembers, IGetPostulatedMembers, ITeam, ITranslationInValidation, LeaveTeam, PostTranslatorLeaveTeam, PostTranslatorTeamPostulatedAuthorize, PostTranslatorTeamRate, RequestBookClaimed, TeamRequest, TeamRequestInfo, TranslationInValidation } from '../../../Api/Team';
import { AllWorldLanguages } from '../../Dashboard/Subcomponent/TraductorEditInterface';

const TeamMod: React.FC = () => {
    const {user} = useContext(MainContext);
    const [team, setTeam] = useState<ITeam | undefined>(undefined);
    const [open, setOpen] = useState<boolean>(false);
    const [mytraduction, setmytraduction] = useState<ITranslatedBook[]>([]);
    const [note, setnote] = useState<number>(0);
    const navigate = useNavigate();

    const url = new URL(window.location.href);
    const id = url.searchParams.get('id');

    useEffect(() => {
        if (id) {
            TeamRequestInfo(id).then((team) => {
                team.data.team_ID = id;
                GetTranslatorTeamMembers(id).then((res) => {
                    team.data.members = res;
                    setTeam(team.data);
                });
            });

            GetBooksClaimed(id).then((response) => {
                if (response.status === 200)
                    setmytraduction(response.data);
            });
        }
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            {open && <div onClick={(e) => {
                setOpen(false);
            }} className='fixed z-[60] top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center'>
                <div onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }} className='bg-white text-white w-[30%] rounded-xl flex flex-col gap-8 items-center justify-between p-4 select-none'>
                    <div className='relative w-full flex gap-4 items-center justify-center'>
                        {[1, 2, 3, 4, 5].map((i) => {
                            return (
                                <img src={(i <= note) ? full_start : empty_start} alt='start' className='h-[40px] w-[40px]' onClick={() => {
                                    setnote(i);
                                }} />
                            );
                        })}
                    </div>
                    <button onClick={() => {
                        if (team?.team_ID && note > 0) {
                            PostTranslatorTeamRate(team.team_ID, note).then((res) => {
                                if (res.status === 200) {
                                    setOpen(false);
                                    if (id)
                                        TeamRequestInfo(id).then((team) => {
                                            team.data.team_ID = id;
                                            GetTranslatorTeamMembers(id).then((res) => {
                                                team.data.members = res;
                                                setTeam(team.data);
                                            });
                                        });
                                } else {
                                    alert('Vous avez déjà noté cette équipe');
                                    setOpen(false);
                                }
                            });
                        }
                    }} className={`relative w-full h-[50px] Montserrat-SemiBold rounded-lg self-end
                        ${(note === 0) ? 'cursor-not-allowed bg-gray-300 text-gray-500' : "bg-gatek-400 text-white"}
                    `}>
                        Confirmer
                    </button>
                </div>
            </div>}
            {(id && team) && <div className='relative w-screen h-screen overflow-hidden overflow-y-scroll bg-gray-100 flex flex-col'>
                <div className='relative h-[10%]'>
                    <Navbar is_black={true} />
                </div>
                <div className='relative w-full h-full flex flex-col bg-gray-100 gap-2 px-[8%] py-4'>
                    <div className='relative h-[240px] w-full rounded-3xl overflow-hidden flex gap-4'>
                        <img src={trad_front} alt='trad_front' className='absolute h-full w-full object-cover' />
                        <div className='absolute w-full h-full bg-black bg-opacity-90'></div>
                        <div className='relative w-[20%] flex flex-col items-center justify-center'>
                            <img src={logo_gatek} className='relative h-[170px] w-[170px] rounded-full border-2 border-yellow-400' onError={handleImageError} />
                            <h1 className='absolute bottom-[10%] px-4 rounded-2xl bg-white text-xs font-bold border-2 Montserrat-Regular border-yellow-400'>{team.lvl?.level}</h1>
                        </div>
                        <div className='relative w-[50%] flex flex-col justify-between py-[2%]'>
                            <h1 className='text-white text-2xl font-bold Montserrat-Regular'>{team?.team_name}</h1>
                            <div className='relative w-full flex flex-col gap-1'>
                                <h1 className='text-gray-400 text-xs self-end italic font-bold Montserrat-Regular'>{team.lvl?.xp} / {team.lvl?.xpMax}</h1>
                                <div className='relative w-full h-[3px] bg-white rounded-full'>
                                    <div style={{width: (((team.lvl?.xp ||1) / (team.lvl?.xpMax || 1))*100)+'%'}} className='h-full bg-gatek-400 rounded-full'></div>
                                </div>
                            </div>
                        </div>
                        <div className='relative w-[30%] py-[2%]'>
                            <div className='relative w-[100%] h-full border-l-2 border-gray-500 p-2 flex flex-col gap-4'>
                                {team.creationDate && <h1 className='text-gray-400 text-xs font-bold Montserrat-Regular flex justify-between w-1/2'>crée depuis<span className='text-white text-left w-1/2'>{
                                    new Date(team.creationDate).toLocaleDateString()
                                }</span></h1>}
                                <h1 className='text-gray-400 text-xs font-bold Montserrat-Regular flex justify-between w-1/2'>oeuvre claim<span className='text-white text-left w-1/2'>{team.claimNumber}</span></h1>
                                <h1 className='text-gray-400 text-xs font-bold Montserrat-Regular flex justify-between w-1/2'>chap traduit<span className='text-white text-left w-1/2'>{team.translationDone}</span></h1>
                                <div className='relative w-[40%] flex justify-between'>
                                    <div>
                                        <h1 className='text-gray-400 text-xs font-bold Montserrat-Regular'>note auteur</h1>
                                        <h1 className='text-white text-base font-bold Montserrat-Regular'>{team.authorRating?.toFixed(1)}<span className='ml-2 text-xs'>({team.authorRatingTotal})</span></h1>
                                    </div>
                                    <div>
                                        <h1 className='text-gray-400 text-xs font-bold Montserrat-Regular'>note lecteur</h1>
                                        <h1 className='text-white text-base font-bold Montserrat-Regular'>{team.readerRating?.toFixed(1)}<span className='ml-2 text-xs'>({team.readerRatingTotal})</span></h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button onClick={() => {
                        setOpen(true);
                    }} className='relative w-[150px] h-[40px] self-end rounded-2xl bg-gatek-400 text-white font-bold Montserrat-Regular'>Noter</button>

                    <div className='relative w-full h-[16%] flex flex-col gap-4'>
                        <h1 className='text-2xl Montserrat-Bold'>A propos</h1>
                        <textarea disabled={true} className='relative w-[100%] h-full outline-none resize-none text-justify bg-transparent text-sm Montserrat-Regular' value={team?.description} readOnly />
                    </div>

                    <div className='relative w-full flex flex-wrap'>
                        <div className='relative w-[80%]'>
                            <h1 className='text-xl Montserrat-SemiBold'>Oeuvre claim</h1>
                            <div className='relative flex flex-wrap gap-8'>
                                {mytraduction.map((trad) => {
                                    return (
                                        <div className='relative h-[248px] w-[170px]'>
                                            <img src={backend_url+trad.manga_cover} alt={trad.manga_name} className='relative h-full w-full object-cover' />
                                            <h1 className='relative text-xs truncate Montserrat-SemiBold'>{trad.manga_name}</h1>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <div className='relative w-[20%] border-l-2 border-gray-300 px-4'>
                            <h1 className='text-xl Montserrat-SemiBold'>{team?.members?.length} Membre(s)</h1>
                            <div className='flex flex-col h-full overflow-y-scroll'>
                                {team?.members?.map((member) => {
                                    return (
                                        <div className='relative w-full flex gap-4'>
                                            <img src={backend_url+member.profile} className='relative h-[40px] w-[40px] rounded-full object-cover' />
                                            <h1 className='text-xs Montserrat-Regular'>{member.user_name}</h1>
                                            {(team.user_leader_id === member.user_ID) && <img src={crown} className='relative h-[20px] w-[20px]' />}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </MainContext.Provider>
    );
}

export default TeamMod;