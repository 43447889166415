import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useNavigate } from 'react-router-dom';

import { MainContext } from '../../../Context/Main';
import { IOeuvre, ITranslatedBook } from '../../../Interfaces/Discover';
import { getMyOeuvres, getMyTranslatedOeuvres } from '../../../Api/User';
import OeuvreInterface from './OeuvreInterface';
import { GetNoTradManga } from '../../../Api/Oeuvres';
import { backend_url } from '../../../App';
import { getMangaInfo } from '../../../Api/Oeuvres';
import { ITeam, TeamRequest } from '../../../Api/Team';

export const AllWorldLanguages:string[] = ["English", "Mandarin Chinese", "Hindi", "Spanish", "French", "Arabic", "Bengali", "Russian", "Portuguese", "Indonesian", "German", "Japanese", "Swahili", "Italian", "Turkish", "Korean", "Vietnamese", "Urdu", "Persian", "Dutch", "Thai", "Polish", "Punjabi", "Telugu", "Tamil", "Malay", "Kannada", "Oriya", "Gujarati", "Ukrainian", "Kurdish", "Romanian", "Hebrew", "Malayalam", "Greek", "Czech", "Swedish", "Hungarian", "Belarusian", "Finnish"];


const Edit: React.FC = () => {
    const {user, setuser, setcurrent_oeuvre, current_language, setcurrent_language, myteam, setmyteam} = useContext(MainContext);
    const [submenu, setsubmenu] = useState<string>("accueil");
    const navigate = useNavigate();

    useEffect(() => {
        if ( !myteam ) 
            return;

        const href = window.location.href;

        if (submenu === "accueil" && myteam && href.includes("/dashboard/edit")) {
            sessionStorage.setItem('team_id', myteam.team_ID);
            navigate('/dashboard/edit/teams');
        } else if (submenu === "traductions" && href.includes("/dashboard/edit") && !href.includes("translateoeuvre")) {
            navigate('/dashboard/edit/teamtraductions');
        } else if (submenu === "membres" && href.includes("/dashboard/edit")) {
            navigate('/dashboard/edit/teammembers');
        }
    }, [myteam]);

    useEffect(() => {
        const sub = sessionStorage.getItem('sub_menu');
        if (sub !== null) {
            setsubmenu(sub);
        } else {
            setsubmenu("accueil");
        }
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full'>
                {!myteam && <div className='relative w-full h-full flex flex-col gap-2'>
                    <div className='relative w-full h-1/4 flex flex-col gap-4 items-center justify-center border-b border-gatek-400'>
                        <h1 className='relative Inter-SemiBold italic text-3xl'>Vous n'avez pas encore d'équipe de traduction. C'est le moment de se lancer !</h1>
                        <button onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            navigate('/dashboard/teamcreate');
                        }} className='relative Inter-Bold text-3xl bg-gatek-400 py-2 px-8 rounded-2xl text-white shadow-md shadow-black transform transition-all duration-150 hover:scale-95 hover:bg-gatek_red-600'>Je me lance !</button>
                    </div>
                    <div className='relative w-full h-full flex flex-col'>
                        <h1 className='relative Inter-SemiBold text-3xl'>Teams qui recrutent</h1>
                    </div>
                </div> }
                {myteam && <div className='relative w-full h-full'>
                    <div className='relative w-full h-[6%] flex justify-between gap-4'>
                        <button onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setsubmenu("accueil");
                            sessionStorage.setItem('sub_menu', 'accueil');
                            sessionStorage.setItem('team_id', myteam.team_ID);
                            navigate('/dashboard/edit/teams');
                        }} className={`relative h-full w-full ${submenu === "accueil" ? ' bg-gatek_orange-600 scale-95' : ' bg-gatek-200'}
                        transform transition-all duration-200 hover:scale-95`}>Accueil</button>
                        <button onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setsubmenu("traductions");
                            sessionStorage.setItem('sub_menu', 'traductions');
                            navigate('/dashboard/edit/teamtraductions');
                        }} className={`relative h-full w-full ${submenu === "traductions" ? ' bg-gatek_orange-600 scale-95' : ' bg-gatek-200'}
                        transform transition-all duration-200 hover:scale-95`}>Traductions</button>
                        <button onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setsubmenu("membres");
                            sessionStorage.setItem('sub_menu', 'membres');
                            navigate('/dashboard/edit/teammembers');
                        }} className={`relative h-full w-full ${submenu === "membres" ? ' bg-gatek_orange-600 scale-95' : ' bg-gatek-200'}
                        transform transition-all duration-200 hover:scale-95`}>Membres</button>
                    </div>
                    <div className='mt-4 relative w-full h-full'>
                        <Outlet />
                    </div>
                </div>}
            </div>
        </MainContext.Provider>
    );
}

export default Edit;