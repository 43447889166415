import React, {useContext, useEffect, useRef, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import ico_trad_white from '../../../Assets/Images/ico_trad_white.png';
import ico_trad_black from '../../../Assets/Images/ico_trad_black.png';

import ico_lib_white from '../../../Assets/Images/ico_lib_white.png';
import ico_lib_black from '../../../Assets/Images/ico_lib_black.png';

import ico_search_white from '../../../Assets/Images/ico_search_white.png';
import ico_search_black from '../../../Assets/Images/ico_search_black.png';

import white_notif from '../../../Assets/Images/white_notif.png';
import black_notif from '../../../Assets/Images/black_notif.png';

import edition_white from '../../../Assets/Images/edition_white.png';
import edition_black from '../../../Assets/Images/edition_black.png';

import disconnect_white from '../../../Assets/Images/disconnect_white.png';
import disconnect_black from '../../../Assets/Images/disconnect_black.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import { searchingRequest, SearchResponseType } from '../../../Api/Search';
import SearchSuggestions from '../../Dashboard/Subcomponent/SearchSuggestions';
import Notifications from './Notifications';
import { GetNotificationsAuthorGetUnseen, GetTranslatorTeamCompetition, PostNotificationGetunseen } from '../../../Api/User';

export const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    // URL de votre image de remplacement
    e.currentTarget.src = logo_gatek;
};

const Navbar: React.FC<{is_black?: boolean}> = (d) => {
    const {user, setToken, setuser} = useContext(MainContext);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const color = d.is_black ? 'black' : 'white';
    const [searchInput, setSearchInput] = useState('');
    const [openSearch, setOpenSearch] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [searchTab, setSearchTab] = useState<SearchResponseType | undefined>(undefined);
    const [openNotif, setOpenNotif] = useState(false);
    const [notifNumber , setNotifNumber] = useState(0);

    useEffect(() => {
        if (openSearch && inputRef.current) {
          inputRef.current.focus();
        }
    }, [openSearch]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setSearchInput(inputValue);
        searchingRequest(inputValue).then((res: any) => {
            console.log(res.data)
            if (res.data.books.length === 0 && res.data.authors.length === 0 && res.data.teams.length === 0 )
                setSearchTab(undefined);
            else
                setSearchTab(res.data);
        }).catch((e) => {
            console.log(e);
        }); // Appeler searchRequest à chaque modification de l'input

    }

    useEffect(() => {
        // evrey 5 seconds
        const interval = setInterval(() => {
            let nb = 0;
            PostNotificationGetunseen().then((res) => {
                nb += res.length
                GetNotificationsAuthorGetUnseen().then((res) => {
                    nb += res.newArticle.length + res.newManga.length
                    GetTranslatorTeamCompetition().then((res) => {
                        nb += res.length
                        setNotifNumber(nb);
                    });
                });
            });

        }, 5000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className={`
            relative h-full w-full px-[8%] flex items-center justify-between gap-[3%]
            ${d.is_black && 'border-b-2 border-gray-400 border-opacity-50'}
        `}>
            {searchInput && <div onMouseLeave={(e) => {
                e.stopPropagation();
                setOpenSearch(false);
                setSearchInput('');
            }} className='fixed mt-[25%] right-[15%] w-[40%] z-50 h-[50%] overflow-hidden'>
                {searchInput ? <SearchSuggestions searchTab={searchTab} setOpenSearch={setOpenSearch} /> : <Outlet />}
            </div>}
            {openNotif && <div onMouseLeave={(e) => {
                e.stopPropagation();
                // setOpenNotif(false);
            }} className='fixed mt-[28%] right-[5%] w-[20%] z-50 h-[50%] overflow-hidden'>
                {openNotif ? <Notifications /> : <Outlet />}
            </div>}
            <div className='relative h-full w-1/2 flex gap-[8%] items-center p-1'>
                <h1 onClick={(e) => {
                    e.stopPropagation();
                    navigate('/discover');
                }} className={'text-'+color+' PermanentMarker-Regular select-none cursor-pointer text-3xl'}>GATEK</h1>
                <button onClick={() => {
                    navigate('/library');
                }} className={'Montserrat-Regular duration-150 flex items-center justify-center gap-2 hover:underline text-'+color}>
                    <img src={d.is_black ? ico_lib_black : ico_lib_white} className='h-5 object-contain' />
                    Bibliothèque
                </button>
                {user?.role !== "author" ? <button onClick={() => {
                    navigate('/traduction');
                }} className={'Montserrat-Regular duration-150 flex items-center justify-center gap-2 hover:underline text-'+color}>
                    <img src={d.is_black ? ico_trad_black : ico_trad_white} className='h-5 object-contain' />
                    Traduction
                </button> :<button onClick={() => {
                    navigate('/edit');
                }} className={'Montserrat-Regular duration-150 flex items-center justify-center gap-2 hover:underline text-'+color}>
                    <img src={d.is_black ? edition_black : edition_white} className='h-5 object-contain' />
                    Edition
                </button>}
            </div>
            <div className='relative h-full w-[40%] flex justify-end gap-[5%] items-center p-1'>
                {/* <img src={d.is_black ? ico_search_black : ico_search_white} className='h-6 object-contain' />
                <img src={d.is_black ? ico_notif_black : ico_notif_white} className='h-6 object-contain' /> */}
                <div className='relative w-[80%] h-full flex items-center overflow-hidden'>
                    {!openSearch && <img onClick={() => {
                        setOpenSearch(!openSearch)
                    }} className='absolute h-6 right-0 transform hover:scale-105 cursor-pointer' src={d.is_black ? ico_search_black : ico_search_white} alt='' />}
                    {openSearch && <div className='absolute slide-in right-0 w-full h-6 flex items-center justify-center'>
                        <div className='relative w-full h-full flex items-center justify-center bg-gatek_gray-100 p-0.5 px-2 rounded-lg gap-1'>
                            <img onClick={() => setOpenSearch(!openSearch)} className='h-full' src={ico_search_black} alt='' />
                            <input
                                ref={inputRef}
                                className='w-full h-full outline-none'
                                type='text'
                                placeholder='Rechercher'
                                value={searchInput}
                                onChange={handleInputChange}
                                onBlur={() => {
                                    if (searchInput === '') setOpenSearch(false);
                                }}
                            />
                        </div>
                    </div>}
                </div>
                <div onClick={() => {
                    setOpenNotif(!openNotif);
                }} className='relative h-6'>
                    {notifNumber > 0 && <div className='absolute z-10 -top-1 -right-1 w-4 h-4 bg-gatek_red-500 text-white text-xs flex items-center justify-center rounded-full'></div>}
                    <img src={!d.is_black ? white_notif : black_notif} className='h-full object-contain cursor-pointer hover:scale-105 transform duration-150' />
                </div>

                <div onClick={(e) => {
                    e.stopPropagation();
                    navigate('/parameters');
                    // setIsOpen(!isOpen);
                }}
                    onMouseEnter={()=>setIsOpen(true)}
                className='relative h-full flex items-center truncate'>
                    <img className='relative justify-end bg-gatek_gray-100 rounded-full h-8 w-8 p-0.5 cursor-pointer' src={backend_url+user?.profilePicture} alt='' onError={handleImageError} />
                </div>
                <img onClick={(e) => {
                    e.stopPropagation();
                    setToken(undefined);
                    sessionStorage.removeItem('token');
                    setuser(undefined);
                    navigate("/");
                }} src={d.is_black ? disconnect_black : disconnect_white} className='h-6 object-contain cursor-pointer' />
            </div>
            {/* {isOpen && (
                <div onClick={(e) => {e.stopPropagation(); setIsOpen(!isOpen);}} onMouseLeave={()=>setIsOpen(false)} className='absolute w-[10%] h-[100%] z-40 right-[9%] transform duration-200 fade-in-complete'>
                    <div className='relative w-full h-[28%] bg-transparent'></div>
                    <div className='w-full max-h-[55%] bg-gatek-400 flex flex-col text-sm Inter-Regular text-white truncate p-2'>
                        <button onClick={() => {
                            navigate("./parametres");
                        }} className='hover:underline text-left'>Mon compte</button>
                    </div>
                    <div className='relative h-[17%] w-full border-t bg-gatek-400 text-sm Inter-Regular text-white truncate flex items-center justify-center'>
                        <button onClick={() => {
                            setToken(undefined);
                            sessionStorage.removeItem('token');
                            setuser(undefined);
                            navigate("/");
                        }} className='hover:underline'>Se déconnecter</button>
                    </div>
                </div>
            )} */}
        </div>
    );
}

export default Navbar;