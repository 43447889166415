import React, {useContext, useEffect, useRef, useState} from 'react';
import logo_gatek from '../../Assets/Images/logo_gatek.png';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import edit from '../../Assets/Images/edit.png';
import lecture from '../../Assets/Images/lecture.png';
import discover from '../../Assets/Images/discover.png';
import search from '../../Assets/Images/search.png';
import search_black from '../../Assets/Images/search_black.png';
import coin from '../../Assets/Images/coin.png';

import { MainContext } from '../../Context/Main';
import { GetUsers } from '../../Interfaces/User';
import WatchDog from '../WatchDog/WatchDog';
import { SearchResponseType, searchingRequest } from '../../Api/Search';
import SearchModal from './Subcomponent/SearchSuggestions';
import SearchSuggestions from './Subcomponent/SearchSuggestions';
import Loading from './Subcomponent/Loading/Loading';
import OeuvreModal from './Subcomponent/OeuvreModal';
import ArticleModal from './Subcomponent/ArticleModal';
import { GetStorage } from '../../Api/Storage';
import { backend_url } from '../../App';
import { handleImageError } from './Subcomponent/Discover';

const Dashboard: React.FC = () => {
    const {user, setuser, language, seedetails, setseedetails, seearticle, setseearticle, token, setToken} = useContext(MainContext);
    const [searchInput, setSearchInput] = useState('');
    const [openSearch, setOpenSearch] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const [searchTab, setSearchTab] = useState<SearchResponseType | undefined>(undefined);
    
    const [isOpen, setIsOpen] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!openSearch) {
            setSearchInput('');
            setSearchTab(undefined);
        }
    }, [openSearch]);

    useEffect(() => {
        if (token !== undefined) {
            console.log("token", token);
            sessionStorage.setItem('token', token);

            // const user_id = sessionStorage.getItem('user_id');
            const user_id = GetStorage(token, 'user_id');
            if (user_id !== undefined) {
                GetUsers(user_id).then((res) => {
                    console.log("GetUsers", res.data);
                    if (res.data !== undefined) {
                        res.data.id = user_id;
                        setuser(res.data);
                    } else {
                        // while user is undefined, try every 500ms to get the user
                        setTimeout(() => {
                            GetUsers(user_id).then((res) => {
                                if (res.data !== undefined) {
                                    res.data.id = user_id;
                                    setuser(res.data);
                                }
                            });
                        }, 500);
                    }
                });
            } else {
                navigate("/login");
            }
        }
    }, [token]);

    useEffect(() => {
        const t = sessionStorage.getItem('token');
        console.log(t);
        if (t !== null) { setToken(t); } else { navigate("../login"); return; }

        const path = window.location.pathname;
        if (!path.includes('/dashboard')) {
            navigate("/dashboard/discover");
        }

        document.body.className = 'bg-black';
        return () => {
            document.body.className = 'bg-black';
        };
    }, [])

    useEffect(() => {
        if (openSearch && inputRef.current) {
          inputRef.current.focus();
        }
      }, [openSearch]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setSearchInput(inputValue);
        searchingRequest(inputValue).then((res: any) => {
            console.log(res.data)
            if (res.data.books.length === 0 && res.data.authors.length === 0 && res.data.teams.length === 0 )
                setSearchTab(undefined);
            else
                setSearchTab(res.data);
        }).catch((e) => {
            console.log(e);
        }); // Appeler searchRequest à chaque modification de l'input
    }
    return (
        <MainContext.Provider value={useContext(MainContext)}>
            {user ? <div onClick={() => setIsOpen(false)} className='relative select-none w-full h-full flex flex-col'>
                <div className='relative z-10 w-full h-[5%] bg-gatek_red-500 flex items-center justify-between overflow-hidden shadow-md px-2'>

                    <div onClick={(e) => {
                        e.stopPropagation();
                        navigate("/dashboard/discover", { replace: true });
                        setOpenSearch(false);
                    }} className='relative w-[10%] h-full flex items-center justify-center cursor-pointer truncate'>
                        <img className='h-full object-contain' src={logo_gatek} />
                        <p className='PermanentMarker-Regular text-gatek_gray-100 text-4xl'>GATEK</p>
                    </div>

                    <div className='relative w-[20%] grid grid-cols-3 overflow-hidden truncate'>
                        <button disabled={location.pathname.includes("discover")} onClick={() => {navigate("./discover"); setOpenSearch(false);}} className={`w-full truncate ${location.pathname.includes("discover") ? " Inter-Bold" : "Inter-Regular cursor-pointer  hover:opacity-70"} text-gatek_gray-100 text-sm`}>
                            Nouveautés
                        </button>
                        <button disabled={location.pathname.includes("library")} onClick={() => {navigate("./library"); setOpenSearch(false);}} className={`w-full truncate ${location.pathname.includes("library") ? " Inter-Bold" : "Inter-Regular cursor-pointer  hover:opacity-70"} text-gatek_gray-100 text-sm`}>
                            Bibliothèque
                        </button>
                        {
                        <button disabled={location.pathname.includes("edit")} onClick={() => {navigate("./edit"); setOpenSearch(false);}} className={`w-full truncate ${location.pathname.includes("edit") ? " Inter-Bold" : "Inter-Regular cursor-pointer  hover:opacity-70"} text-gatek_gray-100 text-sm`}>
                            Édition
                        </button>}
                    </div>

                    <div className='relative w-[50%] h-full flex items-center overflow-hidden'>
                        {!openSearch && <img onClick={() => {
                            setOpenSearch(!openSearch)
                        }} className='absolute h-[40%] right-0 transform hover:scale-105 cursor-pointer' src={search} alt='' />}
                        {openSearch && <div className='absolute slide-in right-0 w-[50%] h-[50%] flex items-center justify-center'>
                            <div className='relative w-full h-full flex items-center justify-center bg-gatek_gray-100 p-0.5 px-2 rounded-lg gap-1'>
                                <img onClick={() => setOpenSearch(!openSearch)} className='h-full' src={search_black} alt='' />
                                <input
                                    ref={inputRef}
                                    className='w-full h-full outline-none'
                                    type='text'
                                    placeholder='Rechercher'
                                    value={searchInput}
                                    onChange={handleInputChange}
                                    onBlur={() => {
                                        if (searchInput === '') setOpenSearch(false);
                                    }}
                                />
                            </div>
                        </div>}
                    </div>
                    <div className='relative w-[15%] flex justify-between items-center'>
                        <div
                            onClick={(e) => {e.stopPropagation(); setIsOpen(!isOpen);}}
                            onMouseEnter={()=>setIsOpen(true)}
                        className='relative w-[50%] h-full flex items-center truncate'>
                            <img className='relative justify-end bg-gatek_gray-100 rounded-full h-8 w-8 p-1 cursor-pointer' src={backend_url+user?.avatar} alt='' onError={handleImageError} />
                        </div>

                        <div className='w-[50%] truncate border-2 rounded-2xl border-gatek_red-900 px-4 flex justify-between items-center bg-gatek-200 bg-opacity-70 Inter-Regular'>
                            {/* <WatchDog /> */}
                            <img className='h-6 w-6' src={coin} alt='' />
                            <h1 className='text-white text-sm'>{user.coins} Coin(s)</h1>
                        </div>
                    </div>
                </div>
                {seedetails && <div onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setseedetails(undefined);
                }} className='fixed top-0 left-0 w-full h-full z-10 flex justify-center items-center'>
                    <div className='absolute w-full h-full bg-black fade-in'>

                    </div>
                    <OeuvreModal {...seedetails} />
                </div>}
                {seearticle && <div onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setseearticle(undefined);
                }} className='fixed top-0 left-0 w-full h-full bg-gatek_red-100 bg-opacity-50 z-10 flex justify-center items-center'>
                    <ArticleModal {...seearticle} />
                </div>}

                <div className='relative w-full h-[95%] overflow-auto'>
                    {searchInput ? <SearchSuggestions searchTab={searchTab} setOpenSearch={setOpenSearch} /> : <Outlet />}
                </div>
                {isOpen && (
                    <div onClick={(e) => {e.stopPropagation(); setIsOpen(!isOpen);}} onMouseLeave={()=>setIsOpen(false)} className='absolute w-[10%] h-[20%] z-40 right-[9%] transform duration-200 fade-in-complete'>
                        <div className='relative w-full h-[28%] bg-transparent'></div>
                        <div className='w-full max-h-[55%] bg-gatek-400 flex flex-col text-sm Inter-Regular text-white truncate p-2'>
                            <button onClick={() => {
                                navigate("./parametres");
                            }} className='hover:underline text-left'>Mon compte</button>
                        </div>
                        <div className='relative h-[17%] w-full border-t bg-gatek-400 text-sm Inter-Regular text-white truncate flex items-center justify-center'>
                            <button onClick={() => {
                                setToken(undefined);
                                sessionStorage.removeItem('token');
                                setuser(undefined);
                                navigate("/");
                            }} className='hover:underline'>Se déconnecter</button>
                        </div>
                    </div>
                )}
            </div> : <Loading />}
        </MainContext.Provider>
    );
}

export default Dashboard;