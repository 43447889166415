import React, {useEffect, useState} from 'react';
import logo_gatek from '../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import all_gatek_logo from '../../Assets/Images/all_gatek_logo.png';
import login from '../../Assets/Images/login.png';
import Clay from '../../Assets/Images/Clay.png';
import Phones from '../../Assets/Images/Phones.png';
import Vector from '../../Assets/Images/Vector.png';
import rawkuma from '../../Assets/Images/1_rawkuma.png';
import mail from '../../Assets/Images/mail.png';
import instagram from '../../Assets/Images/instagram.png';

import WatchDog from '../WatchDog/WatchDog';
import ProjectInformation from './ProjectInformation';
import Team from './Team';
import Contact from './Contact';
import Timeline from './Timeline';
import pandaroux_livre from '../../Assets/Images/pandaroux_livre.webp';
import { getDiscoverd } from '../../Api/Oeuvres';
import { IOeuvre } from '../../Interfaces/Discover';
import { backend_url } from '../../App';
import { handleImageError } from '../Dashboard/Subcomponent/ObjectBox';

const Home: React.FC = () => {
    const navigate = useNavigate();
    const [discover_oeuvres, setdiscover_oeuvres] = useState<IOeuvre[]>([]);
    const Subs: {x: number, y: number, z :number, rotation: number}[] = [
        {x: -5, y: -5, z:10, rotation: 20},
        {x: -5, y: 30, z:50, rotation: 3},
        {x: -3, y: 60, z:10, rotation: -30},
        {x: -2, y: 80, z:10, rotation: 10},
        {x: 11, y: 85, z:10, rotation: 15},
        {x: 25, y: 85, z:10, rotation: -20},
        {x: 33, y: 85, z:50, rotation: 20},
        {x: 47, y: 85, z:10, rotation: -5},
        {x: 59, y: 90, z:10, rotation: 3},
        {x: 72, y: 75, z:50, rotation: -10},
        {x: 84, y: 75, z:40, rotation: 10},
        {x: 90, y: 65, z:30, rotation: -30},
        {x: 90, y: 40, z:20, rotation: -3},
        {x: 92, y: 15, z:10, rotation: 10},
        {x: 90, y: -15, z:10, rotation: -30},

    ];

    const handleDownload = async () => {
        console.log('download');
        const link = document.createElement('a');
        // Chemin relatif du fichier depuis la position de ce script
        link.href = require('../../Assets/APK/GatekApp.apk');
        link.download = 'GatekApp.apk';  // Nom du fichier à sauvegarder
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    useEffect(() => {
        getDiscoverd().then((new_oeuvres) => {
            setdiscover_oeuvres(new_oeuvres);
        });
    }, []);

    return (
        <div className='relative h-screen w-screen overflow-x-hidden overflow-y-scroll'>
            <div className='relative h-screen w-screen flex justify-center items-center overflow-hidden bg-gray-100'>
                <div className='relative top-0 left-0 w-1/3 h-1/3 flex flex-col items-center justify-center'>
                    <img src={all_gatek_logo} className='relative top-0 left-0 h-[90%] object-contain' />
                    <button onClick={() => {
                        navigate('/register');
                    }} className=' Inter-Regular hover:bg-gatek_red-700 duration-150 w-[30%] px-8 flex items-center justify-center p-1 gap-2 bg-gatek-400 rounded-2xl text-white'>
                        COMMENCER À LIRE
                    </button>
                </div>
                <h1 className='absolute top-[2%] left-[14%] PermanentMarker-Regular text-4xl'>Gatek</h1>
                <button onClick={() => {
                    navigate('/login');
                }} className='absolute top-[2%] right-[14%] Inter-Regular hover:bg-gatek_red-700 duration-150 flex items-center justify-center p-2 px-8 gap-2 bg-gatek-400 rounded-full text-white'>
                    <img src={login} className='h-5 object-contain' />
                    se connecter
                </button>

                {Subs.map((sub, index) => {
                    return (
                        <div style={
                            {
                                top: `${sub.y}%`,
                                left: `${sub.x}%`,
                                zIndex: sub.z,
                                transform: `rotate(${sub.rotation}deg)`,
                                width: '14%',
                                height: '40%',
                            }
                        } className='absolute bg-black border-4 rounded-2xl'>
                            <img src={discover_oeuvres[index] && backend_url+discover_oeuvres[index].cover} onError={handleImageError} className='w-full h-full object-cover' />
                        </div>
                    );
                })}
            </div>
            <div className='relative h-1/2 w-screen flex justify-between items-center overflow-hidden'>
                <img src={Clay} className='relative object-contain' />
                <div className='relative w-1/2 flex flex-col gap-8'>
                    <h1 className='Poppins-Bold text-2xl'>Une librairie numérique orienté sur <p className='text-gatek_red-500'>les mangas méconnues</p></h1>
                    <ul className='list-disc pl-4 space-y-4 Poppins-Regular ml-4'>
                        <li><span className='text-gatek_red-500'>Découvrez</span> des mangas moins connus et uniques</li>
                        <li><span className='text-gatek_red-500'>Traduisez</span> des œuvres dans la langue de votre choix</li>
                        <li><span className='text-gatek_red-500'>Partagez</span> votre avis et interagissez avec la communauté</li>
                        <li><span className='text-gatek_red-500'>Publiez</span> votre œuvre pour atteindre un public plus large</li>
                    </ul>
                </div>
            </div>
            <div className='relative h-2/3 w-screen flex justify-between items-center overflow-hidden bg-gradient-to-b from-gray-300 to-gray-100'>
                <div className='relative left-[15%] w-1/5 flex flex-col gap-4'>
                    <h1 className='Poppins-Bold text-2xl text-gatek_red-500'>android</h1>
                    <h1 className='Poppins-Bold text-2xl'>Découvrez notre site en version mobile.</h1>
                    <button onClick={() => {handleDownload()}} className='w-1/2 Inter-Regular hover:bg-gatek_red-700 duration-150 flex items-center justify-center p-2 gap-2 bg-gatek-400 rounded-full text-white'>
                        <img src={Vector} className='h-5 object-contain' />
                        télécharger
                    </button>
                </div>
                <img src={Phones} className='relative object-contain mt-[10%]' />

            </div>

            <div className='relative h-full w-full overflow-hidden bg-gatek_red-600 p-4'>
                <div className='absolute left-[25%] w-[65%] flex flex-col gap-4 text-white'>
                    <h1 className='Poppins-SemiBold text-3xl'>Une intelligence artificielle spécialisée dans la detection de texte</h1>
                    <h1 className='Poppins-Regular text-sm'>Notre intelligence artificielle est conçue pour détecter et analyser du texte sur les pages de manga, facilitant le travaille des traducteurs. Fiable et intuitive, elle est capable de remplacer le texte originale par la traduction</h1>
                </div>
                <img src={rawkuma} className='absolute object-contain w-[20%]' />
                <img src={rawkuma} className='absolute object-contain w-[20%] right-4 bottom-4' />
            </div>

            <div className='relative h-[10%] w-full overflow-hidden bg-white flex items-center justify-between px-[15%]'>
                <h1 className='PermanentMarker-Regular text-4xl text-gatek_red-500'>Gatek</h1>
                <div className='relative flex w-[8%] justify-between items-center'>
                    <img src={mail} className='h-full object-contain' />
                    <img src={instagram} className='h-full object-contain' />
                </div>
            </div>
        </div>
    );
}

export default Home;