import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useNavigate } from 'react-router-dom';

import { MainContext } from '../../../Context/Main';
import { IChapter, IDBChapter, IOeuvre } from '../../../Interfaces/Discover';
import { getMyOeuvres } from '../../../Api/User';
import Add from '../../Edit/Add';
import { AddChapterToDB, DeleteMangaRequest, GetPossibleTranslation, ModifyMangaRequest, getChapter, getMangaInfo, getMangaInfoByID } from '../../../Api/Oeuvres';
import { backend_url } from '../../../App';
import ObjectBox from './ObjectBox';
import edit from '../../../Assets/Images/edit.png';
import bin from '../../../Assets/Images/recycle-bin - Copie.png';


const OeuvreInterface: React.FC = () => {
    const {user, setuser, current_oeuvre, setcurrent_oeuvre} = useContext(MainContext);
    const [addchapter, setaddchapter] = useState<boolean>(false);
    const navigate = useNavigate();
    const [error, seterror] = useState<string>('');
    const [chapters, setchapters] = useState<IDBChapter[]>([]);
    const [modify, setmodify] = useState<boolean>(false);
    const [tmpchapter, settmpchapter] = useState<IChapter>({
        id: 0,
        title: 'Titre',
        description: 'Description',
        cover: undefined,
        pages: [],
        oeuvre_id: 0,
    });

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const filesArray: File[] = Array.from(event.target.files);  // Convertir FileList en tableau
            const imgURIs = filesArray.map(file => URL.createObjectURL(file)); // Créer des URI pour chaque fichier
            const readers = filesArray.map(file => new FileReader()); // Créer un FileReader pour chaque fichier
    
            let loadedFiles: string[] = [];
    
            readers.forEach((reader, index) => {
                reader.onloadend = () => {
                    loadedFiles.push(reader.result as string);
    
                    // Vérifier si tous les fichiers sont chargés
                    if (loadedFiles.length === filesArray.length) {
                        settmpchapter(prevState => ({
                            ...prevState,
                            files: filesArray,
                            imageUris: imgURIs,
                            pages: loadedFiles
                        }));
                    }
                };
                reader.readAsDataURL(filesArray[index]);
            });
        }
    };

    useEffect(() => {
        let current_oeuvre_id = sessionStorage.getItem('current_oeuvre_id');
        if (current_oeuvre_id !== null) {
            getMangaInfoByID(current_oeuvre_id).then((ovuer) => {
                setcurrent_oeuvre(ovuer);
            });
            getChapter(current_oeuvre_id, "original", user?.id).then((chapter) => {
                setchapters(chapter);
            });
        }
    }, []);

    return (
        <div className='relative w-full h-full gap-4 justify-between items-center'>
            <div className='relative shadow-md shadow-black w-full h-1/2 justify-start flex gap-4 p-4 bg-gradient-to-r from-gatek_red-500 via-gatek_red-400 to-gatek_red-300'>
                <img className='relative w-1/6 h-full object-contain' src={backend_url+current_oeuvre?.cover || logo_gatek} />
                <div className='relative w-1/2 h-full flex flex-col gap-2'>
                    {!modify ? <h1 className='Poppins-SemiBold text-4xl bg-transparent text-white border-b flex justify-between items-center'>{current_oeuvre?.name}
                        <p className='Inter-Regular text-xl'>{chapters.length} Chapitre(s)</p>
                    </h1> : <input onChange={(e) => {
                        if (current_oeuvre)
                            setcurrent_oeuvre({...current_oeuvre, name: e.target.value});
                    }} className='Poppins-SemiBold border border-dashed  text-4xl bg-transparent text-white border-b' type='text' value={current_oeuvre?.name} />}
                    {!modify ? <h1 className='Inter-Regular text-base bg-transparent text-white'>{current_oeuvre?.description}</h1>
                    : <textarea onChange={(e) => {
                        if (current_oeuvre)
                            setcurrent_oeuvre({...current_oeuvre, description: e.target.value});
                    }} className='Inter-Regular border border-dashed h-1/2 text-base bg-transparent text-white'>{current_oeuvre?.description}</textarea>}
                </div>
                <div className='absolute w-1/12 right-4 bottom-4 flex gap-2 justify-between'>
                    <img onClick={(e) => {
                        if (modify && current_oeuvre) {
                            console.log("modify")
                            ModifyMangaRequest(current_oeuvre)
                            // Update oeuvre in server
                        }
                        setmodify(!modify)
                    }} className='relative transform hover:scale-110 cursor-pointer duration-150 w-14 h-14 object-contain' src={edit} />
                    <img onClick={(e) => {
                        // Delete oeuvre in server
                        if (current_oeuvre) {
                            DeleteMangaRequest(current_oeuvre?.id)
                            navigate('../edit');
                        }
                    }
                    } className='relative transform hover:scale-110 cursor-pointer duration-150 w-14 h-14 object-contain' src={bin} />
                </div>
            </div>
            <div className='relative w-full h-full px-8'>
                {current_oeuvre && <div className='relative w-full h-[75%]'><ObjectBox label="Chapitres" oeuvres={[current_oeuvre]} chapters={chapters} author={true} white={true} /></div>}
            </div>
        </div>        
    );
}

export default OeuvreInterface;