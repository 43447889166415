import React, {useContext, useEffect, useState} from 'react';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import redcross from '../../../Assets/Images/add.png';
import bin from '../../../Assets/Images/recycle-bin - Copie.png';
import add_tag from '../../../Assets/Images/add_tag.png';
import down_black from '../../../Assets/Images/down_black.png';
import up from '../../../Assets/Images/up_black.png';
import down from '../../../Assets/Images/down_black.png';

import { backend_url } from '../../../App';
import { MainContext } from '../../../Context/Main';
import Navbar, { handleImageError } from '../Discovered/Navbar';
import { IArticle, IComment, IDBChapter, IOeuvre } from '../../../Interfaces/Discover';
import { BookCommentRequest, DeleteMangaRequest, getBookComments, getChapter, getDiscoverd, getMangaInfoByID, GetPossibleTranslation, ModifyMangaRequest, Tags } from '../../../Api/Oeuvres';
import { ArticleModifyRequest, getArticleByID, SendLikeDislikeBook, SendLikeDislikeBookComment } from '../../Edit/Utils/IAdd';
import { User } from '../../../Interfaces/User';
import { getMyArticles, getMyOeuvres, getUser } from '../../../Api/User';

const ModifyArticle: React.FC = () => {
    const {user} = useContext(MainContext);
    const [article, setArticle] = useState<IArticle | undefined>(undefined);
    const navigate = useNavigate();

    const url = new URL(window.location.href);
    const id = url.searchParams.get('id');

    useEffect(() => {
        if (!id) {
            navigate('/discover');
            return;
        }

        if (user)
            getArticleByID(user, id).then((oeu) => {
                setArticle(oeu);
            });
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full bg-gray-100 overflow-x-hidden'>
                <div className='relative h-[10%]'>
                    <Navbar is_black={true} />
                </div>
                <div className='relative px-[8%] flex gap-8 flex-col py-4 items-end'>
                    <input type='text' onChange={(e) => {
                        if (article)
                            setArticle({...article, title: e.target.value});
                    }} className='w-[100%] h-[50px] bg-white outline-none rounded-3xl p-2 border-2 border-gray-300' value={article?.title} />

                    <textarea onChange={(e) => {
                        if (article)
                            setArticle({...article, description: e.target.value});
                    }} className='w-[100%] h-[600px] resize-none outline-none bg-white rounded-3xl p-2 border-2 border-gray-300' value={article?.description} />

                    <button onClick={() => {
                        if (id && article)
                        ArticleModifyRequest(id, article?.title, article?.description).then((res) => {
                            if (res.status === 200)
                                navigate('/edit');
                        });
                    }} className='w-[117px] Montserrat-Regular text-sm h-[40px] bg-gatek-400 text-white rounded-3xl p-2 border-2 border-gray-300'>Modifier</button>
                </div>

            </div>
        </MainContext.Provider>
    );
}

export default ModifyArticle;