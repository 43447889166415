import React, {useContext, useEffect, useState} from 'react';
import red_check from '../../../Assets/Images/red_check.png';
import sendmail from '../../../Assets/Images/sendmail.png';
import disconnect from '../../../Assets/Images/disconnect.png';
import black_coin from '../../../Assets/Images/black_coin.png';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import { MainContext } from '../../../Context/Main';
import { GetAuthorSupportList, GetSubscriptions, IGetAuthorSupportList, ISubscription, modifyUserInfo, PostAuthorSupportUnsupport, PostCredentialsPasswordModify, StripePay, SubscriptionType } from '../../../Api/User';
import { backend_url } from '../../../App';
import { handleImageError } from './Discover';
import Navbar from '../../Intra/Discovered/Navbar';
import { GetUsers } from '../../../Interfaces/User';
import { AllWorldLanguages } from './TraductorEditInterface';
import { PostCredentialsEmailExist, PostCredentialsEmailSecretCode, PostCredentialsEmailVerify } from '../../../Api/Register';

const ModifyPassword: React.FC = () => {
    const {user, setuser, language, setlanguage} = useContext(MainContext);
    const [password, setPassword] = useState<string | undefined>(undefined);
    const [passwordConf, setPasswordConf] = useState<string | undefined>(undefined);
    const navigate = useNavigate();

    useEffect(() => {
        if (user)
            setPassword(user.password);
    }, []);

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-full overflow-y-auto bg-gray-100'>
                <div className='relative h-[10%]'>
                    <Navbar is_black={true} />
                </div>
                <div className='relative flex flex-col gap-4 py-4 px-[8%]'>
                    <div className='relative w-full h-full flex flex-col gap-4 justify-center items-center'>
                        <h1 className='text-3xl Montserrat-Bold border-b border-black'>Modifier votre email</h1>
                        <input name='passwprd' type='text' placeholder="Votre mot de passe acutel" value={password} onChange={(e) => {
                            setPassword(e.target.value);
                        }} className='relative Inter-Regular outline-none text-sm w-full h-full border-2 bg-white rounded-xl p-2' />
                        <input name='passwprd' type='text' placeholder="Confirmer votre mot de passe" value={passwordConf} onChange={(e) => {
                            setPasswordConf(e.target.value);
                        }} className='relative Inter-Regular outline-none text-sm w-full h-full border-2 bg-white rounded-xl p-2' />
                        <button className={`w-[170px] h-[55px] Montserrat-Regular rounded-xl
                            ${!password || !passwordConf ? 'opacity-50 cursor-not-allowed border-2 border-gray-500 bg-gray-300 text-gray-500' : 'bg-gatek-400 text-white '}
                        `} onClick={() => {
                            if (password && passwordConf && user) {
                                PostCredentialsPasswordModify(password, passwordConf).then((res) => {
                                    if (res.status === 200 && user) {
                                        GetUsers(user.id).then((res) => {
                                            if (res.status === 200 && res.data) {
                                                setuser(res.data);
                                                navigate('/parameters');
                                            }
                                        });
                                    }
                                });
                            }
                        }}>Confirmer</button>
                    </div>
                </div>
            </div>
        </MainContext.Provider>
    );
}

export default ModifyPassword;